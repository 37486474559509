import { CenteredGrid } from '@dtk/public-website-ui-components';
import { Container } from '@dtk/ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const CenteredGridBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="centered-grid">
    <Container backgroundColor={blok.theme}>
      <CenteredGrid
        theme={blok.theme}
        headingType={blok.headingType}
        headline={blok.headline}
        preline={blok.preline}
        subline={blok.subline}
        buttonLink={blok.buttonLink}
        buttonText={blok.buttonText}
        items={blok.items}
      />
    </Container>
  </div>
);

export default CenteredGridBlok;

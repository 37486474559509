import { BrochureForm, InfoBrochureFormSectionMark } from '@dtk/public-website-ui-components';

import { HeaderText } from '@dtk/ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const InfoBrochureFormSectionBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="info-brochure-form-section">
    <div className="bg-white pt-0 pb-0 md:pb-[8rem] md:pt-[3.75rem]">
      <div className={`bg-${blok.theme} mx-auto max-w-[51rem] shadow-md`}>
        <HeaderText
          overline={blok.overline}
          headline={blok.headline}
          subtitle={blok.subtitle}
          theme={blok.theme}
          headingType="h2"
        />
        <div className="flex flex-col">
          {blok.marks.map((mark, key) => (
            <InfoBrochureFormSectionMark
              key={key}
              text={mark.text}
              icon={mark.icon?.filename}
              iconAlt={mark.icon.alt}
            />
          ))}
        </div>
        <BrochureForm theme={blok.theme} />
      </div>
    </div>
  </div>
);

export default InfoBrochureFormSectionBlok;

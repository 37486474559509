import { CheckCircleIcon } from '@heroicons/react/solid';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import { ImageProps, RichtextContent } from '../utils';
import { StoryblokIcon } from '../01_elements';

interface PartnerCardContentProps {
  cardImage: ImageProps;
  cardOverline: string;
  cardHeadline: string;
  features: { description: string }[];
  noteText: string;
  partnerForm: SbBlokData[];
  feedbackText: string;
}

interface PartnerCardProps {
  card: PartnerCardContentProps;
}

export interface ProductDetailProps extends PartnerCardContentProps {
  overline: string;
  headline: string;
  description: string;
  discountText: string;
  discountValue: string;
  content: unknown;
  body: SbBlokData[];
}

const PartnerCheckoutCard = ({ card }: PartnerCardProps) => {
  return (
    <div
      key={`${card.cardHeadline}-checkout-card`}
      className="col-span-12 mt-5 flex flex-col lg:col-span-4 lg:col-start-9 lg:mx-auto lg:mt-2 lg:max-w-sm"
    >
      <div className="border-coolGray-200 rounded-2xl border bg-white p-8 pt-4 shadow-xl lg:sticky lg:top-14 lg:max-h-[90vh] lg:overflow-y-auto lg:pt-0">
        {card.cardImage?.filename && (
          <StoryblokIcon
            iconSource={card.cardImage.filename}
            className="mx-auto max-h-[13.75rem] w-full max-w-[13.75rem] rounded-t-2xl object-cover"
            alt={card.cardImage.alt}
            loading="eager"
          />
        )}
        <p className="text-gold-gold my-2 text-sm uppercase tracking-[.2rem]">{card.cardOverline}</p>
        <h3 className="font-headline-medium text-2xl">{card.cardHeadline}</h3>

        {card.features && (
          <ul className="my-3 space-y-4">
            {card.features.map((feature) => (
              <li key={feature.description} className="flex">
                <CheckCircleIcon className="text-gold h-6 w-6 flex-shrink-0" aria-hidden="true" />
                <p className="ml-3">{feature.description}</p>
              </li>
            ))}
          </ul>
        )}

        {card.noteText && (
          <>
            <p className="font-body-bold mb-4 text-lg">Hinweis:</p>
            <p>{card.noteText}</p>
          </>
        )}
        {card.partnerForm && card.partnerForm.length === 1 && (
          <StoryblokComponent key={card.partnerForm[0]._uid} blok={card.partnerForm[0]} />
        )}
        {card.feedbackText && (
          <>
            <p className="font-body-bold mb-4 text-lg">Rückfragen:</p>
            <p>{card.feedbackText}</p>
          </>
        )}
      </div>
    </div>
  );
};

export function ProductDetailSection({ ...props }: React.PropsWithChildren<ProductDetailProps>) {
  const { overline, headline, description, discountText, discountValue, content, body, children } = props;
  const card: PartnerCardContentProps = {
    cardImage: props.cardImage,
    cardOverline: props.cardOverline,
    cardHeadline: props.cardHeadline,
    features: props.features,
    noteText: props.noteText,
    partnerForm: props.partnerForm,
    feedbackText: props.feedbackText,
  };

  return (
    <div className="text-navy-navy grid grid-cols-12 px-5 py-14 lg:relative lg:h-full xl:mx-auto xl:max-w-7xl">
      <div className="col-span-12 lg:col-span-7">
        {overline && <p className="text-gold-gold mb-2 text-sm uppercase tracking-[.2rem]">{overline}</p>}
        {headline && <h1 className="font-headline-medium mb-4 text-2xl leading-6 md:text-3xl">{headline}</h1>}
        {description && <p className="font-body-bold mb-2 text-lg">{description}</p>}
        {discountText && (
          <div className="flex flex-row items-end space-x-1">
            <p>{discountText}</p>
            {discountValue && <p className="text-coral font-body-bold text-3xl">{discountValue}</p>}
          </div>
        )}
        <div className="my-10 hidden lg:block">
          <RichtextContent content={content} />
          {body
            ? body.map((nestedBlok: SbBlokData) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)
            : children}
        </div>
      </div>

      <PartnerCheckoutCard card={card} />

      <div className="col-span-12 mt-10 mb-7 block lg:hidden">
        <RichtextContent content={content} />
        {body
          ? body.map((nestedBlok: SbBlokData) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)
          : children}
      </div>
    </div>
  );
}

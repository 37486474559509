import classNames from 'classnames';
import { Fragment } from 'react';
import { StoryblokIcon } from '@dtk/ui-components';
import { Menu, Transition } from '@headlessui/react';

interface NavigationButtonComponentProps {
  iconSource: string;
  iconAlt: string;
  buttonName: string;
}

export const NavigationButtonComponent = ({ iconSource, iconAlt, buttonName }: NavigationButtonComponentProps) => {
  return (
    <div>
      <Menu.Button className="hidden rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 xl:flex xl:items-center">
        <span className="sr-only">{iconAlt}</span>
        <StoryblokIcon iconSource={iconSource} alt={iconAlt} width={14} />
        <p className="text-navy ml-2 text-base font-normal">{buttonName}</p>
      </Menu.Button>
      <Menu.Button className="grid grid-cols-1 justify-items-center gap-y-0.5 rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 xl:hidden">
        <span className="sr-only">{iconAlt}</span>
        <StoryblokIcon iconSource={iconSource} alt={iconAlt} width={17} />
        <p className="text-navy text-sm font-normal">{buttonName}</p>
      </Menu.Button>
    </div>
  );
};

interface MenuItemComponentProps {
  itemLink?: string;
  itemText?: string;
}

const MenuItemComponent = ({ itemLink, itemText }: MenuItemComponentProps) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <a
          href={itemLink}
          className={classNames(
            active && 'bg-gray-100',
            'text-gray flex justify-between px-4 py-2 text-base font-semibold'
          )}
        >
          {itemText}
          <img
            src="https://a.storyblok.com/f/148087/x/eb6ca46d6c/arrow-narrow-right.svg"
            className="inline"
            alt="Pfeil"
          />
        </a>
      )}
    </Menu.Item>
  );
};

interface NavigationToPortalComponentProps {
  loginLink?: string;
  loginText?: string;
  registerText?: string;
  registerLink?: string;
}

export const NavigationToPortalComponent = ({
  loginLink,
  loginText,
  registerLink,
  registerText,
}: NavigationToPortalComponentProps) => {
  return (
    <div className="hidden md:visible md:ml-6 md:flex md:items-center">
      <Menu as="div" className="relative z-40 ml-3">
        <div>
          <NavigationButtonComponent
            iconSource="https://a.storyblok.com/f/148087/12x13/32bad2b087/user_sm.svg"
            iconAlt="Mein Konto"
            buttonName="Mein Konto"
          />
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <MenuItemComponent itemLink={loginLink} itemText={loginText} />
            <MenuItemComponent itemLink={registerLink} itemText={registerText} />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export function Spinner() {
  return (
    <div data-testid="spinner" className="flex w-full flex-col items-center justify-center">
      <div
        style={{ borderTopColor: 'transparent' }}
        className="border-dtkGold-100 h-16 w-16 animate-spin rounded-full border-4 border-solid"
      />
    </div>
  );
}

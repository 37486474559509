import { Field, FieldHookConfig, useField } from 'formik';
import classNames from 'classnames';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { getHighlightedText, validationMessages } from '../../utils';

type LabelColors = 'grey' | 'white';

interface ValidationTextProps {
  text: string;
  highlightedPart: string;
}

export interface InputFieldProps {
  containerClassNames?: string;
  isLargeField?: boolean;
  isMandatory?: boolean;
  isOptional?: boolean;
  hasSuccessMarker?: boolean;
  labelColor?: LabelColors;
  labelBold?: boolean;
  infoMessage?: ValidationTextProps;
  errorMessage?: string;
  successMessage?: string;
  errorMessageHandler?: (errorMessage: string | undefined) => void;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export function InputField(
  props: React.HTMLProps<HTMLInputElement> & FieldHookConfig<string | number | unknown> & InputFieldProps
) {
  const [showInfoText, setShowInfoText] = useState(false);
  const [isOutOfFocusFirstTime, setIsOutOfFocus] = useState(false);
  const [field, meta] = useField(props);
  const {
    id,
    name,
    label,
    type,
    disabled,
    containerClassNames,
    isLargeField,
    isMandatory,
    isOptional,
    hasSuccessMarker,
    labelColor,
    labelBold,
    infoMessage,
    successMessage,
    errorMessage,
    errorMessageHandler,
    icon: Icon,
    onBlur,
    ...restProps
  } = props;

  const showError = Boolean((meta.error || errorMessage) && meta.touched);
  const showSuccess = Boolean(
    !meta.error && !errorMessage && isOutOfFocusFirstTime && hasSuccessMarker && (meta.value as string)
  );

  return (
    <div className={containerClassNames}>
      <div className="font-body flex justify-between space-x-2">
        {label && (
          <label
            className={classNames(
              'block',
              labelColor === 'white' ? 'text-white' : 'text-navy',
              labelBold ? 'font-body-semibold' : 'font-body-medium',
              isLargeField ? 'text-lg' : 'text-base'
            )}
            htmlFor={id || name}
          >
            {isMandatory && !isOptional ? `${label}*` : label}
          </label>
        )}
        {label && isOptional && !isMandatory && <label className="text-coolGray-500">Optional</label>}
      </div>
      <div className="font-body relative mt-2 rounded-md shadow-sm">
        {Icon && (
          <div className="pointer-events-none absolute inset-y-0 left-5 flex items-center">
            <Icon
              className={classNames(
                showError && 'text-red-secondary',
                showSuccess && 'text-green-500',
                !showError && !showSuccess && 'text-coolGray-500 ',
                disabled && '!text-coolGray-400',
                isLargeField ? 'h-6 w-6' : 'h-5 w-5'
              )}
              aria-hidden="true"
            />
          </div>
        )}
        <Field
          {...field}
          {...restProps}
          id={id}
          type={type}
          className={classNames(
            !meta.error && 'text-navy bg-white',
            showSuccess && 'border-green-500 bg-green-50 pr-12 text-green-500 placeholder:!text-green-500',
            showError && 'text-red-secondary placeholder:!text-red-secondary border-red-500 bg-red-50 pr-12',
            disabled
              ? '!bg-coolGray-100 !text-coolGray-400'
              : 'border focus:border-cyan-700 focus:outline-0 focus:ring-0',
            isLargeField ? 'py-4 text-lg' : 'py-3 text-base',
            Icon && 'pl-12',
            'placeholder:text-coolGray-400 border-coolGray-300 block w-full rounded-md',
            restProps?.className
          )}
          disabled={disabled}
          onFocus={() => {
            setShowInfoText(true);
          }}
          onBlur={(event: never) => {
            field.onBlur(event);
            onBlur && onBlur(event);
            setShowInfoText(false);
            setIsOutOfFocus(true);
          }}
        />
        {showSuccess && (
          <CheckIcon
            className={classNames(
              'pointer-events-none absolute inset-y-0 right-5 my-auto h-6 w-6',
              disabled ? '!text-coolGray-400' : 'text-green-500'
            )}
            aria-hidden="true"
          />
        )}
        {showError && (
          <ExclamationCircleIcon
            className={classNames(
              'pointer-events-none absolute inset-y-0 right-5 my-auto h-6 w-6',
              disabled ? '!text-coolGray-400' : 'text-red-secondary'
            )}
            aria-hidden="true"
          />
        )}
      </div>
      {!showError && !showSuccess && showInfoText && infoMessage && (
        <p
          className="text-coolGray-500 font-body mt-2 text-sm antialiased"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(infoMessage.text, infoMessage.highlightedPart, 'coolGray-500', {
              fontFamily: 'font-body',
              isBold: true,
            }),
          }}
        />
      )}
      {showError && (
        <>
          <p
            data-testid={`${name}-error`}
            id={`${name}-error`}
            className="text-red-secondary font-body mt-2 text-sm"
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(
                meta.error || errorMessage || validationMessages.error.defaultInputField.text,
                validationMessages.error.defaultInputField.highlightedPart,
                'red-secondary',
                { fontFamily: 'font-body', isBold: true }
              ),
            }}
          />
          {errorMessageHandler && errorMessageHandler(meta.error || errorMessage)}
        </>
      )}
      {showSuccess && successMessage && (
        <p data-testid={`${name}-success`} className="font-body mt-2 text-sm text-green-500" id="success">
          {successMessage}
        </p>
      )}
    </div>
  );
}

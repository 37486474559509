import { TrustPilotBox } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';
import { getHeightByReviewStyle } from './heightMapper';

const TrustPilotBoxBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="trust-pilot-box">
    <TrustPilotBox
      containerClassNames="flex justify-center mx-auto max-w-7xl py-10"
      businessUnitName={blok.businessUnitName}
      backgroundColor={blok.backgroundColor}
      reviewStyle={blok.reviewStyle}
      reviewTag={blok.reviewTag}
      reviewStars={blok.reviewStars}
      height={getHeightByReviewStyle(blok.reviewStyle)}
      width="100%"
    />
  </div>
);

export default TrustPilotBoxBlok;

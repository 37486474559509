import React from 'react';
import { YoutubeVideoWidescreen } from '@dtk/public-website-ui-components';
import { storyblokEditable } from '@storyblok/react';

const YoutubeVideoWidescreenBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="youtube-video-widescreen">
      <YoutubeVideoWidescreen
        youtubeVideoId={blok.youtubeVideoId}
        name={blok.name}
        description={blok.description}
        uploadDate={blok.uploadDate}
        duration={blok.duration}
        screenSize={blok.screenSize}
      />
    </div>
  );
};

export default YoutubeVideoWidescreenBlok;

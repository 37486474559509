import classNames from 'classnames';
import Image from 'next/future/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { StoryblokIcon } from '@dtk/ui-components';
import { Popover, Portal } from '@headlessui/react';

import {
  NavigationFeaturedBlogPostProps,
  NavigationFooterItemProps,
  NavigationFooterLinkProps,
  NavigationLinkProps,
  NavigationSubLinkProps,
} from './Navigation';
import { cutText, isActive } from './utils';
import { storyBlokLoader } from '../../utils';

export const MainMenu = (
  {
    imageWidth,
    mobileIcon,
    hoverIcon,
    name,
    featuredBlogPosts,
    navigationSubLinks,
    navigationFooter,
    url,
    showMobileOnly,
  }: NavigationLinkProps,
  key: number
) => {
  const router = useRouter();

  const [isShowing, setIsShowing] = useState(false);
  const [currentImageFilename, setCurrentImageFilename] = useState(mobileIcon?.filename);

  const togglePanel = () => {
    setIsShowing(!isShowing);
  };

  return !showMobileOnly ? (
    <div onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
      <Popover key={key} className="flex h-full items-center">
        {!url && navigationSubLinks && navigationSubLinks.length > 0 && name && (
          <Popover.Button
            onClick={() => togglePanel()}
            className={classNames(
              isActive(router, navigationSubLinks, undefined) && 'text-cyan-secondary',
              'py-5 text-base'
            )}
          >
            <div
              className={classNames(
                isActive(router, navigationSubLinks, undefined) && 'text-cyan-secondary',
                'text-navy focus:text-cyan-secondary bg-transparent shadow-none ring-transparent hover:text-cyan-700 focus:ring-transparent'
              )}
            >
              {name}
            </div>
          </Popover.Button>
        )}
        {url && navigationSubLinks?.length === 0 && name && (
          <Link passHref href={url}>
            <a
              href={url}
              className={classNames(
                isActive(router, undefined, url) && 'text-cyan-secondary',
                'text-navy bg-transparent py-5 shadow-none ring-transparent',
                'focus:text-cyan-secondary hover:text-cyan-700 focus:ring-transparent'
              )}
            >
              {name}
            </a>
          </Link>
        )}
        {url && navigationSubLinks?.length === 0 && !name && mobileIcon?.filename && hoverIcon?.filename && (
          <Link passHref href={url}>
            <a
              href={url}
              onMouseEnter={() => setCurrentImageFilename(hoverIcon?.filename)}
              onMouseLeave={() => setCurrentImageFilename(mobileIcon.filename)}
            >
              <StoryblokIcon
                iconSource={currentImageFilename || ''}
                alt={mobileIcon?.alt}
                width={imageWidth}
                height={imageWidth}
              />
            </a>
          </Link>
        )}
        {isShowing && !url && (
          <Portal>
            <div className="relative">
              <div className="fixed inset-0 z-40 bg-black bg-opacity-50" />
            </div>
          </Portal>
        )}
        {navigationSubLinks && navigationSubLinks?.length > 0 && !showMobileOnly && (
          <div
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
            className={classNames(
              'absolute top-16 left-0 z-50 grid w-[61.875rem] grid-cols-3 divide-x-2 rounded-b-md border-2 bg-white',
              isShowing
                ? 'visible scale-100 transform opacity-100 duration-[350ms] ease-out'
                : 'invisible scale-95 transform opacity-0 duration-200 ease-in'
            )}
          >
            <div className="col-span-1 ml-6 mr-4 mt-10">
              <div className="flex flex-col space-y-2">
                {navigationSubLinks?.map((props: NavigationSubLinkProps, id) => (
                  <NavigationSubLink key={id} {...props} />
                ))}
              </div>
            </div>
            <div className="col-span-2">
              <div className="mt-10 ml-6 mr-2 flex flex-col space-y-2">
                {featuredBlogPosts.map((props: NavigationFeaturedBlogPostProps, id) => (
                  <NavigationBlogPost key={id} {...props} />
                ))}
              </div>

              <div className="ml-[2.5rem] mt-8 mb-10 text-sm font-semibold text-cyan-700 hover:text-cyan-500">
                <Link passHref href="/blog">
                  <a href="/blog">Zu den Blogeinträgen</a>
                </Link>
              </div>
            </div>
            {navigationFooter && navigationFooter?.length > 0 && (
              <div className="bg-navy-50 col-span-3">
                <div className="my-10 mx-10 flex flex-row space-x-6">
                  {navigationFooter.map((props: NavigationFooterItemProps, id) => (
                    <NavigationFooterItem key={id} {...props} />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </Popover>
    </div>
  ) : null;
};

const NavigationSubLink = ({ url, icon, name }: NavigationSubLinkProps) => {
  return (
    <Link key={name} passHref href={url}>
      <a href={url}>
        <div className="block rounded-md p-4 hover:bg-gray-50">
          {icon?.filename && (
            <StoryblokIcon
              iconSource={icon.filename}
              alt={icon.alt}
              width={32}
              height={32}
              className="text-navy-600 inline"
            />
          )}
          <p className="text-navy ml-5 inline text-base font-semibold">{name}</p>
        </div>
      </a>
    </Link>
  );
};

const NavigationBlogPost = ({ content, full_slug, slug }: NavigationFeaturedBlogPostProps) => {
  const pageCategory = window.location.pathname.split('/')[1];
  const image = content?.featured_image;

  // prettier-ignore
  const redirectSlug = content?.component === 'blog_post_summary'
      ? (content?.blog_slug as string)
      : pageCategory === 'blog'
        ? slug
        : full_slug;

  return (
    <Link key={slug} passHref href={redirectSlug}>
      <a key={slug} href={redirectSlug} className="flex flex-row rounded-md p-2 hover:bg-gray-50">
        {image?.filename && (
          <div style={{ maxWidth: '640px' }}>
            <Image
              className="mt-2 rounded-md object-contain"
              loader={storyBlokLoader}
              src={image?.filename}
              alt={image?.alt}
              width={640}
              height={200}
              sizes="15vw"
              loading="lazy"
            />
          </div>
        )}
        <div className="mx-8 space-y-1">
          <div className="text-navy font-body-bold text-base">{content?.title}</div>
          <div className="text-navy text-base font-normal">{cutText(content?.excerpt, 150)}</div>
        </div>
      </a>
    </Link>
  );
};

const NavigationFooterItem = ({ headline, links }: NavigationFooterItemProps) => {
  return (
    <div key={headline} className="flex-grow">
      <div className="text-gold font-body-bold text-sm">{headline}</div>
      <div className="flex flex-col space-y-0.5">
        {links.map(({ name, url }: NavigationFooterLinkProps, id) => (
          <div key={id} className="text-navy-700 hover:text-navy-400 text-sm font-normal">
            <Link passHref href={url}>
              <a href={url}>{name}</a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

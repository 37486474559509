import Head from 'next/head';
import React from 'react';

type MetaTagsProps = {
  metaTags?: {
    description?: string;
    og_title?: string;
    og_image?: string;
    og_description?: string;
    twitter_image?: string;
    twitter_title?: string;
    twitter_description?: string;
    meta_robots?: string;
  };
};

const MetaTags = ({ metaTags }: MetaTagsProps) => {
  const {
    description = '',
    og_title = '',
    og_image = '',
    og_description = '',
    twitter_image = '',
    twitter_title = '',
    twitter_description = '',
    meta_robots = '',
  } = metaTags;

  return (
    <Head>
      {description && <meta key="description" name="description" content={description} />}
      {og_title && <meta key="og_title" property="og:title" content={og_title} />}
      {og_image && <meta key="og_image" property="og:image" content={og_image} />}
      {og_description && <meta key="og_description" property="og:description" content={og_description} />}
      {twitter_image && <meta key="twitter_image" property="twitter:image" content={twitter_image} />}
      {twitter_title && <meta key="twitter_title" property="twitter:title" content={twitter_title} />}
      {twitter_description && (
        <meta key="twitter_description" property="twitter:description" content={twitter_description} />
      )}
      <meta
        name="robots"
        content={`${meta_robots || 'index, follow'}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      />
    </Head>
  );
};

export default MetaTags;

import classNames from 'classnames';
import { BackgroundThemeVariants, themesForBackgroundColor } from '../utils/themes';
import { headingType, Headline } from './Headline';

export interface HeaderTextProps {
  overline?: string;
  headline: string;
  subtitle?: string;
  headingType?: headingType;
  theme?: BackgroundThemeVariants;
}

export const HeaderText = ({ overline = '', headline = '', subtitle = '', headingType, theme }: HeaderTextProps) => {
  const chosenHeadingType = headingType || 'h2';
  theme = theme || (themesForBackgroundColor.defaultBackgroundColor as BackgroundThemeVariants);
  const textColor = themesForBackgroundColor.darkBackgroundColors.includes(theme) ? 'text-white' : 'text-navy';

  return (
    <div className={`bg-${theme}`}>
      <div className="mx-auto max-w-7xl space-y-2 px-4 py-8 text-center sm:px-6 lg:space-y-4 lg:px-8 lg:pb-14 lg:pt-10">
        {overline && (
          <p className={`text-${themesForBackgroundColor.overlineColor[theme]} text-sm uppercase tracking-[.2em]`}>
            {overline}
          </p>
        )}
        {headline && (
          <Headline
            headingType={chosenHeadingType}
            headlineClassNames={classNames(textColor, 'font-headline')}
            headline={headline}
            isBold={true}
          />
        )}
        {subtitle && <p className={classNames(textColor, 'font-body text-lg')}>{subtitle}</p>}
      </div>
    </div>
  );
};

import CountUp from 'react-countup';
import classNames from 'classnames';

export interface SubPublicHeroSectionProps {
  headline: string;
  subline?: string;
}

export function SubPublicHeroSection({ headline, subline }: SubPublicHeroSectionProps) {
  return (
    <div className={classNames('bg-navy', ' pt-16')}>
      <div className="mt-4 flex flex-wrap justify-start sm:flex-nowrap sm:justify-center">
        <div className="max-w-3xl">
          <h3 className="font-semibold-700 font-headline text-gold mx-3 mt-2 text-left text-2xl sm:mx-0 sm:text-center sm:text-4xl">
            {headline}
          </h3>
          {subline && (
            <p className="font-normal-400 mx-3 mt-3 text-left text-xl text-white sm:mx-0 sm:text-center">{subline}</p>
          )}
        </div>
      </div>
      <div>
        <div className="mx-3 max-w-screen-md pt-11 sm:mx-auto">
          <div className="grid grid-cols-3 gap-4 pb-28 text-white">
            <div className="w-fill">
              <p className="text-center text-4xl sm:text-6xl">
                <CountUp delay={0.5} end={80} /> +
              </p>
              <p className="mt-1 text-center text-xl">Teamkollegen</p>
            </div>
            <div className="w-fill">
              <p className="text-center text-4xl sm:text-6xl">
                <CountUp delay={0.5} end={120} /> %
              </p>
              <p className="mt-1 text-center text-xl">On Fire</p>
            </div>
            <div className="w-fill">
              <p className="text-center text-4xl sm:text-6xl">
                <CountUp delay={0.5} end={10} /> +
              </p>
              <p className="mt-1 text-center text-xl">Offene Stellen</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

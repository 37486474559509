import { formatCurrency, formatSquareMeter } from '@dtk/formatter';
import {
  MAXIMUM_CONSTRUCTION_YEAR,
  MAXIMUM_LAND_AREA,
  MAXIMUM_LIVING_SPACE,
  MAXIMUM_REAL_ESTATE_VALUE,
  MAXIMUM_SALES_CLOSING_YEAR,
  MINIMUM_CONSTRUCTION_YEAR,
  MINIMUM_EXISTING_LOADS_AMOUNT,
  MINIMUM_LAND_AREA,
  MINIMUM_LIVING_SPACE,
  MINIMUM_REAL_ESTATE_VALUE,
  MINIMUM_REQUESTED_PAYOUT,
  MINIMUM_SALES_CLOSING_YEAR,
} from '../estateValues';

export const validationMessages = {
  info: {
    firstName: { text: 'Bitte geben Sie Ihren Vornamen ein.', highlightedPart: 'Vornamen' },
    lastName: { text: 'Bitte geben Sie Ihren Nachnamen ein.', highlightedPart: 'Nachnamen' },
    street: { text: 'Bitte geben Sie Ihren Straßennamen ein.', highlightedPart: 'Straßennamen' },
    houseNr: { text: 'Bitte geben Sie Ihre Hausnummer ein.', highlightedPart: 'Hausnummer' },
    zipCode: { text: 'Bitte geben Sie Ihre 5-stellige Postleitzahl ein.', highlightedPart: '5-stellige Postleitzahl' },
    city: { text: 'Bitte geben Sie Ihren Wohnort ein.', highlightedPart: 'Wohnort' },
    phone: {
      text: 'Bitte geben Sie Ihre Telefon- oder Mobilfunknummer ein.',
      highlightedPart: 'Telefon- oder Mobilfunknummer',
    },
    email: { text: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.', highlightedPart: 'E-Mail-Adresse' },
    password: { text: 'Bitte geben Sie Ihr Passwort ein.', highlightedPart: 'Passwort' },
    newPassword: {
      text: 'Das Passwort muss mindestens eine Zahl, einen Kleinbuchstaben und einen Großbuchstaben beinhalten sowie 8 Zeichen lang sein',
      highlightedPart: 'Passwort',
    },
    company: { text: 'Bitte geben Sie das Unternehmen an für das Sie arbeiten.', highlightedPart: 'Unternehmen' },
    ihkNr: { text: 'Bitte geben Sie Ihre individuelle IHK-Nummer ein.', highlightedPart: 'IHK-Nummer' },
    estateAgentID: {
      text: 'Bitte geben Sie Ihre individuelle 12-stellige Makler-ID ein.',
      highlightedPart: '12-stellige Makler-ID',
    },
    realEstateValue: {
      text: 'Bitte geben Sie den geschätzten Immobilienwert ein.',
      highlightedPart: 'geschätzten Immobilienwert',
    },
    requestedPayout: {
      text: 'Die Wunschauszahlung darf 50 % des geschätzten Immobilienwertes nicht übersteigen.',
      highlightedPart: '50 % des geschätzten Immobilienwertes',
    },
    existingLoadsAmount: { text: 'Bitte geben Sie die bestehenden Lasten ein.', highlightedPart: 'bestehenden Lasten' },
    livingSpace: {
      text: 'Sofern Ihnen die genaue Wohnfläche nicht bekannt ist, können Sie diese auch schätzen.',
      highlightedPart: 'Wohnfläche',
    },
    landArea: {
      text: 'Sofern Ihnen die genaue Grundstücksfläche nicht bekannt ist, können Sie diese auch schätzen.',
      highlightedPart: 'Grundstücksfläche',
    },
    constructionYear: {
      text: 'Bitte geben Sie das Baujahr ihrer Immobilie ein.',
      highlightedPart: 'Baujahr',
    },
    garageParkingSpaces: {
      text: 'Bitte geben Sie die Anzahl ihrer Garagenstellplätze ein.',
      highlightedPart: 'Garagenstellplätze',
    },
    outdoorParkingSpaces: {
      text: 'Bitte geben Sie die Anzahl ihrer Aussenstellplätze ein.',
      highlightedPart: 'Aussenstellplätze',
    },
  },
  error: {
    loginFeedback: {
      text: 'E-Mail-Adresse oder Passwort sind nicht korrekt. Bitte versuchen Sie es erneut.',
      magicLinkText:
        'Diese E-Mail-Adresse ist uns nicht bekannt. Bitte überprüfen Sie Ihre Eingabe oder erstellen Sie ein Konto.',
    },
    defaultBanner: {
      text: 'Ihre Angaben sind noch nicht vollständig. Bitte überprüfen Sie die rot markierten Felder.',
      highlightedPart: 'rot markierten Felder.',
    },
    leadBanner: {
      text: 'Ihre Angaben sind noch nicht vollständig. Bitte füllen Sie alle rot markierten Felder auf den ersten beiden Formularseiten aus!',
      highlightedPart: 'rot markierten Felder',
    },
    portalFormBanner: {
      text: 'Leider ist bei der Übertragung ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich an Ihren Ansprechpartner.',
      highlightedPart: 'ein Fehler',
    },
    defaultInputField: {
      text: 'Dieses Feld ist ein Pflichtfeld.',
      promptText: 'Bitte geben Sie einen Inhalt ein.',
      highlightedPart: 'Pflichtfeld.',
    },
    defaultDropdownField: {
      text: 'Bitte treffen Sie eine Auswahl.',
      choiceText: 'Dies ist keine gültige Auswahl.',
      highlightedPart: 'Auswahl.',
    },
    salutation: { text: 'Anrede muss eine der Auswahlmöglichkeiten entsprechen', highlightedPart: 'Anrede' },
    zipCode: {
      text: 'Möglicherweise ist in Ihrer Postleitzahl ein Tippfehler. Diese muss eine 5-stellige Zahl sein.',
      highlightedPart: 'Tippfehler.',
    },
    city: {
      text: 'Um eine fundierte Immobilienbewertung vorzunehmen, benötigen wir die vollständigen Adressdaten.',
      highlightedPart: 'vollständigen Adressdaten.',
    },
    email: { text: 'Möglicherweise ist in Ihrer E-Mail-Adresse ein Tippfehler.', highlightedPart: 'Tippfehler.' },
    password: { defaultText: 'E-Mail-Adresse oder Passwort sind nicht korrekt. Bitte versuchen Sie es erneut.' },
    passwordConfirmation: 'Die Passwörter stimmen nicht überein.',
    estateAgentID: { text: 'Ihre Makler-ID muss 12-stellig sein.', highlightedPart: '12-stellig' },
    realEstateValue: {
      min: {
        text: `Der Immobilienwert muss mindestens ${formatCurrency(
          MINIMUM_REAL_ESTATE_VALUE,
          0,
          false
        )} Euro betragen.`,
        highlightedPart: `mindestens ${formatCurrency(MINIMUM_REAL_ESTATE_VALUE, 0, false)} Euro`,
      },
      max: {
        text: `Ihr Immobilienwert darf maximal ${formatCurrency(MAXIMUM_REAL_ESTATE_VALUE, 0, false)} Euro betragen.`,
        highlightedPart: `maximal ${formatCurrency(MAXIMUM_REAL_ESTATE_VALUE, 0, false)} Euro`,
      },
    },
    requestedPayout: {
      min: {
        text: `Die Wunschauszahlung muss mindestens ${formatCurrency(MINIMUM_REQUESTED_PAYOUT, 0, false)} betragen.`,
        highlightedPart: `mindestens ${formatCurrency(MINIMUM_REQUESTED_PAYOUT, 0, false)} Euro`,
      },
      max: {
        text: 'Die Wunschauszahlung darf 50 % des geschätzten Immobilienwertes nicht übersteigen.',
        highlightedPart: '50 % des geschätzten Immobilienwertes',
      },
    },
    totalSalesAfterYears: {
      min: { text: `Der Gesamtverkauf nach Jahren muss mindestens ${MINIMUM_SALES_CLOSING_YEAR} betragen.` },
      max: { text: `Der Gesamtverkauf nach Jahren darf maximal ${MAXIMUM_SALES_CLOSING_YEAR} betragen.` },
    },
    existingLoads: {
      text: 'Nur mit Angabe der bestehenden Lasten können wir ein Angebot für Sie erstellen.',
      highlightedPart: 'bestehenden Lasten',
    },
    existingLoadsAmount: {
      min: {
        text: `Die Höhe der Lasten muss mindestens ${formatCurrency(
          MINIMUM_EXISTING_LOADS_AMOUNT,
          0,
          false
        )} Euro betragen`,
        highlightedPart: `mindestens ${formatCurrency(MINIMUM_EXISTING_LOADS_AMOUNT, 0, false)} Euro`,
      },
      max: {
        text: 'Die Lasten dürfen den Wunschauszahlungsbetrag nicht übersteigen.',
        highlightedPart: 'Die Lasten dürfen den Wunschauszahlungsbetrag nicht übersteigen.',
      },
    },
    objectType: {
      text: 'Immobilienart muss eine der Auswahlmöglichkeiten entsprechen',
      highlightedPart: 'Immobilienart',
    },
    buildingType: { text: 'Gebäudeart muss eine der Auswahlmöglichkeiten entsprechen', highlightedPart: 'Gebäudeart' },
    livingSpace: {
      min: {
        text: `Die Wohnfläche muss mindestens ${formatSquareMeter(MINIMUM_LIVING_SPACE, 0)} betragen.`,
        highlightedPart: `mindestens ${formatSquareMeter(MINIMUM_LIVING_SPACE, 0)}`,
      },
      max: {
        text: `Ihre Wohnfläche darf maximal ${formatSquareMeter(MAXIMUM_LIVING_SPACE, 0)} betragen`,
        highlightedPart: `maximal ${formatSquareMeter(MAXIMUM_LIVING_SPACE, 0)}`,
      },
    },
    landArea: {
      min: {
        text: `Die Grundstücksfläche muss mindestens ${formatSquareMeter(MINIMUM_LAND_AREA, 0)} betragen.`,
        highlightedPart: `mindestens ${formatSquareMeter(MINIMUM_LAND_AREA, 0)}`,
      },
      max: {
        text: `Ihre Grundstücksfläche darf maximal ${formatSquareMeter(MAXIMUM_LAND_AREA, 0)} betragen`,
        highlightedPart: `maximal ${formatSquareMeter(MAXIMUM_LAND_AREA, 0)}`,
      },
    },
    constructionYear: {
      min: {
        text: `Das Baujahr kann nicht vor ${MINIMUM_CONSTRUCTION_YEAR} liegen.`,
        highlightedPart: `nicht vor ${MINIMUM_CONSTRUCTION_YEAR}`,
      },
      max: {
        text: `Das Baujahr kann nicht nach ${MAXIMUM_CONSTRUCTION_YEAR} liegen.`,
        highlightedPart: `nicht nach ${MAXIMUM_CONSTRUCTION_YEAR}`,
      },
    },
    flatCount: {
      min: { text: 'Anzahl der Wohneinheiten darf nicht kleiner als 1 sein.', highlightedPart: 'nicht kleiner als 1' },
    },
    floorCount: {
      min: { text: 'Anzahl der Geschosse darf nicht kleiner als 1 sein.', highlightedPart: 'nicht kleiner als 1' },
    },
    construction: { text: 'Bauweise muss eine der Auswahlmöglichkeiten entsprechen', highlightedPart: 'Bauweise' },
    loftConversion: {
      text: 'Dachausbau muss eine der Auswahlmöglichkeiten entsprechen',
      highlightedPart: 'Dachausbau',
    },
    basementType: {
      text: 'Ausbau des Kellers muss eine der Auswahlmöglichkeiten entsprechen',
      highlightedPart: 'Ausbau des Kellers',
    },
    basementExtension: {
      text: 'Ausbaustufe muss eine der Auswahlmöglichkeiten entsprechen',
      highlightedPart: 'Ausbaustufe',
    },
    garageParkingSpaces: {
      max: (value: number) => {
        return {
          text: `Die Anzahl ihrer Garagenstellplätze darf ${value} nicht übersteigen.`,
          highlightedPart: 'Garagenstellplätze',
        };
      },
    },
    outdoorParkingSpaces: {
      max: (value: number) => {
        return {
          text: `Die Anzahl ihrer Aussenstellplätze darf ${value} nicht übersteigen.`,
          highlightedPart: 'Aussenstellplätze',
        };
      },
    },
  },
  newPassword: [
    { criterion: 'Das Passwort muss mindestens 8 Zeichen lang sein.', checkValue: 8, checkType: 'min' },
    {
      criterion: 'Das Passwort muss mindestens einen Kleinbuchstaben beinhalten.',
      checkValue: /^(?=.*[a-z])/,
      checkType: 'match',
    },
    {
      criterion: 'Das Passwort muss mindestens einen Großbuchstaben beinhalten.',
      checkValue: /^(?=.*[A-Z])/,
      checkType: 'match',
    },
    { criterion: 'Das Passwort muss mindestens eine Zahl beinhalten.', checkValue: /^(?=.*[0-9])/, checkType: 'match' },
  ],
};

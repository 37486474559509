import { Button } from '@dtk/ui-components';
import { DownloadIcon } from '@heroicons/react/solid';

export interface DownloadItemProps {
  downloadButtonText: string;
  images: DownloadItemImageProps[];
  title: string;
  buttonText: string;
  downloadUrl?: string;
}

export interface DownloadItemImageProps {
  id?: number;
  alt?: string;
  name?: string;
  title?: string;
  filename: string;
}

export const DownloadItem = ({ downloadButtonText, images, title, buttonText, downloadUrl }: DownloadItemProps) => {
  return (
    <div className="mb-4 mt-6 flex flex-col first:mt-0">
      <p className="border-b-2 pb-2 text-lg font-semibold">{title}</p>
      {images.map((item, itemId) => (
        <div key={itemId} className="grid grid-flow-col grid-cols-2 border p-2 sm:grid-cols-3">
          <div className="col-span-2 flex items-center sm:col-span-1">
            <p className="inline-block text-base font-semibold sm:w-80">{item.name}</p>
          </div>
          <div className="invisible flex items-center justify-self-end sm:visible">{item.alt}</div>
          <div className="font-semibold-400 justify-self-end text-base leading-6">
            <Button variant="white" size="sm" icon={DownloadIcon} iconPosition="right" href={item.filename} isexternal>
              {downloadButtonText}
            </Button>
          </div>
        </div>
      ))}
      {downloadUrl && (
        <div className="ml-auto mt-2">
          <Button size="sm" href={downloadUrl}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

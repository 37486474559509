import React, { useContext } from 'react';

import { AngebotsstreckeContext } from '../../contexts/AngebotsstreckeContext';
import { NavigationButtons } from './NavigationButtons';

const AngebotsstreckeNavigation = () => {
  const { activeStep } = useContext(AngebotsstreckeContext);

  return (
    <>
      <div className="mb-5 mt-10 hidden w-full justify-between gap-5 sm:flex">
        <NavigationButtons activeStep={activeStep} />
      </div>

      <div className="fixed bottom-0 left-0 z-50 w-full bg-white py-3 shadow-inner sm:hidden">
        <div className="flex w-full justify-between gap-5 pl-2 pr-5">
          <NavigationButtons activeStep={activeStep} />
        </div>
      </div>
    </>
  );
};

export default AngebotsstreckeNavigation;

import { ReactNode } from 'react';

export interface HeaderProps {
  bannerUrl: string;
  name: string;
  iconValues: IconValueProps[];
  button?: ReactNode;
}

export interface IconValueProps {
  key: string;
  value: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const renderIconValue = ({ value, icon: Icon, key }: IconValueProps) => (
  <div key={key} data-testid="header-icon" className="text-coolGray-500 mt-2 flex items-center text-sm">
    <Icon className="text-coolGray-400 mr-1.5 h-5 w-5 flex-shrink-0" aria-hidden="true" />
    {value}
  </div>
);

export const Header = ({ bannerUrl, name, iconValues, button }: HeaderProps) => (
  <div className="bg-white pb-4">
    <div>
      <img
        data-testid="header-banner"
        className="h-32 w-auto object-cover sm:w-full lg:h-48"
        src={bannerUrl}
        alt="Banner"
      />
    </div>
    <div className="mx-auto max-w-screen-xl px-4 sm:px-0">
      <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
        <div className="flex">
          <img
            data-testid="header-profil-image"
            className="h-24 w-24 rounded-full object-cover ring-4 ring-white sm:h-32 sm:w-32"
            src="/immobilie/images/Profile.png"
            alt="Profil"
          />
        </div>

        <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
          <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
            <h1 data-testid="header-heading" className="text-navy truncate text-2xl font-bold">
              {name}
            </h1>
          </div>
        </div>

        {button && (
          <div className="justify-stretch mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
            {button}
          </div>
        )}
      </div>
      <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
        <h1 data-testid="header-name" className="text-navy truncate text-2xl font-bold">
          {name}
        </h1>
      </div>
      <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 md:ml-36">
        {iconValues && iconValues.map((iconValue) => renderIconValue(iconValue))}
      </div>
    </div>
  </div>
);

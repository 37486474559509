/** @type {import('tailwindcss').Config} */
const plugin = require('tailwindcss/plugin');

module.exports = {
  content: ['./apps/**/*.{js,ts,jsx,tsx}', './libs/**/*.{js,ts,jsx,tsx}'],
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: '#FFFFFF',
      black: '#000000',
      creme: '#F5F0E6',
      'black-transparent': 'rgba(0,0,0,0.05)',
      nearlyBlack: '#333333',
      neutral: '#FCFCFC',
      aqua: '#93d8fd',
      navy: {
        navy: '#19283C',
        800: '#233753',
        700: '#2A4365',
        600: '#365682',
        500: '#3C6090',
        400: '#4873AD',
        300: '#6F93C3',
        200: '#93AED2',
        100: '#C9D7E9',
        50: '#F1F4F9',
        DEFAULT: '#19283C',
      },
      khaki: {
        khaki: '#575944',
        600: '#70734C',
        400: '#909748',
        300: '#9DA739',
        100: '#F8FE88',
        DEFAULT: '#575944',
      },
      gold: {
        900: '#3C2C11',
        800: '#5B441A',
        700: '#836125',
        600: '#9B732C',
        gold: '#AF8232',
        sand: '#CDB991',
        300: '#DFD2B9',
        200: '#E7DFCE',
        100: '#EFE9DC',
        creme: '#F5F0E6',
        DEFAULT: '#AF8232',
      },
      cyan: {
        900: '#013F65',
        secondary: '#015F98',
        700: '#017FCB',
        600: '#019FFE',
        500: '#34B2FE',
        400: '#67C5FE',
        300: '#9AD8FE',
        200: '#B3E2FF',
        100: '#CCECFF',
        50: '#E6F5FF',
        DEFAULT: '#015F98',
      },
      blue: {
        pastelBlue: '#7AA8D1',
      },
      red: {
        900: '#661700',
        800: '#992300',
        700: '#CC2F00',
        secondary: '#E53500',
        500: '#FF460F',
        400: '#FF6E42',
        300: '#FF9D80',
        200: '#FFBCA8',
        100: '#FFD8CC',
        50: '#FFEFEB',
        DEFAULT: '#E53500',
      },
      coolGray: {
        900: '#111928',
        800: '#1F2A37',
        700: '#374151',
        600: '#4B5563',
        500: '#6B7280',
        400: '#9CA3AF',
        300: '#D1D5DB',
        200: '#E5E7EB',
        100: '#F3F4F6',
        50: '#F9FAFB',
        DEFAULT: '#6B7280',
      },
      gray: {
        900: '#18181B',
        800: '#27272A',
        700: '#3F3F46',
        600: '#52525B',
        500: '#71717A',
        400: '#A1A1AA',
        300: '#D4D4D8',
        stoneGray: '#D5D2CA',
        200: '#E4E4E7',
        'stoneGray-200': '#E8E3D9',
        100: '#F4F4F5',
        50: '#FAFAFA',
        DEFAULT: '#27272A',
      },
      green: {
        700: '#046C4E',
        500: '#0E9F6E',
        400: '#31C48D',
        lightGreen: '#D5DD42',
        50: '#F3FAF7',
      },
      purple: {
        600: '#7E3AF2',
      },
      pink: {
        600: '#D61F69',
      },
      orange: {
        400: '#FF8A4C',
      },
      coral: {
        800: '#D84136',
        DEFAULT: '#F37068',
      },
      mint: {
        DEFAULT: '#CBE2D8',
      },
      rostPastell: {
        DEFAULT: '#C2ADA8',
      },
    },
    screens: {
      xs: '460px',
      // => @media (min-width: 360px) { ... }

      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1024px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
      navi: '1280px',
      // => @media (min-width: 1280px) { ... }
    },
    extend: {
      minWidth: {
        0: '0',
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        full: '100%',
        25: '6.25rem', // 100px
        40: '10rem', // 160px
        56.25: '18.75rem', // 300px
        vollmachtCard: '55.12rem',
        '50px': '3.125rem',
      },
      maxWidth: {
        '200px': '12.5rem',
      },
      minHeight: {
        200: '12.5rem',
        300: '18.75rem',
      },
      height: {
        modal: '37.5rem', //600px
      },
      keyframes: {
        shrink: {
          '0%': { clipPath: 'inset(100% 0 0 0)' },
          '100%': { clipPath: 'inset(0 0 0 0)' },
        },
        microwiggle: {
          '0%, 100%': { transform: 'rotate(-0.1deg)' },
          '50%': { transform: 'rotate(0.1deg)' },
        },
      },
      animation: {
        shrink: 'shrink 0.8s ease-out',
        microwiggle: 'microwiggle 0.5s ease-in-out',
      },
    },
  },
  safelist: [
    'backdrop-blur-none',
    'backdrop-blur-sm',
    'backdrop-blur',
    'backdrop-blur-md',
    'backdrop-blur-lg',
    'backdrop-blur-xl',
    'backdrop-blur-2xl',
    'backdrop-blur-3xl',
    'md:backdrop-blur-none',
    'md:backdrop-blur-sm',
    'md:backdrop-blur',
    'md:backdrop-blur-md',
    'md:backdrop-blur-lg',
    'md:backdrop-blur-xl',
    'md:backdrop-blur-2xl',
    'md:backdrop-blur-3xl',
  ],
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    plugin(({ addUtilities }) => {
      const tiltOfBackgroundDelimitation = {
        0: '0deg',
        45: '45deg',
        90: '90deg',
        135: '135deg',
        180: '180deg',
      };

      // For using new themes, add a new theme variant and corresponding color pair
      const themeVariants = ['Gray50AndNavy', 'LgAndDimGray'];
      const themes = {
        Gray50AndNavy: ['#FAFAFA', '#19283C'],
        LgAndDimGray: ['LightGray', 'DimGray'],
      };

      const linearGradientUtility = Object.entries(tiltOfBackgroundDelimitation).reduce(
        (result, [shorthand, direction]) => {
          const classVariants = themeVariants.map((variant) => {
            const className = `.linear-gradient-${shorthand}-halfBg-${variant}`;
            return {
              [className]: {
                backgroundImage: `linear-gradient(${direction}, ${themes[variant][0]} 50%, ${themes[variant][1]} 50%)`,
              },
            };
          });
          const stepClasses = Object.assign(...classVariants);
          return {
            ...result,
            ...stepClasses,
          };
        },
        {}
      );
      addUtilities(linearGradientUtility);
    }),
  ],
};

import { formatDate } from '@dtk/formatter';
export interface CardMetaProps {
  authorName: string;
  authorSlug: string;
  authorImageUrl: string;
  readingTime?: string;
  first_published_at: string;
  authorPosition: string;
  className?: string;
}

// Needs Fix B: WEB-672 remove default Author
const defaultAuthorImage = {
  filename: 'https://a.storyblok.com/f/148087/40x40/bffb345878/microsoftteams-image-46.png',
};
const defaultAuthor = {
  author_image: defaultAuthorImage,
  author_name: 'Deutsche Teilkauf',
  author_position: 'Redakteur',
};

export const CardMeta = ({ ...props }: CardMetaProps) => {
  return (
    <div className={props.className + ' flex'}>
      <div className="flex-shrink-0">
        <span className="sr-only">{props.authorName || defaultAuthor.author_name}</span>
        <img
          className="h-10 w-10 rounded-full object-cover object-top"
          src={`${props.authorImageUrl || defaultAuthorImage.filename}/m/200x0/filters:quality(${80})`}
          alt="Autor"
          loading="lazy"
          {...{ fetchpriority: 'low' }}
        />
      </div>
      <div className="ml-3">
        <p className="text-navy text-sm font-medium">{props.authorName || defaultAuthor.author_name}</p>
        <div className="text-coolGray-400 flex space-x-1 text-sm">
          <time dateTime={props.first_published_at}>{formatDate(props.first_published_at)}</time>
          <span aria-hidden="true">&middot;</span>
          <span>{props.readingTime} Lesedauer</span>
        </div>
      </div>
    </div>
  );
};

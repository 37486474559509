import { PencilIcon, RefreshIcon } from '@heroicons/react/outline';

import { AuthorProps } from '../../types';
import Link from 'next/link';
import { ShareButton } from '@dtk/ui-components';
import { formatDateWithLongMonth } from '@dtk/formatter';

type TopAuthorBlogPostPageProps = {
  author: AuthorProps;
  firstPublishedAt: string;
  publishedAt?: string;
};

export const TopAuthorBlogPostPage = ({ author, firstPublishedAt, publishedAt }: TopAuthorBlogPostPageProps) => {
  const oneMinuteInUnixTime = 60000;
  const {
    content: { author_image, author_name, author_position },
    full_slug,
  } = author;
  const alt = author_image?.alt;
  const filename = author_image?.filename;
  const datesDiffer = () => {
    return publishedAt && new Date(publishedAt).getTime() >= new Date(firstPublishedAt).getTime() + oneMinuteInUnixTime;
  };
  return (
    <div className="mt-10 grid grid-cols-12">
      <div className="col-span-12	mb-2 flex w-full flex-row border-b border-gray-200">
        <div className="relative mt-1">
          {author_image && (
            <img
              className="h-10 w-10 rounded-full object-cover object-top"
              src={`${filename}/m/150x0/filters:quality(${80})`}
              alt={alt}
            />
          )}
        </div>
        <div className="ml-2">
          <Link passHref href={'/' + full_slug} className="text-navy cursor-pointer font-bold text-blue-700">
            {author_name}
          </Link>
          <p className="text-navy mb-2 text-sm">{author_position}</p>
        </div>
      </div>
      <div className="col-span-12 my-2 flex flex-col justify-between sm:flex-row">
        <div className="flex flex-col xl:flex-row">
          <div className="mb-1 flex items-center text-sm sm:mb-0 md:ml-2">
            <span className="text-navy flex items-center text-sm">
              <PencilIcon className="mr-1" width="16" height="16" />
              Veröffentlicht am
            </span>
            <time className="text-navy ml-1 flex space-x-1 text-sm" dateTime={firstPublishedAt}>
              {formatDateWithLongMonth(firstPublishedAt)}
            </time>
          </div>
          {datesDiffer() && (
            <div className="flex items-center md:ml-2">
              <span className="text-navy flex items-center text-sm">
                <RefreshIcon className="mr-1" width="16" height="16" />
                Aktualisiert am
              </span>
              {publishedAt && (
                <time className="text-navy ml-1 flex space-x-1 text-sm " dateTime={publishedAt}>
                  {formatDateWithLongMonth(publishedAt)}
                </time>
              )}
            </div>
          )}
        </div>
        <div className="mt-2 flex justify-start sm:mt-0 sm:justify-end">
          <ShareButton selectedPlatforms={['whatsApp', 'facebook', 'twitter', 'linkedIn', 'email', 'print']} />
        </div>
      </div>
    </div>
  );
};

import { showSecondLayer } from '@dtk/ui-components';
import classNames from 'classnames';

const PrivacySettingsLinkBlok = ({ _, styles }) => (
  <div onClick={() => showSecondLayer()} className={classNames('text-navy-700 mb-2 cursor-pointer', styles)}>
    Privatsphäre-Einstellungen
  </div>
);

export default PrivacySettingsLinkBlok;

import { CalculationStepProps } from './PropertyCalculationSection';
import {
  calculateEstateAgentCost,
  calculateModernizationCostForYears,
  calculateRealEstateValueWithValueEnhancement,
  calculateRentalCostForYears,
  calculateUsageFeeForYears,
} from './estateCalculations';

interface CalculationStepTextProps {
  estateAgentCostTooltipText: string;
  rentalCostTooltipText: string;
  secondPayoutTooltipText: string;
  usageFeeTooltipText: string;
  modernizationCostTooltipText: string;
}

export const getAllCalculationSteps = (
  realEstateValue: string,
  requestedPayout: string,
  sliderValue: number,
  allTooltipTextElements: CalculationStepTextProps
) => {
  const IS_ESTATE_VALUE_VALID = realEstateValue && requestedPayout;
  const calculatedValueDefaultStyles = 'font-body-bold xs:font-body xs:text-lg text-2xl ml-auto';
  const totalSaleCalculationSteps: CalculationStepProps[] = [
    {
      label: 'Heutiger Verkaufserlös Ihrer Immobilie',
      calculatedValue: parseInt(realEstateValue) || 0,
      calculatedValueClassNames: `${calculatedValueDefaultStyles} text-green-400`,
    },
    {
      label: 'Abzüglich Maklerkosten',
      tooltipText: allTooltipTextElements.estateAgentCostTooltipText,
      calculatedValue: IS_ESTATE_VALUE_VALID ? -calculateEstateAgentCost(realEstateValue) : 0,
      calculatedValueClassNames: `${calculatedValueDefaultStyles} text-red-700`,
    },
    {
      label: 'Abzüglich Mietkosten',
      tooltipText: allTooltipTextElements.rentalCostTooltipText,
      calculatedValue: IS_ESTATE_VALUE_VALID ? -calculateRentalCostForYears(realEstateValue, sliderValue) : 0,
      calculatedValueClassNames: `${calculatedValueDefaultStyles} text-red-700`,
    },
  ];

  const partialSaleCalculationSteps: CalculationStepProps[] = [
    {
      label: '1. Auszahlung',
      calculatedValue: parseInt(requestedPayout) || 0,
      calculatedValueClassNames: `${calculatedValueDefaultStyles} text-green-400`,
    },
    {
      label: `2. Auszahlung (in ${sliderValue} Jahren)`,
      tooltipText: allTooltipTextElements.secondPayoutTooltipText,
      calculatedValue: calculateRealEstateValueWithValueEnhancement(realEstateValue, requestedPayout, sliderValue),
      calculatedValueClassNames: `${calculatedValueDefaultStyles} text-green-400`,
    },
    {
      label: 'Abzüglich Nutzungsentgelt',
      tooltipText: allTooltipTextElements.usageFeeTooltipText,
      calculatedValue: IS_ESTATE_VALUE_VALID ? -calculateUsageFeeForYears(requestedPayout, sliderValue) : 0,
      calculatedValueClassNames: `${calculatedValueDefaultStyles} text-red-700`,
    },
    {
      label: 'Kalkulatorische Instandhaltungskosten',
      tooltipText: allTooltipTextElements.modernizationCostTooltipText,
      calculatedValue: IS_ESTATE_VALUE_VALID ? -calculateModernizationCostForYears(sliderValue) : 0,
      calculatedValueClassNames: `${calculatedValueDefaultStyles} text-red-700`,
    },
  ];
  return { totalSaleCalculationSteps, partialSaleCalculationSteps };
};

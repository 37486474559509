import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { useOfferStore } from '../../../store/OfferStore';
import { useRouter } from 'next/router';
import { InputFieldWithAddOn } from '@dtk/ui-components';

export const InputRechnerBlok = ({ blok }) => {
  const router = useRouter();
  const { inputLabelDesiredPayment } = blok;
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const requestedPayout = parseInt(queryParams.get('requested-payout'));

    if (requestedPayout) {
      setFieldValue('requestedPayout', requestedPayout);
      useOfferStore.setState({ requestedPayout: requestedPayout });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestedPayoutChange = (event) => {
    const value = parseInt(event.currentTarget.value.replace(/[€.,' ']+/g, '')) || 0;
    useOfferStore.setState({ requestedPayout: value });
    if (router.query?.['requested-payout']) {
      router.replace(
        {
          query: { ...router.query, ['requested-payout']: value },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  return (
    <div className="col-span-12 mb-3 space-y-4">
      <InputFieldWithAddOn
        suffix="€"
        id="requestedPayout"
        name="requestedPayout"
        type="text"
        label={inputLabelDesiredPayment}
        placeholder="z.B. 250.000"
        onBlur={handleRequestedPayoutChange}
      />
    </div>
  );
};

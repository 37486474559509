const translateSchedule = (val: string) => {
  let translatedSchedule = '';
  switch (val) {
    case 'full-time':
      translatedSchedule = 'Vollzeit';
      break;
    case 'part-time':
      translatedSchedule = 'Teilzeit';
      break;
    default:
      translatedSchedule = 'Vollzeit';
  }
  return translatedSchedule;
};

export { translateSchedule };

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const CheckingText = (props: { text: string }) => {
  if (props.text) {
    return (
      <div className="col-span-1">
        <div className="text-navy-navy inline-block pr-2.5">{props.text}</div>
        <div className="inline-block font-bold text-cyan-700">&#10003;</div>
      </div>
    );
  }
  return null;
};
export interface LoadingComponentProbs {
  ccvalue: number;
  title: string;
  subtitle: string;
  firstCheckingText: string;
  secondCheckingText: string;
  thirdCheckingText: string;
}

export const LoadingComponent = ({
  ccvalue,
  title,
  subtitle,
  firstCheckingText,
  secondCheckingText,
  thirdCheckingText,
}: LoadingComponentProbs) => {
  return (
    <>
      <div className="loading-component mx-auto w-[6.25rem]">
        <CircularProgressbar
          className="mb-10 mt-5 lg:mt-0"
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.5,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'round',
            textSize: '16px',
            // How long animation takes to go from one percentage to another, in seconds
            // pathTransitionDuration: 0.5,
            // Can specify path transition in more detail, or remove it entirely
            pathTransition: 'none',
            // Colors
            pathColor: '#017FCB',
            // textColor: '#f88',
            trailColor: '#E5E7EB',
            backgroundColor: '#AF8232',
          })}
          value={ccvalue}
        />
      </div>

      <div className="grid max-w-7xl grid-cols-1 py-3 antialiased sm:p-1">
        <div className="text-navy-navy font-headline-bold text-center text-2xl sm:mx-0">{title}</div>
        <div className="text-navy-navy mt-2 text-center text-base sm:mx-0">{subtitle}</div>
      </div>
      <div key="separator" className="border-navy-100 mx-auto mb-4 mt-3 max-w-[30rem] border-b" />
      <div className="grid grid-cols-1 justify-items-center gap-y-5 antialiased sm:grid-cols-3">
        <CheckingText text={firstCheckingText} />
        <CheckingText text={secondCheckingText} />
        <CheckingText text={thirdCheckingText} />
      </div>
    </>
  );
};

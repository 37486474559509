import { DropdownField, defaultOption, estateValueDropdownMapper } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

import React, { useEffect } from 'react';
import { useOfferStore } from '../../../store/OfferStore';
import { useFormikContext } from 'formik';

export const InputSelectWithDropdownBlok = ({ blok }) => {
  const { values, setFieldValue } = useFormikContext();

  const keys = useOfferStore.getState().data;
  const DEFAULT_OPTIONS = [
    { name: defaultOption, value: '' },
    { name: 'Keine Angabe', value: 'kA' },
  ];
  const OPTIONS = estateValueDropdownMapper[blok.name] || DEFAULT_OPTIONS;

  const handleValueChange = (event) => {
    const { value } = event.target;
    useOfferStore.setState({ data: keys.set(blok.name, value) });
  };

  useEffect(() => {
    values?.[blok.name] && setFieldValue(blok.name, keys.get(blok.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      {...storyblokEditable(blok)}
      data-test="input-select-with-dropdown"
      className={`col-span-12 grid grid-flow-row sm:col-span-${blok.width} mb-4`}
    >
      <DropdownField
        label={blok.label}
        id={blok.name}
        name={blok.name}
        value={keys.get(blok.name) || values?.[blok.name]}
        isMandatory={blok.isRequired}
        isOptional={blok.isOptional}
        options={OPTIONS}
        showFirstOptionInitially={blok.showFirstOptionInitially}
        onBlur={handleValueChange}
      />
    </div>
  );
};

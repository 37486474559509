import React, { useEffect, useState } from 'react';

import Image from 'next/image';
import { storyBlokLoader } from '@dtk/storyblok';
import { storyblokEditable } from '@storyblok/react';

const useHeadingsData = (optionalTableElements) => {
  const [headings, setHeadings] = useState([]);
  useEffect(() => {
    if (optionalTableElements?.length == 0) {
      const headingElements = Array.from(document.querySelectorAll('h2'));
      headingElements.forEach(function (element, i) {
        if (!element.id) {
          element.id = (i + 1).toString();
          element.classList.add('scroll-mt-24', 'lg:scroll-mt-36');
        }
      });
      setHeadings(headingElements);
    } else {
      setHeadings(optionalTableElements);
    }
  }, [optionalTableElements]);

  return { headings };
};

const selectHeadline = (innerText: string) => {
  const headline = innerText.split('\n');

  if (headline.length === 1) return headline[0];
  if (headline.length >= 2) return headline[1];
  return '';
};

const Headings = ({ headings, hasImage }) => {
  return headings.map((heading, i) => (
    <div
      key={i}
      className={`${hasImage ? 'w-auto ' : 'grid max-w-max justify-items-stretch'}
        ${headings.length === i ? 'relative' : 'relative pb-5'}`}
    >
      {headings.length != i + 1 && (
        <div className="bg-gold absolute left-5 -ml-px mt-0.5 h-full w-0.5" aria-hidden="true" />
      )}
      <div className="group relative flex items-start">
        <span className="flex h-9 items-center" aria-hidden="true">
          <span className="z-11 border-gold relative flex h-10 w-10 items-center justify-center rounded-full border-2 bg-white">
            <span className="z-9 bg-navy-600 absolute left-1 h-7 w-7 rounded-full" />
            <span className="z-11 relative flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600 text-white group-hover:bg-indigo-800">
              {i + 1}
            </span>
          </span>
        </span>
        <span className="ml-12 flex min-w-0 flex-col">
          <span className="mb-3 mt-1 text-xl font-semibold tracking-wide text-indigo-600">
            <a href={`#${heading.id}`} key={heading.id}>
              {selectHeadline(heading.innerText || heading.textContent)}
            </a>
          </span>
        </span>
      </div>
    </div>
  ));
};

const TableOfContentWithoutImage = ({ blok }) => {
  const { headings } = useHeadingsData(blok.Entrys);
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="grid">
      <div className={`grid grid-cols-12 bg-gray-50`}>
        <div className="text-navy-navy col-span-10 col-start-2 h-full p-4 sm:p-12 ">
          <div
            className={`font-headline text-secondary-4xl text-gold mb-6 mt-1 flex justify-center text-2xl sm:text-3xl`}
          >
            Inhaltsverzeichnis
          </div>
          <nav aria-label="Table of contents">
            <div className="m-auto max-w-max">{headings && <Headings headings={headings} hasImage={false} />}</div>
          </nav>
        </div>
      </div>
    </div>
  );
};

const TableOfContentWithImage = ({ blok }) => {
  const { headings } = useHeadingsData(blok.Entrys);

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="grid">
      <div className="grid grid-cols-12 bg-gray-50">
        <div
          className={`${blok.imageLeft ? 'md:order-2' : 'md:order-1'}
          text-navy-navy order-1 col-span-12 h-full max-w-xl p-4 sm:p-12 md:col-span-6 md:m-auto`}
        >
          <div className="font-headline text-secondary-4xl text-gold mb-6 mt-1 text-2xl sm:text-4xl">
            Inhaltsverzeichnis
          </div>
          <nav aria-label="Table of contents">{headings && <Headings headings={headings} hasImage={true} />}</nav>
        </div>
        <div className={`${blok.imageLeft ? 'md:order-1' : 'md:order-2'} min-h-300 order-2 col-span-12 md:col-span-6`}>
          <div className="image-container h-full">
            {blok.image?.filename && (
              <Image
                src={blok.image?.filename}
                loader={storyBlokLoader}
                alt={blok.image?.alt}
                className="custom-img"
                layout="fill"
                objectFit="contain"
                loading="lazy"
                {...{ fetchpriority: 'low' }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TableOfContentBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="table-of-content" id="inhaltsverzeichnis">
      {blok.image.filename ? <TableOfContentWithImage blok={blok} /> : <TableOfContentWithoutImage blok={blok} />}
    </div>
  );
};

export default TableOfContentBlok;

import { PostsCarousel } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const PostsCarouselBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="posts-carousel">
      <div className="bg-gray-50">
        <PostsCarousel
          showFilter={blok.showFilter}
          showTitle={blok.showTitle}
          title={blok.title}
          buttonText={blok.buttonText}
          buttonLink={blok.buttonLink}
          posts={blok.posts}
          categories={blok.categories}
        />
      </div>
    </div>
  );
};

import { Button, headingType, Headline } from '@dtk/ui-components';
import classNames from 'classnames';

type ImagePosition = 'right' | 'left';

export interface SplitWithImageProps {
  imagePosition: ImagePosition;
  image: SplitWithImageImageProps;
  headingType: headingType;
  headline: string;
  subtitle: string;
  buttonLink: string;
  buttonText?: string;
  content?: string;
}

export interface SplitWithImageImageProps {
  id?: number;
  alt?: string;
  name?: string;
  title?: string;
  filename: string;
}

export const SplitWithImage = ({
  imagePosition,
  image,
  headingType,
  headline,
  subtitle,
  content,
  buttonLink,
  buttonText,
}: SplitWithImageProps) => {
  const chosenHeadingType = headingType || 'h2';

  return (
    <div className="grid grid-cols-12 ">
      <div className={classNames(imagePosition === 'left' ? 'order-1' : 'order-2', 'col-span-12 sm:col-span-6')}>
        {image?.filename && (
          <img src={image.filename + '/m/960x0/smart'} alt="" className="h-full w-full object-cover" loading="lazy" />
        )}
      </div>
      <div
        className={classNames(
          imagePosition === 'left' ? 'order-2' : 'order-1',
          'bg-gold-creme col-span-12 pt-16 pl-16 pr-16 sm:col-span-6'
        )}
      >
        <Headline headingType={chosenHeadingType} headlineClassNames="text-navy font-headline" headline={headline} />
        <p className="text-navy pt-2 text-base">{subtitle}</p>

        {content && <p className="text-navy pt-2 text-base">{content}</p>}

        {buttonText && (
          <Button variant="red" className="mt-4 mb-4 w-full md:w-auto" size="lg" href={buttonLink}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedInShareButton,
  PrintButton,
  TwitterShareButton,
  WhatsAppShareButton,
} from './PlatformButtons';

export type ShareButtonsType = 'linkedIn' | 'facebook' | 'twitter' | 'email' | 'whatsApp' | 'print';

export interface ShareButtonComponentProps {
  platformUrl: string;
  articleUrl: string;
  className: string;
  icon: string;
}

export interface PlatformProps {
  href: string;
  label: string;
  Component: React.ReactNode;
}

export interface socialMediaPlatformConfig {
  [key: string]: PlatformProps;
}

export const socialMediaPlatformConfig = {
  linkedIn: {
    // 'https://www.linkedin.com/shareArticle?&url=https://deutsche-teilkauf.de/blog/steigende-heizkosten-was-die-wohngeldreform-ihnen-bringen-kann&title=document-title&&summary=YourarticleSummary&source=asdasdsd'
    href: 'https://www.linkedin.com/shareArticle?&',
    label: 'LinkedIn',
    Component: LinkedInShareButton,
    icon: 'https://a.storyblok.com/f/148087/x/40b7e6fa6a/icon-linked-in.svg',
  },
  facebook: {
    // href: 'https://www.facebook.com/sharer.php?u=https://example.com/blog/blog-article',
    href: 'https://www.facebook.com/sharer.php?u=',
    label: 'Share on Facebook',
    Component: FacebookShareButton,
    icon: 'https://a.storyblok.com/f/148087/x/e8d8292f02/icon-facebook.svg',
  },
  twitter: {
    // href: "https://twitter.com/intent/tweet?url=https://example.com&text=Example%20Text",
    href: 'https://twitter.com/intent/tweet?',
    label: 'Twitter',
    Component: TwitterShareButton,
    icon: 'https://a.storyblok.com/f/148087/x/c1793a0f09/icon-twitter.svg',
  },
  email: {
    // href: "mailto:?subject=Blog%20Article&body=https://example.com/blog/blog-article",
    href: 'mailto:?',
    label: 'Email',
    Component: EmailShareButton,
    icon: 'https://a.storyblok.com/f/148087/x/426fd5d875/icon-e-mail.svg',
  },
  whatsApp: {
    // href: 'whatsapp://send?text=This is WhatsApp sharing example using link',
    href: 'whatsapp://send?',
    label: 'WhatsApp',
    Component: WhatsAppShareButton,
    icon: 'https://a.storyblok.com/f/148087/x/28589cdfd7/icon-whatsapp.svg',
  },
  print: {
    // href: 'whatsapp://send?text=This is WhatsApp sharing example using link',
    href: '',
    label: 'Drucken',
    Component: PrintButton,
    icon: '',
  },
};

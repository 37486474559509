import { storyblokEditable } from '@storyblok/react';
import { useEffect, useState } from 'react';
import { LoaderWithImage } from './LoaderWithImage/LoaderWithImage';

export const LoaderWithImageBlok = ({ ...props }) => {
  const { blok } = props;

  const [progress, setProgress] = useState(10);
  const [activeLoader, setActiveLoader] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          setActiveLoader(false);
          window.location = blok.redirectUrl;
          return 100;
        }
        return prevProgress >= 100 ? 0 : prevProgress + 10;
      });
    }, blok.duration);
    return () => {
      clearInterval(timer);
    };
  }, [activeLoader, blok.duration, blok.redirectUrl]);

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="loader-with-image">
      <LoaderWithImage
        ccvalue={progress}
        image={blok.image}
        subtitle={blok.subtitle}
        title={blok.title}
        firstCheckingText={blok.firstCheckingText}
        secondCheckingText={blok.secondCheckingText}
        thirdCheckingText={blok.thirdCheckingText}
      />
    </div>
  );
};

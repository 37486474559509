import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Breadcrumbs } from '../../utils';
import { render } from 'storyblok-rich-text-react-renderer';
import styles from './PressePostPage.module.scss';

export interface PressePostPageProps {
  content: ContentProps;
}

interface ContentProps {
  featured_image: ImageProps;
  featured_image_text: string;
  reading_time: string;
  title: string;
  excerpt: string;
  content: string[];
}

interface ImageProps {
  filename: string;
  alt: string;
}

export const PressePostPage = ({ content }: PressePostPageProps) => {
  return (
    <div
      className={styles['postPage'] + ' y-14 relative mx-auto flex flex-col md:max-w-xl lg:max-w-2xl xl:max-w-[50rem]'}
    >
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <Breadcrumbs />
        <h1 className="text-navy font-headline text-4xl">{content?.title}</h1>
        <p className="text-navy mt-5 mb-10 text-2xl">{content?.excerpt}</p>
        {content?.featured_image?.filename && (
          <img
            className="rounded-sm"
            src={content?.featured_image.filename + '/m/960x0/smart'}
            alt={content?.featured_image.alt}
          />
        )}
        <span className="text-2xs text-gray-500">{content?.featured_image_text}</span>
        <div className="post-content richtext-editor-styles my-5">{render(content?.content)}</div>
      </div>
    </div>
  );
};

import 'rc-slider/assets/index.css';
import 'react-circular-progressbar/dist/styles.css';

import { formatCurrency } from '@dtk/formatter';
import {
  Button,
  InputFieldWithAddOn,
  TrustPilotBox,
  getTrustPilotWidgetStyles,
  useMediaQuery,
  validationMessages,
  getAlternativePath,
  ImageProps,
} from '@dtk/ui-components';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { Form, Formik } from 'formik';
import { useState } from 'react';

import { Tooltip } from '../../01_elements/Tooltip';
import { BackgroundImage } from './BackgroundImage';
import { CertificationIcon } from './CertificationIcon';
import styles from './QuickRechner.module.scss';

export interface QuickRechnerProps {
  blok: BlokProps;
}

interface BlokProps {
  backgroundImage?: ImageProps;
  showBanner?: string;
  disruptorImage?: ImageProps;
  disruptorHeader?: string;
  disruptorSubline?: string;
  disruptorButtonName?: string;
  disruptorButtonLink?: string;
  iconImage?: ImageProps;
  iconLink?: string;
  headline?: string;
  slogan?: string;
  paragraphForSlogan?: string;
  inputLabelRequestedPayout?: string;
  inputTooltipRequestedPayout?: string;
  placeholderRequestedPayout?: string;
  usageFeeText?: string;
  usageFeeTooltip?: string;
  offerButtonText?: string;
  offerButtonTextOnSmallDevice?: string;
  offerButtonLink?: string;
}

export const QuickRechner = (props: QuickRechnerProps) => {
  const {
    backgroundImage,
    showBanner,
    disruptorImage,
    disruptorHeader,
    disruptorSubline,
    disruptorButtonName,
    disruptorButtonLink,
    iconImage,
    iconLink,
    headline: overline,
    slogan,
    paragraphForSlogan,
    inputLabelRequestedPayout,
    inputTooltipRequestedPayout,
    placeholderRequestedPayout,
    usageFeeText,
    usageFeeTooltip,
    offerButtonText,
    offerButtonTextOnSmallDevice,
    offerButtonLink,
  } = props.blok;

  const { smallerXs, smallerSm } = useMediaQuery();
  const [requestedPayout, setRequestedPayout] = useState<number>(0);

  const requestOfferPath =
    offerButtonLink && getAlternativePath({ originalLink: offerButtonLink, condition: smallerSm });

  const initValues = {
    requestedPayout: '',
  };

  function calculateUsageFee() {
    if (requestedPayout === 0 || isNaN(requestedPayout)) return 0;
    return Math.round((requestedPayout * 0.0499) / 12);
  }

  return (
    <div className="text-navy relative lg:mb-40">
      <BackgroundImage imageFilename={backgroundImage?.filename} imageAltText={backgroundImage?.alt} />
      {iconImage?.filename && (
        <CertificationIcon
          styles="absolute hidden lg:inline right-[1.5rem] top-[28rem] xl:top-[31.5rem] xl:right-[4.5rem] cursor-pointer"
          iconLink={iconLink}
          showBanner={showBanner}
          iconImageFilename={iconImage.filename}
          iconImageText={iconImage?.alt}
        />
      )}
      <div className="grid grid-cols-12 md:px-[1.5rem] lg:pl-[2rem] xl:mx-auto xl:max-w-7xl">
        <div
          className={`${styles['box-shadow']} relative -top-[5rem] col-span-12 bg-white py-10 px-6 shadow-xl lg:top-[8rem] lg:col-span-5 lg:-mt-20 lg:py-6`}
        >
          {iconImage?.filename && (
            <CertificationIcon
              styles="absolute lg:hidden inline right-[1rem] md:right-[2rem] top-[-4rem] xs:top-[-5.25rem] md:top-[-6.5rem]"
              iconLink={iconLink}
              showBanner={showBanner}
              iconImageFilename={iconImage.filename}
              iconImageText={iconImage?.alt}
            />
          )}
          <p className="text-gold-gold mb-3 text-sm tracking-[.2em] md:mb-1">{overline}</p>
          <h1 className="font-headline-semibold mb-3 text-2xl leading-normal md:mb-4 md:text-3xl md:leading-normal lg:text-2xl lg:leading-normal xl:text-3xl xl:leading-normal">
            {slogan}
          </h1>
          <p className="mb-3 text-base leading-normal antialiased xl:mb-6 xl:text-lg">{paragraphForSlogan}</p>
          <Formik initialValues={initValues} onSubmit={() => undefined}>
            {({ values }) => (
              <>
                {setRequestedPayout(parseFloat(values.requestedPayout) || 0)}
                <Form>
                  <div className="flex flex-col justify-between lg:flex-row">
                    {inputLabelRequestedPayout && (
                      <span className="mr-2 flex items-center gap-2">
                        {inputLabelRequestedPayout}
                        <Tooltip className="-right-16 lg:-right-24" text={inputTooltipRequestedPayout}>
                          <ExclamationCircleIcon className="h-5 w-5 cursor-pointer" />
                        </Tooltip>
                      </span>
                    )}
                  </div>
                  <div className="mb-4">
                    <InputFieldWithAddOn
                      suffix="€"
                      id="requestedPayout"
                      name="requestedPayout"
                      infoMessage={validationMessages.info.requestedPayout}
                      placeholder={placeholderRequestedPayout}
                    />
                  </div>
                  <div className="mb-4 flex flex-row justify-between lg:mb-8">
                    <div className="flex items-start">
                      <span className="mr-2">{usageFeeText}</span>
                      <Tooltip className="-right-8 lg:-right-16" text={usageFeeTooltip}>
                        <ExclamationCircleIcon className="h-5 w-5 cursor-pointer" />
                      </Tooltip>
                    </div>
                    <span className="ml-2">{formatCurrency(calculateUsageFee(), 0)}</span>
                  </div>
                  <Button
                    className="my-2 lg:my-0"
                    href={`${requestOfferPath}?requested-payout=${requestedPayout}`}
                    fullWidth
                  >
                    {smallerXs ? offerButtonTextOnSmallDevice : offerButtonText}
                  </Button>
                  <div className="mt-3 flex justify-center lg:mt-5">
                    <TrustPilotBox
                      width="250px"
                      height="40px"
                      reviewStyle={getTrustPilotWidgetStyles().microTrustScore}
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
      {disruptorButtonLink && showBanner === 'disruptor' && disruptorImage?.filename && (
        <div className="mb-5 mt-10 flex justify-center lg:flex-none">
          <div className="bg-creme xs:inline-flex items-center rounded py-5 px-4 shadow-xl lg:absolute lg:bottom-[10%] lg:right-[2%] xl:bottom-[29%]">
            <div className="inline-flex">
              <div className="min-h-[2.25rem] min-w-[2.25rem] pr-5">
                <img src={disruptorImage.filename} alt={disruptorImage.alt} />
              </div>
              <div className="grid grid-cols-1 text-base sm:text-xl">
                <p className="col-span-1 font-black">{disruptorHeader}</p>
                <p className="col-span-1 font-thin">{disruptorSubline}</p>
              </div>
            </div>
            {disruptorButtonName && (
              <Button
                className={`xs:ml-5 text-center ${smallerXs && 'mt-3 w-full px-[32%]'}`}
                href={disruptorButtonLink}
              >
                {disruptorButtonName}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

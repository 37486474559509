import React, { useState } from 'react';
import { useRouter } from 'next/router';

//import { AngebotsstreckeContext } from '../../contexts/AngebotsstreckeContext';
import { useFormikContext } from 'formik';
import { sendOffer } from './sendOffer';

import { Button } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';
import { useAppConfig } from '@dtk/config';
import { SpinnerAngebotsstrecke } from '@dtk/public-website-ui-components';

const SaveFormBlok = ({ blok }) => {
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);
  //const { setSaveDataLocalstorage } = useContext(AngebotsstreckeContext);
  const { isValid, dirty, values } = useFormikContext();
  const { config } = useAppConfig();
  const router = useRouter();

  return (
    <div className="col-span-6" {...storyblokEditable(blok)} data-test="save-form-button">
      <Button
        size="lg"
        fullWidth
        onClick={() => {
          setIsSendingRequest(true);
          //setSaveDataLocalstorage(true); // temporary disable local storage intermediate save
          sendOffer(values, config);
          setTimeout(() => {
            router.push(blok.redirectionPath);
          }, 1000 * blok.timeUntilRedirectionInSeconds);
        }}
        disabled={!(dirty && isValid) || isSendingRequest}
      >
        {!isSendingRequest && blok.buttonText}
        {isSendingRequest && <SpinnerAngebotsstrecke />}
      </Button>
    </div>
  );
};

export default SaveFormBlok;

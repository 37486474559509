import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { Breadcrumbs } from '../../utils';
import { FetchingPriority, ImageComponent } from '@dtk/ui-components';
import { TopAuthorBlogPostPage } from '../Author/TopAuthorBlogPostPage';
import { render } from 'storyblok-rich-text-react-renderer';
import styles from './BlogPostPage.module.scss';

export interface BlogPostPageProps {
  content: ContentProps;
  story: {
    first_published_at: string;
    published_at: string;
  };
}

interface ContentNestedBlockProps {
  component: string;
}

interface ContentProps {
  blocks?: ContentNestedBlockProps[];
  featured_image: ImageProps;
  featured_image_text: string;
  reading_time: string;
  title: string;
  excerpt: string;
  content: string[];
  author: {
    name: string;
    content: {
      author_email: string;
      author_name: string;
      author_position: string;
      author_image: {
        alt: string;
        filename: string;
        title: string;
      };
    };
    full_slug: string;
  };
  MetaTags: {
    description: string;
  };
}

interface ImageProps {
  filename: string;
  alt: string;
  title: string;
}

export const BlogPostPage = ({ content, story }: BlogPostPageProps) => {
  const nestedBlocks = content.blocks;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const blogSummary: any = nestedBlocks?.find((block) => block.component === 'blog_post_summary_select');
  const summaryContent = blogSummary?.blog_post_summary?.content as ContentProps;

  const hasFeaturedImage = !!summaryContent?.featured_image?.filename || !!content?.featured_image?.filename;

  const getImage = () => {
    return {
      Caption: summaryContent?.featured_image.title || content?.featured_image.title,
      BigImage: {
        filename: summaryContent?.featured_image.filename || content?.featured_image.filename,
        alt: summaryContent?.featured_image.alt || content?.featured_image.alt,
      },
      maxWidth: 736,
      fetchingpriority: 'high' as FetchingPriority,
    };
  };

  return (
    <div
      className={styles['postPage'] + ' y-14 relative mx-auto flex flex-col md:max-w-xl lg:max-w-2xl xl:max-w-[50rem]'}
    >
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <Breadcrumbs />
        <h1 className="text-navy font-headline text-4xl	font-medium leading-snug">
          {summaryContent?.title || content?.title}
        </h1>
        <TopAuthorBlogPostPage
          author={summaryContent?.author || content?.author}
          firstPublishedAt={story.first_published_at}
          publishedAt={story.published_at}
        />
        <p className="text-navy mt-5 mb-10 text-2xl">{content?.excerpt}</p>
        {hasFeaturedImage && <ImageComponent data={getImage()} />}
        <span className="text-2xs text-gray-500">{content?.featured_image_text}</span>
        <div className="richtext-editor-styles my-5">{render(content?.content)}</div>
      </div>
    </div>
  );
};

import { number, object, string } from 'yup';
import {
  MAXIMUM_CONSTRUCTION_YEAR,
  MAXIMUM_LAND_AREA,
  MAXIMUM_LIVING_SPACE,
  MINIMUM_CONSTRUCTION_YEAR,
  MINIMUM_EXISTING_LOADS_AMOUNT,
  MINIMUM_LAND_AREA,
  MINIMUM_LIVING_SPACE,
  MINIMUM_REQUESTED_PAYOUT,
  validationMessages,
} from '@dtk/ui-components';

const INPUT_FIELD_DEFAULT_ERROR = validationMessages.error.defaultInputField.text;
const DROPDOWN_DEFAULT_ERROR = validationMessages.error.defaultDropdownField.text;

const registrationDataSchema = {
  salutation: string().required(DROPDOWN_DEFAULT_ERROR),
  company: string().required(INPUT_FIELD_DEFAULT_ERROR),
  ihkNumber: string().required(INPUT_FIELD_DEFAULT_ERROR),
  firstName: string().required(INPUT_FIELD_DEFAULT_ERROR),
  lastName: string().required(INPUT_FIELD_DEFAULT_ERROR),
  email: string().email(validationMessages.error.email.text).required(INPUT_FIELD_DEFAULT_ERROR),
  street: string().required(INPUT_FIELD_DEFAULT_ERROR),
  houseNr: string().required(INPUT_FIELD_DEFAULT_ERROR),
  plz: string()
    .length(5, validationMessages.error.zipCode.text)
    .matches(/^\d+$/, validationMessages.error.zipCode.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  city: string().required(INPUT_FIELD_DEFAULT_ERROR),
  phone: string().required(INPUT_FIELD_DEFAULT_ERROR),
};

const personalDataSchema = {
  estateAgentID: string().length(14, validationMessages.error.estateAgentID.text).required(INPUT_FIELD_DEFAULT_ERROR),
  salutation: string().required(DROPDOWN_DEFAULT_ERROR),
  firstName: string().required(INPUT_FIELD_DEFAULT_ERROR),
  lastName: string().required(INPUT_FIELD_DEFAULT_ERROR),
  email: string().email(validationMessages.error.email.text).required(INPUT_FIELD_DEFAULT_ERROR),
  phone: string().required(INPUT_FIELD_DEFAULT_ERROR),
  street: string().required(INPUT_FIELD_DEFAULT_ERROR),
  houseNumber: string().required(INPUT_FIELD_DEFAULT_ERROR),
  zipCode: string()
    .length(5, validationMessages.error.zipCode.text)
    .matches(/^\d+$/, validationMessages.error.zipCode.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  city: string().required(INPUT_FIELD_DEFAULT_ERROR),
  requestedPayout: number()
    .typeError(INPUT_FIELD_DEFAULT_ERROR)
    .min(MINIMUM_REQUESTED_PAYOUT, validationMessages.error.requestedPayout.min.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  existingLoads: string().required(DROPDOWN_DEFAULT_ERROR),
  existingLoadsAmount: number().when('existingLoads', {
    is: 'Ja',
    then: number()
      .min(MINIMUM_EXISTING_LOADS_AMOUNT, validationMessages.error.existingLoadsAmount.min.text)
      .required(INPUT_FIELD_DEFAULT_ERROR)
      .test({
        name: 'max',
        exclusive: false,
        message: validationMessages.error.existingLoadsAmount.max.text,
        test: function (value) {
          return (value || 0) <= parseInt(this.parent.requestedPayout);
        },
      }),
    otherwise: number(),
  }),
};

const realEstateTypeSchema = {
  objectType: string().required(DROPDOWN_DEFAULT_ERROR),
  buildingType: string().when('objectType', {
    is: 'Haus',
    then: string().required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
  livingSpace: number()
    .min(MINIMUM_LIVING_SPACE, validationMessages.error.livingSpace.min.text)
    .max(MAXIMUM_LIVING_SPACE, validationMessages.error.livingSpace.max.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  landArea: number()
    .min(MINIMUM_LAND_AREA, validationMessages.error.landArea.min.text)
    .max(MAXIMUM_LAND_AREA, validationMessages.error.landArea.max.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  constructionYear: number()
    .min(MINIMUM_CONSTRUCTION_YEAR, validationMessages.error.constructionYear.min.text)
    .max(MAXIMUM_CONSTRUCTION_YEAR, validationMessages.error.constructionYear.max.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  floorCount: number().when('objectType', {
    is: 'Haus',
    then: number().min(1, validationMessages.error.floorCount.min.text).required(INPUT_FIELD_DEFAULT_ERROR),
    otherwise: number(),
  }),
  construction: string().required(DROPDOWN_DEFAULT_ERROR),
  loftConversion: string().required(DROPDOWN_DEFAULT_ERROR),
  basementType: string().required(DROPDOWN_DEFAULT_ERROR),
  basementExtension: string().when('basementType', {
    is: (value: string) => value === 'vollunterkellert' || value === 'teilweiseunterkellert',
    then: string().required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
};

export const registrationDataValidationSchema = object(registrationDataSchema);
export const personalDataValidationSchema = object(personalDataSchema);
export const realEstateTypeValidationSchema = object(realEstateTypeSchema);
export const allLeadTransmissionValidationSchema = object({ ...personalDataSchema, ...realEstateTypeSchema });

import { Form, Formik } from 'formik';
import { useState } from 'react';
import { object } from 'yup';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import {
  Button,
  InputFieldWithAddOn,
  StoryblokIcon,
  useMediaQuery,
  getAlternativePath,
  propertyFields,
} from '@dtk/ui-components';

import { Circle } from './Circle';
import { calculateMonthlyUsageFee } from './estateCalculations';
import { PercentageComponent } from './PercentageComponent';
import { LabelWithTooltipAndCalculatedValue } from './LabelWithTooltipAndCalculatedValue';

export interface RechnerSofortauszahlungProps {
  inputLabelValueOfProperty: string;
  inputTooltipValueOfProperty: string;
  inputLabelDesiredPayment: string;
  inputTooltipDesiredPayment: string;
  usageFeeText: string;
  usageFeeTooltip: string;
  calculateButtonText: string;
  offerButtonText: string;
  offerButtonLink: string;
  widget?: SbBlokData[];
  theme?: string;
}

export const RechnerSofortauszahlung = (props: RechnerSofortauszahlungProps) => {
  const { smallerSm } = useMediaQuery();

  const {
    inputLabelValueOfProperty,
    inputTooltipValueOfProperty,
    inputLabelDesiredPayment,
    inputTooltipDesiredPayment,
    usageFeeText,
    usageFeeTooltip,
    calculateButtonText,
    offerButtonText,
    offerButtonLink,
    widget,
    theme: backgroundColor = 'coolGray-50',
  } = props;

  const OFFER_JOURNEY_PAGE_SLUG = '/angebot-anfordern';
  const offerLink = getAlternativePath({ originalLink: offerButtonLink, condition: smallerSm });
  const validationSchema = object(propertyFields);

  const queryParams = new URLSearchParams(window.location.search);
  const queryRequestedPayout = queryParams.get('requested-payout');
  const initValues = {
    realEstateValue: '',
    requestedPayout: queryRequestedPayout ? queryRequestedPayout : '',
  };

  const [providedValues, setProvidedValues] = useState(initValues);
  const { requestedPayout, realEstateValue } = providedValues;
  const parsedRequestedPayout = parseInt(requestedPayout);
  const parsedRealEstateValue = parseInt(realEstateValue);

  const percentageDtk =
    parsedRealEstateValue > 0 ? Math.round((parsedRequestedPayout / parsedRealEstateValue) * 100) : 0;

  const onSubmitHandler = (values: { realEstateValue: string; requestedPayout: string }) => {
    setProvidedValues({
      realEstateValue: values.realEstateValue,
      requestedPayout: values.requestedPayout,
    });
  };

  return (
    <div className={`bg-${backgroundColor}`}>
      <div className="xs:px-10 mx-auto flex max-w-7xl flex-col-reverse px-5 pt-10 lg:grid lg:grid-cols-12 lg:gap-20">
        <div className="col-span-12 hidden self-center md:block lg:col-span-7">
          <div className="grid grid-cols-12 self-center md:gap-10 lg:gap-5">
            <div className="col-span-4 self-center pt-40">
              <PercentageComponent owner="DTK" percent={percentageDtk} amount={parsedRequestedPayout || 0} />
            </div>
            <div className="col-span-8 self-end">
              <Circle anteilDtk={percentageDtk || 0}>
                <div className="absolute top-0 bottom-0 right-0 left-0 m-auto flex items-center justify-center">
                  <PercentageComponent
                    owner="user"
                    percent={100 - percentageDtk}
                    amount={parsedRealEstateValue - parsedRequestedPayout || 0}
                  />
                </div>
              </Circle>
            </div>
          </div>
        </div>
        <div className="col-span-12 -mt-10 mb-6 block md:hidden">
          <div>
            <PercentageComponent owner="DTK" percent={percentageDtk} amount={parsedRequestedPayout || 0} />
          </div>
          <div className="border-t-coolGray-300 mt-2 border-t">
            <PercentageComponent
              owner="user"
              percent={100 - percentageDtk}
              amount={parsedRealEstateValue - parsedRequestedPayout || 0}
            />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-5">
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmitHandler}
          >
            <Form>
              <div className="mb-4 flex flex-col">
                <LabelWithTooltipAndCalculatedValue
                  label={inputLabelValueOfProperty}
                  tooltipText={inputTooltipValueOfProperty}
                />
                <InputFieldWithAddOn
                  suffix="€"
                  className="text-navy-navy h-12 w-full rounded-l-sm border-r-0 border-gray-200 lg:w-64"
                  id="realEstateValue"
                  name="realEstateValue"
                  type="number"
                  placeholder="z.B. 200.000"
                  infoMessage={{ text: inputTooltipValueOfProperty, highlightedPart: '' }}
                />
              </div>
              <div className="mb-4 flex flex-col">
                <LabelWithTooltipAndCalculatedValue
                  label={inputLabelDesiredPayment}
                  tooltipText={inputTooltipDesiredPayment}
                />
                <InputFieldWithAddOn
                  suffix="€"
                  className="text-navy-navy h-12 w-full rounded-l-sm border-r-0 border-gray-200 lg:w-64"
                  id="requestedPayout"
                  name="requestedPayout"
                  type="number"
                  placeholder="z.B. 100.000"
                  infoMessage={{ text: inputTooltipDesiredPayment, highlightedPart: '' }}
                />
              </div>
              <Button
                className="bg-navy-navy my-8"
                type="submit"
                fullWidth
                variant="navy"
                size="lg"
                storyblokIconSource="https://a.storyblok.com/f/148087/17x16/e7ddf6504b/calculator.svg"
                iconPosition="left"
              >
                {calculateButtonText}
              </Button>
              <LabelWithTooltipAndCalculatedValue
                containerClassNames="mb-4 xs:flex-row xs:space-y-0 flex flex-col justify-between space-y-2"
                label={usageFeeText}
                tooltipText={usageFeeTooltip}
                calculatedValue={calculateMonthlyUsageFee(requestedPayout)}
                calculatedValueClassNames="text-navy-navy mr-2 text-2xl font-bold ml-auto mb-4"
              />
              <div className="mr-2 flex flex-row items-start text-lg">
                <StoryblokIcon
                  className="mt-1"
                  iconSource="https://a.storyblok.com/f/148087/x/eb10faeee1/check-badge.svg"
                  width={16}
                />
                <p className="text-navy-navy ml-2 mb-2">
                  <span className="font-body-semibold">Sie behalten weiterhin die Entscheidungshoheit</span> über Ihre
                  Immobilie
                </p>
              </div>
              <div className="mr-2 flex flex-row items-start text-lg">
                <StoryblokIcon
                  className="mt-1"
                  iconSource="https://a.storyblok.com/f/148087/x/eb10faeee1/check-badge.svg"
                  width={16}
                />
                <p className="text-navy-navy ml-2 mb-2">
                  <span className="font-body-semibold">Sie bleiben zu 50 % Eigentümer,</span> die Deutsche Teilkauf wird
                  zu 50 % stiller Miteigentümer
                </p>
              </div>

              <Button
                type="button"
                className="my-8"
                fullWidth
                size="lg"
                onClick={() => {
                  window.location.href =
                    requestedPayout && offerLink.includes(OFFER_JOURNEY_PAGE_SLUG)
                      ? `${offerLink}?requested-payout=${requestedPayout}`
                      : `${offerLink}`;
                }}
              >
                {offerButtonText}
              </Button>

              <div className="-mt-6 flex justify-center pb-8">
                {widget &&
                  widget.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
                  ))}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

import classNames from 'classnames';

export const TableColumn = ({ ...props }) => {
  const { blok, isFixed, isHighlighted } = props['data'];

  return (
    <th
      className={classNames(
        isFixed ? 'min-w-56.25' : 'min-w-[10rem]',
        isHighlighted && '!bg-gold-100',
        '!text-navy !rounded-none !border-0 !p-8 align-top font-bold'
      )}
    >
      <span className="block">{blok.content}</span>
    </th>
  );
};

import Link from 'next/link';
import { ReactNode } from 'react';
import { MARK_BOLD, MARK_LINK, NODE_HEADING, NODE_UL, render } from 'storyblok-rich-text-react-renderer';

import { Icon, ImageComponent } from '../01_elements';
import { Table } from '../02_composite/';

interface MarkLinkProps {
  href?: string | undefined;
  target?: string | undefined;
  linktype?: string | undefined;
}

interface HeadingProps {
  children: ReactNode;
  level: number;
}

interface RichtextProps {
  content: unknown;
}

export interface RichtextContentProps {
  type?: string;
  content: RichtextContentProps[];
  text?: string;
}

const RichTextHeader = ({ children, level }: HeadingProps): JSX.Element => {
  level = level === 1 ? 2 : level;
  const HeadingComponent = `h${level}` as keyof JSX.IntrinsicElements;
  return <HeadingComponent>{children}</HeadingComponent>;
};

export const defaultBlockResolver = (name: string, props: Record<string, unknown>) => (
  <>
    <code>Missing blok resolver for blok type "{name}".</code>
    <pre>
      <code>{JSON.stringify(props, undefined, 2)}</code>
    </pre>
  </>
);

export const blokResolvers = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ImageComponent: (props: any) => {
    return <ImageComponent data={{ ...props, maxWidth: 736 }} />;
  },
  Table: (props: Record<string, unknown>) => {
    return <Table blok={props} />;
  },
  Icon: (props: Record<string, unknown>) => {
    return <Icon blok={props} />;
  },
};

export const markResolvers = {
  [MARK_BOLD]: (children: ReactNode) => <span className="font-body-bold">{children}</span>,
  [MARK_LINK]: (children: ReactNode, props: MarkLinkProps) => {
    const href = props.href || '';
    const target = props.target;

    if (target === '_blank') {
      return (
        <a href={href} target={target} rel="noopener noreferrer nofollow">
          {children}
        </a>
      );
    }

    if (target === '_self' && !href?.endsWith('/')) {
      return (
        <Link href={href + '/'} target={target}>
          <a href={href + '/'} target={target}>
            {children}
          </a>
        </Link>
      );
    }

    return (
      <Link href={href} target={target}>
        <a href={href} target={target}>
          {children}
        </a>
      </Link>
    );
  },
};

export const nodeResolvers = {
  [NODE_UL]: (children: ReactNode) => <ul className="list">{children}</ul>,
  [NODE_HEADING]: (children: ReactNode, { level }: { level: number }) => (
    <RichTextHeader children={children} level={level} />
  ),
};

export const RichtextContent = ({ content }: RichtextProps) => {
  return (
    <div className="richtext-editor-styles">
      {render(content, {
        defaultBlokResolver: defaultBlockResolver,
        blokResolvers: blokResolvers,
        nodeResolvers: nodeResolvers,
        markResolvers: markResolvers,
      })}
    </div>
  );
};

export const hasRichtextElement = (richtextContent: RichtextContentProps[]): boolean =>
  richtextContent.reduce((exists, element) => {
    if (exists) return true;
    if (element['text']) return true;
    if (element.content) {
      return hasRichtextElement(element.content);
    }
    return false;
  }, false);

import classNames from 'classnames';
import { ErrorState, ErrorStateVariants } from './ErrorState';
import { Spinner } from '../01_elements';
import { InfoCard } from '../02_composite';

export enum CardSize {
  SMALL = 'xs',
  MEDIUM = 'md',
  LARGE = 'lg',
}

interface QueryStatusCardProps {
  containerClassNames?: string;
  title?: string;
  tooltipText?: string;
  isLoading?: boolean;
  message?: string;
  state?: ErrorStateVariants;
  cardSize?: CardSize;
}

const getCardStyle = (cardSize: CardSize) => {
  switch (cardSize) {
    case CardSize.SMALL:
      return classNames('col-span-12 xs:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-3');
    case CardSize.MEDIUM:
      return classNames('col-span-12 xs:col-span-12 lg:col-span-6');
    case CardSize.LARGE:
      return classNames('col-span-12 xs:col-span-12');
  }
};

export function QueryStatusCard({
  containerClassNames,
  title,
  tooltipText,
  message,
  isLoading = false,
  state,
  cardSize = CardSize.SMALL,
}: QueryStatusCardProps) {
  return (
    <div className={classNames('h-full shadow-lg', getCardStyle(cardSize))}>
      <InfoCard
        title={title ?? ''}
        tooltipText={tooltipText ?? ''}
        containerStyle={classNames('xs:pb-2 md:pb-0 min-h-[380px] rounded-lg', containerClassNames)}
      >
        <div className="text-coolGray-400 flex h-full items-center justify-center">
          {isLoading ? <Spinner /> : message || <ErrorState state={state ?? 'tryAgainLater'} />}
        </div>
      </InfoCard>
    </div>
  );
}

import { PeopleSection, Section } from '@dtk/public-website-ui-components';

import { Container } from '@dtk/ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const PeopleSectionBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="people-section">
    <Container className="bg-white">
      <Section
        className="bg-white"
        variant="white"
        headingType={blok.headingType}
        headline={blok.headline}
        subline={blok.subline}
      >
        <PeopleSection people={blok.people} />
      </Section>
    </Container>
  </div>
);

export default PeopleSectionBlok;

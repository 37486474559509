import { StoryblokIcon } from '@dtk/ui-components';

interface NavigationIconWithTextMobileProps {
  iconSource: string;
  iconText: string;
}

export const NavigationIconWithTextMobile = ({
  iconSource,

  iconText,
}: NavigationIconWithTextMobileProps) => {
  return (
    <div className="grid grid-cols-1 justify-items-center gap-y-0.5">
      <StoryblokIcon iconSource={iconSource} alt={iconText} width={18} />
      <p className="text-navy text-sm font-normal">{iconText}</p>
    </div>
  );
};

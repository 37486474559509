import classNames from 'classnames';
import { HeaderText, headingType } from '../01_elements';
import { BackgroundThemeVariants, ImageProps } from '../utils';

export interface ThankYouSectionProps {
  iconimage?: ImageProps;
  hasShadow: boolean;
  backgroundTheme: BackgroundThemeVariants;
  overline?: string;
  headline?: string;
  subtitle?: string;
  headingType: headingType;
}

export const ThankYouSection = ({
  iconimage,
  hasShadow,
  backgroundTheme,
  overline,
  headline = '',
  subtitle,
  headingType = 'h1',
}: ThankYouSectionProps) => {
  const chosenTheme = backgroundTheme || 'navy-50';

  return (
    <div className={classNames(`bg-${chosenTheme}`, 'w-full pb-[0rem] pt-[0rem] lg:pb-[4.034rem] lg:pt-[4.034rem]')}>
      <div
        className={classNames(
          hasShadow ? 'bg-white shadow-md' : `bg-${chosenTheme}`,
          'mx-auto w-full p-[1rem] lg:w-[51rem]'
        )}
      >
        {iconimage?.filename && (
          <div className="pt-[4rem] pb-[2.375rem]">
            <img src={iconimage.filename} alt={iconimage.alt} className="max-[9rem] mx-auto max-w-[9rem]" />
          </div>
        )}
        <HeaderText
          headingType={headingType}
          overline={overline}
          headline={headline}
          subtitle={subtitle}
          theme={hasShadow ? 'white' : chosenTheme}
        />
      </div>
    </div>
  );
};

import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

export const ListBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <ul key={blok._uid} className="" {...storyblokEditable(blok)} data-test="list">
      {blok?.items?.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </ul>
  );
};

/**
 * Formats a number to square meter.
 * Default is without decimals in format "25 m²"
 */
export const formatSquareMeter = (value: number, decimals = 0) => {
  if (!value) {
    return 'k.A.';
  }

  return (
    value.toLocaleString('DE-de', {
      maximumFractionDigits: value % 2 === 0 ? 0 : decimals,
    }) + ' m²'
  );
};

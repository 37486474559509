import React from 'react';
import { SubPublicHeroSection } from '@dtk/public-website-ui-components';
import { storyblokEditable } from '@storyblok/react';
const SubPublicHeroSectionBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="sub-public-hero-section">
    <SubPublicHeroSection headline={blok.headline} subline={blok.subline} />
  </div>
);

export default SubPublicHeroSectionBlok;

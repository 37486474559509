import { headingType, Headline } from '@dtk/ui-components';
import { themesForBackgroundColor, BackgroundThemeVariants } from '@dtk/ui-components';
import classNames from 'classnames';

export interface SectionHeaderProps {
  variant: BackgroundThemeVariants;
  overline?: string;
  headingType: headingType;
  headline: string;
  subline?: string;
  children: React.ReactNode;
  className?: string;
}

export function Section({
  variant,
  overline,
  headingType,
  headline,
  subline,
  children,
  className,
}: SectionHeaderProps) {
  const chosenHeadingType = headingType || 'h2';
  const textColor = themesForBackgroundColor.darkBackgroundColors.includes(variant) ? 'text-white' : 'text-navy';

  return (
    <div className={className}>
      <div className="flex flex-wrap justify-center p-0 py-10 sm:flex-nowrap sm:p-20">
        <div className="max-w-7xl">
          {overline && (
            <p
              className={classNames(
                `text-${themesForBackgroundColor.overlineColor[variant]}`,
                'mb-1 text-center text-xs uppercase leading-[2rem] tracking-[.2rem] sm:text-sm'
              )}
            >
              {overline}
            </p>
          )}
          <Headline
            headingType={chosenHeadingType}
            headlineClassNames={classNames(textColor, 'font-headline text-center sm:mx-0')}
            headline={headline}
          />
          {subline && <p className={classNames(textColor, 'mt-3 text-center text-lg sm:mx-0')}>{subline}</p>}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}

import classNames from 'classnames';

import { ButtonHero } from './ButtonHero';
import { HeadlineHero } from './HeadlineHero';
import { HeroBackgroundImage } from './HeroBackgroundImage';
import { HeroCertificateIcon } from './HeroCertificateIcon';
import { HeroContainer } from './HeroContainer';
import { HeroDescription } from './HeroDescription';
import { HeroStoerer } from './HeroStoerer';
import { PublichHeroSectionV2Props } from './types';

export const PublicHeroSectionV2 = ({
  theme,
  backgroundImage,
  backgroundImageMobile,
  loadingPriority,
  overlineColor,
  overline,
  headingType = 'h1',
  headlineText,
  subHeadlineText,
  highlightText,
  highlightTextColor,
  highlightedTextIsBold,
  description,
  buttonColor,
  buttonText,
  buttonNameMobil,
  buttonLink,
  stoererBackgroundColor,
  stoererIcon,
  stoererTextSize,
  isStoererHeadlineBold,
  stoererHeadline,
  stoererText,
  showCertificateIcon = false,
  bannerImage,
  bannerImageLink,
  backgroundBlur = false,
  blurDesktop = 'md:backdrop-blur-xl',
  blurMobile = 'backdrop-blur-xl',
  bgFrozeColor = {
    r: 50,
    g: 130,
    b: 50,
    a: 0.3,
  },

  descriptionSize = 'lg',
}: PublichHeroSectionV2Props) => {
  const chosenTheme = theme || 'white';

  return (
    <div
      className={classNames(`bg-${chosenTheme}`, 'xs:bg-transparent relative')}
      style={{ hyphens: 'auto', WebkitHyphens: 'auto', msHyphens: 'auto' }}
    >
      {backgroundImage?.filename && (
        <HeroBackgroundImage
          loadingPriority={loadingPriority}
          backgroundImage={backgroundImage}
          backgroundImageMobile={backgroundImageMobile}
          backgroundBlur={backgroundBlur}
          blurDesktop={blurDesktop}
          blurMobile={blurMobile}
          bgFrozeColor={bgFrozeColor}
        />
      )}
      {!stoererText && !buttonLink && showCertificateIcon && bannerImage?.filename && (
        <HeroCertificateIcon isMobile bannerImage={bannerImage} bannerImageLink={bannerImageLink} />
      )}
      <div className="xs:min-h-[20.31rem] relative w-full flex-row items-stretch">
        <HeroContainer>
          <div className="xs:pb-16 xs:pt-14 xs:space-y-6 xs:block my-auto grid space-y-4 pb-8 pt-8 sm:grid sm:w-4/6 md:w-1/2 xl:pt-20">
            {headlineText && (
              <HeadlineHero
                headlineText={`${headlineText} <br> ${subHeadlineText}`}
                highlightText={highlightText as string}
                theme={chosenTheme}
                prelineColor={overlineColor}
                preline={overline as string}
                highlightTextColor={highlightTextColor}
                isBold={highlightedTextIsBold}
                headingType={headingType}
              />
            )}
            {description?.text && (
              <HeroDescription
                boldText={description.boldText}
                theme={chosenTheme}
                text={description.text}
                descriptionSize={descriptionSize}
              />
            )}
            {stoererText && (
              <HeroStoerer
                backgroundColor={stoererBackgroundColor}
                icon={stoererIcon}
                textSize={stoererTextSize}
                isHeadlineBold={isStoererHeadlineBold}
                headline={stoererHeadline}
                subline={stoererText}
                buttonColor={buttonColor}
                buttonNameMobil={buttonNameMobil}
                buttonText={buttonText}
                buttonLink={buttonLink}
              />
            )}
            {!stoererText && buttonText && buttonLink && (
              <ButtonHero buttonColor={buttonColor} buttonText={buttonText} buttonLink={buttonLink} />
            )}
          </div>
        </HeroContainer>
      </div>
      {!stoererText && !buttonLink && showCertificateIcon && bannerImage?.filename && (
        <HeroCertificateIcon bannerImage={bannerImage} bannerImageLink={bannerImageLink} />
      )}
    </div>
  );
};

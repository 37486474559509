import { Table } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const TableBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="table">
      <Table blok={blok} />
    </div>
  );
};

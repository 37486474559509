import { ImageProps } from '../../utils';

interface FeatureSectionListitemProps {
  iconForTitle: ImageProps;
  title: string;
  text?: string;
}

export const FeatureSectionListitem = ({ ...props }: FeatureSectionListitemProps) => (
  <div className="relative mb-10 flex items-stretch">
    <div className="absolute top-0 left-0 h-full w-14 md:w-16">
      <img
        src={props.iconForTitle.filename}
        alt={props.iconForTitle.alt}
        loading="lazy"
        {...{ fetchpriority: 'low' }}
      />
    </div>
    <div className="font-body text-navy pl-20">
      <h5 className="mb-4 text-xl font-bold leading-9">{props.title}</h5>
      {props.text && <div className="text-lg leading-6">{props.text}</div>}
    </div>
  </div>
);

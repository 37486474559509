import classNames from 'classnames';
import { FieldHookConfig, useField } from 'formik';
import { ReactNode } from 'react';

export interface CheckBoxProps {
  label?: string;
  name: string;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
}

export function CheckBox({
  label,
  name,
  children,
  className,
  disabled,
  onChange,
  ...restProps
}: React.HTMLProps<HTMLInputElement> & FieldHookConfig<string | number | unknown> & CheckBoxProps) {
  const [field, meta] = useField(name);

  return (
    <div className={classNames('font-body relative flex items-start', className)}>
      <div className="flex h-6 items-center">
        <input
          {...field}
          {...restProps}
          id={name}
          aria-describedby="comments-description"
          name={name}
          type="checkbox"
          className={classNames(
            'mr-3 h-4 w-4 cursor-pointer rounded text-cyan-700 focus:outline-0 focus:outline-offset-0 focus:ring-0 focus:ring-offset-cyan-200',
            meta.error && meta.touched ? 'border-red-secondary focus:border-red-secondary border-2' : 'border-navy',
            disabled && 'bg-coolGray-100'
          )}
          disabled={disabled}
          onChange={(event: never) => {
            field.onChange(event);
            onChange && onChange(event);
          }}
        />
      </div>
      {children ? (
        children
      ) : (
        <div className="ml-3 text-base">
          <label htmlFor={name} className="text-navy font-medium">
            {label}
          </label>
        </div>
      )}
    </div>
  );
}

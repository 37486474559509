import { FieldHookConfig, useField } from 'formik';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { StoryblokIcon } from '../StoryblokIcon';
import { getHighlightedText, isStringArray, validationMessages } from '../../utils';

const DEFAULT_OPTION_INFO = 'Bitte wählen Sie aus';

interface DropdownFieldOptionProps {
  name: string;
  value: string;
}

interface DropdownFieldProps {
  isMandatory?: boolean;
  isOptional?: boolean;
  options: string[] | DropdownFieldOptionProps[];
  showFirstOptionInitially?: boolean;
}

const getOptionValueByName = (options: string[] | DropdownFieldOptionProps[], searchValue: string) => {
  if (isStringArray(options)) {
    return searchValue === DEFAULT_OPTION_INFO ? '' : searchValue;
  }
  const option = (options as DropdownFieldOptionProps[]).find((option) => option.name === searchValue);
  return option ? option.value : '';
};

const getOptionNameByValue = (options: string[] | DropdownFieldOptionProps[], searchValue: string) => {
  if (isStringArray(options)) {
    return searchValue === DEFAULT_OPTION_INFO ? '' : searchValue;
  }
  const option = (options as DropdownFieldOptionProps[]).find((option) => option.value === searchValue);
  return option ? option.name : '';
};

export const DropdownField = (
  props: React.HTMLProps<HTMLSelectElement> & FieldHookConfig<string | number | unknown> & DropdownFieldProps
) => {
  const [field, meta, helpers] = useField(props);
  const { id, value, label, disabled, isMandatory, isOptional, options, showFirstOptionInitially, onBlur } = props;

  let initialOptionName = DEFAULT_OPTION_INFO;
  if (showFirstOptionInitially && isStringArray(options)) {
    initialOptionName = options[0] as string;
  }
  if (showFirstOptionInitially && !isStringArray(options)) {
    initialOptionName = (options as DropdownFieldOptionProps[])[0].name;
  }

  const [selectedOption, setSelectedOption] = useState(
    getOptionNameByValue(options, (value ? value : (field?.value as string))?.toString()) || initialOptionName
  );

  const [focusOutCounter, setFocusOutCounter] = useState(0);
  const handleFocusOut = () => {
    focusOutCounter > 0 && helpers.setTouched(true);
    setFocusOutCounter(focusOutCounter + 1);
  };

  return (
    <Listbox
      as="div"
      value={selectedOption}
      onChange={(selectedOption: string) => {
        helpers.setValue(getOptionValueByName(options, selectedOption));
        setSelectedOption(selectedOption);
      }}
      disabled={disabled}
    >
      {({ open }) => (
        <>
          <div className="flex justify-between space-x-2">
            <Listbox.Label className="text-navy font-body-medium block">
              {isMandatory ? `${label}*` : label}
            </Listbox.Label>
            {label && isOptional && !isMandatory && (
              <Listbox.Label className="text-coolGray-500">Optional</Listbox.Label>
            )}
          </div>
          <div className="font-body relative mt-2">
            <Listbox.Button
              className={classNames(
                id,
                'ring-coolGray-200 w-full py-3 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset focus:outline-none focus:ring-1 ',
                meta.touched && meta.error ? 'bg-red-50 ring-red-500' : 'bg-white focus:ring-cyan-700',
                open ? 'rounded-t-md' : 'rounded-md',
                disabled && 'bg-coolGray-100 pointer-events-none'
              )}
              aria-label={props['aria-label']}
              tabIndex={disabled ? -1 : undefined}
              value={field?.value as string}
              onBlur={(event: never) => {
                onBlur && onBlur(event);
                handleFocusOut();
              }}
            >
              <span
                className={classNames(
                  meta.touched && meta.error ? 'text-red-secondary' : 'text-navy',
                  disabled && 'text-coolGray-400',
                  'block truncate'
                )}
              >
                {selectedOption}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                {open ? (
                  <StoryblokIcon
                    iconSource="https://a.storyblok.com/f/148087/250x150/b03a9ed03e/chevron-up-navy.svg"
                    className="ml-2 h-2 w-3"
                    aria-hidden="true"
                  />
                ) : (
                  <StoryblokIcon
                    iconSource="https://a.storyblok.com/f/148087/250x150/7d37c5f67d/chevron-down-navy.svg"
                    className="ml-2 h-2 w-3"
                    aria-hidden="true"
                  />
                )}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  'absolute z-10 ml-0.5 max-h-60 w-[99%] cursor-pointer overflow-auto bg-white py-1 text-base shadow-lg',
                  'ring-1 ring-black ring-opacity-5 focus:outline-none',
                  open ? 'rounded-b-md' : 'rounded-md'
                )}
              >
                {options.map((option, index) => (
                  <Listbox.Option
                    key={`${(option as DropdownFieldOptionProps).name || option}-${index}`}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-navy-50 text-navy' : 'text-coolGray-500',
                        'relative select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={(option as DropdownFieldOptionProps).name || option}
                  >
                    {({ active }) => (
                      <>
                        <span className="block truncate">
                          {(option as DropdownFieldOptionProps).name || (option as string)}
                        </span>
                        {(field?.value as string)?.toString() ===
                        ((option as DropdownFieldOptionProps).value || option) ? (
                          <span
                            className={classNames(
                              active ? 'text-navy' : 'text-coolGray-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {meta.touched && meta.error && (
            <p
              className="text-red-secondary font-body mt-2 text-sm"
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(
                  meta.error,
                  validationMessages.error.defaultDropdownField.highlightedPart,
                  'red-secondary',
                  { fontFamily: 'font-body', isBold: true }
                ),
              }}
            />
          )}
        </>
      )}
    </Listbox>
  );
};

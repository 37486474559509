import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Fragment, useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

import styles from '../BlogPostListing/BlogPostsListing.module.scss';
import { CardWithMeta, CardWithMetaProps } from '../Cards';
import classNames from 'classnames';
import { FeaturedPost } from '../FeaturedPost';

const viewOptions = [{ name: 'Kacheln' }, { name: 'Liste' }];

export interface BlogPostSummaryListingProps {
  postSummaries: CardWithMetaProps[];
  categories: CategoriesProps[];
}

interface CategoriesProps {
  name: string;
  content: CategoriesContentProps;
}

interface CategoriesContentProps {
  name: string;
}

interface ChangePageProps {
  selected: number;
}

export const BlogPostSummaryListing = ({ postSummaries, categories }: BlogPostSummaryListingProps) => {
  const [postsArr] = useState<CardWithMetaProps[]>([...postSummaries]);
  const customCategories = ['Alle', ...categories.map((cat) => cat.content?.name)];
  const [categoryName, setCategoryName] = useState<string>('Alle');

  const [filteredPosts, setFilteredPosts] = useState<CardWithMetaProps[]>([]);
  const featuredPostElement = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [selectedView] = useState(viewOptions[0]);

  const POSTS_PER_PAGE = 6;
  const [pageNumber, setPageNumber] = useState(0);
  const numberOfAlreadyShownPosts = pageNumber * POSTS_PER_PAGE;
  // all filtered posts - featured post (-1) as it is only shown as the top post and not within post listing
  const pageCount = Math.ceil((filteredPosts.length <= 1 ? 1 : filteredPosts.length - 1) / POSTS_PER_PAGE);

  const filterAndSortPosts = () => {
    setPageNumber(0);
    if (categoryName === 'Alle') {
      return setFilteredPosts([
        ...postsArr
          .filter((post) => post.first_published_at != null)
          .sort((a, b) => (a.first_published_at > b.first_published_at ? -1 : 1)),
      ]);
    }

    const newFilteredPosts = postsArr
      .filter((post) => {
        return (
          post.content.categories.some((cat: { name: string }) => cat.name === categoryName) &&
          post.first_published_at != null
        );
      })
      .sort((a, b) => (a.first_published_at > b.first_published_at ? -1 : 1));
    setFilteredPosts([...newFilteredPosts]);
  };

  useEffect(() => {
    filterAndSortPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryName]);

  const onPageChange = ({ selected }: ChangePageProps) => {
    setPageNumber(selected);
    featuredPostElement.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };

  return (
    <div className="relative m-auto max-w-7xl justify-center py-5 px-4">
      <div className="mt-5 flex items-center justify-center  md:hidden">
        <Listbox value={categoryName} onChange={setCategoryName}>
          <div className="relative mt-1  mb-6 w-64">
            <Listbox.Button
              className={classNames(
                'relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md',
                'focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white',
                'focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
              )}
            >
              <span className="block truncate">{categoryName}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon className="text-coolGray-400 h-5 w-5" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  'absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1',
                  'text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                )}
              >
                {customCategories.map((cat, id) => (
                  <Listbox.Option
                    key={id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-amber-100 text-amber-900' : 'text-navy'
                      }`
                    }
                    value={cat}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{cat}</span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="mb-8 hidden md:block">
        <div className="border-cold-gray-300 mb-4 flex flex-row justify-center border-0 sm:mb-6 sm:border-b">
          <div className="mx-2 flex flex-col sm:mx-0">
            <div className="text-coolGray-500 flex flex-row items-center justify-center px-4 text-xs">
              {customCategories.map((category, index) => {
                return (
                  <button
                    className={classNames(
                      category === categoryName && 'text-navy relative font-bold',
                      'mx-2 inline-block w-max pb-2 text-sm sm:mx-4 sm:pb-6'
                    )}
                    key={index}
                    onClick={() => setCategoryName(category)}
                  >
                    {category}
                    {category === categoryName && (
                      <div className="border-gold absolute bottom-0 left-0 right-0 h-1 border-b-2" />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {postSummaries?.length > 0 && (
        <div ref={featuredPostElement}>
          <FeaturedPost featuredPost={postSummaries[0]} />
        </div>
      )}

      <div className="grid gap-16 md:grid-cols-3">
        {filteredPosts &&
          filteredPosts.length > 0 &&
          filteredPosts
            .filter((post) => post.content.title !== postSummaries[0].content.title)
            .slice(numberOfAlreadyShownPosts, numberOfAlreadyShownPosts + POSTS_PER_PAGE)
            .map((post, id) => (
              <CardWithMeta
                viewOption={selectedView.name}
                className="flex justify-center"
                key={id}
                content={post.content}
                full_slug={post.content?.blog_slug}
                first_published_at={post.first_published_at}
                titleMaxLength={82}
                descMaxLength={120}
                isImageVisible={true}
                isMetaVisible={true}
                isCategoryVisible={true}
                isButtonVisible={false}
                imagePosition={selectedView.name === 'Kacheln' ? 'top' : 'left'}
              />
            ))}
      </div>
      <ReactPaginate
        className={styles['postsPagination'] + ' mt-5'}
        previousLabel={'Zurück'}
        nextLabel={'Vor'}
        pageCount={pageCount}
        onPageChange={onPageChange}
        containerClassName={'paginationBttns'}
        previousLinkClassName={'previousBttn'}
        nextLinkClassName={'nextBttn'}
        disabledClassName={'paginationDisabled'}
        activeClassName={styles['paginationActive']}
        forcePage={pageNumber}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
      />
    </div>
  );
};

import { logoSrc } from './utils';

export const DtkLogo = ({ ...props }) => (
  <img
    {...props}
    title="Deutsche Teilkauf Logo"
    alt="Deutsche Teilkauf Logo"
    src={logoSrc}
    aria-label="Deutsche Teilkauf Logo"
    width="118"
    height="32"
    loading={props.loading}
    {...{ fetchpriority: props.loading === 'eager' ? 'high' : 'low' }}
  />
);

import { Button, acceptConsent, getConsentStatus, showSecondLayer, useMediaQuery } from '@dtk/ui-components';

import classNames from 'classnames';
import Image from 'next/future/image';
import Script from 'next/script';
import { useState, useEffect } from 'react';
import { storyBlokLoader } from '../utils';
import date from 'date-and-time';

export interface VideoWidescreenProbs {
  youtubeVideoId: string;
  name?: string;
  description?: string;
  uploadDate?: string;
  duration?: string;
  screenSize?: string;
}

const convertToYouTubeDuration = (duration: string) => {
  const [hours, minutes, seconds] = duration.split(':');
  let youtubeDuration = '';
  if (hours !== '0') {
    youtubeDuration += `${hours}H`;
  }
  if (minutes !== '0') {
    youtubeDuration += `${minutes}M`;
  }
  if (seconds !== '0') {
    youtubeDuration += `${seconds}S`;
  }
  return `PT${youtubeDuration}`;
};

export const YoutubeVideoWidescreen = ({
  youtubeVideoId = '',
  name = '',
  description = '',
  uploadDate = '',
  duration = '',
  screenSize,
}: VideoWidescreenProbs) => {
  const YOUTUBE_SERVICE_ID = 'BJz7qNsdj-7';
  const { smallerXs } = useMediaQuery();
  const [hasYoutubeConsent, setYoutubeConsent] = useState<boolean>(getConsentStatus(YOUTUBE_SERVICE_ID));

  const wrapperClassses =
    screenSize === 'grid' &&
    'grid-cols-12 content-center px-4 sm:px-6 lg:px-8 md:max-w-xl lg:max-w-2xl xl:max-w-[50rem]';

  const videoClasses =
    screenSize === 'grid'
      ? 'h-[19rem] sm:h-[22rem] md:h-[19rem] lg:h-[21rem] xl:h-[26rem]'
      : 'h-[15rem] sm:h-[27rem] md:h-[33.5rem] lg:h-[34.4rem] xl:h-[37.75rem]';

  const youtubePlaceholderClasses = 'w-full h-[30rem] xs:h-[32rem] xs:max-h-[34rem] relative mx-auto max-h-[40rem]';

  const updateConsentStatus = () => {
    getConsentStatus(YOUTUBE_SERVICE_ID) ? setYoutubeConsent(true) : setYoutubeConsent(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateConsentStatus();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classNames(wrapperClassses, 'mx-auto my-4 max-w-7xl px-4 lg:px-8')}>
      <div
        className={classNames(
          'youtube-video-position',
          hasYoutubeConsent || getConsentStatus(YOUTUBE_SERVICE_ID) ? 'block' : 'hidden'
        )}
      >
        <iframe
          className={classNames(videoClasses, 'h-max-40 w-full')}
          src={
            hasYoutubeConsent || getConsentStatus(YOUTUBE_SERVICE_ID)
              ? `https://www.youtube.com/embed/${youtubeVideoId}?rel=0`
              : 'about:blank'
          }
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={name}
        />
      </div>

      <div
        className={classNames(
          'youtube-placeholder',
          youtubePlaceholderClasses,
          hasYoutubeConsent || getConsentStatus(YOUTUBE_SERVICE_ID) ? 'hidden' : 'block'
        )}
      >
        <Image
          loader={storyBlokLoader}
          className="object-cover object-right"
          src={
            smallerXs
              ? 'https://a.storyblok.com/f/148087/354x500/eb047a7d07/230620_youtube_thumbnail_mobile.png'
              : 'https://a.storyblok.com/f/148087/1280x720/25180a10c3/230620_youtube_thumbnail_dektop.png'
          }
          alt="youtube-placeholder-bg-image"
          fill
          sizes="(max-width: 360px) 75vw, 100vw"
        />
        <div className="shadow-coolGray relative flex h-full flex-col justify-center rounded-md bg-white/75 py-5 px-4 text-center shadow-sm ">
          <h3 className="font-headline-semibold text-navy-navy mb-5 text-center text-lg sm:mb-3 md:text-2xl">
            Wir benötigen Ihre Zustimmung, um den YouTube-Dienst zu laden.
          </h3>
          <p>
            Wir nutzen 3rd-Party-Services, um Videoinhalte einzubetten. Diese Dienste können möglicherweise Daten von
            Ihnen sammeln. Daher müssen Sie zuerst dem Service YouTube Video zustimmen, um dieses Video zu sehen.
          </p>
          <p className="mb-4">Der Inhalt konnte nicht geladen werden.</p>
          <div className="xs:flex-row flex flex-col justify-center gap-4">
            <Button onClick={() => showSecondLayer()} variant="gray" size="base">
              Mehr Informationen
            </Button>
            <Button onClick={() => acceptConsent(YOUTUBE_SERVICE_ID)} variant="cyan" size="base">
              Annehmen
            </Button>
          </div>
        </div>
      </div>

      <Script
        id="youtube-video-json-ld-markup"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name,
            description,
            uploadDate:
              uploadDate && date.isValid(uploadDate, 'YYYY-MM-DD hh:mm:ss')
                ? date.parse(uploadDate, 'YYYY-MM-DD hh:mm:ss').toISOString()
                : new Date().toISOString(),
            duration: duration ? convertToYouTubeDuration(duration) : 'PT00H03M57S',
            thumbnailUrl: `https://img.youtube.com/vi/${youtubeVideoId}/maxresdefault.jpg`,
            contentUrl: `https://www.youtube.com/watch?v=${youtubeVideoId}`,
            embedUrl: `https://www.youtube.com/embed/${youtubeVideoId}?rel=0`,
          }),
        }}
      />
    </div>
  );
};

import { ContactPerson } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const ContactPersonBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="contact-person">
      <ContactPerson
        contact={{
          firstname: blok.firstname,
          lastname: blok.lastname,
          eMail: blok.email,
          phone: blok.phone,
          position: blok.position,
          pictureUrl: blok.portrait_image?.filename,
          pictureAltName: blok.portrait_image?.alt,
          pictureFocus: blok.portrait_image?.focus,
          theme: 'navy',
          linearGradientBackgroundColor: 'linear-gradient-gray50-to-navy',
        }}
      />
    </div>
  );
};

import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const PlaceholderBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="placeholder">
    <h1>PLACEHOLDER</h1>
  </div>
);

export default PlaceholderBlok;

import { CardMeta, CardWithMetaProps } from './Cards';

import { Badge } from '../01_elements/Badge';
import Link from 'next/link';
import { substringText } from '../utils/substringText';

export interface FeaturedPostProps {
  featuredPost: CardWithMetaProps;
}

export const FeaturedPost = ({ featuredPost }: FeaturedPostProps) => {
  const slug = featuredPost.content?.blog_slug || featuredPost.slug || '';
  return (
    <div className="featured-post mb-10 w-full">
      <div className="flex flex-col overflow-hidden rounded-sm shadow-lg md:flex-row">
        {featuredPost.content?.featured_image?.filename && (
          <div className="flex-shrink-0 md:w-7/12 ">
            <img
              className="h-auto w-full object-cover md:h-full"
              src={featuredPost.content?.featured_image.filename + '/m/793x347/smart'}
              alt={featuredPost.content?.featured_image.alt}
            />
          </div>
        )}
        <div className="flex-shrink-1	flex flex-col justify-between bg-white p-6">
          <div className="flex-1">
            {featuredPost.content?.categories.map((cat, index) => (
              <p key={index} className="my-2 mr-4 inline-block text-sm font-medium">
                <Badge
                  className={'rounded-full px-3'}
                  name={cat.name}
                  bgColor={cat.name === 'Presseveröffentlichung' ? 'cyan' : 'gold'}
                />
              </p>
            ))}
            <Link passHref href={slug}>
              <a href="/#" className="mt-2 block">
                <p className="text-navy text-xl font-semibold">{substringText(featuredPost.content?.title, 85)}</p>
                <p className="text-navy mt-3 text-base">
                  {featuredPost.content?.excerpt && substringText(featuredPost.content?.excerpt, 200)}
                </p>
              </a>
            </Link>
          </div>
          <div className="mt-6 flex items-center">
            <Link passHref href={slug}>
              <a href="/#" className="mt-2 block">
                <CardMeta
                  authorName={featuredPost.content?.author.content?.author_name}
                  authorSlug={featuredPost.content?.author.content?.authorSlug}
                  authorImageUrl={featuredPost.content?.author.content?.author_image.filename}
                  first_published_at={featuredPost.first_published_at}
                  authorPosition={featuredPost.content?.author.content?.author_position}
                  readingTime={featuredPost.content?.reading_time}
                />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

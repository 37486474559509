import classNames from 'classnames';
import { Button, ButtonVariantsRedesign } from '../01_elements/Button';
import { useMediaQuery } from '../hooks';
import { getAlternativePath } from '../utils';
import { headingType, Headline } from '../01_elements/Headline';
import { themesForBackgroundColor, BackgroundThemeVariants } from '../utils/themes';
import { ImageProps } from '../utils/image';

export interface CTASectionProps {
  backgroundTheme: BackgroundThemeVariants;
  overline?: string;
  headingType: headingType;
  headline: string;
  subline?: string;
  image?: ImageProps;
  primaryButtonName?: string;
  primaryButtonLink?: string;
  primaryButtonTheme: ButtonVariantsRedesign;
  secondaryButtonName?: string;
  secondaryButtonLink?: string;
}

export const CTASection = ({
  backgroundTheme,
  overline,
  headingType,
  headline,
  subline,
  image,
  primaryButtonName = '',
  primaryButtonLink = '',
  primaryButtonTheme,
  secondaryButtonName = '',
  secondaryButtonLink = '',
}: CTASectionProps) => {
  const { largerXl, smallerSm } = useMediaQuery();

  const chosenHeadingType = headingType || 'h2';
  const chosenTheme = backgroundTheme || 'navy';

  const textColor = themesForBackgroundColor.darkBackgroundColors.includes(chosenTheme) ? 'text-white' : 'text-navy';
  const secondaryButtonColor =
    themesForBackgroundColor.darkBackgroundColors.includes(chosenTheme) || chosenTheme === 'gold-sand'
      ? 'white'
      : 'navy';

  const primaryRedirectPath = getAlternativePath({ originalLink: primaryButtonLink, condition: smallerSm });
  const secondaryRedirectPath = getAlternativePath({ originalLink: secondaryButtonLink, condition: smallerSm });

  return (
    <div className={classNames(`bg-${chosenTheme}`, 'relative py-10')}>
      {image?.filename && (
        <div className="absolute top-0 left-0 right-0 bottom-0 z-0 overflow-hidden">
          <img className="z-0 w-full" src={image.filename} alt={image.alt} loading="lazy" />
        </div>
      )}
      <div className="mx-auto flex max-w-7xl flex-wrap justify-center px-4 sm:px-6 lg:px-8">
        <div className="z-10 space-y-2 lg:space-y-4">
          {overline && (
            <p
              className={classNames(
                `text-${themesForBackgroundColor.overlineColor[chosenTheme]}`,
                'text-center text-xs uppercase leading-[2rem] tracking-[.2rem] sm:text-sm'
              )}
            >
              {overline}
            </p>
          )}
          {headline && (
            <Headline
              headingType={chosenHeadingType}
              headlineClassNames={classNames(textColor, 'font-headline text-center')}
              headline={headline}
              isBold={true}
            />
          )}
          {subline && <p className={classNames(textColor, 'text-center md:text-lg')}>{subline}</p>}

          {(primaryButtonName || secondaryButtonName) && (
            <div className="flex flex-col justify-center px-6 sm:flex-row">
              {primaryButtonName && (
                <a href={primaryRedirectPath} className="z-10 mb-5 sm:mx-3 md:mb-0">
                  <Button variant={primaryButtonTheme || 'cyan'} size={largerXl ? 'lg' : 'base'} fullWidth>
                    {primaryButtonName}
                  </Button>
                </a>
              )}
              {secondaryButtonName && (
                <a href={secondaryRedirectPath} className="z-10 sm:mx-3">
                  <Button variant={secondaryButtonColor} size={largerXl ? 'lg' : 'base'} fullWidth>
                    {secondaryButtonName}
                  </Button>
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import axios from 'axios';
import { Form, Formik, FormikTouched } from 'formik';
import TagManager from 'gtm-for-react';
import { useRef, useState } from 'react';
import { object, string } from 'yup';

import { useAppConfig } from '@dtk/config';
import {
  BackgroundThemeVariants,
  Button,
  DropdownField,
  InputField,
  MandatoryFieldsMarker,
  MessageBanner,
  OptInCheckboxes,
  defaultOption,
  hasWebNavigationWithinPage,
  salutationOptions,
  themesForBackgroundColor,
  titleOptions,
  validationMessages,
} from '@dtk/ui-components';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import { SpinnerWeb } from '../01_elements';

interface BrochureFormProps {
  theme?: BackgroundThemeVariants;
}

const tagManagerArgs = {
  dataLayer: {
    event: 'InfoPaket angefordert',
  },
};

type CookieType = 'utmpara' | 'facebookClickId' | 'bing' | 'google' | 'referrer' | 'faOrderID';

const getCookie = (cookieType: CookieType) => {
  const cookie = document.cookie.match('(^|;)\\s*' + cookieType + '\\s*=\\s*([^;]+)');
  if (cookie && cookie.length > 0) {
    const valueStart = cookie[0].indexOf('=');
    if (valueStart !== -1) {
      return cookie[0].substring(valueStart + 1);
    }
    return cookie[0];
  }
  return '';
};

const mapCookieArrToObj = (str: string[]) => {
  const obj: Record<string, string> = {};
  for (let i = 0; i < str.length; i++) {
    const split = str[i].split('=');
    obj[split[0]?.trim()] = split[1]?.trim();
  }
  return obj;
};

export const BrochureForm = ({ theme }: BrochureFormProps) => {
  theme = theme || (themesForBackgroundColor.defaultBackgroundColor as BackgroundThemeVariants);
  const { config } = useAppConfig();

  const [showSendBrochure, setSendBrochure] = useState(true);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const componentHead = useRef() as React.MutableRefObject<HTMLDivElement>;

  const validationSchema = object({
    salutation: string().required(validationMessages.error.defaultDropdownField.text),
    firstName: string().required(validationMessages.error.defaultInputField.text),
    lastName: string().required(validationMessages.error.defaultInputField.text),
    phone: string().required(validationMessages.error.defaultInputField.text),
    email: string()
      .email(validationMessages.error.email.text)
      .required(validationMessages.error.defaultInputField.text),
    strasse: string().required(validationMessages.error.defaultInputField.text),
    strasseNr: string().required(validationMessages.error.defaultInputField.text),
    plz: string()
      .length(5, validationMessages.error.zipCode.text)
      .matches(/^\d+$/, validationMessages.error.zipCode.text)
      .required(validationMessages.error.defaultInputField.text),
    ort: string().required(validationMessages.error.defaultInputField.text),
  });

  const initValues = {
    salutation: '',
    userTitle: '',
    firstName: '',
    lastName: '',
    email: '',
    strasse: '',
    strasseNr: '',
    plz: '',
    ort: '',
    phone: '',
    advertisingConsent: false,
    leadDistributionAllowed: false,
  };

  interface Values {
    salutation: string;
    userTitle: string;
    firstName: string;
    lastName: string;
    email: string;
    strasse: string;
    strasseNr: string;
    plz: string;
    ort: string;
    phone: string;
    advertisingConsent: boolean;
    leadDistributionAllowed: boolean;
  }

  const handleClick = (touched: FormikTouched<unknown>, isValid: boolean) => {
    if (isValid && Object.keys(touched).length > 0) {
      setShowMessageBanner(false);
      return;
    }
    setShowMessageBanner(true);
    return componentHead.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = (values: Values) => {
    setSendBrochure(false);

    const extpublisher = mapCookieArrToObj(getCookie('utmpara').split('&'))['extpublisher']?.substring(0, 50) || '';
    const referrer = getCookie('referrer')?.substring(0, 150) || '';

    TagManager.dataLayer(tagManagerArgs);

    const brochure = {
      salutation: values.salutation === defaultOption ? '' : values.salutation,
      firstname: values.firstName,
      lastname: values.lastName,
      street: values.strasse,
      steetNumber: values.strasseNr,
      zip: values.plz,
      city: values.ort,
      email: values.email,
      mobile: values.phone,
      title: values.userTitle === defaultOption ? '' : values.userTitle,
      cookieUtmpara: getCookie('utmpara'),
      cookieFacebook: getCookie('facebookClickId'),
      cookieBing: getCookie('bing'),
      cookieGoogle: getCookie('google'),
      extpublisher,
      referrer,
      faOrderID: getCookie('faOrderID'),
      advertisingConsent: values.advertisingConsent,
      leadDistributionAllowed: values.leadDistributionAllowed,
    };

    axios
      .post(config['API_BASE'] + '/public/infobrochure', brochure, {})
      .then((response) => {
        window.location.href = response.data;
      })
      .catch(() => {
        window.location.href = '/error';
      });
  };

  return (
    <div className={`${hasWebNavigationWithinPage() && 'scroll-mt-16 xl:scroll-my-28'}`} ref={componentHead}>
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ touched, isValid }) => (
          <Form>
            {!isValid && showMessageBanner && (
              <MessageBanner
                classNames="my-10"
                message={validationMessages.error.defaultBanner.text}
                highlightText={validationMessages.error.defaultBanner.highlightedPart}
                isError
              />
            )}
            <div className={`mx-auto grid w-full grid-cols-6 gap-x-6 gap-y-4 bg-${theme} p-[2rem] sm:max-w-[51rem]`}>
              <div className="col-span-6 sm:col-span-3">
                <DropdownField
                  data-testid="salutation"
                  id="salutation"
                  name="salutation"
                  label="Anrede"
                  options={salutationOptions}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <DropdownField
                  data-testid="userTitle"
                  id="userTitle"
                  name="userTitle"
                  label="Titel"
                  options={titleOptions}
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="firstName"
                  name="firstName"
                  label="Vorname"
                  type="text"
                  infoMessage={validationMessages.info.firstName}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="lastName"
                  name="lastName"
                  label="Nachname"
                  type="text"
                  infoMessage={validationMessages.info.lastName}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="phone"
                  name="phone"
                  label="Telefonnummer"
                  type="text"
                  icon={PhoneIcon}
                  infoMessage={validationMessages.info.phone}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="email"
                  name="email"
                  label="E-Mail-Adresse"
                  type="text"
                  placeholder="max@mustermail.de"
                  icon={MailIcon}
                  infoMessage={validationMessages.info.email}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="strasse"
                  name="strasse"
                  label="Straße"
                  type="text"
                  infoMessage={validationMessages.info.street}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="strasseNr"
                  name="strasseNr"
                  label="Nr."
                  type="text"
                  infoMessage={validationMessages.info.houseNr}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="plz"
                  name="plz"
                  label="PLZ"
                  type="text"
                  infoMessage={validationMessages.info.zipCode}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="ort"
                  name="ort"
                  label="Ort"
                  type="text"
                  infoMessage={validationMessages.info.city}
                  isMandatory
                />
              </div>

              <MandatoryFieldsMarker />
              <span />
              <OptInCheckboxes />

              <div className="col-span-6 mb-10">
                {showSendBrochure && (
                  <Button fullWidth type="submit" onClick={() => handleClick(touched, isValid)}>
                    Teilverkauf-Broschüre anfordern
                  </Button>
                )}
                {!showSendBrochure && <SpinnerWeb />}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { CardWithMeta, CardWithMetaProps } from '../Cards';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Fragment, useRef, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

import { FeaturedPost } from '../FeaturedPost';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import styles from './BlogPostsListing.module.scss';

const viewOptions = [{ name: 'Kacheln' }, { name: 'Liste' }];

export interface BlogPostsListingProps {
  posts: CardWithMetaProps[];
  categories: CategoriesProps[];
  currentPage: string;
  totalPages: number;
}

interface CategoriesProps {
  name: string;
}

interface ChangePageProps {
  selected: number;
}

export const BlogPostsListing = ({ posts, categories }: BlogPostsListingProps) => {
  const POSTS_PER_PAGE = 6;
  const allLabel = 'Alle';
  const customCategories = [allLabel, ...categories.map((category) => category.name)];
  const sortedPosts = posts.sort((a, b) => (a.first_published_at > b.first_published_at ? -1 : 1));

  const featuredPostElement = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [categoryName, setCategoryName] = useState<string>(allLabel);
  const [selectedView] = useState(viewOptions[0]);
  const [pageNumber, setPageNumber] = useState(0);
  const [filteredPosts, setFilteredPosts] = useState<CardWithMetaProps[]>(sortedPosts);
  const [totalPages, setTotalPages] = useState(Math.ceil((filteredPosts?.length - 1) / POSTS_PER_PAGE));

  const numberOfAlreadyShownPosts = 1 + pageNumber * POSTS_PER_PAGE; // 1 is for featured post

  const onPageChange = ({ selected }: ChangePageProps) => {
    setPageNumber(selected);
    featuredPostElement.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const onCategoryChange = (value: string) => {
    let newFilteredPosts = [];

    if (value === allLabel) {
      newFilteredPosts = sortedPosts;
    } else {
      newFilteredPosts = sortedPosts.filter((post) => {
        return post.content.categories.some((category) => category.name === value);
      });
    }

    setCategoryName(value);
    setFilteredPosts(newFilteredPosts);
    setTotalPages(Math.ceil((newFilteredPosts?.length - 1) / POSTS_PER_PAGE));
    setPageNumber(0);
  };

  return (
    <div className="relative m-auto max-w-7xl justify-center py-5 px-4">
      <div className="mt-5 flex items-center justify-center  md:hidden">
        <Listbox value={categoryName} onChange={onCategoryChange}>
          <div className="relative mt-1  mb-6 w-64">
            <Listbox.Button
              className={classNames(
                'relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md',
                'focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white',
                'focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
              )}
            >
              <span className="block truncate">{categoryName}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon className="text-coolGray-400 h-5 w-5" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  'absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1',
                  'text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                )}
              >
                {customCategories.map((cat, id) => (
                  <Listbox.Option
                    key={id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-amber-100 text-amber-900' : 'text-navy'
                      }`
                    }
                    value={cat}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{cat}</span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="mb-8 hidden md:block">
        <div className="border-cold-gray-300 mb-4 flex flex-row justify-center border-0 sm:mb-6 sm:border-b">
          <div className="mx-2 flex flex-col sm:mx-0">
            <div className="text-coolGray-500 flex flex-row items-center justify-center px-4 text-xs">
              {customCategories.map((category, index) => {
                return (
                  <button
                    className={classNames(
                      category === categoryName && 'text-navy relative font-bold',
                      'mx-2 inline-block w-max pb-2 text-sm sm:mx-4 sm:pb-6'
                    )}
                    key={index}
                    onClick={() => onCategoryChange(category)}
                  >
                    {category}
                    {category === categoryName && (
                      <div className="border-gold absolute bottom-0 left-0 right-0 h-1 border-b-2" />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {filteredPosts && filteredPosts.length > 0 && (
        <div ref={featuredPostElement}>
          <FeaturedPost featuredPost={filteredPosts[0]} />
        </div>
      )}

      <div className="grid gap-16 md:grid-cols-3">
        {filteredPosts &&
          filteredPosts.length > 0 &&
          filteredPosts
            .slice(numberOfAlreadyShownPosts, numberOfAlreadyShownPosts + POSTS_PER_PAGE)
            .map((post, id) => (
              <CardWithMeta
                viewOption={selectedView.name}
                className="flex justify-center"
                key={id}
                content={post.content}
                full_slug={post.slug}
                first_published_at={post.first_published_at}
                titleMaxLength={82}
                descMaxLength={120}
                isImageVisible={true}
                isMetaVisible={true}
                isCategoryVisible={true}
                isButtonVisible={false}
                imagePosition={selectedView.name === 'Kacheln' ? 'top' : 'left'}
              />
            ))}
      </div>

      <ReactPaginate
        className={styles['postsPagination'] + ' mt-5'}
        previousLabel={'Zurück'}
        previousAriaLabel={'Zurück'}
        nextLabel={'Vor'}
        nextAriaLabel={'Vor'}
        pageCount={totalPages}
        onPageChange={onPageChange}
        containerClassName={'paginationBttns'}
        previousLinkClassName={'previousBttn'}
        nextLinkClassName={'nextBttn'}
        disabledClassName={'paginationDisabled'}
        activeClassName={styles['paginationActive']}
        forcePage={pageNumber}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        prevRel={null}
        nextRel={null}
        prevPageRel={null}
        selectedPageRel={null}
        nextPageRel={null}
      />
    </div>
  );
};

import { useFormikContext } from 'formik';
import React from 'react';
import { InputFieldCounter } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';
import { useOfferStore } from '../../../store/OfferStore';

export const InputCounterBlok = ({ blok }) => {
  const { values } = useFormikContext();
  const keys = useOfferStore.getState().data;

  if (!keys.has(blok.name)) {
    useOfferStore.setState({ data: keys.set(blok.name, parseInt(blok.defaultValue) || 0) });
  }

  const handleValueChange = (event) => {
    let currentValue = parseInt(values?.[blok.name]) || 0;

    if ((event.currentTarget.id as string).includes('plus')) {
      currentValue++;
    }
    if ((event.currentTarget.id as string).includes('minus')) {
      currentValue--;
    }
    useOfferStore.setState({ data: keys.set(blok.name, currentValue) });
  };

  return (
    <div
      {...storyblokEditable(blok)}
      data-test="input-counter"
      className={`col-span-12 grid sm:col-span-${blok.width} mb-4 sm:mb-0`}
    >
      <InputFieldCounter
        id={blok.name}
        name={blok.name}
        label={blok.label}
        type="number"
        onClick={handleValueChange}
        onBlur={handleValueChange}
      />
    </div>
  );
};

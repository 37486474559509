import { ColumnImagesCard, ColumnImagesCardProps } from './ColumnImagesCard';

export interface PeopleSectionProps {
  people: ColumnImagesCardProps[];
}

export function PeopleSection({ ...people }: PeopleSectionProps) {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl pb-10 lg:pb-20">
        <div>
          <ul className=" sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-2 lg:gap-x-16">
            {people.people.map((personBlock, index) => (
              <ColumnImagesCard
                key={index}
                personName={personBlock.personName}
                personPosition={personBlock.personPosition}
                personImage={personBlock.personImage}
                personText={personBlock.personText}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

import { KontaktSection } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';
export const KontaktSectionBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="kontakt-section">
      <KontaktSection
        imageUrl={blok.image?.filename}
        personEmail={blok.email}
        personName={blok.name}
        personPosition={blok.position}
        headingType={blok.headingType}
        headline={blok.headline}
        buttonText={blok.buttonText}
        buttonLink={blok.buttonLink}
        firstLine={blok.firstLine}
        secondLine={blok.secondLine}
        thirdLine={blok.thirdLine}
        lastLine={blok.lastLine}
      />
    </div>
  );
};

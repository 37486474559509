import { Dialog, Transition } from '@headlessui/react';
import { Fragment, SetStateAction, useState } from 'react';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import { Button } from '../../01_elements/Button';
import { XIcon } from '@heroicons/react/outline';

interface ImageProps {
  filename: string;
  alt: string;
}

interface ModalProps {
  btnIcon?: ImageProps;
  btnText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dialog: any;
  logoLink: string;
  isModalForLeavingPage: boolean;
  isLeavingPageModalOpen?: boolean;
  setIsLeavingPageModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

export const Modal = ({
  btnIcon,
  btnText,
  dialog,
  logoLink,
  isModalForLeavingPage,
  isLeavingPageModalOpen,
  setIsLeavingPageModalOpen,
}: ModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {btnText && !isModalForLeavingPage && (
        <Button variant="ghost" size="sm" onClick={() => setIsModalOpen(true)}>
          {btnIcon?.filename && <img className="scale-75" src={`${btnIcon.filename}/m/`} alt={btnIcon.alt} loading="lazy"/>}
          <span className="ml-2 hidden text-base font-semibold md:block">{btnText}</span>
        </Button>
      )}
      <Transition appear show={isModalOpen || isLeavingPageModalOpen} as={Fragment}>
        <Dialog
          static
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsModalOpen(false);
            setIsLeavingPageModalOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-sm bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <button
                    className="float-right"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsLeavingPageModalOpen(false);
                    }}
                  >
                    <XIcon width={20} height={20} />
                  </button>
                  {dialog &&
                    dialog.map((nestedBlok: SbBlokData) => (
                      <div className="mb-2" key={nestedBlok._uid}>
                        <StoryblokComponent
                          blok={nestedBlok}
                          logoLink={logoLink}
                          setIsLeavingPageModalOpen={setIsLeavingPageModalOpen}
                        />
                      </div>
                    ))}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

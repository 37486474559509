import React from 'react';
import classNames from 'classnames';
import getConfig from 'next/config';
import { useMediaQuery } from '../../hooks';
import { setObjectPosition } from '../../utils/image';

export type FetchingPriority = 'low' | 'high';

export interface ImageComponentData {
  Caption?: string;
  BigImage: {
    filename: string;
    alt?: string;
    focus?: string;
  };
  SmallImage?: {
    filename: string;
    alt?: string;
    focus?: string;
  };
  SmallImageQuality?: string;
  BigImageQuality?: string;
  maxWidth?: number;
  fetchingpriority?: FetchingPriority;
  ImageAlignmentWidth?: string;
}

const buildUrl = (imgUrl?: string, width = 0, quality?: string, retina = '1x') => {
  let url = `${imgUrl}/m/${width}x0`;

  if (quality && parseInt(quality) >= 0 && parseInt(quality) <= 100) {
    url += '/filters';
    url += quality ? `:quality(${quality})` : '';
  }
  url += ' ' + retina;

  return url;
};

const buildImageSourceSetUrl = (imgUrl?: string, width = 0, quality?: string) => {
  let url = buildUrl(imgUrl, width, quality, '1x');
  url += ',' + buildUrl(imgUrl, 2 * width, quality, '2x');
  return url;
};

const generateImageSources = (deviceSizes: number[], maxW: number, imgUrl?: string, quality?: string) => {
  const deviceSize = deviceSizes[deviceSizes.length - 1];
  if (imgUrl?.includes('.webp')) {
    return <source key={deviceSize} srcSet={imgUrl} media={`(max-width: ${deviceSize}px)`} />;
  }

  return deviceSizes
    .filter((deviceSize) => deviceSize <= maxW)
    .sort((a, b) => a - b)
    .map((width) => {
      const srcSet = buildImageSourceSetUrl(imgUrl, width, quality);
      return <source key={width} srcSet={srcSet} media={`(max-width: ${width}px)`} />;
    });
};

const getPreloadedImageSource = (
  fetchingpriority: FetchingPriority,
  isMobileDevice: boolean,
  defaultMobileImageUrl: string,
  defaultDesktopImageUrl: string
) => {
  if (fetchingpriority === 'high') {
    const imageElement = new Image();
    imageElement.src = isMobileDevice ? defaultMobileImageUrl : defaultDesktopImageUrl;
    return imageElement.src;
  }
  return isMobileDevice ? defaultMobileImageUrl : defaultDesktopImageUrl;
};

export const ImageComponent: React.FC<{ data: ImageComponentData }> = ({ data }) => {
  const { mobileDeviceSizes, desktopDeviceSizes } = getConfig().publicRuntimeConfig;
  const { smallerSm } = useMediaQuery();

  const {
    Caption,
    SmallImage,
    SmallImageQuality,
    BigImage,
    BigImageQuality,
    maxWidth,
    fetchingpriority = 'low',
    ImageAlignmentWidth,
  } = data;

  const desktopImageUrl = BigImage.filename;
  const desktopFocalPoint = BigImage?.focus || '';
  const desktopAltText = BigImage?.alt;

  const mobileImageUrl = SmallImage?.filename || BigImage?.filename;
  const mobileFocalPoint = SmallImage?.focus || BigImage?.focus;
  const mobileAltText = SmallImage?.alt || BigImage?.alt;

  let maxW = Math.max(...desktopDeviceSizes);

  if (ImageAlignmentWidth === 'fixedContainer' && maxWidth) {
    maxW = maxWidth;
  }

  return (
    <figure
      className={classNames(
        !ImageAlignmentWidth && 'h-full object-cover',
        ImageAlignmentWidth === 'fullWidth' && 'px-4 py-10 sm:px-6 lg:py-14 lg:px-8',
        ImageAlignmentWidth === 'fixedContainer' && 'relative mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:py-14 lg:px-8'
      )}
    >
      <picture>
        {generateImageSources(mobileDeviceSizes, maxW, mobileImageUrl, SmallImageQuality)}
        {generateImageSources(desktopDeviceSizes, maxW, desktopImageUrl, BigImageQuality)}

        <img
          className="h-full w-full object-cover"
          style={setObjectPosition(desktopImageUrl, desktopFocalPoint, smallerSm, mobileImageUrl, mobileFocalPoint)}
          src={getPreloadedImageSource(fetchingpriority, smallerSm, mobileImageUrl, desktopImageUrl)}
          alt={smallerSm ? mobileAltText : desktopAltText}
          loading={fetchingpriority === 'high' ? 'eager' : 'lazy'}
          {...{ fetchpriority: fetchingpriority }}
        />
      </picture>

      {Caption && (
        <figcaption className={classNames('text-coolGray-500 mx-auto mt-3 mb-6 md:mb-16')}>{Caption}</figcaption>
      )}
    </figure>
  );
};

export default ImageComponent;

import axios from 'axios';
import { Form, Formik, FormikTouched } from 'formik';
import { useState, useRef } from 'react';
import { object, string } from 'yup';

import { useAppConfig } from '@dtk/config';
import {
  Button,
  DropdownField,
  InputField,
  MandatoryFieldsMarker,
  MessageBanner,
  OptInCheckboxes,
  defaultOption,
  hasWebNavigationWithinPage,
  salutationOptions,
  validationMessages,
} from '@dtk/ui-components';
import { MailIcon } from '@heroicons/react/solid';
import { SpinnerWeb } from '../../01_elements';

interface LeadGenerationFormProps {
  actionButtonRedirectUrl: string;
  actionButtonText: string;
  additionalNotes: string;
  emailTriggerId: string;
  marketingEventSubtype: string;
  marketingEventType: string;
}

export const LeadGenerationForm = (props: LeadGenerationFormProps) => {
  const { config } = useAppConfig();
  const [showActionButton, setShowActionButton] = useState(true);
  const [showErrorMessageBanner, setShowErrorMessageBanner] = useState(false);
  const componentHead = useRef() as React.MutableRefObject<HTMLDivElement>;

  const validationSchema = object({
    salutation: string().required(validationMessages.error.defaultDropdownField.text),
    firstName: string().required(validationMessages.error.defaultInputField.text),
    lastName: string().required(validationMessages.error.defaultInputField.text),
    email: string()
      .email(validationMessages.error.email.text)
      .required(validationMessages.error.defaultInputField.text),
  });

  const initValues = {
    salutation: '',
    userTitle: '',
    firstName: '',
    lastName: '',
    email: '',
    advertisingConsent: false,
    leadDistributionAllowed: false,
  };

  interface Values {
    salutation: string;
    firstName: string;
    lastName: string;
    email: string;
    advertisingConsent: boolean;
    leadDistributionAllowed: boolean;
  }

  const handleClick = (touched: FormikTouched<unknown>, isValid: boolean) => {
    if (isValid && Object.keys(touched).length > 0) {
      setShowErrorMessageBanner(false);
      return;
    }
    setShowErrorMessageBanner(true);
    return componentHead.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = (values: Values) => {
    setShowActionButton(false);

    const payload = {
      salutation: values.salutation === defaultOption ? '' : values.salutation,
      email: values.email,
      firstname: values.firstName,
      lastname: values.lastName,
      advertisingConsent: values.advertisingConsent,
      leadDistributionAllowed: values.leadDistributionAllowed,
      additionalNotes: props.additionalNotes,
      emailTriggerId: props.emailTriggerId,
      marketingEventSubtype: props.marketingEventSubtype,
      marketingEventType: props.marketingEventType,
    };

    axios
      .post(config['API_BASE'] + '/public/lead-generation', payload, {})
      .then(() => {
        window.location.href = props.actionButtonRedirectUrl;
      })
      .catch(() => {
        window.location.href = '/error';
      });
  };

  return (
    <div
      className={`bg-coolGray-50 ${hasWebNavigationWithinPage() && 'scroll-mt-16 xl:scroll-my-28'}`}
      ref={componentHead}
    >
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ touched, isValid }) => (
          <Form>
            {!isValid && showErrorMessageBanner && (
              <MessageBanner
                classNames="my-10"
                message={validationMessages.error.defaultBanner.text}
                highlightText={validationMessages.error.defaultBanner.highlightedPart}
                isError
              />
            )}
            <div className={`mx-auto grid w-full grid-cols-6 gap-x-6 gap-y-4 p-[2rem] sm:max-w-[51rem]`}>
              <div className="col-span-6 sm:col-span-3">
                <DropdownField
                  data-testid="salutation"
                  id="salutation"
                  name="salutation"
                  label="Anrede"
                  options={salutationOptions}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="email"
                  name="email"
                  label="E-Mail-Adresse"
                  type="text"
                  placeholder="max@mustermail.de"
                  icon={MailIcon}
                  infoMessage={validationMessages.info.email}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="firstName"
                  name="firstName"
                  label="Vorname"
                  type="text"
                  infoMessage={validationMessages.info.firstName}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="lastName"
                  name="lastName"
                  label="Nachname"
                  type="text"
                  infoMessage={validationMessages.info.lastName}
                  isMandatory
                />
              </div>

              <MandatoryFieldsMarker />
              <span />
              <OptInCheckboxes />

              <div className="col-span-6 mb-10">
                {showActionButton && (
                  <Button fullWidth type="submit" onClick={() => handleClick(touched, isValid)}>
                    {props.actionButtonText}
                  </Button>
                )}
                {!showActionButton && <SpinnerWeb />}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

import React, { useEffect, useState } from 'react';

import { LexikonListing } from '@dtk/public-website-ui-components';
import Storyblok from '../../utils/storyblok';
import { storyblokEditable } from '@storyblok/react';

interface LinksProps {
  slug: string;
  name: string;
}

const LexikonPageBlok = ({ blok }) => {
  const [links, setLinks] = useState<LinksProps[]>([]);

  useEffect(() => {
    Storyblok.get('cdn/links/', {
      starts_with: 'lexikon/',
    }).then((response) => {
      const linksArr = Object.entries(response.data.links);
      setLinks([
        ...linksArr.map((name: any) => {
          return { name: name[1].name, slug: name[1].slug };
        }),
      ]);
    });
  }, []);

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="lexikonListing">
      <div className="bg-gray-50 pb-5">
        <LexikonListing links={links} />
      </div>
    </div>
  );
};

export default LexikonPageBlok;

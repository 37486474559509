import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { getHighlightedText } from '../utils';
import { useMediaQuery } from '../hooks';

type BannerState = 'error' | 'success' | 'info';

interface MessageWithIconProps {
  bannerState: BannerState;
  backgroundColor: string;
  textColor: string;
  styles?: string;
  headline?: string;
  message: string;
  highlightText?: string;
  linkText?: string;
}

interface MessageBannerProps {
  classNames?: string;
  headline?: string;
  message: string;
  highlightText?: string;
  linkText?: string;
  isError?: boolean;
  isSuccess?: boolean;
}

const MessageWithIcon = ({
  bannerState,
  backgroundColor,
  textColor,
  styles,
  headline,
  message,
  highlightText,
  linkText,
}: MessageWithIconProps) => {
  const { largerSm } = useMediaQuery();
  const iconClassNames = 'h-5 w-5 basis-1/12';

  if (message) {
    return (
      <div className={backgroundColor}>
        <div className={classNames('mx-auto flex max-w-7xl flex-row items-center py-4 px-6', styles)}>
          <div className="ml-auto">
            {largerSm && bannerState === 'error' && (
              <XCircleIcon className={classNames('text-red-700', iconClassNames)} />
            )}
            {largerSm && bannerState === 'success' && (
              <CheckCircleIcon className={classNames('text-green-700', iconClassNames)} />
            )}
            {largerSm && bannerState === 'info' && (
              <InformationCircleIcon className={classNames('ml-auto text-cyan-700', iconClassNames)} />
            )}
          </div>
          <div className="mr-auto flex flex-col sm:ml-2">
            {headline && <p className={classNames(textColor, 'font-body-semibold mb-2')}>{headline}</p>}
            <p
              className={classNames(textColor)}
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(
                  message,
                  highlightText || '',
                  textColor,
                  { fontFamily: 'font-body', isBold: true },
                  linkText
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export const MessageBanner = (props: MessageBannerProps) => {
  let bannerState: BannerState = 'info';
  if (props.isError) {
    bannerState = 'error';
    return (
      <MessageWithIcon
        bannerState={bannerState}
        backgroundColor="bg-red-50"
        textColor="text-red-700"
        styles={props.classNames}
        headline={props.headline}
        message={props.message}
        highlightText={props.highlightText}
        linkText={props.linkText}
      />
    );
  } else if (props.isSuccess) {
    bannerState = 'success';
    return (
      <MessageWithIcon
        bannerState={bannerState}
        backgroundColor="bg-green-50"
        textColor="text-green-700"
        styles={props.classNames}
        headline={props.headline}
        message={props.message}
        highlightText={props.highlightText}
        linkText={props.linkText}
      />
    );
  }

  return (
    <MessageWithIcon
      bannerState={bannerState}
      backgroundColor="bg-cyan-50"
      textColor="text-cyan-700"
      styles={props.classNames}
      headline={props.headline}
      message={props.message}
      highlightText={props.highlightText}
      linkText={props.linkText}
    />
  );
};

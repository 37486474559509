import { BlogPostsListingBlok } from './content-types/BlogPostsListingBlok';
import { BlogPostSummaryListingBlok } from './content-types/BlogPostSummaryListingBlok';
import { CardWithImageTextElementsAndButtonBlok } from './02_composite/Cards/CardWithImageTextElementsAndButtonBlok';
import { CardsContainerBlok } from './02_composite/Cards/CardsContainerBlok';
import { CheckoutCardBlok } from './02_composite/Cards/CheckoutCardBlok';
import { ClubFAQCardBlok } from './02_composite/DTK-Club/ClubFAQCardBlok';
import { ContactPersonBlok } from './02_composite/ContactPersonBlok';
import { CTASectionBlok } from './02_composite/CTASectionBlok';
import { FAQSectionBlok } from './02_composite/FAQSectionBlok';
import { FeatureSectionBlok } from './02_composite/FeatureSection/FeatureSectionBlok';
import { HeaderTextBlok } from './01_elements/HeaderTextBlok';
import { HeadlineBlok } from './01_elements/HeadlineBlok';
import { IconBlok } from './01_elements/IconBlok';
import { ImageComponentBlok } from './01_elements/ImageComponentBlok';
import { ImagesCarouselBlok } from './02_composite/ImagesCarouselBlok';
import { KontaktSectionBlok } from './02_composite/KontaktSectionBlok';
import { ListBlok } from './01_elements/List/ListBlok';
import { ListItemBlok } from './01_elements/List/ListItemBlok';
import { LoaderWithImageBlok } from './02_composite/LoaderWithImageBlok';
import { ModalBlok } from './02_composite/Modals/Modal/ModalBlok';
import { OverviewCardSectionBlok } from './02_composite/DTK-Club/OverviewCardSectionBlok';
import { PageBlok } from './content-types/PageBlok';
import { PageRedirectionButtonsBlok } from './01_elements/PageRedirectionButtonsBlok';
import { PartnerBannerOfferingBlok } from './02_composite/DTK-Club/PartnerBannerOfferingBlok';
import { PartnerBannerOfferingListBlok } from './02_composite/DTK-Club/PartnerBannerOfferingListBlok';
import { PartnerOfferFormSectionBlok } from './02_composite/DTK-Club/PartnerOfferFormSectionBlok';
import { PlaceholderBlok } from './01_elements/PlaceholderBlok';
import { PostsCarouselBlok } from './02_composite/PostsCarouselBlok';
import { ProductDetailSectionBlok } from './02_composite/DTK-Club/ProductDetailSectionlBlok';
import { PublicHeroSectionBlok } from './02_composite/PublicHeroSectionBlok';
import { RichTextBlok } from './02_composite/RichText/RichTextBlok';
import { StandardCardContainerBlok } from './01_elements/StandardCardContainerBlok';
import { SublineBlok } from './01_elements/SublineBlok';
import { TableBlok } from './02_composite/Table/TableBlok';
import { TableRowBlok } from './02_composite/Table/TableRowBlok';
import { TableColumnBlok } from './02_composite/Table/TableColumnBlok';
import { TableRowCellBlok } from './02_composite/Table/TableRowCellBlok';
import { TeamSectionBlok } from './02_composite/TeamSectionBlok';
import { ThankYouSectionBlok } from './02_composite/ThankYouSectionBlok';

export const sharedStoryblokComponents = {
  HeaderText: HeaderTextBlok,
  Headline: HeadlineBlok,
  PageRedirectionButtons: PageRedirectionButtonsBlok,
  blog_posts_listing: BlogPostsListingBlok,
  blog_post_summary_listing: BlogPostSummaryListingBlok,
  ImagesCarousel: ImagesCarouselBlok,
  Kontakt: KontaktSectionBlok,
  PostsCarousel: PostsCarouselBlok,
  ContactPerson: ContactPersonBlok,
  page: PageBlok,
  CardsContainer: CardsContainerBlok,
  CardWithImageTextElementsAndButton: CardWithImageTextElementsAndButtonBlok,
  Placeholder: PlaceholderBlok,
  Modal: ModalBlok,
  FeatureSection: FeatureSectionBlok,
  CTASection: CTASectionBlok,
  FAQSection: FAQSectionBlok,
  TeamSection: TeamSectionBlok,
  RichTextBlok: RichTextBlok,
  RichText: RichTextBlok,
  StandardCardContainer: StandardCardContainerBlok,
  Subline: SublineBlok,
  List: ListBlok,
  ListItem: ListItemBlok,
  ThankYouSection: ThankYouSectionBlok,
  PublicHeroSection: PublicHeroSectionBlok,
  LoaderWithImage: LoaderWithImageBlok,
  ImageComponent: ImageComponentBlok,
  Table: TableBlok,
  TableRow: TableRowBlok,
  TableColumn: TableColumnBlok,
  TableRowCell: TableRowCellBlok,
  Icon: IconBlok,
  PartnerBannerOffering: PartnerBannerOfferingBlok,
  PartnerBannerOfferingList: PartnerBannerOfferingListBlok,
  CheckoutCard: CheckoutCardBlok,
  ClubFAQCard: ClubFAQCardBlok,
  OverviewCardSection: OverviewCardSectionBlok,
  PartnerOfferFormSection: PartnerOfferFormSectionBlok,
  ProductDetailSection: ProductDetailSectionBlok,
};

import { FrozeRBGColor, PublicHeroSectionV2 } from '@dtk/ui-components';

import { storyblokEditable } from '@storyblok/react';

function getColorsRGBA(colorString: string): FrozeRBGColor {
  const convertColor: FrozeRBGColor = {
    r: 175,
    g: 130,
    b: 50,
    a: 0.3,
  };
  while (colorString.includes(' ')) {
    colorString = colorString.replace(' ', '');
  }
  //Remove last ',' to get a better Storyblok config liveview
  if (colorString[colorString.length - 1] === ',') {
    colorString = colorString.substring(0, colorString.length - 1);
  }
  //Split input string
  const splittedColorString: string[] = colorString.split(',');
  //Multiple ifs so the customer see his configs in real-time and not only the result at the end.
  if (splittedColorString.length >= 1) {
    convertColor.r = Number(splittedColorString[0]);
  }
  if (splittedColorString.length >= 2) {
    convertColor.g = Number(splittedColorString[1]);
  }
  if (splittedColorString.length >= 3) {
    convertColor.b = Number(splittedColorString[2]);
  }
  if (splittedColorString.length === 4) {
    convertColor.a = Number(splittedColorString[3]);
  }
  return convertColor;
}

export const PublicHeroSectionBlok = ({ ...props }) => {
  const { blok } = props;

  const textElements = {
    overline: blok.overline,
    headingType: blok.headingType,
    headlineText: blok.headline,
    subHeadlineText: blok.subHeadline,
    highlightedTextIsBold: blok.highlightedTextIsBold,
    highlightText: blok.underline,
    highlightTextColor: blok.highlightTextColor,
    description: {
      text: blok.description,
      boldText: blok.descriptionBoldText,
    },
    descriptionSize: blok.descriptionSize,
  };

  const buttonElements = {
    buttonText: blok.buttonName,
    buttonNameMobil: blok.buttonNameMobil,
    buttonLink: blok.buttonLink,
    buttonColor: blok.buttonColor,
  };

  const stoerer = {
    stoererBackgroundColor: blok.stoererBackgroundColor,
    stoererIcon: blok.stoererIcon,
    stoererTextSize: blok.stoererTextSize,
    isStoererHeadlineBold: blok.isStoererHeadlineBold,
    stoererHeadline: blok.stoererHeadline,
    stoererText: blok.StoererText,
  };

  const banner = {
    showCertificateIcon: blok.showCertificateIcon,
    bannerImage: blok.bannerImage,
    bannerImageLink: blok.bannerImageLink,
  };

  const effectSettings = {
    //Blur effect
    backgroundBlur: blok.backgroundBlur,
    blurDesktop: blok.blurDesktop, //px
    blurMobile: blok.blurMobile, //px
    bgFrozeColor: getColorsRGBA(blok.blurColor ? blok.blurColor : '175, 175, 175, 0.1'),
  };

  const allHeroSectionProps = { ...textElements, ...buttonElements, ...stoerer, ...banner, ...effectSettings };
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="public-hero-section">
      <PublicHeroSectionV2
        theme={blok.theme}
        backgroundImage={blok.image}
        backgroundImageMobile={blok.mobileImage}
        loadingPriority={blok.loadingPriority}
        {...allHeroSectionProps}
      />
    </div>
  );
};

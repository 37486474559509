import classNames from 'classnames';

export type BadgeBgColorPreset = 'cyan' | 'gold' | 'red';

const BGColors: Record<BadgeBgColorPreset, string> = {
  cyan: 'text-cyan bg-navy-50',
  gold: 'text-navy bg-gold-200',
  red: 'text-white bg-red',
};

export interface BadgeProps {
  name: string;
  href?: string;
  borderRadius?: string;
  bgColor?: BadgeBgColorPreset;
  className?: string;
}

export const Badge = ({ bgColor = 'gold', className = '', ...restProps }: BadgeProps) => {
  const { name } = restProps;
  const badgeClassName = classNames('', BGColors[bgColor], className);

  return <span className={badgeClassName}>{name}</span>;
};

/**
 * Formats a number to a currency.
 * Default is EUR in format "70.000 €"
 */
export const formatCurrency = (value: number, decimals?: number, showCurrency = true) => {
  if (value === undefined || value === null) return;
  return new Intl.NumberFormat('DE-de', {
    currency: 'EUR',
    ...(showCurrency && { style: 'currency' }),
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
};

/**
 * Formats a number to a currency.
 * Default is EUR in format "70.000 €"
 * Unit should be written in short like m = meter
 */
export const formatCurrencyWithUnit = (value: number, decimals?: number, unit?: string, exponentForUnit?: number) => {
  const allExponents = ['²', '³'];

  if (!unit || exponentForUnit === undefined || exponentForUnit < 2) return;

  const appendingUnit = exponentForUnit ? `${unit}${allExponents[exponentForUnit - 2]}` : `${unit}`;
  return `${formatCurrency(value, decimals, true)}/${appendingUnit}`;
};

import classNames from 'classnames';
import { ReactNode } from 'react';

export interface CardProps {
  id?: string;
  tabBarTitle?: string;
  headline?: string;
  subline?: string;
  children: ReactNode;
  info?: ReactNode;
  fullHeight?: boolean;
  className?: string;
}

const Headline = (headline: string) => {
  return <h3 className="text-navy mb-2 text-lg font-semibold leading-6 md:mb-3">{headline}</h3>;
};

const HeadlineWithSubline = (headline: string, subline: string) => {
  return (
    <>
      <h3 className="text-navy text-lg font-semibold leading-6">{headline}</h3>
      <p className="text-coolGray-500 mt-1 mb-2 text-sm">{subline}</p>
    </>
  );
};

export function Card({
  children,
  tabBarTitle,
  headline,
  subline,
  info,
  fullHeight = false,
  className = '',
  id,
}: CardProps) {
  const cardClassNames = classNames(
    className,
    tabBarTitle && 'rounded-tl-none',
    'shadow-md bg-white rounded-md',
    fullHeight && 'h-full'
  );

  return (
    <div id={id} className={cardClassNames}>
      <div className="divide-y-2 border-gray-200 px-4 py-5 sm:px-6">
        {headline && (
          <div>
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-4">{subline ? HeadlineWithSubline(headline, subline) : Headline(headline)}</div>
              <div className="ml-4 mt-4 flex-shrink-0 pb-2">{info}</div>
            </div>
          </div>
        )}
        <div className="py-2 md:py-3">{children}</div>
      </div>
    </div>
  );
}

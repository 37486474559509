import { Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import {
  ADDRESS_FIELD_NAMES,
  REQUESTED_PAYOUT_FIELD_NAME,
  getValidationSchema,
  offerJourneySchema,
} from '@dtk/ui-components';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { getAllValuesBySpecifiedKey, getFieldValueByFieldName } from '../utils';
import AngebotsstreckeNavigation from '../../AngebotStrecke/Navigation';
import { AngebotsstreckeContext } from '../../../contexts/AngebotsstreckeContext';
import { useOfferStore } from '../../../store/OfferStore';

const FormBlok = ({ blok }) => {
  const { formRef, handleNext } = useContext(AngebotsstreckeContext);

  const data = useOfferStore.getState().data;
  const requestedPayout = useOfferStore.getState().requestedPayout;

  let allCurrentFieldNames: string[] = getAllValuesBySpecifiedKey(blok.fields, 'name');
  const currentComponentNames: string[] = blok.fields
    .filter((field) => typeof field.component === 'string')
    .map((field) => field.component);

  const initValues = {};
  let validationSchema = getValidationSchema(offerJourneySchema, allCurrentFieldNames);

  allCurrentFieldNames.forEach((fieldName) => {
    if (getFieldValueByFieldName(blok.fields, fieldName, 'component') === 'InputCounter') {
      return (initValues[fieldName] = parseInt(getFieldValueByFieldName(blok.fields, fieldName, 'defaultValue')));
    }
    return (initValues[fieldName] = '');
  });

  if (currentComponentNames.includes('InputRechner')) {
    initValues[REQUESTED_PAYOUT_FIELD_NAME] = '';
    validationSchema = getValidationSchema(offerJourneySchema, [REQUESTED_PAYOUT_FIELD_NAME]);
  }

  if (currentComponentNames.includes('InputAddressWithAutocomplete')) {
    ADDRESS_FIELD_NAMES.forEach((fieldName) => {
      initValues[fieldName] = '';
    });
    validationSchema = getValidationSchema(offerJourneySchema, ADDRESS_FIELD_NAMES);
  }

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      allCurrentFieldNames = currentComponentNames.includes('InputRechner')
        ? [REQUESTED_PAYOUT_FIELD_NAME]
        : allCurrentFieldNames;

      allCurrentFieldNames = currentComponentNames.includes('InputAddressWithAutocomplete')
        ? ADDRESS_FIELD_NAMES
        : allCurrentFieldNames;

      allCurrentFieldNames.forEach((fieldName) => {
        const digitExpression = /^\d/;
        const isFirstCharacterDigit = digitExpression.test(fieldName);

        if (!isFirstCharacterDigit) {
          const formElement =
            (document?.getElementById(fieldName) as HTMLInputElement) ||
            (document?.querySelector(`.${fieldName}`) as HTMLButtonElement);
          formElement?.blur();
        }
      });

      const submitButton = document.querySelector('.navigation-next-button') as HTMLButtonElement | null;
      submitButton?.click();
    }
  };

  const loadFormData = () => {
    if (data.size > 0) {
      data.forEach((value, key) => {
        formRef.current.setFieldValue(key, value, true);
      });
    }
    if (requestedPayout) {
      formRef.current.setFieldValue(REQUESTED_PAYOUT_FIELD_NAME, requestedPayout, true);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      handleEnterKeyPress(event);
    };
    loadFormData();

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const onpopstateHandler = (event) => {
  //     const HISTORY_AND_PROGRESS_STEPS_DIFF = 2;
  //     if (!event?.state?.page || event.state.page + HISTORY_AND_PROGRESS_STEPS_DIFF === progressStep) {
  //       handleBack();
  //     } else {
  //       handleNext();
  //       window.scrollTo({ top: 0, left: 0 });
  //     }
  //   };

  //   onpopstate = (event) => {
  //     onpopstateHandler(event);
  //   };

  //   return () => {
  //     onpopstate = () => {
  //       onpopstateHandler(event);
  //     };
  //   };
  // }, [handleBack, handleNext, progressStep]);

  const onSubmitHandler = () => {
    // history.pushState({ ...history.state, page: (history.state?.page || 0) + 1 }, null);
    handleNext();
    window.scrollTo({ top: 0, left: 0 });
  };

  return (
    <div {...storyblokEditable(blok)} className="form" key={blok._uid} data-test="form">
      <Formik
        innerRef={formRef}
        key={blok._uid}
        initialValues={initValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={onSubmitHandler}
      >
        <Form>
          <div className="grid grid-cols-6 gap-x-5">
            {blok.fields.map((nestedBlok: SbBlokData) => (
              <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
            ))}
          </div>
          <AngebotsstreckeNavigation />
        </Form>
      </Formik>
    </div>
  );
};

export default FormBlok;

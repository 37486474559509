import classNames from 'classnames';
import Link from 'next/link';

const FooterLinkBlok = ({ blok, styles }) => (
  <div className={classNames('text-navy-700 mb-2', styles)}>
    <Link href={blok.url}>
      <a>{blok.text}</a>
    </Link>
  </div>
);

export default FooterLinkBlok;

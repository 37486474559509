import Image from 'next/future/image';
import { ImageProps } from '../../utils';
import { storyBlokLoader } from '../../utils/storyblok-loader';

interface FeatureImageProps {
  image: ImageProps;
  imageStyles: string;
}

export const FeatureImage = ({ image, imageStyles }: FeatureImageProps) => {
  return (
    <div className="relative">
      <Image
        loader={storyBlokLoader}
        className={imageStyles}
        src={image.filename}
        alt={image.alt}
        width={1000}
        height={1000}
        loading="lazy"
        sizes="(max-width: 640) 100vw, (max-width: 768) 75vw, (max-width: 1024) 50vw, 33vw"
      />
    </div>
  );
};

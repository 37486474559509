type LoadingType = 'eager' | 'lazy';

interface LogoProps {
  className?: string;
  iconSource: string;
  alt?: string;
  loading?: LoadingType;
  width?: number;
  height?: number;
}

export const StoryblokIcon = ({ className, iconSource, alt, loading, width, height }: LogoProps) =>
  iconSource ? (
    <img
      className={className}
      src={iconSource}
      alt={alt || 'icon'}
      width={width || 24}
      height={height || 24}
      aria-label={alt || 'icon'}
      loading={loading || 'lazy'}
      {...{ fetchpriority: loading === 'eager' ? 'high' : 'low' }}
    />
  ) : null;

import { number, object, string } from 'yup';
import { useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Form, Formik, FormikTouched } from 'formik';
import axios from 'axios';

import { useAppConfig } from '@dtk/config';
import {
  Button,
  DropdownField,
  InputField,
  MandatoryFieldsMarker,
  MessageBanner,
  hasWebNavigationWithinPage,
  salutationOptions,
  titleOptions,
  validationMessages,
} from '@dtk/ui-components';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import { SpinnerWeb } from '../01_elements';

const DEFAULT_COMPANY_NAME = 'Deutschen Teilkauf GmbH';

export interface ContactRequestFormProps {
  pageSlug?: string;
  companyName?: string;
  destinationEmailAddress?: string;
}

interface FormValues {
  salutation: string;
  title?: string;
  firstname: string;
  lastname: string;
  email: string;
  telephoneNumber: string;
  street: string;
  houseNr: string;
  plz: string;
  city: string;
  feedback?: string;
  prefix: string;
}

const initialValues = {
  salutation: '',
  title: '',
  firstname: '',
  lastname: '',
  email: '',
  telephoneNumber: '',
  street: '',
  houseNr: '',
  plz: '',
  city: '',
  feedback: '',
  prefix: '',
};

const validationSchema = object({
  salutation: string().required(validationMessages.error.defaultDropdownField.text),
  firstname: string().required(validationMessages.error.defaultInputField.text),
  lastname: string().required(validationMessages.error.defaultInputField.text),
  email: string().email(validationMessages.error.email.text).required(validationMessages.error.defaultInputField.text),
  telephoneNumber: number().integer().required(validationMessages.error.defaultInputField.text),
  street: string().required(validationMessages.error.defaultInputField.text),
  houseNr: string().required(validationMessages.error.defaultInputField.text),
  plz: string()
    .length(5, validationMessages.error.zipCode.text)
    .matches(/^\d+$/, validationMessages.error.zipCode.text)
    .required(validationMessages.error.defaultInputField.text),
  city: string().required(validationMessages.error.defaultInputField.text),
});

export const ContactRequestForm = ({ pageSlug, companyName, destinationEmailAddress }: ContactRequestFormProps) => {
  const { config } = useAppConfig();
  const router = useRouter();
  const [showSendButton, setShowSendButton] = useState(true);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const componentHead = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleClick = (touched: FormikTouched<unknown>, isValid: boolean) => {
    if (isValid && Object.keys(touched).length > 0) {
      setShowMessageBanner(false);
      return;
    }
    setShowMessageBanner(true);
    return componentHead.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (values: FormValues) => {
    setShowSendButton(false);

    const payload = {
      ...values,
      prefix: 'WebContactForm',
      streetNumber: values.houseNr,
      areaCode: values.plz.toString(),
      telephoneNumber: values.telephoneNumber.toString(),
      destinationEmailAddress: destinationEmailAddress,
    };

    try {
      const response = await axios.post(config['API_BASE'] + '/public/contact-request', payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      // eslint-disable-next-line no-console
      console.log(response.data);
      router.push(`${pageSlug}/vielen-dank-kontaktformular`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      router.push(`${pageSlug}/error`);
    }
  };

  return (
    <div
      className={`${hasWebNavigationWithinPage() && 'scroll-mt-14 xl:scroll-my-24'} bg-coolGray-50`}
      ref={componentHead}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ touched, isValid }) => (
          <Form>
            {!isValid && showMessageBanner && (
              <MessageBanner
                classNames="mb-10"
                message={validationMessages.error.defaultBanner.text}
                highlightText={validationMessages.error.defaultBanner.highlightedPart}
                isError
              />
            )}
            <div className="mx-auto grid w-full grid-cols-6 gap-x-6 gap-y-4 p-[2rem] sm:max-w-[51rem]">
              <div className="col-span-6 sm:col-span-3">
                <DropdownField
                  data-testid="salutation"
                  name="salutation"
                  label="Anrede"
                  options={salutationOptions}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <DropdownField label="Titel" id="title" name="title" options={titleOptions} />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  label="Vorname"
                  id="firstname"
                  name="firstname"
                  type="text"
                  infoMessage={validationMessages.info.firstName}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  label="Nachname"
                  id="lastname"
                  name="lastname"
                  type="text"
                  infoMessage={validationMessages.info.lastName}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  label="Straße"
                  id="street"
                  name="street"
                  type="text"
                  infoMessage={validationMessages.info.street}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  label="Nr."
                  id="houseNr"
                  name="houseNr"
                  type="text"
                  infoMessage={validationMessages.info.houseNr}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  label="PLZ"
                  id="plz"
                  name="plz"
                  type="text"
                  infoMessage={validationMessages.info.zipCode}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  label="Ort"
                  id="city"
                  name="city"
                  type="text"
                  infoMessage={validationMessages.info.city}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="email"
                  name="email"
                  label="E-Mail-Adresse"
                  type="text"
                  placeholder="email@mustermann.de"
                  icon={MailIcon}
                  infoMessage={validationMessages.info.email}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="telephoneNumber"
                  name="telephoneNumber"
                  label="Telefonnummer"
                  type="number"
                  icon={PhoneIcon}
                  infoMessage={validationMessages.info.phone}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <InputField
                  placeholder="Ihre optionale Nachricht an uns..."
                  label="Möchten Sie uns etwas mitteilen?"
                  id="feedback"
                  name="feedback"
                  as="textarea"
                  rows={4}
                  maxLength={1000}
                />
              </div>
              <MandatoryFieldsMarker />
              <span />

              <div className="col-span-6 sm:col-span-6">
                <label className="mr-1">
                  Ihre Daten werden von der <span>{companyName || DEFAULT_COMPANY_NAME}</span> nur zum Zweck der
                  Bearbeitung Ihres Anliegens und der persönlichen Kontaktaufnahme sowie Kommunikation mit Ihnen per
                  Post, E-Mail oder Telefon verarbeitet. Sie können Ihre Einwilligung jederzeit mit Wirkung für die
                  Zukunft und ohne Angabe von Gründen widerrufen, nähere Informationen zur Verarbeitung Ihrer Daten
                  finden Sie in unseren{' '}
                  <Link passHref href={pageSlug ? `${pageSlug}/datenschutz` : '/datenschutz'}>
                    <a target="_blank" rel="noopener noreferrer" href="/" className="text-cyan-700">
                      Datenschutzhinweisen
                    </a>
                  </Link>
                  .
                </label>
              </div>
              <div className="relative col-span-6">
                {showSendButton && (
                  <Button fullWidth type="submit" onClick={() => handleClick(touched, isValid)}>
                    Jetzt abschicken
                  </Button>
                )}
                {!showSendButton && <SpinnerWeb />}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

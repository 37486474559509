import { DownloadItem, DownloadItemImageProps, DownloadItemProps } from './DownloadItem';

export interface DownloadAreaProps {
  headline: string;
  subtitle?: string;
  downloadButtonText: string;
  logos: DownloadItemImageProps[];
  logosTitle: string;
  logosButtonText: string;
  pictures: DownloadItemImageProps[];
  picturesTitle: string;
  picturesButtonText: string;
  downloadLogosUrl?: string;
  downloadPicturesUrl?: string;
}

export const DownloadArea = ({
  headline,
  subtitle,
  downloadButtonText,
  logos,
  logosTitle,
  downloadLogosUrl,
  logosButtonText,
  pictures,
  picturesTitle,
  downloadPicturesUrl,
  picturesButtonText,
}: DownloadAreaProps) => {
  const downloadItems: DownloadItemProps[] = [
    {
      title: logosTitle,
      downloadUrl: downloadLogosUrl,
      downloadButtonText,
      buttonText: logosButtonText,
      images: logos,
    },
    {
      title: picturesTitle,
      downloadUrl: downloadPicturesUrl,
      downloadButtonText,
      buttonText: picturesButtonText,
      images: pictures,
    },
  ];

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row">
          <div className="w-5/5 mr-0 pr-0 md:mr-0 lg:mr-20 lg:w-2/5 lg:pr-20">
            <h2 className="font-headline text-navy-navy mt-5 mb-5 text-left text-3xl leading-10 tracking-wide sm:mb-3 sm:text-center md:text-4xl lg:text-left">
              {headline}
            </h2>
            <h3 className="text-navy-navy mb-12 text-left text-xl font-normal tracking-tight sm:text-center sm:text-xl md:text-center lg:text-left">
              {subtitle}
            </h3>
          </div>
          <div className="text-navy md:6/7 w-7/7 gap-x-5 sm:m-auto sm:grid lg:w-auto">
            {downloadItems.map((item) => (
              <DownloadItem
                key={item.title}
                downloadButtonText={downloadButtonText}
                images={item.images}
                title={item.title}
                buttonText={item.buttonText}
                downloadUrl={item.downloadUrl}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

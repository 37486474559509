import { storyblokEditable } from '@storyblok/react';

export const SublineBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <span
      key={blok._uid}
      className="text-navy-navy block text-sm font-normal"
      {...storyblokEditable(blok)}
      data-test="step-subline"
    >
      {blok.title}
    </span>
  );
};

import { useGetCustomer, Customer } from '@dtk/query';
import { ErrorState, Spinner } from '@dtk/ui-components';
import {
  Energieausweis48Wrapper,
  EnterWrapper,
  FassadenBlitzWrapper,
  MeinAlarm24Wrapper,
  PlanerConsultingWrapper,
} from './CustomForms';
import { DefaultWrapper } from './DefaultForm/Wrapper';
import { PARTNER_NAME, PartnerOfferBlokProps, CustomerDataProps } from '../types';

interface PartnerOfferWrapperProps {
  blok: PartnerOfferBlokProps;
  partnerName: PARTNER_NAME;
}

export const PartnerOfferWrapper = ({ blok, partnerName }: PartnerOfferWrapperProps) => {
  const { isLoading, data, isError } = useGetCustomer();

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorState state="techDifficultyPartnerOffer" />;
  }

  const { vorgangsNummer, leadDistributionAllowed, postCode, street, city, houseNumber } = data?.data as Customer;

  const customerData: CustomerDataProps = {
    vorgangsNummer: vorgangsNummer,
    leadDistributionAllowed: !!leadDistributionAllowed,
  };

  if (postCode && street && city) {
    customerData.address = {
      postCode,
      street,
      city,
      houseNumber,
    };
  }

  const map: Record<PARTNER_NAME, JSX.Element> = {
    enter: <EnterWrapper blok={blok} customerData={customerData} />,
    energieausweis48: <Energieausweis48Wrapper blok={blok} customerData={customerData} />,
    fassadenblitz: <FassadenBlitzWrapper blok={blok} customerData={customerData} />,
    'planer-consulting': <PlanerConsultingWrapper blok={blok} customerData={customerData} />,
    'shop-apotheke': <DefaultWrapper blok={blok} customerData={customerData} />,
    'mein-alarm-24': <MeinAlarm24Wrapper blok={blok} customerData={customerData} />,
    'bio-delikatessen': <DefaultWrapper blok={blok} customerData={customerData} />,
    grundriss: <DefaultWrapper blok={blok} customerData={customerData} />,
  };

  /* Requests for future partner are handled by the DefaultWrapper */
  return map[partnerName] ?? <DefaultWrapper blok={blok} customerData={customerData} />;
};

import { storyblokEditable } from '@storyblok/react';
import { Modal } from '@dtk/ui-components';

export const ModalBlok = ({ ...props }) => {
  const { blok, logoLink, isLeavingPage, setIsLeavingPage, isModalForLeavingPage } = props;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="modal">
      <Modal
        btnIcon={blok.btnIcon}
        btnText={blok.btnText}
        dialog={blok.dialog}
        logoLink={logoLink}
        isModalForLeavingPage={isModalForLeavingPage}
        isLeavingPageModalOpen={isLeavingPage}
        setIsLeavingPageModalOpen={setIsLeavingPage}
      />
    </div>
  );
};

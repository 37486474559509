import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import classNames from 'classnames';

export const CardsContainerBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="cards-container">
      <div className={classNames(blok.backgroundTheme, 'relative px-4 pb-20 sm:px-6 lg:px-8 lg:pb-28')}>
        {blok?.allCards?.map((nestedBlok: SbBlokData) => (
          <div key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </div>
        ))}
      </div>
    </div>
  );
};

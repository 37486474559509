import { Button } from '../../01_elements';
import { trackTask } from '@dtk/user-tracking';

export enum TaskUniqueNames {
  VOLLMACHT,
  MISSING_DOCUMENTS,
}

export type Task = {
  uniqueName?: TaskUniqueNames;
  title: string;
  description: string;
  button?: {
    link: string;
    text: string;
  };
  priority: number;
  status: TaskStatus;
};

export enum TaskStatus {
  OPEN,
  DONE,
}

export interface TaskProps {
  task: Task;
}

export function Task({ task }: TaskProps) {
  const { title, description, button } = task;

  const fireTrackingEvent = () => {
    if (!button) {
      return;
    }
    trackTask(task.title);
  };

  return (
    <div className="shadow-md">
      <div className="divide-y-2 border-gray-200 bg-cyan-50 px-4 py-5 sm:px-6">
        <div>
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-navy text-lg font-semibold leading-6">{title}</h3>
              <p className="text-navy mt-1 text-sm">{description}</p>
              {button && (
                <Button
                  className="border-coolGray-300 mt-3"
                  variant="white"
                  size="sm"
                  href={button.link}
                  onClick={() => fireTrackingEvent()}
                >
                  {button.text}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Form, Formik, FormikTouched } from 'formik';
import TagManager from 'gtm-for-react';
import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';

import { useAppConfig } from '@dtk/config';
import { SpinnerAngebotsstrecke } from '@dtk/public-website-ui-components';
import { Button, MessageBanner, getValidationSchema, offerJourneySchema, validationMessages } from '@dtk/ui-components';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { sendOffer } from '../sendOffer';
import { getAllValuesBySpecifiedKey, getFieldValueByFieldName } from '../../FormElements/utils';
import { useOfferStore } from '../../../store/OfferStore';

const CheckoutBlok = ({ ...props }) => {
  const { blok } = props;
  const { config } = useAppConfig();
  const router = useRouter();

  const [disableSendButton, setDisableSendButton] = useState(false);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const componentHead = useRef() as React.MutableRefObject<HTMLDivElement>;

  const allCurrentFieldNames: string[] = getAllValuesBySpecifiedKey(blok.bottomSide, 'name');
  const validationSchema = getValidationSchema(offerJourneySchema, allCurrentFieldNames);

  const initValues = {};
  allCurrentFieldNames.forEach((fieldName) => {
    if (getFieldValueByFieldName(blok.bottomSide, fieldName, 'component') === 'InputCheckbox') {
      return (initValues[fieldName] = false);
    }
    return (initValues[fieldName] = '');
  });

  const handleClick = (touched: FormikTouched<unknown>, isValid: boolean) => {
    if (isValid && Object.keys(touched).length > 0) {
      setShowMessageBanner(false);
      return;
    }
    setShowMessageBanner(true);
    return componentHead.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCheckout = (values) => {
    setDisableSendButton(true);
    try {
      sendOffer(values, config);
      useOfferStore.getState().resetValues();
      triggerGTMTrichterFinish();
      setTimeout(() => {
        router.push('/angebotsanfrage-vielen-dank/');
      }, 1000);
    } catch (err) {
      router.push('/error/');
    }
  };

  return (
    <div {...storyblokEditable(blok)} data-test="checkout" className="checkout flex flex-col">
      {blok.topSide &&
        blok.topSide.map((nestedBlok: SbBlokData) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
      <div className="mx-auto max-w-[51rem] px-5">
        <Formik
          key={blok._uid}
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={(values) => {
            handleCheckout(values);
          }}
        >
          {({ touched, isValid }) => (
            <Form>
              <div ref={componentHead}>
                {!isValid && showMessageBanner && (
                  <MessageBanner
                    classNames="my-10"
                    message={validationMessages.error.defaultBanner.text}
                    highlightText={validationMessages.error.defaultBanner.highlightedPart}
                    isError
                  />
                )}
                {blok.bottomSide?.length > 0 && (
                  <div className="grid grid-cols-12 gap-x-6 gap-y-4">
                    {blok.bottomSide.map((nestedBlok: SbBlokData) => (
                      <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
                    ))}
                  </div>
                )}
              </div>
              <Button
                type="submit"
                className="mt-7"
                size="lg"
                fullWidth
                disabled={disableSendButton}
                onClick={() => handleClick(touched, isValid)}
              >
                {!disableSendButton ? <>Unverbindliches Angebot anfordern</> : <SpinnerAngebotsstrecke />}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex justify-center pb-8">
        {blok.widget &&
          blok.widget.map((nestedBlok: SbBlokData) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
      </div>
    </div>
  );
};

function triggerGTMTrichterFinish() {
  const data = useOfferStore.getState().data;
  const firstName = data.get('firstName');
  const lastName = data.get('lastName');
  const emailAddress = data.get('email');
  const phoneNr = data.get('phone');

  TagManager.dataLayer({
    dataLayer: {
      event: 'trichterfinish',
    },
  });

  if (firstName && lastName && emailAddress && phoneNr) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'fillDetails',
        'upd.vorname': firstName,
        'upd.nachname': lastName,
        'upd.email': emailAddress,
        'upd.phone': phoneNr,
      },
    });
  }
}
export default CheckoutBlok;

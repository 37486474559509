import { ReactElement, ReactNode } from 'react';

type ChildrenType = ReactElement | ReactElement[] | ReactNode | ReactNode[];

interface VisibleCommon {
  when: boolean;
}

interface VisibleWithChildren extends VisibleCommon {
  children: ChildrenType;
  renderIf?: never;
  renderElse?: never;
}

interface VisibleIfElse extends VisibleCommon {
  children?: never;
  renderIf: ChildrenType;
  renderElse: ChildrenType;
}

type VisibleProps = VisibleWithChildren | VisibleIfElse;

const IfElse = ({ renderElse, renderIf, when }: VisibleIfElse) => {
  return <>{when ? renderIf : renderElse}</>;
};

export const Visible = ({ when, children, renderElse, renderIf }: VisibleProps) => {
  if (renderElse || renderIf) {
    return <IfElse {...{ when, renderElse, renderIf }} />;
  }

  if (!when) {
    return null;
  }

  return <>{children}</>;
};

import { PostsListing } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const PostsListingBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="grid">
    <div className="bg-gray-50">
      <PostsListing posts={blok.posts} categories={blok.categories} />
    </div>
  </div>
);

export default PostsListingBlok;

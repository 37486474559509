import classNames from 'classnames';
import { ReactNode } from 'react';
import { useMediaQuery } from '@dtk/ui-components';

export const Tooltip = ({ text, children, className }: { text?: string; children: ReactNode; className?: string }) => {
  const { smallerSm } = useMediaQuery();
  return (
    <div className="group relative flex cursor-pointer flex-col items-center">
      {children}
      {text && (
        <div
          className={classNames(
            className,
            'fixed top-[50%] left-[50%] z-50 mb-6 hidden w-64 items-center text-center group-hover:flex sm:absolute sm:top-auto sm:bottom-0 sm:left-0 lg:w-80'
          )}
          style={smallerSm ? { transform: 'translate(-50%, -50%)' } : {}}
        >
          <span className="relative w-64 whitespace-normal rounded-md bg-gray-600 p-2 text-sm leading-6 text-white shadow-lg">
            {text}
          </span>
        </div>
      )}
    </div>
  );
};

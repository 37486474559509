import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { usePatchPartnerProductOffer } from '@dtk/query';
import {
  MessageBanner,
  Spinner,
  useMediaQuery,
  validationMessages,
  OptInCheckboxes,
  isExternalPage,
} from '@dtk/ui-components';

import { PartnerOfferButtons } from '../02_composite/Buttons';
import { PartnerOfferFormProps, PartnerOfferValues } from '../types';

export const PartnerOfferFormSection = ({
  externalFormData,
  validationSchema,
  customerData,
  children,
}: PartnerOfferFormProps) => {
  const { smallerLg } = useMediaQuery();
  const [showSendButton, setShowSendButton] = useState(true);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const componentHead = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { mutate } = usePatchPartnerProductOffer();

  const {
    productId,
    productName,
    hasConfirmationEmail,
    acceptOfferButtonText,
    redirectUrl,
    emarsysTriggerId,
    alternativeButtonText,
    alternativeEmarsysTriggerId,
    selectButtonOptions,
  } = externalFormData;
  const [currentButtonName, setCurrentButtonName] = useState(acceptOfferButtonText);

  const initialValues = {
    productId: productId,
    productName: productName,
    email: '',
    vorgangsNummer: '',
    additionalInfo: '',
    zipCode: '',
    leadDistributionAllowed: false,
  };

  const handleSubmit = (values: PartnerOfferValues) => {
    const triggerId = currentButtonName === acceptOfferButtonText ? emarsysTriggerId : alternativeEmarsysTriggerId;
    const additionalInfo: string[] | undefined = selectButtonOptions ? [] : undefined;
    setShowSendButton(false);

    selectButtonOptions &&
      selectButtonOptions.forEach((option) => {
        if (option.name in values && values[option.name] && !additionalInfo?.includes(option.value))
          additionalInfo?.push(option.value);
        else additionalInfo?.filter((item) => item !== option.value);
      });

    const payload = {
      productId: productId,
      productName: productName,
      email: customerData.email,
      vorgangsNummer: customerData.vorgangsNummer,
      additionalInfo: selectButtonOptions && additionalInfo ? additionalInfo.toString() : undefined,
      triggerId: hasConfirmationEmail ? parseInt(String(triggerId)) : 0,
      leadDistributionAllowed: true,
    };

    mutate(
      { data: payload },
      {
        onSuccess: async () => {
          isExternalPage(redirectUrl) ? window.open(redirectUrl, '_blank') : window.location.assign(redirectUrl);
          isExternalPage(redirectUrl) && window.location.reload();
        },
        onError: () => {
          window.location.href = '/error';
        },
      }
    );
  };

  const offerButtonText = acceptOfferButtonText || 'Jetzt unverbindliches Angebot sichern';

  return (
    <div className="mt-6 bg-white md:mt-3" ref={componentHead}>
      <Formik
        initialValues={{ ...initialValues, leadDistributionAllowed: !!customerData.leadDistributionAllowed }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ touched, isValid }) => (
          <Form>
            {!isValid && showMessageBanner && smallerLg && (
              <MessageBanner
                classNames="mb-10"
                message={validationMessages.error.defaultBanner.text}
                highlightText={validationMessages.error.defaultBanner.highlightedPart}
                isError
              />
            )}

            <div className="mx-auto grid w-full grid-cols-6 gap-x-6 gap-y-4">
              {children}
              {!customerData.leadDistributionAllowed && (
                <div className="col-span-6 py-3 text-sm">
                  <OptInCheckboxes showLeadDistributionAllowed isMandatory />
                </div>
              )}
              {showSendButton && (
                <PartnerOfferButtons
                  setCurrentButtonName={setCurrentButtonName}
                  setShowMessageBanner={setShowMessageBanner}
                  componentHead={componentHead}
                  offerButtonText={offerButtonText}
                  alternativeButtonText={alternativeButtonText}
                  touched={touched}
                  isValid={isValid}
                />
              )}
              <div className="col-span-6">{!showSendButton && <Spinner />}</div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

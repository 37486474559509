import React from 'react';
import classNames from 'classnames';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { getStorageItemWithExpiry, setStorageItemWithExpiry } from '@dtk/ui-components';

const Page = ({ blok }) => {
  const HOME_ROUTE = '/';

  if (window.location.pathname === HOME_ROUTE) {
    const searchParams = new URLSearchParams(window.location.search);
    const maklerIdParam = searchParams.get('maklerid');

    if (maklerIdParam && !getStorageItemWithExpiry('maklerId')) {
      setStorageItemWithExpiry('maklerId', maklerIdParam, 90 * 24 * 60 * 60 * 1000); // 90 days
    }
  }

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="page">
      <div id="portal-root" key="portal-root"></div>
      {blok?.body.map((nestedBlok) => (
        <div
          key={nestedBlok._uid}
          className={classNames(
            nestedBlok.reference?.slug.includes('navigation') || nestedBlok.component === 'Navigation'
              ? 'sticky top-0 z-50'
              : ''
          )}
        >
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ))}
    </div>
  );
};

export default Page;

import Link from 'next/link';
import React from 'react';
import { StoryblokIcon } from './StoryblokIcon';
import classNames from 'classnames';

export type ButtonSizes = 'sm' | 'base' | 'lg';
export type ButtonIconPosition = 'left' | 'right';
export type ButtonVariantsRedesign =
  | 'navy'
  | 'cyan'
  | 'red'
  | 'coral'
  | 'gray'
  | 'white'
  | 'ghost'
  | 'mint'
  | 'alternativeWhite'
  | 'alternativeGhost'
  | 'pastelBlue'
  | 'lightGreen'
  | 'stoneGray'
  | 'darkStoneGray'
  | 'khaki';
export type LinkLocation = 'internal' | 'external';

interface Button {
  size?: ButtonSizes;
  variant?: ButtonVariantsRedesign;
  fullWidth?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  storyblokIconSource?: string;
  storyblokIconAlt?: string;
  iconPosition?: ButtonIconPosition;
  circular?: boolean;
  isexternal?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export type ButtonProps = JSX.IntrinsicElements['button'] &
  Button & {
    href?: undefined | Record<string, unknown>;
    as?: undefined | string;
  };

type LinkProps = JSX.IntrinsicElements['a'] &
  Button & {
    href: string | Record<string, unknown>;
    as?: undefined | string;
  };

type PolymorphicProps = ButtonProps | LinkProps;
type PolymorphicButton = {
  (props: LinkProps): JSX.Element;
  (props: ButtonProps): JSX.Element;
};

const hasLink = (props: PolymorphicProps): props is LinkProps => {
  return props.href !== undefined;
};

const hasIcon = (props: PolymorphicProps): props is ButtonProps => {
  return props.icon !== undefined;
};

export const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, PolymorphicProps>(
  (props, forwardedRef) => {
    const {
      size = 'base',
      variant = 'cyan',
      fullWidth,
      icon: Icon,
      storyblokIconSource,
      storyblokIconAlt,
      iconPosition,
      circular,
      isexternal,
      onClick,
      ...restProps
    } = props;
    const { className, children } = restProps;

    const buttonDefaultClassNames = classNames(
      className,
      fullWidth && 'w-full',
      'flex inline-flex border border-transparent justify-center font-medium items-center'
    );

    const buttonShapeClassNames = classNames(
      size === 'sm' && !circular && 'px-3 py-2 text-sm rounded-md focus:ring-2',
      size === 'base' && !circular && 'min-w-[10rem] px-[2.6rem] py-3 text-base rounded-md focus:ring',
      size === 'lg' && !circular && 'min-w-[10rem] px-9 py-4 text-lg rounded-md focus:ring',

      size === 'sm' && circular && 'p-1 rounded-full focus:ring-2',
      size === 'base' && circular && 'p-1.5 rounded-full focus:ring',
      size === 'lg' && circular && 'p-[1.875rem] rounded-full focus:ring'
    );

    const buttonColorClassNames = classNames(
      variant === 'navy' && 'text-white bg-navy shadow-sm hover:bg-navy-700 focus:ring-navy-600',
      variant === 'cyan' && 'text-white bg-cyan shadow-sm hover:bg-cyan-900 focus:ring-cyan-200',
      variant === 'red' && 'text-white bg-red shadow-sm hover:bg-red-800 focus:ring-red-200',
      variant === 'coral' && 'text-white bg-coral shadow-sm hover:bg-coral-800 focus:ring-red-200',
      variant === 'gray' &&
        'text-navy bg-coolGray-200 border-coolGray-200 shadow-sm hover:bg-coolGray-300 focus:ring-coolGray-100',
      variant === 'white' && 'text-navy bg-white border-coolGray-200 shadow hover:bg-coolGray-100 focus:ring-cyan-700',
      variant === 'ghost' &&
        'text-navy bg-transparent shadow-none ring-transparent hover:text-cyan-700 focus:text-cyan-secondary focus:ring-transparent',
      variant === 'mint' && 'text-white bg-navy shadow-sm hover:bg-navy-700 focus:ring-navy-600',
      variant === 'alternativeWhite' &&
        'text-navy bg-white border-coolGray-300 shadow hover:bg-coolGray-100 focus:ring-green-lightGreen',
      variant === 'alternativeGhost' &&
        'text-navy bg-transparent shadow-none ring-transparent hover:text-green-lightGreen focus:text-khaki focus:ring-transparent',
      variant === 'pastelBlue' && 'text-white bg-blue-pastelBlue shadow-sm hover:bg-navy-400 focus:ring-navy-200',
      variant === 'lightGreen' &&
        'text-navy hover:text-white bg-green-lightGreen shadow-sm hover:bg-khaki-300 focus:ring-khaki-100',
      variant === 'stoneGray' &&
        'text-navy bg-gray-stoneGray-200 shadow-sm hover:bg-gray-stoneGray focus:ring-gold-creme',
      variant === 'khaki' && 'text-white bg-khaki shadow-sm hover:bg-khaki-600 focus:ring-khaki',
      variant === 'darkStoneGray' && 'bg-gray-stoneGray'
    );

    const buttonStateClassNames = classNames(
      !props.disabled && buttonColorClassNames,
      !props.disabled && variant === 'ghost' && hasLink(props) && 'hover:underline',
      props.disabled && 'text-coolGray-400 bg-coolGray-200 pointer-events-none'
    );

    const iconClasses = classNames(
      size === 'sm' && iconPosition === 'left' && '-ml-0.5 mr-2 h-4 w-4',
      size === 'base' && iconPosition === 'left' && '-ml-1 mr-2 h-5 w-5',
      size === 'lg' && iconPosition === 'left' && '-ml-1.5 mr-4 h-6 w-6',

      size === 'sm' && iconPosition === 'right' && 'ml-2 -mr-0.5 h-4 w-4',
      size === 'base' && iconPosition === 'right' && 'ml-2 -mr-1 h-5 w-5',
      size === 'lg' && iconPosition === 'right' && 'ml-4 -mr-1.5 h-6 w-6',

      size === 'sm' && Icon && circular && 'h-4 w-4',
      size === 'base' && Icon && circular && 'h-4 w-4',
      size === 'lg' && Icon && circular && 'h-5 w-5'
    );

    const PositionedIcon = () => (
      <>
        {circular && Icon && <Icon className={iconClasses} />}
        {hasIcon(props) && Icon && iconPosition === 'left' && <Icon className={iconClasses} />}
        {storyblokIconSource && iconPosition === 'left' && (
          <StoryblokIcon className={iconClasses} iconSource={storyblokIconSource} alt={storyblokIconAlt} />
        )}
        {children}
        {storyblokIconSource && iconPosition === 'right' && (
          <StoryblokIcon className={iconClasses} iconSource={storyblokIconSource} alt={storyblokIconAlt} />
        )}
        {hasIcon(props) && Icon && iconPosition === 'right' && <Icon className={iconClasses} />}
      </>
    );

    return hasLink(props) ? (
      <>
        {props.isexternal && (
          <a
            {...(restProps as LinkProps)}
            className={classNames(buttonDefaultClassNames, buttonStateClassNames, buttonShapeClassNames)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick || undefined}
          >
            <PositionedIcon />
          </a>
        )}
        {!props.isexternal && (
          <Link {...(restProps as LinkProps)} ref={forwardedRef as React.ForwardedRef<HTMLAnchorElement>}>
            <a
              {...(restProps as LinkProps)}
              className={classNames(buttonDefaultClassNames, buttonStateClassNames, buttonShapeClassNames)}
              onClick={onClick || undefined}
            >
              <PositionedIcon />
            </a>
          </Link>
        )}
      </>
    ) : (
      <button
        {...(restProps as ButtonProps)}
        className={classNames(buttonDefaultClassNames, buttonStateClassNames, buttonShapeClassNames)}
        ref={forwardedRef as React.ForwardedRef<HTMLButtonElement>}
        onClick={onClick || undefined}
      >
        <PositionedIcon />
      </button>
    );
  }
) as PolymorphicButton;

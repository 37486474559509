import { Button } from '../../01_elements/Button';
import { headingType, Headline } from '../../01_elements/Headline';

export interface KontaktSectionProps {
  imageUrl: string;
  headingType: headingType;
  headline: string;
  personName: string;
  personPosition: string;
  personEmail: string;
  buttonText: string;
  buttonLink: string;
  firstLine: string;
  secondLine: string;
  thirdLine: string;
  lastLine: string;
}

export const KontaktSection = ({
  imageUrl,
  headingType,
  headline,
  personName,
  personPosition,
  personEmail,
  buttonText,
  buttonLink,
  firstLine,
  secondLine,
  thirdLine,
  lastLine,
}: KontaktSectionProps) => {
  const chosenHeadingType = headingType || 'h2';

  return (
    <div className="lg:linear-gradient-90-halfBg-Gray50AndNavy bg-white">
      <div className="flex max-w-7xl flex-col lg:mx-auto lg:flex-row">
        <div className="relative flex flex-col pt-10 lg:pl-6">
          {imageUrl && (
            <img
              className="absolute inset-x-0 m-auto max-h-80 w-11/12 max-w-md rounded-md object-cover object-top md:max-h-96 md:max-w-sm lg:inset-x-10 lg:h-80 lg:w-80"
              src={`${imageUrl}/m/960x0/smart`}
              alt="Kontakt"
              width={1200}
              height={1200}
              loading="lazy"
            />
          )}
          <div className="text-navy top-96 z-20 hidden py-4 px-4 sm:px-6 md:px-10 lg:mt-80 lg:ml-4 lg:block lg:px-0">
            <span className="text-xl font-semibold">{personName}</span>
            <br />
            <span className="text-md font-normal">{personPosition}</span>
          </div>
        </div>

        <div className="bg-navy mt-64 py-20 md:mt-80 lg:ml-5 lg:mt-0 lg:rounded-l-md lg:py-16 lg:pl-20">
          <div className="mx-auto px-4 sm:px-6 md:mx-10 md:px-0 lg:px-0 lg:pl-44 lg:pb-20">
            <div className="mb-8 block text-white lg:hidden">
              <span className="text-xl font-semibold">{personName}</span>
              <br />
              <span className="text-md font-normal">{personPosition}</span>
            </div>
            <Headline
              headlineClassNames="text-gold font-headline"
              headline={headline}
              headingType={chosenHeadingType}
            />
            <div className="mt-4 text-base font-medium text-white lg:mt-6 lg:text-lg">
              {firstLine && <p>{firstLine}</p>}
              {secondLine && <p className="inline">{secondLine}</p>} <a href={`mailto:${personEmail}`}>{personEmail}</a>{' '}
              {thirdLine && <p className="inline">{thirdLine}</p>}
              {lastLine && <p className="mt-8 mb-6">{lastLine}</p>}
            </div>
            <div className="mt-6">
              <Button className="w-full lg:w-auto" size="lg" href={buttonLink}>
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { BlogPostsListing } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const BlogPostsListingBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} data-test="blog-posts-listing">
      <div className="bg-gray-50">
        <BlogPostsListing
          posts={blok.posts}
          categories={blok.categories}
          currentPage={blok.currentPage}
          totalPages={blok.totalPages}
        />
      </div>
    </div>
  );
};

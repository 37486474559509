import React, { useContext, useEffect, useState } from 'react';

import { AngebotsstreckeContext } from '../../contexts/AngebotsstreckeContext';
import { LoadingComponent } from '@dtk/public-website-ui-components';
import { storyblokEditable } from '@storyblok/react';

const LoadingComponentBlok = ({ blok }) => {
  const { handleNext } = useContext(AngebotsstreckeContext);
  const [ccvalue, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((val) => {
        if (val <= 100) {
          return val + 1;
        }
        handleNext();
        window.scrollTo({ top: 0, left: 0 });
        return 0;
      });
    }, blok.duration);
    return () => clearInterval(interval);
  }, [blok.duration, handleNext]);

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="grid">
      <LoadingComponent
        ccvalue={ccvalue}
        title={blok.title}
        subtitle={blok.subtitle}
        firstCheckingText={blok.firstCheckingText}
        secondCheckingText={blok.secondCheckingText}
        thirdCheckingText={blok.thirdCheckingText}
      />
    </div>
  );
};

export default LoadingComponentBlok;

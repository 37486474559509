export const getHeightByReviewStyle = (reviewStyle: string) => {
  const allHeightsForReviewStyles = {
    '53aa8807dec7e10d38f59f32': '125px',
    '53aa8912dec7e10d38f59f36': '140px',
    '5419b637fa0340045cd0c936': '25px',
    '5419b6a8b0d04a076446a9ad': '25px',
    '5419b6ffb0d04a076446a9af': '25px',
    '5419b732fbfb950b10de65e5': '25px',
    '5419b757fa0340045cd0c938': '25px',
    '54ad5defc6454f065c28af8b': '250px',
    '5613c9cde69ddc09340c6beb': '50px',
    '56278e9abfbbba0bdcd568bc': '60px',
  };
  return allHeightsForReviewStyles[reviewStyle];
};

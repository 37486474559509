import { Field, useFormikContext } from 'formik';
import React from 'react';
import { InputFieldWithAddOn, getHighlightedText, validationMessages } from '@dtk/ui-components';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import styles from '../InputField.module.scss';
import { useOfferStore } from '../../../store/OfferStore';

export const InputSelectTextBlok = ({ blok }) => {
  const { values, touched, errors, setErrors, setFieldValue } = useFormikContext();

  const handleChoiceChange = (event) => {
    const { checked, name } = event.target;
    const keys = useOfferStore.getState().data;
    const value = checked ? name : '';

    setFieldValue(blok.name, value);
    useOfferStore.setState({ data: keys.set(blok.name, value) });
    setErrors({});

    (document.activeElement as HTMLElement).blur();
  };

  function handleValueChange(event, fieldName: string) {
    const value = parseInt(event.currentTarget.value.replace(/[€.,' ']+/g, '')) || 0;
    const keys = useOfferStore.getState().data;
    useOfferStore.setState({ data: keys.set(fieldName, value) });
  }

  return (
    <div {...storyblokEditable(blok)} data-test="input-select-text" className="col-span-12 grid grid-flow-row">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-12">
        {blok.options.map((option) => {
          if (option.type == 'number') {
            return (
              <div key={option._uid} className={`grid grid-flow-row col-span-${option.width} mt-7`}>
                <InputFieldWithAddOn
                  suffix="€"
                  id={option.id}
                  name={option.name}
                  type="number"
                  placeholder={option.placeholder}
                  label={blok.suboptionHeading}
                  isMandatory
                  onBlur={(event) => handleValueChange(event, option.name)}
                />
              </div>
            );
          } else {
            return (
              <div key={option._uid} className={`grid grid-flow-row col-span-${option.width}`}>
                <Field
                  className={styles['checkboxInput'] + ''}
                  id={option.id}
                  type="checkbox"
                  value={values?.[option.name]}
                  name={option.name}
                  checked={values?.[blok.name] === option.name}
                  onChange={handleChoiceChange}
                  onBlur={handleChoiceChange}
                />
                <label className="font-body-medium" htmlFor={option.id}>
                  {option.label}
                </label>
              </div>
            );
          }
        })}
      </div>
      <div className="grid grid-cols-12 gap-5">
        {blok.options &&
          blok.options.map(
            (nestedBlok) =>
              values?.[blok.name] === nestedBlok.name && <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
          )}
      </div>
      {touched?.[blok.name] && errors?.[blok.name] && (
        <p
          className="text-red-secondary font-body mt-2 text-sm"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(
              errors?.[blok.name],
              validationMessages.error.defaultDropdownField.highlightedPart,
              'red-secondary',
              { fontFamily: 'font-body', isBold: true }
            ),
          }}
        />
      )}
    </div>
  );
};

import { Card, FaqDisclosure, QuestionAnswerData } from '../02_composite';

interface ClubFAQCardProps {
  headline: string;
  faqs: QuestionAnswerData[];
}

export function ClubFAQCard({ headline, faqs }: ClubFAQCardProps) {
  return (
    <Card headline={headline || 'Themenbereich / FAQs'}>
      <FaqDisclosure questionAnswerData={faqs} />
    </Card>
  );
}

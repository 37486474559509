import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/outline';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';
import { useRef, useState } from 'react';

type BackgroundColorOptions = {
  [key: string]: {
    columnHeadingBgColor: string;
    rowBgColor: string;
  };
};

export const Table = ({ ...props }) => {
  const { blok } = props;
  const { screenSize = 'grid', backgroundColor = 'red', fixedColumns = '', highlightedColumns = '' } = blok;
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const tableRef = useRef<HTMLDivElement | null>(null);

  const handleScrollLeft = () => {
    if (tableRef.current) {
      const container = tableRef.current;
      const newScrollPosition = scrollPosition - container.clientWidth;
      setScrollPosition(newScrollPosition);
      container.scrollLeft = newScrollPosition;
    }
  };

  const handleScrollRight = () => {
    if (tableRef.current) {
      const container = tableRef.current;
      const newScrollPosition = scrollPosition + container.clientWidth;
      setScrollPosition(newScrollPosition);
      container.scrollLeft = newScrollPosition;
    }
  };

  const wrapperClassses: string =
    screenSize === 'grid'
      ? 'grid-cols-12 content-center px-4 sm:px-6 lg:px-8 md:max-w-xl lg:max-w-2xl xl:max-w-[50rem] '
      : 'max-w-7xl';

  const backgroundColorOptions: BackgroundColorOptions = {
    cremeBeige: { columnHeadingBgColor: 'bg-gold-creme', rowBgColor: 'bg-gold-300' },
    navy: { columnHeadingBgColor: 'bg-navy-50', rowBgColor: 'bg-navy-100' },
    green: { columnHeadingBgColor: 'bg-green-50', rowBgColor: 'bg-green-100' },
    gold: { columnHeadingBgColor: 'bg-gold-50', rowBgColor: 'bg-gold-200' },
    red: { columnHeadingBgColor: 'bg-red-50', rowBgColor: 'bg-red-100' },
    gray: { columnHeadingBgColor: 'bg-white', rowBgColor: 'bg-gray-100' },
  };

  const showScrollButtons: boolean | null =
    tableRef.current && tableRef.current.scrollWidth > tableRef.current.clientWidth;

  const fixedColumnsArr: number[] = fixedColumns?.split(',').map(Number);

  return (
    <div className={classNames(wrapperClassses, 'relative mx-auto w-full pb-20')}>
      <div ref={tableRef} className="relative overflow-x-auto">
        <table className={'!text-navy mx-auto !border-0'}>
          <thead>
            <tr
              className={classNames(
                '!border-b-gold !border-b-2 text-left',
                backgroundColorOptions[backgroundColor].columnHeadingBgColor
              )}
            >
              {blok?.columns?.map((nestedBlok: SbBlokData, index: number) => {
                const heightlightColumnsArr: number[] = highlightedColumns?.split(',').map(Number);
                const isHighlighted: boolean = heightlightColumnsArr.includes(index + 1);
                const isFixed: boolean = fixedColumnsArr.includes(index + 1);
                return (
                  <StoryblokComponent
                    key={nestedBlok._uid}
                    blok={nestedBlok}
                    isHighlighted={isHighlighted}
                    isFixed={isFixed}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody className="!divide-y !divide-gray-400 bg-white">
            {blok.rows?.map((nestedBlok: SbBlokData, index: number) => (
              <StoryblokComponent
                key={nestedBlok._uid}
                blok={nestedBlok}
                index={index + 1}
                backgroundColor={
                  index % 2 === 0
                    ? backgroundColorOptions[backgroundColor].rowBgColor
                    : backgroundColorOptions[backgroundColor].columnHeadingBgColor
                }
                fixedColumns={fixedColumnsArr}
                highlightedColumns={highlightedColumns}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div
        className={classNames(
          !showScrollButtons && 'hidden',
          'absolute left-0 right-0 mb-4 flex w-full items-center justify-end'
        )}
      >
        <button className="ml-4 rounded p-2" onClick={handleScrollLeft}>
          <ArrowCircleLeftIcon width={64} className="text-coolGray-300 hover:text-coolGray-500 stroke-1" />
        </button>
        <button className="mr-4 rounded p-2" onClick={handleScrollRight}>
          <ArrowCircleRightIcon width={64} className="text-coolGray-300 hover:text-coolGray-500 stroke-1" />
        </button>
      </div>
    </div>
  );
};

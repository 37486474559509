import { Button, StoryblokIcon } from '../../01_elements';

interface iconBlok {
  alt: string;
  filename: string;
}

export interface NavigationContactMenuItemProps {
  icon: iconBlok;
  titel: string;
  descriptionLine1: string;
  descriptionLine2?: string;
  btnCTAText: string;
  type: string;
}

export const NavigationContactMenuItemComponent = (props: NavigationContactMenuItemProps) => {
  const onClick = (type: string) => {
    if (type === 'email') {
      window.open(`mailto:${props.descriptionLine1}`, '_self');
    }
    if (type === 'phone') {
      window.open(`tel:${props.descriptionLine1}`, '_self');
    }
    if (type === 'appointment') {
      window.open(`/terminanfrage/`, '_self');
    }
    if (type === 'brochure') {
      window.open(`/infobroschure/`, '_self');
    }
  };

  return (
    <div className="bg-navy-800 hover:bg-navy-navy font-body flex h-full flex-col items-center gap-3 rounded-sm text-white">
      <div className="mt-3">
        <StoryblokIcon className="h-9 w-9" iconSource={props.icon?.filename} alt={props.icon?.alt} />
      </div>
      <div className="text-lg">{props.titel}</div>
      <div className="text-sm lg:text-base">{props.descriptionLine1}</div>
      <div className="grow text-sm lg:text-base">{props.descriptionLine2}</div>
      <div>
        <Button className="mb-3 text-sm lg:text-base" size="sm" onClick={() => onClick(props.type)}>
          {props.btnCTAText}
        </Button>
      </div>
    </div>
  );
};

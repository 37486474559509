import { useLayoutEffect, useState } from 'react';
import { useTailwind } from './useTailwind';

export interface UseMediaQueryResult {
  smallerXs: boolean;
  smallerSm: boolean;
  smallerMd: boolean;
  smallerLg: boolean;
  largerXs: boolean;
  largerSm: boolean;
  largerMd: boolean;
  largerLg: boolean;
  largerXl: boolean;
  xsScreen: boolean;
  smScreen: boolean;
  mdScreen: boolean;
  lgScreen: boolean;
  xlScreen: boolean;
}
export const useMediaQuery = (): UseMediaQueryResult => {
  const { screens } = useTailwind();
  let lessThanXs;
  let lessThanSm;
  let lessThanMd;
  let lessThanLg;

  let moreThanXs;
  let moreThanSm;
  let moreThanMd;
  let moreThanLg;
  let moreThanXl;

  let isXs;
  let isSm;
  let isMd;
  let isLg;
  let isXl;

  if (typeof window !== 'undefined') {
    lessThanXs = window.innerWidth < screens.xs;
    lessThanSm = window.innerWidth < screens.sm;
    lessThanMd = window.innerWidth < screens.md;
    lessThanLg = window.innerWidth < screens.lg;

    moreThanXs = window.innerWidth > screens.xs;
    moreThanSm = window.innerWidth > screens.sm;
    moreThanMd = window.innerWidth > screens.md;
    moreThanLg = window.innerWidth > screens.lg;
    moreThanXl = window.innerWidth > screens.xl;

    isXs = window.innerWidth <= screens.xs;
    isSm = window.innerWidth > screens.xs && window.innerWidth <= screens.sm;
    isMd = window.innerWidth > screens.sm && window.innerWidth <= screens.md;
    isLg = window.innerWidth > screens.md && window.innerWidth <= screens.lg;
    isXl = window.innerWidth > screens.lg && window.innerWidth <= screens.xl;
  }

  const [smallerXs, setSmallerXS] = useState(lessThanXs || false);
  const [smallerSm, setSmallerSM] = useState(lessThanSm || false);
  const [smallerMd, setSmallerMd] = useState(lessThanMd || false);
  const [smallerLg, setSmallerLg] = useState(lessThanLg || false);

  const [largerXs, setLargerXS] = useState(moreThanXs || false);
  const [largerSm, setLargerSM] = useState(moreThanSm || false);
  const [largerMd, setLargerMd] = useState(moreThanMd || false);
  const [largerLg, setLargerLg] = useState(moreThanLg || false);
  const [largerXl, setLargerXl] = useState(moreThanXl || false);

  const [xsScreen, setXsSceen] = useState(isXs || false);
  const [smScreen, setSmSceen] = useState(isSm || false);
  const [mdScreen, setMdSceen] = useState(isMd || false);
  const [lgScreen, setLgSceen] = useState(isLg || false);
  const [xlScreen, setXlSceen] = useState(isXl || false);

  const onChange = () => {
    setSmallerXS(window.innerWidth < screens.xs);
    setSmallerSM(window.innerWidth < screens.sm);
    setSmallerMd(window.innerWidth < screens.md);
    setSmallerLg(window.innerWidth < screens.lg);

    setLargerXS(window.innerWidth > screens.xs);
    setLargerSM(window.innerWidth > screens.sm);
    setLargerMd(window.innerWidth > screens.md);
    setLargerLg(window.innerWidth > screens.lg);
    setLargerXl(window.innerWidth > screens.xl);

    setXsSceen(window.innerWidth <= screens.xs);
    setSmSceen(window.innerWidth > screens.xs && window.innerWidth <= screens.sm);
    setMdSceen(window.innerWidth > screens.sm && window.innerWidth <= screens.md);
    setLgSceen(window.innerWidth > screens.md && window.innerWidth <= screens.lg);
    setXlSceen(window.innerWidth > screens.lg && window.innerWidth <= screens.xl);
  };

  useLayoutEffect(() => {
    // timeout for debounce mechanism
    let timeout: NodeJS.Timeout;
    const handleResize = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeout);
      // change width from the state object after 150 milliseconds
      timeout = setTimeout(() => {
        onChange();
      }, 250);
    };
    window.addEventListener('resize', handleResize);

    const handlePageLoad = () => {
      onChange();
    };
    window.addEventListener('load', handlePageLoad);
    // clean up function
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handlePageLoad);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallerMd, smallerSm, smallerXs, smallerLg]);

  return {
    smallerMd,
    smallerSm,
    smallerXs,
    smallerLg,
    largerMd,
    largerSm,
    largerXs,
    largerLg,
    largerXl,
    xsScreen,
    smScreen,
    mdScreen,
    lgScreen,
    xlScreen,
  };
};

import classNames from 'classnames';
import { FeatureImage } from './FeatureImage';
import { Button, ButtonVariantsRedesign, Headline, headingType } from '../../01_elements';
import { useMediaQuery } from '../../hooks';
import {
  BackgroundThemeVariants,
  ImageProps,
  RichtextContent,
  getAlternativePath,
  isExternalPage,
  themesForBackgroundColor,
} from '../../utils';

export interface FeatureSectionProps {
  theme: BackgroundThemeVariants;
  overline: string;
  headingType: headingType;
  title: string;
  image: ImageProps;
  link: string;
  buttonText: string;
  primaryButtonColor?: ButtonVariantsRedesign;
  headlineLinked: boolean;
  textLinked: boolean;
  imageLinked: boolean;
  imageLeft: boolean;
  content: unknown;
}

export const FeatureSection = ({ ...props }: FeatureSectionProps) => {
  const {
    theme,
    overline,
    headingType,
    title,
    image,
    link,
    buttonText,
    primaryButtonColor,
    headlineLinked,
    textLinked,
    imageLinked,
    imageLeft,
    content,
  } = props;
  const { smallerSm } = useMediaQuery();

  const chosenHeadingType = headingType || 'h2';
  const chosenTheme = theme || 'white';
  const linkPath = getAlternativePath({
    originalLink: link,
    condition: smallerSm,
  });

  return (
    <div className={`bg-${chosenTheme}`}>
      <div className="grid grid-cols-12 pt-14 pb-14 xl:mx-auto xl:max-w-[96rem]">
        <div
          className={classNames(
            !imageLeft && 'lg:order-1 lg:col-start-1 xl:col-start-2',
            themesForBackgroundColor.darkBackgroundColors.includes(chosenTheme) ? 'text-white' : 'text-navy',
            'order-2 col-span-12 px-8 lg:col-span-5 xl:col-span-4'
          )}
        >
          {overline && (
            <div
              className={classNames(
                `text-${themesForBackgroundColor.overlineColor[chosenTheme]}`,
                'text-sm uppercase tracking-[.2em]'
              )}
            >
              {overline}
            </div>
          )}
          {title && (
            <div className="my-2 text-3xl leading-8 lg:text-4xl lg:leading-[3rem]">
              {headlineLinked ? (
                <a href={linkPath}>
                  <Headline headingType={chosenHeadingType} headline={title} isBold={true} />
                </a>
              ) : (
                <Headline headingType={chosenHeadingType} headline={title} isBold={true} />
              )}
            </div>
          )}
          <div className={classNames(buttonText ? 'pb-8' : 'pb-4', 'text-base md:text-lg')}>
            {textLinked ? (
              <a href={linkPath}>
                <RichtextContent content={content} />
              </a>
            ) : (
              <RichtextContent content={content} />
            )}
          </div>
          {buttonText && (
            <Button
              variant={primaryButtonColor || 'cyan'}
              size="lg"
              href={linkPath}
              isexternal={isExternalPage(linkPath)}
            >
              {buttonText}
            </Button>
          )}
        </div>
        <div
          className={classNames(
            imageLeft && 'lg:order-1 lg:col-start-1 xl:col-start-2',
            'order-1 col-span-12 inline-table px-8 pb-8 md:col-span-12 lg:col-span-7 xl:col-span-6'
          )}
        >
          {imageLinked && image?.filename ? (
            <a href={linkPath}>
              <FeatureImage image={image} imageStyles="object-cover pt-2" />
            </a>
          ) : (
            <FeatureImage image={image} imageStyles="object-cover" />
          )}
        </div>
      </div>
    </div>
  );
};

import { Button, DropdownField, InputField } from '@dtk/ui-components';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import axios from 'axios';
import classNames from 'classnames';
import { useAppConfig } from '@dtk/config';
import { useState } from 'react';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';

export const ContactForm = () => {
  const [showacceptcheckboxred, setShowacceptcheckboxred] = useState(false);
  const { config } = useAppConfig();
  const validationSchema = object({
    firstName: string().required(),
    lastName: string().required(),
    email: string().email().required(),
    phone: string().required(),
    medium: string().required(),
  });

  const initValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    medium: '',
    acceptcheckbox: false,
    salutation: '',
  };

  interface Values {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    medium: string;
    acceptcheckbox: boolean;
    salutation: string;
  }

  const defaultOption = 'Bitte wählen Sie aus';
  const salutationOptions = [defaultOption, 'Herr', 'Frau', 'Divers'];
  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        if (values.acceptcheckbox) {
          setShowacceptcheckboxred(false);

          setTimeout(() => {
            const headers = {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            };
            const data = {
              email: values.email,
              salutation: values.salutation === defaultOption ? '' : values.salutation,
              firstname: values.firstName,
              lastname: values.lastName,
              phone: values.phone,
              medium: values.medium,
            };
            axios
              .post(config['API_BASE'] + '/public/press/register', data, {
                // TODO
                headers: headers,
              })
              .then((response) => {
                // eslint-disable-next-line no-console
                console.log(response.data[0]);
              });
            setSubmitting(false);
          }, 500);
        } else {
          setShowacceptcheckboxred(true);
        }
      }}
    >
      <Form>
        <div className="bg-coolGray-50 mx-auto grid w-full grid-cols-6 gap-x-6 gap-y-4 p-[2rem] sm:max-w-[51rem]">
          <div className="col-span-6 sm:col-span-2">
            <DropdownField data-testid="salutation" name="salutation" label="Anrede" options={salutationOptions} />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <InputField id="firstName" name="firstName" label="Vorname" type="text" isMandatory />
          </div>

          <div className="col-span-6 sm:col-span-2">
            <InputField id="lastName" name="lastName" label="Nachname" type="text" isMandatory />
          </div>

          <div className="col-span-6 sm:col-span-6">
            <InputField
              id="medium"
              name="medium"
              label="Medium"
              type="text"
              placeholder="z.B. Zeitung, Fernsehen, Radio ..."
              isMandatory
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="phone"
              name="phone"
              label="Telefon"
              type="text"
              placeholder="+49 XXX XXX XXX"
              icon={PhoneIcon}
              isMandatory
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <InputField
              id="email"
              name="email"
              label="E-Mail"
              type="text"
              placeholder="max@mustermail.de"
              icon={MailIcon}
              isMandatory
            />
          </div>
          <div className="col-span-6 sm:col-span-5">
            <label className="mr-1">
              <Field
                type="checkbox"
                name="acceptcheckbox"
                className={classNames('mr-1', 'border-2', showacceptcheckboxred && 'border-red')}
              />
              {showacceptcheckboxred && <strong className="text-red mr-1 text-xl">←</strong>}
              Ich willige in diese Verarbeitung meiner Daten ein. Welche Daten im Einzelnen gespeichert werden und wer
              Zugriff auf diese hat, erfahren Sie hier. Meine Einwilligungen können Sie jederzeit für die Zukunft
              widerrufen.
            </label>
          </div>
          <div className="col-span-6 mb-10">
            <Button type="submit" fullWidth>
              Abschicken
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

import { PageRedirectionButtons } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const PageRedirectionButtonsBlok = ({ ...props }) => {
  const { blok, logoLink, setIsLeavingPageModalOpen } = props;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="intermediate-state-buttons">
      <PageRedirectionButtons
        primaryButtonText={blok.primaryButtonText}
        secondaryButtonText={blok.secondaryButtonText}
        logoLink={logoLink}
        setIsLeavingPageModalOpen={setIsLeavingPageModalOpen}
      />
    </div>
  );
};

import { ReactNode } from 'react';
import classNames from 'classnames';
type SimpleHeroSectionVariant = 'small' | 'big';

export interface SimpleHeroSectionTitle {
  title: string[];
  underline?: string;
  subtitle?: string;
}

export interface SimpleHeroSectionProps {
  status?: ReactNode;
  title: SimpleHeroSectionTitle;
  description?: string;
  backgroundColor?: string;
  buttonText: string;
  imagePath: string;
  children?: ReactNode;
  variation?: SimpleHeroSectionVariant;
}

/**
 * Create a ReactNode for the Header
 * For the underline, search for the string and split it in text before the underline and behind
 * @param title
 */
function createSubTitle(title: SimpleHeroSectionTitle): ReactNode {
  if (title.subtitle === '' || title.subtitle === undefined) {
    return <div />;
  }
  return (
    <p className="max-w-md bg-blue-500 text-lg text-white sm:text-xl md:mt-5 md:max-w-3xl">
      <span className="font-green text-left">{title.subtitle}</span>
    </p>
  );
}

function createHeader(title: SimpleHeroSectionTitle): ReactNode {
  const textDivs = [] as ReactNode[];

  title.title.forEach((string: string, index: number) => {
    const text = string;
    if (title.underline && string.includes(title.underline)) {
      const split = text.split(title.underline);
      textDivs.push(
        <span key={index} className="block whitespace-nowrap text-white">
          {split[0]}
          <span className="text-gold underline--wave block whitespace-pre"> {title.underline}</span>
          {split[1]}
        </span>
      );
      return;
    }
    textDivs.push(
      <span key={index} className="block text-white xl:inline-block">
        {text}
      </span>
    );
  });

  return (
    <>
      <h1 className="font-headline text-navy-navy mt-3 text-4xl tracking-tight">{textDivs}</h1>
      {createSubTitle(title)}
    </>
  );
}

export const SimpleHeroSection = ({ status, title, imagePath, children, variation }: SimpleHeroSectionProps) => {
  if (variation === 'small') {
    return (
      <div className={classNames('relative', 'bg-navy')}>
        {/* BG IMAGE */}
        <div className="absolute top-0 left-0 right-0 bottom-0 z-0">
          <img className={`absolute inset-0 h-full w-full object-cover object-center`} src={imagePath} alt="" />
        </div>
        {/* Content */}
        <div className="grid w-full grid-cols-1 gap-0 sm:grid-cols-2">
          <div className="z-10 px-4 text-center sm:text-left">
            {status}
            {createHeader(title)}
          </div>
          <div className="z-10 px-4 text-center text-white sm:text-left">{children}</div>
        </div>
      </div>
    );
  } else if (variation === 'big') {
    return (
      <div className={classNames('relative', 'bg-navy')}>
        {/* BG IMAGE */}
        <div className=" absolute top-0 left-0 right-0 bottom-0">
          <img className={`absolute inset-0 h-full w-full object-cover object-center`} src={imagePath} alt="" />
        </div>
        {/* Content */}
        <div className="lg:min-h-200 mx-auto lg:relative xl:max-w-7xl">
          <div className="mr-8 w-full max-w-7xl px-4 pt-8 pb-12 align-middle sm:px-4  md:px-4 lg:px-4  lg:text-left xl:px-0">
            <div className="text-center align-middle sm:text-center md:text-center lg:w-1/2 lg:text-left xl:text-left">
              {status}
              {createHeader(title)}
              <p className="mt-10 max-w-md bg-blue-500 text-lg text-white sm:text-xl md:mt-5 md:max-w-3xl">
                <div className="opacity-100">
                  <span className="font-green text-left">lorem ipsum dolor sit amet consectetuer adipiscing elit</span>
                </div>
              </p>
            </div>
          </div>
          <div className="relative hidden h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:flex lg:h-full lg:w-1/2"></div>
          {/* Empty Div for Styling */}
          <div className="relative hidden h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:flex lg:h-full lg:w-1/2">
            {children}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="relative">
      <div className={classNames('relative', 'bg-navy')}>
        <div className="absolute top-0 left-0 right-0 bottom-0 overflow-hidden">
          <div className="visible absolute left-0 right-0 top-0 overflow-hidden xl:invisible">
            {/* Mobile View */}
            <img alt="" src={imagePath + '/m/600x450/smart'} className="visible w-full sm:invisible"></img>
            <div className=" invisible absolute left-0 top-0 right-0 sm:visible">
              {/* Mobile View larger screens */}
              <img alt="" src={imagePath + '/m/600x600/smart'} className="w-full"></img>
            </div>
          </div>
          <div className="invisible absolute left-0 right-0 top-0 bottom-0 overflow-hidden xl:visible">
            <img alt="" src={imagePath + '/m/smart'} className="h-full"></img>
          </div>
        </div>
        <div className="relative mx-auto xl:max-w-7xl">
          <div className="mx-auto px-4 sm:px-32 xl:max-w-7xl">
            <div className="pt-48 pb-12 sm:pt-3 lg:text-left">
              {status}
              {createHeader(title)}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

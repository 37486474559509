import { boolean, object } from 'yup';
import { PartnerWrapperProps } from '../../../types';
import { PartnerOfferDefaultForm } from '../../DefaultForm';

export const Energieausweis48Wrapper = ({ blok, customerData }: PartnerWrapperProps) => {
  const redirectUrl = `${blok.redirectUrl}${customerData.vorgangsNummer}`;

  if (customerData.address) {
    const { postCode, street, city, houseNumber } = customerData.address;
    const addressLine = !houseNumber ? street : `${street} ${houseNumber}`;

    blok.redirectUrl = `${redirectUrl}&addressLine=${addressLine}&zipcode=${postCode}&city=${city}`;
  } else {
    blok.redirectUrl = redirectUrl;
  }

  const getValidationSchema = () => {
    return object({
      leadDistributionAllowed: boolean().oneOf([true]),
    });
  };

  return (
    <PartnerOfferDefaultForm
      externalFormData={blok}
      customerData={customerData}
      validationSchema={getValidationSchema()}
    />
  );
};

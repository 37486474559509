import { boolean, object } from 'yup';
import { PartnerOfferDefaultForm } from '.';
import { PartnerWrapperProps } from '../../types';

export const DefaultWrapper = ({ blok, customerData }: PartnerWrapperProps) => {
  const getValidationSchema = () => {
    return object({
      leadDistributionAllowed: boolean().oneOf([true]),
    });
  };
  return (
    <PartnerOfferDefaultForm
      externalFormData={blok}
      customerData={customerData}
      validationSchema={getValidationSchema()}
    />
  );
};

import classNames from 'classnames';
import ConditionalWrap from 'conditional-wrap';
import Link from 'next/link';
import { BackgroundThemeVariants, ImageProps, themesForBackgroundColor } from '../utils';

export interface TeamElementProps {
  image: ImageProps;
  headline: string;
  component: string;
  description: string;
  isCollapseAvailable: boolean;
  link?: {
    cached_url: string;
  };
}

export interface TeamsProps {
  theme: BackgroundThemeVariants;
  teams: TeamElementProps[];
}

export function Team({ theme, teams }: TeamsProps) {
  const textColor = themesForBackgroundColor.darkBackgroundColors.includes(theme) ? 'text-white' : 'text-navy';
  return (
    <div className="flex flex-wrap">
      {teams.map((team, index) => (
        <div key={index} className="mb-10 w-full md:w-1/2 lg:w-1/3">
          <ConditionalWrap
            condition={!!team.link?.cached_url}
            wrap={(children) => (
              <Link href={team.link?.cached_url ?? ''} passHref>
                <a href={team.link?.cached_url ?? ''}>{children}</a>
              </Link>
            )}
          >
            <>
              {team.image?.filename && (
                <img
                  alt={team.image.alt}
                  src={team.image.filename + '/m/320x320/smart'}
                  className="mx-auto h-40 w-40 rounded-full"
                  loading="lazy"
                />
              )}
              <p className={classNames(textColor, 'font-body-semibold mt-3 text-center text-xl')}>{team.headline}</p>
              <p className={classNames(textColor, 'mt-3 text-center text-lg md:px-4')}>{team.description}</p>
            </>
          </ConditionalWrap>
        </div>
      ))}
    </div>
  );
}

// import { useDecodeEstateAgentData } from '@dtk/query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAppConfig } from '@dtk/config';
import { Spinner } from '@dtk/ui-components';

import { EstateAgentRegistrationForm } from './RegistrationForm';
import { RegistrationBaseValues } from './types';

/*  This example token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1heC5tdXN0ZXJtYW5uQG11c3Rlcm1haWwuY29tIiwiY29tcGFueSI6Ik11c3RlcmZpcm1hIiwiaWhrTnVtYmVyIjoiMTIzNDU2NyIsInNhbHV0YXRpb24iOiJIZXJyIiwiZmlyc3ROYW1lIjoiTWF4IiwibGFzdE5hbWUiOiJNdXN0ZXJtYW5uIiwic3RyZWV0IjoiTXVzdGVyc3RyYcOfZSIsImhvdXNlTnIiOiIxIiwicGx6IjoiMTIzNDUiLCJjaXR5IjoiTXVzdGVyc3RhZHQiLCJwaG9uZSI6IjAxMjM0IDU2Nzg4In0.rEQ9uOSXL7HGE65kykk1C1qRU03y9Lo0RYz-EMU7vuo'
    and represents the follwoing data: {
      header: {
        "alg": "HS256",
        "typ": "JWT"
      },
      payload: {
        "email": "max.mustermann@mustermail.com",
        "company": "Musterfirma",
        "ihkNumber": "1234567",
        "salutation": "Herr",
        "firstName": "Max",
        "lastName": "Mustermann",
        "street": "Musterstraße",
        "houseNr": "1",
        "plz": "12345",
        "city": "Musterstadt",
        "phone": "01234 56788"
      },
      secret: lies in AWS System Manager > Parameterstore under /dev/app/public-function-config/ESTATE_AGENT_JWT_SECRET
    }
    This token should be used as query parameter in url
                */
interface EstateAgentRegistrationFormWrapperProps {
  token: string;
}

export const EstateAgentRegistrationFormWrapper = ({ token }: EstateAgentRegistrationFormWrapperProps) => {
  const { config } = useAppConfig();
  const baseUrl = config['API_BASE'] || 'https://api.dev.deutsche-teilkauf.digital';
  const [state, setState] = useState({ data: {}, isError: false, isLoading: true });

  useEffect(() => {
    const fetchData = async () => {
      const data = await axios.get(`${baseUrl}/public/estate-agent/decode/${token}`);
      setState({ data: data?.data, isError: false, isLoading: false });
    };
    fetchData();

    return () => {
      setState({ data: {}, isError: false, isLoading: false });
    };
  }, [token, baseUrl]);

  if (state.isLoading) {
    return <Spinner />;
  }

  if (!state.data || state.isError) {
    return <EstateAgentRegistrationForm />;
  }

  const requestedData = state.data as RegistrationBaseValues;
  return <EstateAgentRegistrationForm requestedData={requestedData} />;
};

export type FontFamily = 'font-headline' | 'font-body';

interface HighlightTextConfigProps {
  fontFamily: FontFamily;
  isBold: boolean | undefined;
}

export function getHighlightedText(
  text: string,
  highlightText: string,
  color?: string,
  highlightTextConfig?: HighlightTextConfigProps,
  link?: string
) {
  const highlightTextColor = color ? 'text-' + color : 'text-gold';
  const boldified =
    (highlightTextConfig?.isBold && highlightTextConfig?.fontFamily === 'font-body' && 'font-body-bold') ||
    (highlightTextConfig?.isBold && highlightTextConfig?.fontFamily === 'font-headline' && 'font-headline-bold') ||
    '';
  const replaceTextArr = highlightText.split(',');
  const regex = new RegExp(replaceTextArr.join('|'), 'gi');

  if (highlightText && link) {
    return text.replace(
      regex,
      (str) =>
        `<a href=${link} target="_blank" rel="noopener noreferrer"><span class='${highlightTextColor} ${boldified} underline'>${str}</span></a>`
    );
  } else if (highlightText) {
    return text.replace(regex, (str) => `<span class='${highlightTextColor} ${boldified}'>${str}</span>`);
  }
  return text;
}

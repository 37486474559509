import { Field, useFormikContext } from 'formik';
import React from 'react';
import { calculateGridColumnSpan, getHighlightedText, validationMessages } from '@dtk/ui-components';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import styles from '../InputField.module.scss';
import { formatMeasuringUnitForAreaOrVolume } from '../../../utils/format';
import { useOfferStore } from '../../../store/OfferStore';
import { getNumberOfOptionsByFieldName } from '../utils';

export const InputSelectWithRadioBlok = ({ blok }) => {
  const { values, errors, touched, setFieldValue, setErrors } = useFormikContext();
  const MAX_NUMBER_OF_COLUMNS = 12;

  const removeAlreadySelectedOptions = (selectedOption) => {
    blok.options.map((option) => {
      option.name !== selectedOption && setFieldValue(option.name, '');
      const keys = useOfferStore.getState().data;
      useOfferStore.setState({ data: keys.set(option.name, '') });
    });
    setErrors({});
  };

  const handleChoiceChange = (e) => {
    const { checked, name } = e.target;
    const keys = useOfferStore.getState().data;
    const value = checked ? name : '';

    setFieldValue(blok.name, value);
    useOfferStore.setState({ data: keys.set(blok.name, value) });
    removeAlreadySelectedOptions(name);

    (document.activeElement as HTMLElement).blur();
  };

  return (
    <div
      {...storyblokEditable(blok)}
      data-test="input-select-with-radios"
      key={blok.id}
      className={`col-span-${MAX_NUMBER_OF_COLUMNS} space-y-2`}
    >
      <div className={`grid grid-cols-${MAX_NUMBER_OF_COLUMNS} gap-x-6 gap-y-4`}>
        {blok.options.map((option) => {
          if (option.type === 'radio') {
            return (
              <div
                key={`${option.name}`}
                className={`col-span-${MAX_NUMBER_OF_COLUMNS} sm:col-span-${calculateGridColumnSpan(
                  blok.options.length,
                  12
                )}`}
              >
                <div className="flex items-center">
                  <Field
                    type="radio"
                    value={values?.[option.name]}
                    id={option.id}
                    parentid={blok.id}
                    checked={values?.[blok.name] === option.name}
                    onChange={handleChoiceChange}
                    onBlur={handleChoiceChange}
                    name={option.name}
                    placeholder={option.placeholder}
                    className="border-navy-navy mr-3 rounded-full border p-2"
                  />
                  <label className="text-navy font-body-medium antialiased" htmlFor={option.id}>
                    {formatMeasuringUnitForAreaOrVolume(option.label)}
                  </label>
                </div>
              </div>
            );
          } else {
            return (
              <div
                key={`${option.name}`}
                className={`col-span-${MAX_NUMBER_OF_COLUMNS} -mt-4 sm:col-span-${calculateGridColumnSpan(
                  blok.options.length,
                  12
                )}`}
              >
                <Field
                  id={option.id}
                  parentid={blok.id}
                  className={styles['checkboxInput'] + ''}
                  type="checkbox"
                  name={option.name}
                  checked={values?.[blok.name] === option.name}
                  onChange={handleChoiceChange}
                  onBlur={handleChoiceChange}
                />
                <label className="font-body-medium" htmlFor={option.id}>
                  {formatMeasuringUnitForAreaOrVolume(option.label)}
                </label>
              </div>
            );
          }
        })}
        {getNumberOfOptionsByFieldName(blok, values?.[blok.name]) > 0 &&
          blok.options &&
          blok.options.map(
            (nestedBlok: SbBlokData) =>
              values?.[blok.name] === nestedBlok.name && <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
          )}
      </div>
      {touched?.[blok.name] && errors?.[blok.name] && (
        <p
          className="text-red-secondary font-body text-sm"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(
              errors?.[blok.name],
              validationMessages.error.defaultDropdownField.highlightedPart,
              'red-secondary',
              { fontFamily: 'font-body', isBold: true }
            ),
          }}
        />
      )}
    </div>
  );
};

import { ContactForm, Section } from '@dtk/public-website-ui-components';

import { Container } from '@dtk/ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const ContactFormBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="contact-form">
    <div className=" bg-gray-100">
      <Container className=" bg-gray-100 pb-[5rem]">
        <Section variant="white" headline={blok.headline} subline={blok.subtitle} headingType={'h2'}>
          <ContactForm></ContactForm>
        </Section>
      </Container>
    </div>
  </div>
);

export default ContactFormBlok;

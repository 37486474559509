import classNames from 'classnames';
export interface Tab {
  name: string;
  current: boolean;
}

export interface TabsProps {
  tabs: Tab[];
  handleCurrent: (name: string) => void;
}

export function Tabs({ tabs, handleCurrent }: TabsProps) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current)?.name}
          onChange={(e) => handleCurrent(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div>
          <nav className="-mb-px flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'text-navy border-cyan-700'
                    : 'text-coolGray-500 hover:border-coolGray-300 hover:text-coolGray-700 border-transparent',
                  'whitespace-nowrap border-b-2 py-2 px-0 text-sm font-normal'
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => handleCurrent(tab.name)}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

import { StoryblokResult } from '@storyblok/react';
import StoryblokClient from 'storyblok-js-client';

const REQUESTED_ENTRIES_PER_PAGE = 1000;

interface DatasourceEntryProps {
  value: string;
}

const Storyblok = new StoryblokClient({
  accessToken: '3koxfeFUxXuPQrk6kSVJxAtt',
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

const getEntriesOfSingleDatasourcePage = async (datasourceName: string, page: number) => {
  try {
    const allDataOfSingleDatasource = await Storyblok.get('cdn/datasource_entries', {
      datasource: datasourceName.toLowerCase(),
      version: 'draft',
      per_page: REQUESTED_ENTRIES_PER_PAGE,
      page: page,
    });

    return allDataOfSingleDatasource;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    console.error('The defined datasource route is eventually not created or not correct!');
  }
  return { data: null, perPage: 0, total: 0, headers: null };
};

export const getAllEntriesForSingleDatasource = async (datasourceName: string) => {
  try {
    let page = 0;
    let datasourceData: StoryblokResult = { data: null, perPage: 1, total: 1, headers: null };
    let allDatasourceEntries: string[] = [];

    while (page < datasourceData.total / datasourceData.perPage) {
      page++;
      datasourceData = await getEntriesOfSingleDatasourcePage(datasourceName, page);
      if (datasourceData) {
        const newEntries = datasourceData.data.datasource_entries.map((entry: DatasourceEntryProps) => {
          return entry.value;
        });
        allDatasourceEntries = [...allDatasourceEntries, ...newEntries];
      }

      if (datasourceData.data === null) {
        break;
      }
    }
    return allDatasourceEntries;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return [];
};

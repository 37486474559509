import {
  Button,
  HeaderText,
  headingType,
  useMediaQuery,
  getAlternativePath,
  BackgroundThemeVariants,
  themesForBackgroundColor,
} from '@dtk/ui-components';
import classNames from 'classnames';

export interface CenteredGridProps {
  theme: BackgroundThemeVariants;
  preline: string;
  headingType: headingType;
  headline: string;
  subline: string;
  buttonText?: string;
  buttonLink?: string;
  items: CenteredGridItemProps[];
}

export interface CenteredGridItemProps {
  icon: CenteredGridItemImageProps;
  headline: string;
  text: string;
}

export interface CenteredGridItemImageProps {
  id?: number;
  alt?: string;
  name?: string;
  title?: string;
  filename: string;
}

export const CenteredGrid = ({
  theme,
  preline,
  headingType,
  headline,
  subline,
  items,
  buttonText,
  buttonLink,
}: CenteredGridProps) => {
  const { smallerSm } = useMediaQuery();
  const alternativeLink = buttonLink && getAlternativePath({ originalLink: buttonLink, condition: smallerSm });
  const textColor = themesForBackgroundColor.darkBackgroundColors.includes(theme) ? 'text-white' : 'text-navy';

  return (
    <div className="mx-auto max-w-7xl px-4 pb-16 sm:px-6 lg:px-8">
      <HeaderText
        overline={preline}
        headline={headline}
        subtitle={subline}
        theme={theme || 'white'}
        headingType={headingType}
      />

      <div className="m-auto grid grid-flow-row grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-x-10">
        {items.map((gridItem, index) => (
          <div key={index} className="flex">
            <img
              src={gridItem.icon.filename}
              className="mt-1 ml-2 h-5 w-5 sm:ml-0 sm:h-10 sm:w-10"
              alt={gridItem.icon.alt}
              loading="lazy"
              {...{ fetchpriority: 'low' }}
            />
            <div className="text-navy ml-6">
              <p className={classNames('font-body-semibold text-xl', textColor)}>{gridItem.headline}</p>
              <p className={classNames('text-lg font-normal', textColor)}>{gridItem.text}</p>
            </div>
          </div>
        ))}
      </div>

      {alternativeLink && buttonText && (
        <div className="mt-10 flex justify-center">
          <Button size="lg" href={alternativeLink}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

import { boolean, object } from 'yup';
import { MeinAlarm24Form } from '.';
import { PartnerOfferDefaultForm } from '../../DefaultForm';
import { PartnerWrapperProps } from '../../../types';

export const MeinAlarm24Wrapper = ({ blok, customerData }: PartnerWrapperProps) => {
  const allSecurityCategories = [
    { name: 'Alarmanlage', value: 'Intrusion' },
    { name: 'Videoanlage', value: 'Video' },
    { name: 'Schließanlage', value: 'Door Lock' },
  ];
  blok.selectButtonOptions = allSecurityCategories;

  const getMeinAlarm24ValidationSchema = () => {
    return object({
      leadDistributionAllowed: boolean().oneOf([true]),
    });
  };

  return (
    <PartnerOfferDefaultForm
      externalFormData={blok}
      validationSchema={getMeinAlarm24ValidationSchema()}
      customerData={customerData}
      children={<MeinAlarm24Form selectButtonOptions={allSecurityCategories} />}
    />
  );
};

import { log } from '@dtk/logging';
export const dataLayer = {
  push: (trackingEvent: BaseTrackingEvent) => {
    ((<any>globalThis).dataLayer || []).push(trackingEvent);
  },
};

/**
 * Track a new data point in the tracking data layer.
 *
 * @param trackingEvent
 */
export const trackDataPoint = (trackingEvent: BaseTrackingEvent) => {
  log.info(trackingEvent, 'Tracking event');
  dataLayer.push(trackingEvent);
};

export interface BaseTrackingEvent {
  [key: string]: string;
}

export interface LoginTrackingEvent extends BaseTrackingEvent {
  event: 'registration_start' | 'registration_success';
  [key: string]: string;
}

export interface FormTrackingEvent extends BaseTrackingEvent {
  event: 'form';
  form_action: 'success' | 'start' | 'error';
  form_name: string;
}

export interface ContactTrackingEvent extends BaseTrackingEvent {
  event: 'contact';
  contact_type: 'email' | 'telephone';
  contact_person: string;
}

export interface FAQTrackingEvent extends BaseTrackingEvent {
  event: 'faq';
  faq_topic: string;
}

export interface TaskTrackingEvent extends BaseTrackingEvent {
  event: 'task';
  task_name: string;
  task_action: 'start';
}

export interface DocumentServiceTrackingEvent extends BaseTrackingEvent {
  event: 'document_service';
  document_service_action: 'start' | string | 'success';
}

export interface DocumentTrackingEvent extends BaseTrackingEvent {
  event: 'document';
  document_category: string;
  document_action: 'upload start' | 'upload success' | 'download' | 'upload error';
}

export interface PropertyInfoTrackingEvent extends BaseTrackingEvent {
  event: 'property information';
  property_chart: 'table' | 'chart';
  property_information_action: 'start' | 'actively engaged';
}

export interface NavigationTrackingEvent extends BaseTrackingEvent {
  event: 'navigation';
  navigation_item: string;
}

export interface CTATrackingEvent extends BaseTrackingEvent {
  event: 'cta';
  cta_action: string;
  cta_name: string;
  cta_text: string;
}

import { QuickRechner } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const QuickRechnerBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="grid">
    <QuickRechner blok={blok} />
  </div>
);

export default QuickRechnerBlok;

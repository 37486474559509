export const ResolveRelations = [
  'presse_post.posts',
  'presse_post.categories',
  'presse_post.author',
  'presse_post.postsCarousel',
  'blog_post.posts',
  'blog_post.categories',
  'blog_post.postsCarousel',
  'blog_post.author',
  'blog_post.blog_post_summary',
  'blog_post_summary_select.blog_post_summary',
  'PostsCarousel.posts',
  'PostsCarousel.categories',
  'PostsCarousel.author',
  'PostsCarousel.presse_post',
  'postColumns.posts',
  'postColumns.categories',
  'postsListing.posts',
  'postsListing.categories',
  'LexikonListing.posts',
  'lexikon_new.LexikonListing',
  'blog_posts_listing.categories',
  'global_reference.reference',
  'posts.author',
  'posts.categories',
  'page.author',
  'NavigationLink.featuredBlogPosts',
  'AuthorComponent.author',
];

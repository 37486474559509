import { PartnerBannerOffering } from '@dtk/dtk-club';
import { storyblokEditable } from '@storyblok/react';

export const PartnerBannerOfferingBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="PartnerBannerOffering">
      <PartnerBannerOffering {...blok} />
    </div>
  );
};

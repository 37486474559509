import { XMLParser } from 'fast-xml-parser';
import { useEffect, useState } from 'react';
import { JobsCarousel } from './JobsCarousel';

const options = {
  ignoreAttributes: false,
};

const parser = new XMLParser(options);

interface JobsCarouselProps {
  title: string;
  subtitle: string;
}

export interface JobsProps {
  id: string;
  name: string;
  department: string;
  recruitingCategory: string;
  schedule: string;
  office: string;
}

export const JobsCarouselDataLoader = ({ title, subtitle }: JobsCarouselProps) => {
  const [jobs, setJobs] = useState<JobsProps[]>([]);
  const [positionOptions, setPositionOptions] = useState<string[]>([]);

  const getSingleFilteredJobDetails = (jobObject: { [x: string]: { position: { [x: string]: unknown } } }) => {
    const keys = ['id', 'name', 'department', 'recruitingCategory', 'schedule', 'office'];

    return [
      Object.keys(jobObject['workzag-jobs'].position)
        .filter((key) => keys.includes(key))
        .reduce((obj, key) => {
          return { ...obj, [key]: jobObject['workzag-jobs'].position[key] };
        }, {}),
    ];
  };

  useEffect(() => {
    const fetchJobs = () => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://deutsche-teilkauf.jobs.personio.de/xml?language=de', true);
      xhr.onload = function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const jsonObj = parser.parse(xhr.responseText);
            let jobsArr;
            if (Array.isArray(jsonObj['workzag-jobs'].position)) {
              jobsArr = jsonObj['workzag-jobs'].position.map(
                (position: {
                  id: string;
                  name: string;
                  department: string;
                  recruitingCategory: string;
                  schedule: string;
                  office: string;
                }) => ({
                  id: position.id,
                  name: position.name,
                  department: position.department,
                  recruitingCategory: position.recruitingCategory,
                  schedule: position.schedule,
                  office: position.office,
                })
              );
            } else {
              jobsArr = getSingleFilteredJobDetails(jsonObj);
            }
            setJobs([...jobsArr]);
            const options = [
              ...jobsArr.map((position: { department: string }) =>
                position.department === undefined ? 'Initiativbewerbung' : position.department
              ),
            ];
            setPositionOptions([...new Set(options)]);
          } else {
            // eslint-disable-next-line no-console
            console.error(xhr.statusText);
          }
        }
      };
      xhr.onerror = function (e) {
        // eslint-disable-next-line no-console
        console.error(xhr.statusText);
      };
      xhr.send(null);
    };
    fetchJobs();
  }, []);

  return <JobsCarousel title={title} subtitle={subtitle} jobs={jobs} positionOptions={positionOptions} />;
};

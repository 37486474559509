import { RechnerSofortauszahlung } from '@dtk/public-website-ui-components';
import { storyblokEditable } from '@storyblok/react';

const RechnerSofortauszahlungBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="rechner-sofortauszahlung">
    <RechnerSofortauszahlung {...blok} />
  </div>
);

export default RechnerSofortauszahlungBlok;

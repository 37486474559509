import { CheckCircleIcon } from '@heroicons/react/solid';
import { Button, StoryblokIcon } from '../../01_elements';
import { ImageProps } from '../../utils';

interface CheckoutCardFeatures {
  description: string;
}

export interface CheckoutCardProps {
  preline: string;
  title: string;
  description?: string;
  features?: CheckoutCardFeatures[];
  buttonText?: string;
  buttonLink?: string;
  image?: ImageProps;
}

export function CheckoutCard({
  preline,
  title,
  description,
  features,
  buttonText,
  buttonLink,
  image,
}: CheckoutCardProps) {
  return (
    <div key={title} className="relative mx-auto flex max-w-sm flex-col border border-gray-200 bg-white shadow-xl">
      {image?.filename && <StoryblokIcon iconSource={image.filename} alt={image.alt} width={700} height={475} />}
      <div className="text-navy-navy flex-1 px-8 pt-8 pb-4">
        <p className="text-gold text-sm uppercase tracking-[.2rem]">{preline}</p>
        <h3 className="font-headline mt-2 text-2xl font-semibold">{title}</h3>
        <p className="mt-6">{description}</p>
        {features && (
          <ul className="mt-6 space-y-4">
            {features.map((feature) => (
              <li key={feature.description} className="flex">
                <CheckCircleIcon className="text-gold h-6 w-6 flex-shrink-0" aria-hidden="true" />
                <p className="text-navy-navy ml-3">{feature.description}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
      {buttonText && buttonLink && (
        <div className="px-8 pb-8">
          <Button className="mt-8" fullWidth href={buttonLink}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
}

import { ROARR, Roarr } from 'roarr';

export const log = Roarr;

// Debug is default
let logLevel = 20;
if (globalThis.localStorage) {
  const updatedLogLevel = parseInt(localStorage.getItem('dtk.loglevel') || '20', 10);
  if (isFinite(updatedLogLevel)) {
    logLevel = updatedLogLevel;
  }
}
if (globalThis.process && globalThis.process.env) {
  const updatedLogLevel = parseInt(process.env['DTK_LOGLEVEL'] || '20', 10);
  if (isFinite(updatedLogLevel)) {
    logLevel = updatedLogLevel;
  }
}

ROARR.write = (message) => {
  const payload = JSON.parse(message);
  if (payload.context.logLevel >= logLevel) {
    console.log(payload);
  }
};

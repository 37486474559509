import { formatCurrency } from '@dtk/formatter';
import { useMediaQuery } from '@dtk/ui-components';
import { LabelWithTooltipAndCalculatedValue } from './LabelWithTooltipAndCalculatedValue';

type TotalAmountColor = 'red' | 'green';

export interface CalculationStepProps {
  label: string;
  calculatedValue: number;
  tooltipText?: string;
  calculatedValueClassNames: string;
}

interface PropertyCalculationSectionProps {
  overline: string;
  headline: string;
  description: string;
  allCalculationSteps: CalculationStepProps[];
  totalAmountColor: TotalAmountColor;
}

export const PropertyCalculationSection = ({
  overline,
  headline,
  description,
  allCalculationSteps,
  totalAmountColor,
}: PropertyCalculationSectionProps) => {
  const { smallerMd } = useMediaQuery();
  const PROPERTY_SUM = allCalculationSteps.reduce(
    (previousValue, currentCalculationStep) => previousValue + currentCalculationStep.calculatedValue,
    0
  );

  return (
    <div className="text-navy-navy flex basis-1/2 flex-col md:my-9">
      <div className="mb-5 space-y-2">
        {overline && <p className="text-gold-gold text-sm uppercase tracking-[.2em]">{overline}</p>}
        {headline && <p className="font-body-bold text-2xl">{headline}</p>}
        {description && <p className="text-lg">{description}</p>}
      </div>
      <div className="mb-5">
        {allCalculationSteps.map((calculationStep, index) => (
          <div className="pb-3" key={index}>
            <LabelWithTooltipAndCalculatedValue
              label={calculationStep.label}
              tooltipText={calculationStep.tooltipText}
              calculatedValue={calculationStep.calculatedValue}
              calculatedValueClassNames={calculationStep.calculatedValueClassNames}
            />
            {(index !== allCalculationSteps.length - 1 || smallerMd) && (
              <div className="border-coolGray-300 flex flex-row justify-center border-b pb-3" />
            )}
          </div>
        ))}
      </div>
      <div className="xs:flex-row xs:space-y-0 xs:space-x-2 mt-auto flex flex-col justify-between space-y-2 sm:items-center">
        <p className="text-navy-navy font-body-bold text-lg md:max-w-[65%]">Ihr verbleibendes Vermögen</p>
        <p
          className={`font-body-bold ml-auto text-2xl md:text-lg ${
            totalAmountColor === 'green' ? 'text-green-400' : 'text-red-700'
          }`}
        >
          {formatCurrency(PROPERTY_SUM, 0)}
        </p>
      </div>
      <div
        className={`mt-3 mb-9 flex flex-row justify-center border-b-[0.2rem] ${
          totalAmountColor === 'green' ? 'border-green-400' : 'border-red-700'
        }`}
      />
    </div>
  );
};

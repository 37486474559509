import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

export const Circle = ({ anteilDtk, children }: CircleProps) => (
  <div className="flex flex-col items-center justify-center">
    <div className="relative mb-14 mt-5 w-[24rem] lg:mt-0 lg:w-[29.2rem]">
      <CircularProgressbar
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
          textSize: '16px',
          pathColor: '#015F98',
          trailColor: '#D1D5DB',
        })}
        value={100 - anteilDtk}
        strokeWidth={18}
      ></CircularProgressbar>
      {children}
    </div>
  </div>
);

export interface CircleProps {
  anteilDtk: number;
  children: React.ReactNode;
}

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { AngebotsstreckeContext } from '../../contexts/AngebotsstreckeContext';
import Image from 'next/image';
import classNames from 'classnames';
import { storyBlokLoader } from '@dtk/storyblok';
import { useContext } from 'react';
import { TrustElements } from '@dtk/public-website-ui-components';
import { isTrustPilotConsentConfirmed } from '@dtk/ui-components';

const StepBlok = ({ ...props }) => {
  const { blok, trustElementsHeadline, trustElementsKeyPoints, footer } = props;
  const { progressStep, stepsCount, currentSectionID } = useContext(AngebotsstreckeContext);

  const IS_CHECKOUT_SLIDE = blok.body[0]?.component === 'Checkout';
  const IS_LOADER_SLIDE = blok.body[0]?.component === 'LoadingComponent';
  const IS_LOADER_REINFORCEMENT_OR_CHECKOUT =
    IS_LOADER_SLIDE || blok.body[0]?.component === 'ReinforcementLayer' || IS_CHECKOUT_SLIDE;

  return (
    <>
      {blok.sectionId === currentSectionID && (
        <>
          <div
            className="step bg-coolGray-50 relative	left-0 right-0 top-0 z-10 flex w-full flex-row opacity-100"
            {...storyblokEditable(blok)}
            data-test="grid"
            data-step-id={blok.gtmId}
          >
            <div
              className={classNames(
                'relative min-h-screen w-full px-5 pb-3 pt-6 md:p-10',
                blok.image.filename ? 'lg:w-2/3' : 'lg:w-3/3'
              )}
            >
              <div className={classNames(!IS_CHECKOUT_SLIDE && 'max-w-xl', 'mx-auto')}>
                {blok.body && !IS_LOADER_REINFORCEMENT_OR_CHECKOUT && (
                  <>
                    <div className="align-center flex w-full flex-row justify-center">
                      <span className="h-3 w-64 rounded-lg bg-gray-200">
                        <div
                          style={{ width: (progressStep / stepsCount) * 100 + '%' }}
                          className="bg-navy-500 block h-full rounded-lg"
                        />
                      </span>
                    </div>

                    <span
                      className={classNames(
                        blok.sectionName && 'border-b border-gray-300',
                        'text-gold-gold font-body-semibold my-6 block pb-2 text-sm'
                      )}
                    >
                      {blok.sectionName}
                    </span>
                    <span className="font-headline-bold text-navy-navy mb-2 block text-2xl leading-9">
                      {blok.headline}
                    </span>
                    <span className="text-navy-navy block text-lg antialiased md:text-base">{blok.subline}</span>
                  </>
                )}
                {blok.body && (
                  <div className={classNames(isTrustPilotConsentConfirmed() ? 'mb-10' : 'mb-14', 'mt-10 h-auto')}>
                    {blok.body.map((nestedBlok) => (
                      <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
                    ))}
                  </div>
                )}
                {!IS_LOADER_SLIDE && !IS_CHECKOUT_SLIDE && (
                  <TrustElements headline={trustElementsHeadline} keyPoints={trustElementsKeyPoints} />
                )}
              </div>
            </div>
            {blok.image.filename && blok.body[0]?.component !== 'Checkout' && (
              <div className="relative hidden lg:block lg:w-1/3">
                <Image
                  src={blok.image.filename}
                  loader={storyBlokLoader}
                  alt={blok.image.alt}
                  className="custom-img"
                  layout="fill"
                  objectFit="cover"
                  priority={true}
                />
              </div>
            )}
          </div>
          <div
            className={classNames(
              'z-20 w-full bg-white',
              !IS_LOADER_SLIDE && !IS_CHECKOUT_SLIDE && `pb-[5.5rem] sm:pb-0`
            )}
          >
            {footer && footer.map((nestedBlok) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
          </div>
        </>
      )}
    </>
  );
};

export default StepBlok;

import { FeatureSection } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const FeatureSectionBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div {...storyblokEditable(blok)} data-test="feature-section">
      <FeatureSection {...blok} />
    </div>
  );
};

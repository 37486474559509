import Slider from 'rc-slider';
import { CSSProperties, Dispatch, ReactNode, SetStateAction } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { Button, InputFieldCounter } from '../01_elements';
import { useMediaQuery } from '../hooks';
import { BackgroundThemeVariants, themesForBackgroundColor } from '../utils';

export interface SliderMarksProps {
  style?: CSSProperties;
  label?: ReactNode;
}

export interface SliderProps {
  backgroundColor: BackgroundThemeVariants;
  fieldCounterId: string;
  fieldCounterLabel: string;
  marks?: Record<string | number, ReactNode | SliderMarksProps>;
  sliderMinimumValue: number;
  sliderMaximumValue: number;
  sliderStep?: number;
  currentSliderValue: number;
  setSliderValue: Dispatch<SetStateAction<number>>;
}

export const SliderWithCounterAndInput = ({
  backgroundColor,
  fieldCounterId,
  fieldCounterLabel,
  marks,
  sliderMinimumValue,
  sliderMaximumValue,
  sliderStep = 1,
  currentSliderValue,
  setSliderValue,
}: SliderProps) => {
  const { smallerXs } = useMediaQuery();
  sliderStep = sliderStep === 0 ? 1 : sliderStep;

  return (
    <div className="xs:mb-12 mb-4 flex flex-row">
      {smallerXs ? (
        <InputFieldCounter
          containerClassNames="w-full"
          type="number"
          id={fieldCounterId}
          name={fieldCounterId}
          label={fieldCounterLabel}
          onBlur={(event: { target: { value: string } }) => {
            currentSliderValue = !event.target.value ? 0 : parseInt(event.target.value);
            currentSliderValue >= sliderMinimumValue &&
              currentSliderValue <= sliderMaximumValue &&
              setSliderValue(currentSliderValue);
          }}
          onClick={(event: { currentTarget: { id: string } }) => {
            const counterInputField = document.getElementById(fieldCounterId) as HTMLInputElement;
            currentSliderValue = parseInt(counterInputField?.value);

            if ((event.currentTarget.id as string).includes('plus')) {
              currentSliderValue++;
            }
            if ((event.currentTarget.id as string).includes('minus')) {
              currentSliderValue--;
            }

            currentSliderValue >= sliderMinimumValue &&
              currentSliderValue <= sliderMaximumValue &&
              setSliderValue(currentSliderValue);
          }}
        />
      ) : (
        <>
          <Button
            type="button"
            variant="white"
            size="lg"
            icon={MinusIcon}
            circular
            onClick={() => {
              if (currentSliderValue > sliderMinimumValue) {
                setSliderValue(currentSliderValue - sliderStep);
              }
            }}
          />
          <Slider
            railStyle={{
              backgroundColor: themesForBackgroundColor.sliderRailColor[backgroundColor],
              height: 8,
            }}
            trackStyle={{ backgroundColor: '#4873AD', height: 8 }}
            handleStyle={{
              borderWidth: 3,
              borderColor: '#015F98',
              height: 48,
              width: 48,
              marginTop: -20,
              backgroundColor: '#FFFFFF',
              opacity: 1,
              filter: 'drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))',
              boxShadow: 'none',
            }}
            dotStyle={{
              display: 'none',
            }}
            marks={marks}
            included={true}
            className="mx-7 mt-8"
            min={sliderMinimumValue}
            max={sliderMaximumValue}
            step={sliderStep || 1}
            value={currentSliderValue}
            onChange={(value) => {
              setSliderValue(Number(value));
            }}
          />
          <Button
            type="button"
            variant="white"
            size="lg"
            icon={PlusIcon}
            circular
            onClick={() => {
              if (currentSliderValue < sliderMaximumValue) {
                setSliderValue(currentSliderValue + sliderStep);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

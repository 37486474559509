import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { Container } from '@dtk/ui-components';
import React from 'react';

const AuthorsBlok = ({ blok }) => {
  const { author_name, author_position, author_description, Navigation } = blok;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="author-blok">
      {Navigation?.[0] && <StoryblokComponent blok={Navigation[0]} />}

      <Container className="mt-8 space-y-8">
        <div className="y-14 relative mx-auto flex flex-col md:max-w-xl lg:max-w-2xl xl:max-w-[50rem]">
          <div className="md:basis-9/12 md:pl-5">
            <h1 className="font-headline text-navy text-2xl leading-6 sm:mx-0 sm:text-4xl">{author_name}</h1>
            <div className="mt-3 mb-3 text-xl font-semibold">{author_position}</div>
            <p className="pb-4 text-base md:text-lg">{author_description}</p>
          </div>
        </div>
      </Container>
      {blok?.body?.map((nestedBlok) => (
        <div key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ))}
    </div>
  );
};

export default AuthorsBlok;

import { DtkLogo } from '@dtk/ui-components';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

const FooterLogoAndIcon = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="footer-column">
    <DtkLogo key="dtk-logo" className="mb-4" loading="lazy" />
    <div key="social-media-icons" className="mb-6 mr-4 flex flex-col sm:flex-row xl:flex-col">
      <p className="text-navy-700 font-body">{blok.socialMediaReferenceText}</p>
      <div className="flex sm:ml-2 xl:ml-0">
        {blok.allSocialMediaIcons &&
          blok.allSocialMediaIcons.map((nestedBlok) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
      </div>
    </div>
  </div>
);

export default FooterLogoAndIcon;

import { Container } from '@dtk/ui-components';
import React from 'react';
// import { presets } from '../tailwind.config';
import { storyblokEditable } from '@storyblok/react';

const PresseArchivBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="presse-archiv">
    <div className="bg-gray-50">
      <Container className="bg-gray-50">PresseArchivBlok</Container>
    </div>
  </div>
);

export default PresseArchivBlok;

import { Form, Formik } from 'formik';
import { MutableRefObject, useRef, useState } from 'react';
import { object } from 'yup';

import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { formatCurrency } from '@dtk/formatter';
import {
  BackgroundThemeVariants,
  Button,
  calculatorFields,
  getAlternativePath,
  InputFieldWithAddOn,
  MAXIMUM_SALES_CLOSING_YEAR,
  MINIMUM_SALES_CLOSING_YEAR,
  propertyFields,
  SliderMarksProps,
  SliderWithCounterAndInput,
  useMediaQuery,
  validationMessages,
} from '@dtk/ui-components';

import { getAllCalculationSteps } from './calculationSteps';
import {
  calculateDtkBonus,
  calculateRealEstateValueAfterYears,
  calculateRealEstateValueWithValueEnhancement,
  calculateTotalPayoutForYears,
} from './estateCalculations';
import { LabelWithTooltipAndCalculatedValue } from './LabelWithTooltipAndCalculatedValue';
import { PropertyCalculationSection } from './PropertyCalculationSection';

export interface RechnerGesamtauszahlungProps {
  theme?: BackgroundThemeVariants;
  realEstateValueTooltipText: string;
  realEstateValueAfterYearsTooltipText: string;
  requestedPayoutTooltipText: string;
  calculateButtonText: string;
  secondPayoutTooltipText: string;
  bonusTooltipText: string;
  executionFeeTooltipText: string;
  offerButtonText: string;
  offerButtonLink: string;
  widget?: SbBlokData[];
  innerComponent: SbBlokData[];
  totalSaleOverline: string;
  totalSaleHeadline: string;
  totalSaleDescription: string;
  partialSaleOverline: string;
  partialSaleHeadline: string;
  partialSaleDescription: string;
  estateAgentCostTooltipText: string;
  rentalCostTooltipText: string;
  usageFeeTooltipText: string;
  modernizationCostTooltipText: string;
  noticeText: string;
  scrollTopButtonText: string;
  secondOfferButtonText: string;
  secondButtonLink: string;
}

const getYearMarks = () => {
  const markStyles = { fontSize: 16, color: '#19283C', marginTop: 25 };
  const allMarks: Record<number, SliderMarksProps> = {};
  for (let i = 10; i <= 30; i += 5) {
    allMarks[i] = { style: markStyles, label: i.toString() };
  }
  return allMarks;
};

export const RechnerGesamtauszahlung = (props: RechnerGesamtauszahlungProps) => {
  const { theme: backgroundColor = 'coolGray-50' } = props;
  const allTooltipTextElements = {
    estateAgentCostTooltipText: props.estateAgentCostTooltipText,
    rentalCostTooltipText: props.rentalCostTooltipText,
    secondPayoutTooltipText: props.secondPayoutTooltipText,
    usageFeeTooltipText: props.usageFeeTooltipText,
    modernizationCostTooltipText: props.modernizationCostTooltipText,
  };

  const SLIDER_START_VALUE = 20;
  const OFFER_JOURNEY_PAGE_SLUG = '/angebot-anfordern';
  const queryParameters = new URLSearchParams(window.location.search);
  const queryRealEstateValue = queryParameters.get('real-estate-value') || '';
  const queryRequestedPayout = queryParameters.get('requested-payout') || '';
  const isValidQueryParameters = !isNaN(parseInt(queryRealEstateValue)) && !isNaN(parseInt(queryRequestedPayout));

  const initValues = {
    totalSalesAfterYears: SLIDER_START_VALUE,
    realEstateValue: '',
    requestedPayout: '',
  };

  if (isValidQueryParameters) {
    initValues.realEstateValue = queryRealEstateValue;
    initValues.requestedPayout = queryRequestedPayout;
  }

  const componentHead = useRef() as MutableRefObject<HTMLDivElement>;
  const { smallerMd, smallerSm } = useMediaQuery();
  const [providedValues, setProvidedValues] = useState(initValues);
  const [sliderValue, setSliderValue] = useState<number>(SLIDER_START_VALUE);

  const offerLink = getAlternativePath({ originalLink: props.offerButtonLink, condition: smallerSm });
  const secondButtonLink = getAlternativePath({ originalLink: props.secondButtonLink, condition: smallerSm });

  const validationSchema = object({
    totalSalesAfterYears: calculatorFields.totalSalesAfterYears,
    ...propertyFields,
  });

  const { requestedPayout, realEstateValue } = providedValues;

  const { totalSaleCalculationSteps, partialSaleCalculationSteps } = getAllCalculationSteps(
    realEstateValue,
    requestedPayout,
    sliderValue,
    allTooltipTextElements
  );
  const totalSaleProperty = totalSaleCalculationSteps.reduce(
    (previousValue, currentCalculationStep) => previousValue + currentCalculationStep.calculatedValue,
    0
  );
  const partialSaleProperty = partialSaleCalculationSteps.reduce(
    (previousValue, currentCalculationStep) => previousValue + currentCalculationStep.calculatedValue,
    0
  );
  const partialSaleProfit = partialSaleProperty - totalSaleProperty;

  const onSubmitHandler = (values: {
    realEstateValue: string;
    requestedPayout: string;
    totalSalesAfterYears: number;
  }) => {
    setProvidedValues({
      totalSalesAfterYears: values.totalSalesAfterYears,
      realEstateValue: values.realEstateValue,
      requestedPayout: values.requestedPayout,
    });
  };

  return (
    <div className={`bg-${backgroundColor} xs:scroll-mt-0 scroll-mt-7 xl:scroll-mt-10`} ref={componentHead}>
      <div className="xs:px-10 xs:pt-16 mx-auto grid max-w-7xl grid-cols-12 px-6 pt-10 pb-5">
        <div className="col-span-12 lg:col-span-8 lg:col-start-3">
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmitHandler}
          >
            <Form>
              <div className="flex flex-col">
                <div className="xs:block mb-9 hidden space-y-3 text-center">
                  <p className="text-navy-navy text-lg">Gesamtverkauf nach</p>
                  <p className="text-cyan-secondary font-body-bold text-2xl">{`${sliderValue} Jahren`}</p>
                </div>
                <SliderWithCounterAndInput
                  backgroundColor={backgroundColor}
                  fieldCounterId="totalSalesAfterYears"
                  fieldCounterLabel="Gesamtverkauf nach Jahren"
                  marks={getYearMarks()}
                  sliderMinimumValue={MINIMUM_SALES_CLOSING_YEAR}
                  sliderMaximumValue={MAXIMUM_SALES_CLOSING_YEAR}
                  currentSliderValue={sliderValue}
                  setSliderValue={setSliderValue}
                />
                <div className="mb-4 flex flex-col">
                  <LabelWithTooltipAndCalculatedValue
                    label="Geschätzter Wert Ihrer Immobilie (heute)"
                    tooltipText={props.realEstateValueTooltipText}
                  />
                  <InputFieldWithAddOn
                    suffix="€"
                    id="realEstateValue"
                    name="realEstateValue"
                    placeholder="z.B. 200.000"
                    infoMessage={{
                      text: validationMessages.info.realEstateValue.text,
                      highlightedPart: validationMessages.info.realEstateValue.highlightedPart,
                    }}
                  />
                </div>
              </div>
              <LabelWithTooltipAndCalculatedValue
                label={`Immobilienwert (nach ${sliderValue} Jahren)`}
                tooltipText={props.realEstateValueAfterYearsTooltipText}
                calculatedValue={calculateRealEstateValueAfterYears(realEstateValue, sliderValue)}
              />
              <div className="border-coolGray-300 mb-4 mt-3.5 flex flex-row justify-center border-b" />
              <div className="flex flex-col">
                <LabelWithTooltipAndCalculatedValue
                  label="Ihre Wunschauszahlung (heute)"
                  tooltipText={props.requestedPayoutTooltipText}
                />
                <InputFieldWithAddOn
                  suffix="€"
                  id="requestedPayout"
                  name="requestedPayout"
                  placeholder="z.B. 100.000"
                  infoMessage={{
                    text: validationMessages.info.requestedPayout.text,
                    highlightedPart: validationMessages.info.requestedPayout.highlightedPart,
                  }}
                />
              </div>
              <Button
                className="my-6"
                type="submit"
                fullWidth
                variant="navy"
                size="lg"
                storyblokIconSource="https://a.storyblok.com/f/148087/17x16/e7ddf6504b/calculator.svg"
                iconPosition="left"
              >
                {props.calculateButtonText}
              </Button>
              <LabelWithTooltipAndCalculatedValue
                label={`2. Auszahlung (nach ${sliderValue} Jahren) inkl. Wertsteigerung`}
                tooltipText={props.secondPayoutTooltipText}
                calculatedValue={calculateRealEstateValueWithValueEnhancement(
                  realEstateValue,
                  requestedPayout,
                  sliderValue
                )}
              />
              <div className="border-coolGray-300 mt-3 mb-4 flex flex-row justify-center border-b" />
              <LabelWithTooltipAndCalculatedValue
                containerClassNames="sm:flex-row flex flex-col justify-between xs:ml-6"
                label="Davon Ihr Bonus"
                tooltipText={props.bonusTooltipText}
                calculatedValue={calculateDtkBonus(realEstateValue, requestedPayout)}
              />
              <div className="border-coolGray-300 mt-3 mb-4 flex flex-row justify-center border-b sm:hidden" />
              <div className="xs:flex-row mt-9 flex flex-col justify-between">
                <div className="flex items-center">
                  <p className="text-navy-navy font-body-bold mr-2 text-2xl">Gesamtauszahlung</p>
                </div>
                <p className="font-body-bold ml-auto text-2xl text-green-400">
                  {formatCurrency(calculateTotalPayoutForYears(realEstateValue, requestedPayout, sliderValue), 0)}
                </p>
              </div>
              <div className="my-2 flex flex-row justify-center border-b-[0.2rem] border-green-400" />
              <LabelWithTooltipAndCalculatedValue
                label="Ihr Durchführungsentgelt beträgt bei uns"
                tooltipText={props.executionFeeTooltipText}
                calculatedValue={0}
              />
              <Button
                type="button"
                className="mt-9"
                fullWidth
                size="lg"
                onClick={() => {
                  window.location.href =
                    realEstateValue && requestedPayout && offerLink.includes(OFFER_JOURNEY_PAGE_SLUG)
                      ? `${offerLink}?real-estate-value=${realEstateValue}&requested-payout=${requestedPayout}`
                      : `${offerLink}`;
                }}
              >
                {props.offerButtonText}
              </Button>
              <div className="flex justify-center">
                {props.widget &&
                  props.widget.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
                  ))}
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      {props.innerComponent &&
        props.innerComponent.map((nestedBlok: SbBlokData) => (
          <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
        ))}

      <div className="mx-auto flex max-w-7xl flex-col justify-center px-6">
        <div className="xs:text-center space-y-3">
          <p className="text-navy-navy text-lg">Gewählter Berechnungszeitraum:</p>
          <p className="text-cyan-secondary font-body-bold text-4xl">
            {sliderValue === 1 ? `1 Jahr` : `${sliderValue} Jahre`}
          </p>
        </div>
        <div className="mt-6 flex flex-col md:mt-0 md:flex-row md:space-x-6">
          <PropertyCalculationSection
            overline={props.totalSaleOverline}
            headline={props.totalSaleHeadline}
            description={props.totalSaleDescription}
            allCalculationSteps={totalSaleCalculationSteps}
            totalAmountColor="red"
          />
          <PropertyCalculationSection
            overline={props.partialSaleOverline}
            headline={props.partialSaleHeadline}
            description={props.partialSaleDescription}
            allCalculationSteps={partialSaleCalculationSteps}
            totalAmountColor="green"
          />
        </div>
        <div className="xs:flex-row xs:space-y-0 xs:space-x-2 flex flex-col justify-between space-y-2 sm:items-center">
          <p className="text-navy-navy font-body-bold xs:max-w-[60%] text-2xl md:max-w-[75%] lg:max-w-none">
            {smallerMd
              ? 'Gewinn bei Teilverkauf'
              : 'Ihr Vorteil bei einem Teilverkauf gegenüber einem Gesamtverkauf heute'}
          </p>
          <p className="font-body-bold ml-auto text-2xl text-green-400">{formatCurrency(partialSaleProfit, 0)}</p>
        </div>
        <div className="mt-3 mb-9 flex flex-row justify-center border-b-[0.2rem] border-green-400" />
        <div className="mb-9 flex items-start space-x-3">
          <span className="flex items-start">
            <ExclamationCircleIcon className="text-gold-gold h-7 w-7" />
          </span>
          <p className="text-navy-navy text-lg">{props.noticeText}</p>
        </div>
      </div>
      <div className="xs:pb-14 mx-auto flex max-w-fit flex-col justify-center space-y-4 px-6 pb-10 md:max-w-none md:flex-row md:space-x-6 md:space-y-0">
        <Button
          onClick={() => {
            window.location.href =
              realEstateValue && requestedPayout && secondButtonLink.includes(OFFER_JOURNEY_PAGE_SLUG)
                ? `${secondButtonLink}?real-estate-value=${realEstateValue}&requested-payout=${requestedPayout}`
                : `${secondButtonLink}`;
          }}
          size="lg"
        >
          {props.secondOfferButtonText}
        </Button>
        <Button variant="white" size="lg" onClick={() => componentHead.current.scrollIntoView({ behavior: 'smooth' })}>
          {props.scrollTopButtonText}
        </Button>
      </div>
    </div>
  );
};

import {
  CardWithImageTextElementsAndButton,
  getColumnStartOfLastCardOnTablet,
  getColumnStartOfLastCardsOnDesktop,
} from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';

export const CardWithImageTextElementsAndButtonBlok = ({ ...props }) => {
  const { blok, index, numberOfCards } = props;

  return (
    <div
      {...storyblokEditable(blok)}
      className={classNames(
        'col-span-4',
        getColumnStartOfLastCardOnTablet(index, numberOfCards),
        getColumnStartOfLastCardsOnDesktop(index, numberOfCards)
      )}
      key={blok._uid}
      data-test="card-with-image-text-elements-and-button"
    >
      <CardWithImageTextElementsAndButton {...blok} />
    </div>
  );
};

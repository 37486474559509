import classNames from 'classnames';
import Link from 'next/link';
import { hasAnchor, isExternalPage } from '../../utils/checkLinks';
import { HeroCertificateIconProps } from './types';
import { useMediaQuery } from '../../hooks';

export const HeroCertificateIcon = ({ ...props }: HeroCertificateIconProps) => {
  const { isMobile, bannerImage, bannerImageLink } = props;
  const { largerXs, largerMd, largerXl } = useMediaQuery();

  const getSizeByViewportidth = () => {
    if (largerXl) return 208;
    if (largerMd) return 160;
    if (largerXs) return 112;
    return 96;
  };

  const BannerImage = ({ bannerImage }: HeroCertificateIconProps) => {
    return (
      <img
        src={bannerImage.filename}
        alt={bannerImage.alt}
        width={getSizeByViewportidth()}
        height={getSizeByViewportidth()}
      />
    );
  };

  if (bannerImage) {
    return (
      <div
        className={classNames(
          'absolute z-10 block h-28 w-full md:bg-transparent',
          isMobile ? 'xs:hidden block' : 'xs:block hidden'
        )}
      >
        <div className="xs:bottom-32 absolute right-4 bottom-20 md:right-8 md:bottom-36 xl:bottom-44 xl:right-20">
          {bannerImageLink && (
            <Link href={bannerImageLink} passHref>
              {hasAnchor(bannerImageLink) ? (
                <a aria-label={bannerImage.alt} href={bannerImageLink}>
                  <BannerImage bannerImage={bannerImage} />
                </a>
              ) : (
                <a
                  aria-label={bannerImage.alt}
                  href={bannerImageLink}
                  target={isExternalPage(bannerImageLink) ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                >
                  <BannerImage bannerImage={bannerImage} />
                </a>
              )}
            </Link>
          )}
          {!bannerImageLink && <BannerImage bannerImage={bannerImage} />}
        </div>
      </div>
    );
  }
  return null;
};

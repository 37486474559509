import { Field, FieldHookConfig, useField } from 'formik';
import classNames from 'classnames';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { getHighlightedText } from '../../utils';

interface ValidationTextProps {
  text: string;
  highlightedPart: string;
}

interface InputFieldCounterProps {
  containerClassNames?: string;
  isMandatory?: boolean;
  isOptional?: boolean;
  startValue?: number;
  infoMessage?: ValidationTextProps;
}

export function InputFieldCounter(
  props: React.HTMLProps<HTMLInputElement> & FieldHookConfig<number> & InputFieldCounterProps
) {
  const [field, meta, helper] = useField(props);
  const {
    id,
    name,
    label,
    disabled,
    containerClassNames,
    isMandatory,
    isOptional,
    startValue,
    infoMessage,
    onClick,
    onBlur,
    ...restProps
  } = props;
  const counterClassNames = classNames(
    'flex-end inline-flex select-none items-center px-4 py-3',
    'text-coolGray-500 border-coolGray-300 bg-coolGray-100 border',
    !props?.disabled && 'cursor-pointer'
  );

  return (
    <div className={containerClassNames}>
      <div className="font-body flex justify-between">
        <label htmlFor={id || name} className="text-navy font-body-medium block">
          {label && isMandatory && !isOptional ? `${label}*` : label}
        </label>
        {label && isOptional && !isMandatory && <label className="text-coolGray-500 antialiased">Optional</label>}
      </div>
      <div className="font-body mt-2 flex justify-between rounded-md shadow-sm">
        <button
          data-testid={`${name}-minus`}
          id={`${name}-minus`}
          className={classNames(counterClassNames, 'rounded-l border-r-0')}
          type="button"
          onClick={(event: never) => {
            onClick && onClick(event);
            if (!disabled && field.value > 0) {
              helper.setValue(field.value - 1);
            }
          }}
          onBlur={(event: never) => field.onBlur(event)}
        >
          <MinusIcon className="h-5 w-5" />
        </button>
        <Field
          {...field}
          {...restProps}
          id={id}
          className={classNames(
            'block w-full min-w-0 flex-1 rounded-none px-5 py-3 text-center text-base',
            disabled && 'bg-coolGray-100 text-coolGray-400',
            meta.error && meta.touched
              ? 'text-red-secondary placeholder:text-red-secondary border-red-500 bg-red-50'
              : 'text-navy border-coolGray-300 placeholder:text-coolGray-400 focus:border-cyan-700 focus:outline-0 focus:ring-0'
          )}
          type="number"
          onBlur={(event: never) => {
            field.onBlur(event);
            onBlur && onBlur(event);
            if (!field.value) helper.setValue(0);
          }}
          disabled={disabled}
        />
        <button
          data-testid={`${name}-plus`}
          id={`${name}-plus`}
          className={classNames(counterClassNames, 'rounded-r border-l-0')}
          type="button"
          onClick={(event: never) => {
            onClick && onClick(event);
            !disabled && helper.setValue(field.value + 1);
          }}
          onBlur={(event: never) => field.onBlur(event)}
        >
          <PlusIcon className="h-5 w-5" />
        </button>
      </div>
      {infoMessage && (
        <p
          className="text-coolGray-500 font-body mt-2 text-sm antialiased"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(infoMessage.text, infoMessage.highlightedPart, 'coolGray-500', {
              fontFamily: 'font-body',
              isBold: true,
            }),
          }}
        />
      )}
      {meta.error && meta.touched && <p className="text-red-secondary mt-2 text-sm">{meta.error}</p>}
    </div>
  );
}

import { useRef, useState } from 'react';
import { Form, Formik, FormikTouched } from 'formik';
import axios from 'axios';

import { useAppConfig } from '@dtk/config';
import {
  Button,
  DropdownField,
  InputField,
  MessageBanner,
  TrustPilotBox,
  getTrustPilotWidgetStyles,
  validationMessages,
  salutationOptions,
  titleOptions,
  hasWebNavigationWithinPage,
  OptInCheckboxes,
  MandatoryFieldsMarker,
} from '@dtk/ui-components';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import { registrationDataValidationSchema } from './leadTransmissionValidationSchemas';
import { RegistrationBaseValues, RegistrationValues, getRegistrationInitialValues } from './types';
import { SpinnerWeb } from '../../01_elements';

interface EstateAgentRegistrationFormProps {
  requestedData?: RegistrationBaseValues;
}

export const EstateAgentRegistrationForm = ({ requestedData }: EstateAgentRegistrationFormProps) => {
  const { config } = useAppConfig();
  const componentHead = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [showSendButton, setShowSendButton] = useState(true);
  const [showMessageBanner, setShowMessageBanner] = useState(false);

  const handleClick = (touched: FormikTouched<unknown>, isValid: boolean) => {
    if (isValid && Object.keys(touched).length > 0) {
      setShowMessageBanner(false);
      return;
    }
    setShowMessageBanner(true);
    return componentHead.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = (values: RegistrationValues) => {
    setShowSendButton(false);

    const payload = {
      email: values.email,
      company: values.company,
      ihkNumber: values.ihkNumber,
      salutation: values.salutation,
      userTitle: values.userTitle,
      firstName: values.firstName,
      lastName: values.lastName,
      street: values.street,
      houseNumber: values.houseNr,
      zip: values.plz,
      city: values.city,
      phone: values.phone,
      additionalInfo: values.additionalInfo,
      advertisingConsent: values.advertisingConsent,
    };

    axios
      .post(config['API_BASE'] + '/public/estate-agent/register', payload, {})
      .then(() => {
        window.location.href = '/vielen-dank-registrierung-makler';
      })
      .catch(() => {
        window.location.href = '/error';
      });
  };

  return (
    <div
      className={`bg-coolGray-50 ${hasWebNavigationWithinPage() && 'scroll-mt-16 xl:scroll-mt-28'}`}
      ref={componentHead}
    >
      <Formik
        initialValues={getRegistrationInitialValues(requestedData)}
        validationSchema={registrationDataValidationSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ touched, isValid }) => (
          <Form>
            {!isValid && showMessageBanner && (
              <MessageBanner
                classNames="mb-10"
                message={validationMessages.error.defaultBanner.text}
                highlightText={validationMessages.error.defaultBanner.highlightedPart}
                isError
              />
            )}
            <div className="mx-auto grid w-full grid-cols-6 gap-x-6 gap-y-4 p-[2rem] sm:max-w-[51rem]">
              <div className="col-span-6">
                <InputField
                  id="company"
                  name="company"
                  label="Unternehmen"
                  type="text"
                  infoMessage={validationMessages.info.company}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <DropdownField
                  data-testid="salutation"
                  name="salutation"
                  label="Anrede"
                  options={salutationOptions}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <DropdownField data-testid="userTitle" name="userTitle" label="Titel" options={titleOptions} />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="firstName"
                  name="firstName"
                  label="Vorname"
                  type="text"
                  infoMessage={validationMessages.info.firstName}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="lastName"
                  name="lastName"
                  label="Nachname"
                  type="text"
                  infoMessage={validationMessages.info.lastName}
                  isMandatory
                />
              </div>

              <div className="col-span-6">
                <InputField
                  id="ihk-number"
                  name="ihkNumber"
                  label="IHK-Nummer"
                  type="text"
                  infoMessage={validationMessages.info.ihkNr}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="street"
                  name="street"
                  label="Straße"
                  type="text"
                  infoMessage={validationMessages.info.street}
                  isMandatory
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="houseNr"
                  name="houseNr"
                  label="Nr."
                  type="text"
                  infoMessage={validationMessages.info.houseNr}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="plz"
                  name="plz"
                  label="PLZ"
                  type="text"
                  infoMessage={validationMessages.info.zipCode}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="city"
                  name="city"
                  label="Ort"
                  type="text"
                  infoMessage={validationMessages.info.city}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="email"
                  name="email"
                  label="E-Mail-Adresse"
                  type="text"
                  placeholder="max@mustermail.de"
                  icon={MailIcon}
                  infoMessage={validationMessages.info.email}
                  isMandatory
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <InputField
                  id="phone"
                  name="phone"
                  label="Telefonnummer"
                  type="text"
                  icon={PhoneIcon}
                  infoMessage={validationMessages.info.phone}
                  isMandatory
                />
              </div>

              <div className="col-span-6">
                <InputField
                  label="Möchten Sie uns noch etwas mitteilen?"
                  placeholder="Ihre optionale Nachricht an uns... Gerne können Sie in diesem Feld zudem den Link zu Ihrer Homepage, sofern vorhanden, hinterlassen."
                  maxLength={255}
                  component="textarea"
                  rows={4}
                  name="additionalInfo"
                />
              </div>
              <MandatoryFieldsMarker />
              <span />
              <OptInCheckboxes showAdvertisingConsent />

              <div className="relative col-span-6 mb-10">
                {showSendButton && (
                  <Button
                    fullWidth
                    className=" absolute top-0 bottom-10 left-0 right-0 h-12 justify-self-end text-center sm:bottom-auto sm:right-auto sm:top-4"
                    type="submit"
                    onClick={() => handleClick(touched, isValid)}
                  >
                    Jetzt abschicken
                  </Button>
                )}
                {!showSendButton && <SpinnerWeb />}
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="mt-3 flex justify-center lg:mt-5">
        <TrustPilotBox width="250px" height="40px" reviewStyle={getTrustPilotWidgetStyles().microTrustScore} />
      </div>
    </div>
  );
};

import Head from 'next/head';
import React from 'react';
import { getBaseUrl } from '@dtk/ui-components';

const CanonicalLink: React.FC<{ story }> = ({ story }) => {
  let selfReference = story?.content?.CanonicalLink || `${getBaseUrl}/${story?.full_slug}`;
  if (!selfReference.endsWith('/')) {
    selfReference = selfReference.concat('/');
  }
  return <Head>{selfReference && <link rel="canonical" href={selfReference} />}</Head>;
};

export default CanonicalLink;

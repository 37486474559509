import { useFormikContext } from 'formik';
import {
  DropdownField,
  InputField,
  InputFieldCounter,
  InputFieldWithAddOn,
  basementExtensionOptions,
  basementTypeOptions,
  buildingTypeOptions,
  constructionOptions,
  loftConversionOptions,
  objectTypeOptions,
  validationMessages,
} from '@dtk/ui-components';
import { LeadTransmissionValues } from './types';

export const RealEstateTypeForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <div className="flex flex-row items-center px-1">
        <div className="text-navy basis-2/6 text-sm uppercase tracking-[.2em]">Immobilientyp</div>
        <span className="border-gold-gold xs:ml-0 ml-4 basis-4/6 border-b border-solid" />
      </div>

      <DropdownField
        data-testid="objectType"
        name="objectType"
        label="Immobilienart"
        options={objectTypeOptions}
        isMandatory
      />

      <DropdownField
        data-testid="buildingType"
        name="buildingType"
        label="Gebäudeart"
        options={buildingTypeOptions}
        disabled={(values as LeadTransmissionValues).objectType !== 'Haus'}
        isMandatory={(values as LeadTransmissionValues).objectType === 'Haus'}
      />

      <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
        <div className="basis-1/2">
          <InputFieldWithAddOn
            className="basis-1/2"
            suffix={'m²'}
            id="livingSpace"
            name="livingSpace"
            label="Wohnfläche"
            type="text"
            infoMessage={validationMessages.info.livingSpace}
            isMandatory
          />
        </div>
        <div className="basis-1/2">
          <InputFieldWithAddOn
            suffix={'m²'}
            id="landArea"
            name="landArea"
            label="Grundstücksfläche"
            type="text"
            infoMessage={validationMessages.info.landArea}
            isMandatory
          />
        </div>
      </div>

      <InputField
        id="constructionYear"
        name="constructionYear"
        label="Baujahr"
        type="number"
        infoMessage={validationMessages.info.constructionYear}
        isMandatory
      />

      <InputFieldCounter
        id="floorCount"
        name="floorCount"
        label="Anzahl der Geschosse"
        startValue={1}
        disabled={(values as LeadTransmissionValues).objectType !== 'Haus'}
      />

      <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
        <InputFieldCounter
          containerClassNames="basis-1/2"
          id="garageParkingSpaces"
          name="garageParkingSpaces"
          label="Anzahl Garagenstellplätze"
          startValue={0}
        />
        <InputFieldCounter
          containerClassNames="basis-1/2"
          id="outdoorParkingSpaces"
          name="outdoorParkingSpaces"
          label="Anzahl Außenstellplätze"
          startValue={0}
        />
      </div>

      <DropdownField
        data-testid="construction"
        name="construction"
        label="Bauweise"
        options={constructionOptions}
        isMandatory
      />

      <DropdownField
        data-testid="loftConversion"
        name="loftConversion"
        label="Dachausbau"
        options={loftConversionOptions}
        isMandatory
      />

      <DropdownField
        data-testid="basementType"
        name="basementType"
        label="Ausbau des Kellers"
        options={basementTypeOptions}
        isMandatory
      />

      <DropdownField
        data-testid="basementExtension"
        name="basementExtension"
        label="Bitte Ausbaustufe auswählen"
        options={basementExtensionOptions}
        disabled={
          !(['vollunterkellert', 'teilweiseunterkellert'].indexOf((values as LeadTransmissionValues).basementType) >= 0)
        }
        isMandatory={
          ['vollunterkellert', 'teilweiseunterkellert'].indexOf((values as LeadTransmissionValues).basementType) >= 0
        }
      />
    </>
  );
};

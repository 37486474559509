import { BackgroundThemeVariants, HeaderText } from '@dtk/ui-components';

interface ImageProps {
  filename: string;
  alt: string;
}
export interface HeaderSectionWithBGImageProps {
  image: ImageProps;
  overline: string;
  headline: string;
  subtitle: string;
  theme: BackgroundThemeVariants;
}

export const HeaderSectionWithBGImage = ({
  image,
  overline,
  headline,
  subtitle,
  theme,
}: HeaderSectionWithBGImageProps) => {
  return (
    <div className="relative max-h-[41rem] w-full justify-end overflow-hidden">
      {image?.filename && (
        <img
          className="max-h-[41rem] w-full justify-end object-cover"
          src={`${image.filename}/m/smart`}
          alt={image.alt}
        />
      )}
      <div className="absolute bottom-0 left-0 right-0 hidden sm:inline-block ">
        <HeaderText overline={overline} headline={headline} subtitle={subtitle} theme={theme} headingType="h1" />
      </div>
    </div>
  );
};

import { storyblokEditable } from '@storyblok/react';
import { Gamification } from '@dtk/ui-components';

const GamificationBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="slider-with-header-text-and-button">
    <Gamification
      backgroundColor={blok.backgroundColor}
      overline={blok.overline}
      inputType={blok.inputType}
      headline={blok.headline}
      description={blok.description}
      isAlternativePrimaryButtonColor={blok.alternativePrimaryButtonColor}
      buttonText={blok.buttonText}
      buttonRedirectionLocation={blok.buttonRedirectionLocation}
    />
  </div>
);

export default GamificationBlok;

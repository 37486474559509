import { isApartment, isHouse } from '@dtk/ui-components';
import React, { useEffect, useRef, useState } from 'react';
import TagManager from 'gtm-for-react';
import { useOfferStore } from '../store/OfferStore';

declare global {
  interface Window {
    faOrderID: unknown;
  }
}

interface AngebotsstreckeCtxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formRef: React.MutableRefObject<any>;
  activeStep: number;
  stepsCount: number;
  progressStep: number;
  currentSectionID: string;
  setCurrentSectionID: React.Dispatch<React.SetStateAction<string>>;
  handleNext: () => void;
  handleBack: () => void;
  saveDataLocalstorage: boolean;
  setSaveDataLocalstorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const AngebotsstreckeContext = React.createContext<AngebotsstreckeCtxProps | null>(null);

const AngebotsstreckeProvider = ({ children }) => {
  const allSlides = children[1].concat(children[2], children[3], children[4]);
  const formRef = useRef(null);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepsCount, setStepsCount] = useState<number>(0);
  const [progressStep, setProgressStep] = useState<number>(1);
  const [slide, setSlide] = useState<number>(1);
  const [currentSectionID, setCurrentSectionID] = useState<string>(allSlides[0]?.props?.blok?.sectionId);
  const [saveDataLocalstorage, setSaveDataLocalstorage] = useState<boolean>(false);

  const data = useOfferStore.getState().data;
  const currentObjectType = data.get('objectType') as string;
  const introductionFlowSteps = children[1].length;
  const houseFlowSteps = children[2].length;
  const apartmentFlowSteps = children[3].length;
  const generalFlowSteps = children[4].length;

  const countStepsShort = (flow) => {
    for (let i = 0; i < flow.length; i++) {
      if (flow[i].props.blok.sectionName === 'ReinforcementLayer') {
        return i + 1;
      }
    }
  };

  const houseFlowShortSteps = countStepsShort(children[2]);
  const appartmentFlowShortSteps = countStepsShort(children[3]);
  //Optimistic approch of steps - if the user chooses a specific flow steps will be updated
  const steps = introductionFlowSteps + Math.min(houseFlowSteps, apartmentFlowSteps) + generalFlowSteps;

  const calculateSteps = (type: string, isForwardDirection?: boolean) => {
    if (isHouse(type)) {
      if (progressStep < introductionFlowSteps + houseFlowShortSteps) {
        return introductionFlowSteps + houseFlowShortSteps;
      } else if (progressStep === introductionFlowSteps + houseFlowShortSteps && !isForwardDirection) {
        return introductionFlowSteps + houseFlowShortSteps;
      }
      return introductionFlowSteps + houseFlowSteps + generalFlowSteps;
    }

    if (isApartment(type)) {
      if (progressStep < introductionFlowSteps + appartmentFlowShortSteps) {
        return introductionFlowSteps + appartmentFlowShortSteps;
      } else if (progressStep === introductionFlowSteps + appartmentFlowShortSteps && !isForwardDirection) {
        return introductionFlowSteps + appartmentFlowShortSteps;
      }
      return introductionFlowSteps + apartmentFlowSteps + generalFlowSteps;
    }

    if (progressStep <= introductionFlowSteps + Math.min(houseFlowShortSteps, appartmentFlowShortSteps)) {
      return introductionFlowSteps + Math.min(houseFlowShortSteps, appartmentFlowShortSteps);
    }
    return steps;
  };

  const handleNext = () => {
    let nextStep = activeStep + 1;

    if (isHouse(currentObjectType)) {
      setStepsCount(calculateSteps(currentObjectType, true));
      if (activeStep + 1 === introductionFlowSteps) {
        nextStep = introductionFlowSteps;
      } else if (activeStep + 1 === introductionFlowSteps + houseFlowSteps) {
        nextStep = introductionFlowSteps + houseFlowSteps + apartmentFlowSteps;
      }
    } else if (isApartment(currentObjectType)) {
      setStepsCount(calculateSteps(currentObjectType, true));
      if (activeStep + 1 === introductionFlowSteps) {
        nextStep = introductionFlowSteps + houseFlowSteps;
      }
    }

    setCurrentSectionID(allSlides[nextStep]?.props?.blok?.sectionId);
    setActiveStep(nextStep);
    setProgressStep(progressStep + 1);

    const stepId = allSlides[nextStep]?.props?.blok?.gtmId;
    setSlide(slide + 1);
    triggerDataLayerPushes(stepId, slide);
  };

  const getStepValuesFromSlideBeforeLoadingComponent = (
    previousStep: number,
    previousProgressStep: number,
    previousSlide: number
  ) => {
    const currentSectionName = allSlides[previousStep]?.props?.blok?.sectionName;

    if (currentSectionName?.includes('Loader')) {
      previousStep--;
      previousProgressStep--;
      previousSlide--;
    }

    return { previousStep, previousProgressStep, previousSlide };
  };

  const handleBack = () => {
    let previousStep = activeStep - 1;
    let previousProgressStep = progressStep - 1;
    let previousSlide = slide - 1;
    const stepValue = getStepValuesFromSlideBeforeLoadingComponent(previousStep, previousProgressStep, previousSlide);
    previousStep = stepValue.previousStep;
    previousProgressStep = stepValue.previousProgressStep;
    previousSlide = stepValue.previousSlide;

    if (isHouse(currentObjectType)) {
      setStepsCount(calculateSteps(currentObjectType, false));
      if (activeStep === introductionFlowSteps) {
        previousStep = introductionFlowSteps - 1;
      } else if (activeStep === introductionFlowSteps + houseFlowSteps + apartmentFlowSteps) {
        previousStep = introductionFlowSteps + houseFlowSteps - 1;
      }
    } else if (isApartment(currentObjectType)) {
      setStepsCount(calculateSteps(currentObjectType, false));
      if (activeStep === introductionFlowSteps + houseFlowSteps) {
        previousStep = introductionFlowSteps - 1;
      } else if (activeStep === introductionFlowSteps + houseFlowSteps + apartmentFlowSteps) {
        previousStep = introductionFlowSteps + houseFlowSteps + apartmentFlowSteps - 1;
      }
    }

    setCurrentSectionID(allSlides[previousStep]?.props?.blok?.sectionId);
    setActiveStep(previousStep);
    setProgressStep(previousProgressStep);

    setSlide(previousSlide);
    const stepId = allSlides[previousStep]?.props?.blok?.gtmId;
    triggerDataLayerPushes(stepId, previousStep);
  };

  function triggerDataLayerPushes(stepId: string, stepNumber: number) {
    const street = data.get('street');
    const zipCode = data.get('zipCode');
    const city = data.get('city');

    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout',
        ecommerce: {
          currencyCode: 'EUR',
          checkout: {
            actionField: {
              step: stepNumber,
            },
            products: [
              {
                name: 'Deutsche Teilkauf Teilverkauf Qualified Lead',
                id: window.faOrderID,
                brand: 'Deutsche Teilkauf',
                category: 'Teilverkauf',
                variant: 'Angebotsstrecke',
                price: '',
                quantity: 1,
              },
            ],
          },
        },
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'virtual-page-view',
        pageName: '/angebot-anfordern/' + stepId,
        stepNumber: stepNumber,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'trichter',
        step: '/angebot-anfordern/' + stepId,
        stepNumber: stepNumber,
      },
    });

    if (street && zipCode && city) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'fillDate',
          'upd.strasse': street,
          'upd.plz': zipCode,
          'upd.stadt': city,
        },
      });
    }
  }

  useEffect(() => {
    const localActiveStep = 0;
    setActiveStep(localActiveStep);
    setStepsCount(calculateSteps(data.get('objectType')?.toString() ?? ''));
    let gtmId;
    if (localActiveStep > 0) {
      setCurrentSectionID(allSlides[localActiveStep]?.props?.blok?.sectionId);
      gtmId = allSlides[localActiveStep]?.props?.blok?.gtmId;
    } else {
      setCurrentSectionID(allSlides[0]?.props?.blok?.sectionId);
      gtmId = allSlides[0]?.props?.blok?.gtmId;
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'virtual-page-view',
        pageName: '/angebot-anfordern/' + gtmId,
        stepNumber: localActiveStep,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'trichter',
        pageName: '/angebot-anfordern/' + gtmId,
        stepNumber: localActiveStep,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AngebotsstreckeContext.Provider
      value={{
        formRef,
        activeStep,
        stepsCount,
        progressStep,
        currentSectionID,
        setCurrentSectionID,
        handleBack,
        handleNext,
        saveDataLocalstorage,
        setSaveDataLocalstorage,
      }}
    >
      {children}
    </AngebotsstreckeContext.Provider>
  );
};

export { AngebotsstreckeProvider, AngebotsstreckeContext };

import { JobsCarouselDataLoader } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const JobsCarouselBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="JobsCarousel">
    <JobsCarouselDataLoader title={blok.headline} subtitle={blok.subline} />
  </div>
);

export default JobsCarouselBlok;

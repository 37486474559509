import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { StoryblokIcon, useMediaQuery } from '@dtk/ui-components';

const FooterSocialMediaBlok = ({ blok }) => {
  const { smallerSm } = useMediaQuery();

  return (
    <div
      className={classNames(
        'my-2 mr-4 sm:my-0 sm:ml-2 sm:mr-2 xl:my-2 xl:ml-0 xl:mr-4',
        !blok.link && 'pointer-events-none'
      )}
    >
      {blok.icon?.filename && (
        <Link href={blok.link} passHref>
          <a href={blok.link} target="_blank" rel="noopener noreferrer">
            <StoryblokIcon
              iconSource={blok.icon.filename}
              alt={blok.icon.alt}
              loading="lazy"
              width={smallerSm ? 50 : 25}
              height={smallerSm ? 50 : 25}
            />
          </a>
        </Link>
      )}
    </div>
  );
};

export default FooterSocialMediaBlok;

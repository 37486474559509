import { NavigationContactMenuItemProps, NavigationContactMenuItemComponent } from './NavigationContactMenuItem';

export interface NavigationContactMenuItemsProps {
  title: string;
  description: string;
  contactItems: NavigationContactMenuItemProps[];
}

export const NavigationContactMenuItemsComponent = (props: NavigationContactMenuItemsProps) => {
  const contactItems = props.contactItems;
  return (
    <div className="bg-navy-800 font-body flex flex-col gap-y-3 rounded-sm pb-6 text-white">
      <div className="-mt-6 self-center">{props.description}</div>
      <div className="grid grid-cols-12 gap-y-3 gap-x-1">
        {contactItems.map((item) => (
          <div className="col-span-6">
            <NavigationContactMenuItemComponent {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

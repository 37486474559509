import { CTASection } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const CTASectionBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="cta-section">
      <CTASection
        backgroundTheme={blok.backgroundTheme}
        overline={blok.overline}
        headingType={blok.headingType}
        headline={blok.headline}
        subline={blok.subline}
        primaryButtonName={blok.primaryButtonName}
        primaryButtonLink={blok.primaryButtonLink}
        primaryButtonTheme={blok.primaryButtonTheme}
        secondaryButtonName={blok.secondaryButtonName}
        secondaryButtonLink={blok.secondaryButtonLink}
        image={blok.image}
      />
    </div>
  );
};

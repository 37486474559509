import { ReactNode } from 'react';
import { NoDataAvailable, NoDocuments, TryAgainLater, TechDifficultyIcon } from '../static';

export type ErrorStateVariants = 'noDataAvailable' | 'noDocuments' | 'tryAgainLater' | 'techDifficultyPartnerOffer';

export interface ErrorStateProps {
  className?: string;
  state: ErrorStateVariants;
}

export const ErrorState = ({ className = '', state }: ErrorStateProps) => {
  const stateMap = new Map<ErrorStateVariants, ReactNode>([
    ['noDataAvailable', <NoDataAvailable />],
    ['noDocuments', <NoDocuments />],
    ['tryAgainLater', <TryAgainLater />],
    ['techDifficultyPartnerOffer', <TechDifficultyIcon />],
  ]);

  const descriptionMap = new Map<ErrorStateVariants, string>([
    [
      'techDifficultyPartnerOffer',
      'Aufgrund technischer Probleme kann Ihnen das Formular zur Anfrage eines Angebots nicht angezeigt werden. Versuchen Sie es in ein paar Minuten erneut.',
    ],
  ]);
  const optionalDescription = descriptionMap.get(state);

  return (
    <div className="mx-auto">
      {className ? <div className={className}>{stateMap.get(state)}</div> : <>{stateMap.get(state)}</>}
      {optionalDescription && <p className="text-sm">{descriptionMap.get(state)}</p>}
    </div>
  );
};

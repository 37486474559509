type SmallFooterProps = {
  datenschutzLink: string;
  copyrightText: string;
  datenschutzText: string;
  impressumText: string;
  impressumLink: string;
};

export const SmallFooter = ({
  datenschutzLink,
  copyrightText,
  datenschutzText,
  impressumText,
  impressumLink,
}: SmallFooterProps) => {
  return (
    <div className="mb-3 lg:mx-3">
      <div key="separator" className="border-coolGray-300 mb-4 flex flex-row justify-center border-b" />
      <div className="xs:grid-cols-2 mx-5 grid grid-cols-1 justify-items-center gap-x-7 lg:mx-2">
        <p className="xs:justify-self-start text-navy text-md font-bold">{copyrightText}</p>
        <div className="xs:justify-self-end xs:mt-0 text-navy mt-2 flex justify-end">
          {impressumLink && (
            <a className="mr-2" href={impressumLink} target="_blank" rel="noreferrer">
              {impressumText}
            </a>
          )}
          {datenschutzLink && (
            <a href={datenschutzLink} target="_blank" rel="noreferrer">
              {datenschutzText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

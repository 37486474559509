import { ProductDetailSection } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const ProductDetailSectionBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} className="product-detail" key={blok._uid} data-test="product-detail-section">
      <ProductDetailSection {...blok} />
    </div>
  );
};

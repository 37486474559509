import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import React from 'react';

const FooterColumnBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="footer-column">
    <>
      <p className="text-navy font-body-bold mb-4 antialiased">{blok.title}</p>
      {blok.content && blok.content.map((nestedBlok) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
    </>
  </div>
);

export default FooterColumnBlok;

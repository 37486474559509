import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { CardWithMeta, CardWithMetaProps } from '../../02_composite/Cards';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Controller, Navigation, Pagination, Thumbs } from 'swiper';
import { useState } from 'react';

import { Button } from '../../01_elements';
import classNames from 'classnames';
import styles from './PostsCarousel.module.scss';

SwiperCore.use([Autoplay, Navigation, Pagination, Controller, Thumbs]);

interface CategoriesContentProps {
  name: string;
}

interface CategoriesProps {
  name: string;
  content: CategoriesContentProps;
}

export interface PostsCarouselProps {
  showFilter: boolean;
  showTitle: boolean;
  title?: string;
  buttonText: string;
  buttonLink: string;
  posts: CardWithMetaProps[];
  categories: CategoriesProps[];
}

interface BlogPostsButtonProps {
  buttonContainerStyles: string;
  buttonLink: string;
  buttonText: string;
  showFilter: boolean;
}

const BlogPostsButton = ({ buttonContainerStyles, buttonText, buttonLink, showFilter }: BlogPostsButtonProps) => (
  <div className={buttonContainerStyles}>
    <Button variant="white" href={buttonLink}>
      <p className="flex font-semibold">
        {buttonText} {showFilter && <ArrowRightIcon className="my-auto ml-3 h-4 w-4" />}
      </p>
    </Button>
  </div>
);

export const PostsCarousel = ({
  showFilter,
  showTitle,
  title,
  buttonText,
  buttonLink,
  posts,
  categories,
}: PostsCarouselProps) => {
  posts.sort((b, a) => Date.parse(a.first_published_at) - Date.parse(b.first_published_at));

  const customCategories = ['Alle', ...categories.map((category) => category.content?.name)];
  const [categoryName, setCategoryName] = useState<string>('Alle');
  const [filteredPosts, setFilteredPosts] = useState<CardWithMetaProps[]>(posts);
  const showsFilterWithoutTitle = showFilter && !showTitle;
  const showsTitleWithoutFilter = !showFilter && showTitle;

  const onCategoryChange = (category: string) => {
    setCategoryName(category);
    if (categoryName === 'Alle') {
      setFilteredPosts(posts);
      return;
    }

    const newFilteredPosts = posts.filter((post) => {
      return post.content?.categories.some((cat: { name: string }) => cat.name === categoryName);
    });
    setFilteredPosts(newFilteredPosts);
  };

  return (
    <div className="relative py-14">
      <div className="bg-navy absolute bottom-0 z-0 h-4/6 w-full" />
      {(showsFilterWithoutTitle || showsTitleWithoutFilter) && (
        <div
          className={classNames(
            showFilter ? 'justify-center border-0 sm:border-b' : 'justify-between border-b',
            'border-cold-gray-300 mx-5 mb-6 flex max-w-[74rem] flex-row items-center sm:mb-10  xl:mx-auto'
          )}
        >
          {showFilter && (
            <div className="mx-2 flex flex-col sm:mx-0">
              <div className="text-coolGray-500 flex w-72 flex-row items-center justify-center px-4 text-xs sm:w-96">
                {customCategories.map((category, index) => {
                  return (
                    <button
                      className={
                        (category === categoryName ? 'text-navy relative font-bold' : '') +
                        ' mx-2 pb-2 text-sm sm:mx-4 sm:pb-6'
                      }
                      key={index}
                      onClick={() => onCategoryChange(categoryName)}
                    >
                      {category}
                      {category === categoryName ? (
                        <div className="border-gold absolute bottom-0 left-0 right-0 h-1 border-b-2" />
                      ) : null}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
          {showTitle && (
            <>
              <p className="text-navy relative text-lg font-semibold">{title}</p>
              <BlogPostsButton
                buttonContainerStyles="mb-3 hidden sm:block"
                buttonLink={buttonLink}
                buttonText={buttonText}
                showFilter={showFilter}
              />
            </>
          )}
        </div>
      )}

      <Swiper
        className={styles['swiperSlideWrapper'] + ' relative max-w-7xl pb-20'}
        speed={1000}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="bg-white mr-1.5 ' + className + '"></span>';
          },
        }}
        // Responsive breakpoints
        breakpoints={{
          1250: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            loop: false,
            centeredSlides: true,
            centeredSlidesBounds: true,
          },
          834: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            centeredSlides: true,
          },
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 20,
            centeredSlides: true,
          },
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 60,
            centeredSlides: true,
          },
        }}
      >
        {filteredPosts &&
          filteredPosts.length > 0 &&
          filteredPosts.map((post, id) => (
            <SwiperSlide key={id} className={styles['swiperSlide'] + ' flex h-auto justify-center px-12'}>
              <CardWithMeta
                viewOption="Kacheln"
                content={post.content}
                full_slug={'/' + post.full_slug}
                first_published_at={post.first_published_at}
                titleMaxLength={82}
                descMaxLength={202}
                isImageVisible={true}
                isMetaVisible={true}
                isCategoryVisible={true}
                isButtonVisible={false}
              />
            </SwiperSlide>
          ))}
        <div className={'swiper-button-next bg-navy-700 text-white ' + styles['swiperButtonNext']}>
          <ChevronRightIcon className="h-6 w-6" />
        </div>
        <div className={'swiper-button-prev bg-navy-700 text-white ' + styles['swiperButtonPrev']}>
          <ChevronLeftIcon className="h-6 w-6" />
        </div>
        <div className={'swiper-pagination ' + styles['swiperPaginationCustom']}></div>
      </Swiper>
      <div className="mt-4 flex justify-center">
        {showsTitleWithoutFilter && buttonLink && (
          <BlogPostsButton
            buttonContainerStyles="z-20 sm:hidden"
            buttonLink={buttonLink}
            buttonText={buttonText}
            showFilter={showFilter}
          />
        )}
        {showsFilterWithoutTitle && buttonLink && (
          <BlogPostsButton
            buttonContainerStyles="z-20"
            buttonLink={buttonLink}
            buttonText={buttonText}
            showFilter={showFilter}
          />
        )}
      </div>
    </div>
  );
};

export const getAllValuesBySpecifiedKey = (data: unknown, targetKey: string): string[] => {
  return Object.keys(data).reduce((keys, key) => {
    if (key === targetKey) {
      keys.push(data[key]);
    }

    if (typeof data[key] === 'object' && data[key] !== null) {
      keys = keys.concat(getAllValuesBySpecifiedKey(data[key], targetKey));
    }
    return keys;
  }, []);
};

export const getFieldValueByFieldName = (blokFields, searchFieldName: string, targetKey: string) => {
  const fieldSettings = blokFields.find((item) => item.name === searchFieldName);
  return fieldSettings?.[targetKey];
};

export const getNumberOfOptionsByFieldName = (blok, searchValue: string): number => {
  const fieldSettings = blok.options.find((item) => item.name === searchValue);
  return fieldSettings?.options.length;
};

import { Button } from '../../../../01_elements/Button';
import Image from 'next/image';
import { ShareButtonComponentProps } from '../socialMediaPlatformConfig';
import { storyBlokLoader } from '../../../../utils/storyblok-loader';

export const FacebookShareButton = ({ ...props }: ShareButtonComponentProps) => {
  const { platformUrl, articleUrl, className, icon } = props;

  const url = platformUrl + articleUrl;

  return (
    <Button
      className={className}
      size="sm"
      variant="ghost"
      isexternal={true}
      data-action="share/whatsapp/share"
      href={url}
    >
      <Image loader={storyBlokLoader} src={icon} width="16" height="16" />
      <span className="ml-3">Facebook</span>
    </Button>
  );
};

import classNames from 'classnames';

export type headingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadlineProps {
  headingType: headingType;
  headlineClassNames?: string;
  headline: string;
  isBold?: boolean;
}

export const headingTypeClassNames: Record<headingType, string> = {
  h1: 'text-3xl leading-[2.5rem] sm:text-5xl sm:leading-[3.75rem]',
  h2: 'text-2xl leading-[2.25rem] sm:text-4xl sm:leading-[3rem]',
  h3: 'text-xl sm:text-3xl sm:leading-[2.5rem]',
  h4: 'text-lg sm:text-2xl',
  h5: 'text-base sm:text-xl',
  h6: 'text-sm sm:text-lg',
};

export const Headline = ({ headingType, headlineClassNames, headline, isBold }: HeadlineProps) => {
  const HeadingType = headingType;

  return (
    <HeadingType
      className={classNames(
        headingTypeClassNames[headingType],
        headlineClassNames,
        isBold ? 'font-headline-semibold' : 'font-headline'
      )}
    >
      {headline}
    </HeadingType>
  );
};

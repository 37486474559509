import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

export const PageBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div {...storyblokEditable(blok)}>
      {blok?.body?.map((nestedBlok: SbBlokData) => (
        <div key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ))}
    </div>
  );
};

import React, { useContext } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { Button, StoryblokIcon, useMediaQuery } from '@dtk/ui-components';
import styles from './ReinforcementLayer.module.scss';
import { AngebotsstreckeContext } from '../../../contexts/AngebotsstreckeContext';
import { BackButton } from '../NavigationButtons';
import { useOfferStore } from '../../../store/OfferStore';

const ReinforcementLayer = ({ blok }) => {
  const { handleNext, setCurrentSectionID } = useContext(AngebotsstreckeContext);
  const { largerSm } = useMediaQuery();

  function goToCheckout() {
    useOfferStore.setState({ isShortForm: true });
    setCurrentSectionID('Checkout');
    window.scrollTo({ top: 0, left: 0 });
  }

  function goToNextSlide() {
    useOfferStore.setState({ isShortForm: false });
    handleNext();
    window.scrollTo({ top: 0, left: 0 });
  }

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="grid">
      <div id="reinforcementLayer">
        <div className="hidden sm:block">
          <BackButton variant="white" size="sm" styles="mb-16" buttonText="Zurück" />
        </div>
        {blok.TitleTop && <h2 className="font-headline-bold text-navy mb-2 text-2xl">{blok.TitleTop}</h2>}
        {blok.SubtitleTop && <p className="text-navy mb-6 font-normal">{blok.SubtitleTop}</p>}
        <Button className="my-4" fullWidth variant="red" onClick={goToNextSlide}>
          {blok.ButtonTextTop}
        </Button>

        {largerSm && (
          <>
            <div className={styles.reinforcementLayer}>
              <span className="bg-coolGray-50 text-gold-gold">oder</span>
            </div>

            {blok.TitleBottom && <h2 className="text-navy font-headline-bold mb-2 text-2xl">{blok.TitleBottom}</h2>}
            {blok.SubtitleBottom && <p className="text-navy mb-10 font-normal">{blok.SubtitleBottom}</p>}
            <hr />
            <div className="text-navy mt-2 mb-10 flex">
              <div className="mr-4 w-8">
                <StoryblokIcon iconSource={blok.Icon.filename} alt="info" />
              </div>
              {blok.InfolineBottom && <p className="text-sm">{blok.InfolineBottom}</p>}
            </div>
          </>
        )}
        <Button variant="cyan" fullWidth onClick={goToCheckout}>
          {blok.ButtonTextBottom}
        </Button>
      </div>
      <div className="relative">
        <div className="fixed bottom-0 left-0 z-50 w-full bg-white py-3 shadow-inner sm:hidden">
          <div className="-ml-5 flex w-full">
            <BackButton size="lg" variant="ghost" buttonText="zurück" />
            <Button onClick={goToCheckout}>Angebot anfordern</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReinforcementLayer;

import { useAppConfig } from '@dtk/config';
import axios from 'axios';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

interface LayoutProps {
  children?: React.ReactNode;
}
const queryClient = new QueryClient();

export default function Layout({ children }: LayoutProps) {
  const configStore = useAppConfig();

  useEffect(() => {
    configStore.getLatest();
  }, [configStore]);

  axios.defaults.baseURL = configStore?.config?.API_BASE;

  return (
    <div className="font-body flex min-h-screen flex-col">
      <main className="flex-grow">
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </main>
    </div>
  );
}

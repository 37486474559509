import { Field, useFormikContext } from 'formik';
import React from 'react';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import styles from '../InputField.module.scss';
import { useOfferStore } from '../../../store/OfferStore';

export const InputMultipleSelectBlok = ({ blok }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleValueChange = (event) => {
    const { checked, name } = event.target;
    const keys = useOfferStore.getState().data;

    if (checked) {
      const allCheckedOptions = values?.[blok.name];
      const checkedFieldsToAdd = [...allCheckedOptions, name];
      setFieldValue(blok.name, checkedFieldsToAdd);
      useOfferStore.setState({ data: keys.set(blok.name, checkedFieldsToAdd) });
    } else {
      const uncheckedFieldsToRemove = values?.[blok.name].filter((fieldName) => fieldName !== name);
      setFieldValue(blok.name, uncheckedFieldsToRemove);
      useOfferStore.setState({
        data: keys.set(blok.name, uncheckedFieldsToRemove),
      });
    }
    (document.activeElement as HTMLElement).blur();
  };

  return (
    <div
      {...storyblokEditable(blok)}
      data-test="input-multiple-select"
      className={`grid grid-flow-row col-span-${blok.width}`}
    >
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
        {blok.options.map((option) => (
          <div key={option._uid} className={`grid grid-flow-row items-center col-span-${option.width}`}>
            <Field
              parentid={blok.id}
              id={option.id}
              className={`${styles['checkboxInput']}`}
              type="checkbox"
              name={option.name}
              checked={values?.[blok.name].includes(option.name)}
              onChange={handleValueChange}
            />
            <label className="font-body-medium" htmlFor={option.id}>
              {option.label}
            </label>
          </div>
        ))}
      </div>

      {blok.options?.length > 0 &&
        blok.options.map(
          (nestedBlok: SbBlokData) =>
            values?.[blok.name] === nestedBlok.name && <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
        )}
    </div>
  );
};

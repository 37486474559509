import { storyblokEditable } from '@storyblok/react';
import { LeadGenerationForm } from '@dtk/public-website-ui-components';

const LeadGenerationFormBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="lead-genration-form">
    <LeadGenerationForm {...blok} />
  </div>
);

export default LeadGenerationFormBlok;

/**
 * Include a usercentrics tracking script.
 *
 * See https://docs.usercentrics.com/#/web-cmp-v1?id=features
 * @param props
 * @returns
 */
import Script from 'next/script';

export const DataLayerScript = (props: DataLayerScriptProps) => {
  return (
    <Script
      type="application/javascript"
      src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      id="usercentrics-cmp"
      data-settings-id={props.containerId}
      async
      strategy="beforeInteractive"
    />
  );
};

export interface DataLayerScriptProps {
  containerId: string;
}

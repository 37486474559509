export interface PlaceholderProbs {
  title?: string;
}

export const Placeholder = ({ title = '' }: PlaceholderProbs) => {
  return (
    <div className=" bg-green-400 py-8 ">
      <h1 className="text-center">{title !== '' ? '"' + title + '"' : 'Component'} Placeholder</h1>
      <h2 className="text-center">Fill me up with data! &#10132;</h2>
    </div>
  );
};

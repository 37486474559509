import { PartnerOfferFormProps } from '../../types';
import { PartnerOfferFormSection } from '../PartnerOfferFormSection';

export const PartnerOfferDefaultForm = ({
  externalFormData,
  validationSchema,
  customerData,
  children,
}: PartnerOfferFormProps) => (
  <PartnerOfferFormSection
    externalFormData={externalFormData}
    validationSchema={validationSchema}
    customerData={customerData}
    children={children}
  />
);

import { Breadcrumbs } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const BreadcrumbsBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="breadcrumbs">
      <Breadcrumbs {...blok}></Breadcrumbs>
    </div>
  );
};

export default BreadcrumbsBlok;

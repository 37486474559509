import { useState } from 'react';
import { SlideOver, NavigationContactMenuItemsComponent, NavigationContactMenuItemProps } from '@dtk/ui-components';
import { NavigationIconWithTextMobile } from './NavigationIconWithText';

export interface NavigationContactMenuProps {
  title: string;
  description: string;
  contactItems: NavigationContactMenuItemProps[];
}

export const NavigationContactMenuComponent = ({ contactItems, title, description }: NavigationContactMenuProps) => {
  const [contactMenuOpen, setContactMenuOpen] = useState(false);

  if (contactItems?.length > 0 && title && description) {
    return (
      <div
        className="navi:hidden flex cursor-pointer items-center justify-center rounded-md p-2 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        onClick={() => setContactMenuOpen(true)}
      >
        <SlideOver
          isOpen={contactMenuOpen}
          setOpen={setContactMenuOpen}
          stylingOptions={{
            mainBgColor: 'bg-navy-800',
            showCloseButton: false,
            headerBgColor: 'bg-navy-800',
            xButtonColor: 'text-white',
            slidePaddingLeft: 'pl-10',
            headlineProps: 'pl-2 sm:pl-0 text-2xl text-white',
          }}
          headline={title}
        >
          <NavigationContactMenuItemsComponent {...{ title, description, contactItems }} />
        </SlideOver>
        <NavigationIconWithTextMobile
          iconSource="https://a.storyblok.com/f/148087/20x20/dcb7cf96ad/phone.svg"
          iconText="Kontakt"
        />
      </div>
    );
  }
  return null;
};

import { MandatoryFieldsMarker, SelectButton, SelectButtonGroupOptionProps } from '@dtk/ui-components';

interface MeinAlarm24FormProps {
  selectButtonOptions: SelectButtonGroupOptionProps[];
}

export const MeinAlarm24Form = ({ selectButtonOptions }: MeinAlarm24FormProps) => {
  return (
    <>
      <div className="col-span-6 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 lg:flex-col lg:space-y-4 lg:space-x-0">
        {selectButtonOptions.map((option) => (
          <SelectButton name={option.name} buttonValue={option.value} numberOfOptions={selectButtonOptions.length} />
        ))}
      </div>
      <MandatoryFieldsMarker />
    </>
  );
};

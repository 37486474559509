import { BlogHeader } from '@dtk/public-website-ui-components';
// import {Container} from '@dtk/ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const BlogHeaderBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="blog-header">
    <BlogHeader
      imageUrl={''}
      headline={''}
      personName={''}
      personPosition={''}
      personEmail={''}
      buttonText={''}
      buttonLink={''}
      firstLine={''}
      secondLine={''}
      thirdLine={''}
      lastLine={''}
    ></BlogHeader>
  </div>
);

export default BlogHeaderBlok;

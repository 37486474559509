import { useFormikContext } from 'formik';
import {
  DropdownField,
  balconySizeOptions,
  energyEfficiencyClassOptions,
  energyEfficiencyHouseStandardOptions,
  heatingOptions,
  outdoorLivingAreaOptions,
  roofCoveringOptions,
  windowOptions,
  yesOrNoOptions,
} from '@dtk/ui-components';
import { LeadTransmissionValues } from './types';

export const BuildingStandardsForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <div className="flex flex-row items-center px-1">
        <div className="text-navy basis-5/12 text-sm uppercase tracking-[.2em]">Gebäudestandard</div>
        <span className="border-gold-gold basis-7/12 border-b border-solid" />
      </div>

      <DropdownField
        data-testid="multipleBathrooms"
        name="multipleBathrooms"
        label="Mehrere Badezimmer vorhanden?"
        options={yesOrNoOptions}
      />

      <DropdownField
        data-testid="guestToilet"
        name="guestToilet"
        label="separates Gäste-WC vorhanden?"
        options={yesOrNoOptions}
      />

      <DropdownField
        data-testid="cablesPlaster"
        name="cablesPlaster"
        label="Leitungen überwiegend auf Putz?"
        options={yesOrNoOptions}
      />

      <DropdownField
        data-testid="outerWallsInsulated"
        name="outerWallsInsulated"
        label="Außenwände überwiegend gedämmt?"
        options={yesOrNoOptions}
      />

      <DropdownField
        data-testid="chimneyOrTiledStove"
        name="chimneyOrTiledStove"
        label="Offener Kamin/Kachelofen vorhanden?"
        options={yesOrNoOptions}
      />

      <DropdownField data-testid="sauna" name="sauna" label="Sauna vorhanden?" options={yesOrNoOptions} />

      <DropdownField data-testid="heating" name="heating" label="Heizung" options={heatingOptions} />

      <DropdownField
        data-testid="roofCovering"
        name="roofCovering"
        label="Dacheindeckung"
        options={roofCoveringOptions}
      />

      <DropdownField data-testid="window" name="window" label="Fenster" options={windowOptions} />

      <DropdownField
        data-testid="outdoorLivingArea"
        name="outdoorLivingArea"
        label="Außenwohnbereiche"
        options={outdoorLivingAreaOptions}
      />

      <DropdownField
        data-testid="balcony"
        name="balcony"
        label="Bitte Balkon-/Terassengröße auswählen:"
        options={balconySizeOptions}
        disabled={(values as LeadTransmissionValues).outdoorLivingArea !== 'BalkonOderTerrasse'}
      />

      <DropdownField
        data-testid="energyEfficiencyClass"
        name="energyEfficiencyClass"
        label="Energieeffizienzklasse"
        options={energyEfficiencyClassOptions}
      />

      <DropdownField
        data-testid="energyEfficiencyHouseStandard"
        name="energyEfficiencyHouseStandard"
        label="Energieeffizienzhausstandard"
        options={energyEfficiencyHouseStandardOptions}
      />
    </>
  );
};

import { BackgroundThemeVariants, DarkVerticalBannerProps, ImageProps } from '@dtk/ui-components';

export interface PartnerBannerProps {
  _uid: string;
  partnerImage: ImageProps;
  headlineText: string;
  headlineTextShort: string;
  theme?: BackgroundThemeVariants;
  partnerName: string;
  partnerDescription: string;
  discountText: string;
  discount: string;
  offerButtonText: string;
  offerButtonLink: string;
  topBannerHeadlineText?: string;
  isTopBanner?: boolean;
  priority: number;
  isSmall: boolean;
  hasSquareCorners?: boolean;
}

export interface TopPartnerBannerProps {
  _uid: string;
  reference?: {
    content: {
      global: PartnerBannerProps[];
    };
  };
}

export type TeaserBannerProps = DarkVerticalBannerProps;

type BannerTypes = PartnerBannerProps | TopPartnerBannerProps | TeaserBannerProps;

export const isPartnerBannerComponent = (bannerComponent: BannerTypes): bannerComponent is PartnerBannerProps => {
  return ('partnerImage' || 'partnerName' || 'partnerDescription') in bannerComponent;
};

import { useFormikContext } from 'formik';
import {
  existingLoadsOptions,
  DropdownField,
  InputField,
  InputFieldWithAddOn,
  salutationOptions,
  titleOptions,
  validationMessages,
} from '@dtk/ui-components';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import { LeadTransmissionValues } from './types';

export const PersonalDataForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleEstateAgentIdChange = (
    key: string,
    fieldValue: string,
    setFieldValue: {
      (field: string, value: string, shouldValidate?: boolean | undefined): void;
    }
  ) => {
    const hyphenTrimmedID = fieldValue.replace(/-/g, '');
    if (hyphenTrimmedID.length % 4 === 1 && key === 'Backspace') {
      setFieldValue('estateAgentID', fieldValue.substring(0, fieldValue.length - 1));
    } else if (hyphenTrimmedID.length > 0 && hyphenTrimmedID.length % 4 === 0 && key !== 'Backspace') {
      setFieldValue('estateAgentID', fieldValue.concat('-'));
    } else {
      setFieldValue('estateAgentID', fieldValue);
    }
  };

  return (
    <>
      <div className="flex flex-row items-center px-1">
        <div className="xs:basis-7/12 text-navy basis-9/12 text-sm uppercase tracking-[.2em]">
          Personenbezogene Daten
        </div>
        <span className="border-gold-gold xs:basis-5/12 xs:ml-0 ml-4 basis-3/12 border-b border-solid" />
      </div>

      <InputField
        id="estateAgentID"
        name="estateAgentID"
        label="Makler-ID"
        type="text"
        infoMessage={validationMessages.info.estateAgentID}
        isMandatory
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onKeyDown={(event: any) => {
          const value = event.currentTarget.value;
          handleEstateAgentIdChange(event.key, value, setFieldValue);
        }}
      />

      <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0">
        <div className="basis-1/2">
          <DropdownField
            data-testid="salutation"
            name="salutation"
            label="Anrede"
            options={salutationOptions}
            isMandatory
          />
        </div>

        <div className="basis-1/2">
          <DropdownField data-testid="userTitle" name="userTitle" label="Titel" options={titleOptions} />
        </div>
      </div>

      <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0">
        <InputField
          containerClassNames="basis-1/2"
          id="firstName"
          name="firstName"
          label="Vorname"
          type="text"
          infoMessage={validationMessages.info.firstName}
          isMandatory
        />
        <InputField
          containerClassNames="basis-1/2"
          id="lastName"
          name="lastName"
          label="Nachname"
          type="text"
          infoMessage={validationMessages.info.lastName}
          isMandatory
        />
      </div>

      <InputField
        id="email"
        name="email"
        label="E-Mail-Adresse"
        type="text"
        placeholder="max@mustermail.de"
        icon={MailIcon}
        infoMessage={validationMessages.info.email}
        isMandatory
      />

      <InputField
        id="phone"
        name="phone"
        label="Telefonnummer"
        type="text"
        icon={PhoneIcon}
        infoMessage={validationMessages.info.phone}
        isMandatory
      />

      <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0">
        <InputField
          containerClassNames="basis-1/2"
          id="street"
          name="street"
          label="Straße"
          type="text"
          infoMessage={validationMessages.info.street}
          isMandatory
        />
        <InputField
          containerClassNames="basis-1/2"
          id="houseNumber"
          name="houseNumber"
          label="Nr."
          type="text"
          infoMessage={validationMessages.info.houseNr}
          isMandatory
        />
      </div>

      <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-6 sm:space-y-0">
        <InputField
          containerClassNames="basis-2/4"
          id="zipCode"
          name="zipCode"
          label="PLZ"
          type="text"
          infoMessage={validationMessages.info.zipCode}
          isMandatory
        />
        <InputField
          containerClassNames="basis-2/4"
          id="city"
          name="city"
          label="Ort"
          type="text"
          infoMessage={validationMessages.info.city}
          isMandatory
        />
      </div>

      <InputFieldWithAddOn
        suffix="€"
        id="realEstateValue"
        name="realEstateValue"
        label="Geschätzter Immobilienwert"
        type="text"
        infoMessage={validationMessages.info.realEstateValue}
      />

      <InputFieldWithAddOn
        suffix="€"
        id="requestedPayout"
        name="requestedPayout"
        label="Wunschauszahlung"
        type="text"
        infoMessage={validationMessages.info.requestedPayout}
        isMandatory
      />

      <DropdownField
        data-testid="existingLoads"
        name="existingLoads"
        label="Ist die Immobilie belastet?"
        options={existingLoadsOptions}
        isMandatory
      />

      <InputFieldWithAddOn
        suffix="€"
        id="existingLoadsAmount"
        name="existingLoadsAmount"
        label="Höhe der Lasten"
        type="number"
        infoMessage={validationMessages.info.existingLoadsAmount}
        disabled={(values as LeadTransmissionValues).existingLoads !== 'Ja'}
        isMandatory={(values as LeadTransmissionValues).existingLoads === 'Ja'}
      />
    </>
  );
};

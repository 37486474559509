import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { StoryblokIcon } from './StoryblokIcon';
import {
  BackgroundThemeVariants,
  getBusinessUnitId,
  getBusinessUnitLink,
  isTrustPilotConsentConfirmed,
  themesForBackgroundColor,
} from '../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export const TrustPilotBox = (props: {
  containerClassNames?: string;
  businessUnitName?: string;
  backgroundColor?: BackgroundThemeVariants;
  reviewStyle: string;
  reviewTag?: string;
  reviewStars?: string;
  height?: string;
  width: string;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    window?.Trustpilot?.loadFromElement(ref.current, true);
  }, [
    props.businessUnitName,
    props.backgroundColor,
    props.reviewStyle,
    props.reviewTag,
    props.reviewStars,
    props.width,
    props.height,
  ]);

  return (
    <div className={props.backgroundColor ? `bg-${props.backgroundColor}` : 'bg-white'}>
      <div
        className={classNames(
          'trustpilot-widget trustpilot-position',
          props.containerClassNames,
          props.backgroundColor && `bg-${props.backgroundColor}`,
          isTrustPilotConsentConfirmed() ? 'block' : 'hidden'
        )}
        ref={ref}
        data-locale="de-DE"
        data-template-id={props.reviewStyle}
        data-tags={props.reviewTag}
        data-businessunit-id={getBusinessUnitId(props.businessUnitName || 'dtk')}
        data-stars={props.reviewStars?.toString() || '4,5'}
        data-review-languages="de"
        data-style-height={props.height}
        data-style-width={props.width}
        data-theme={
          themesForBackgroundColor.darkBackgroundColors.includes(props.backgroundColor ?? '') ? 'dark' : 'light'
        }
      >
        <a href={getBusinessUnitLink(props.businessUnitName || 'dtk')} target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>

      <div
        className={classNames(
          'trustpilot-placeholder',
          props.containerClassNames,
          isTrustPilotConsentConfirmed() ? 'hidden' : 'block'
        )}
      >
        <StoryblokIcon
          iconSource={'https://a.storyblok.com/f/148087/1133x554/19b4e695d7/trustpilot_logo_stars-weiss.jpg'}
          alt="trustpilot-placeholder-bg-image"
          width={90}
        />
      </div>
    </div>
  );
};

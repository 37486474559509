import { CheckBox, getHighlightedText } from '@dtk/ui-components';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { useFormikContext } from 'formik';
import React from 'react';
import { getAllValuesBySpecifiedKey } from '../utils';
import { useOfferStore } from '../../../store/OfferStore';

export const InputCheckboxBlok = ({ blok }) => {
  const { values, setFieldTouched } = useFormikContext();

  const handleValueChange = (e) => {
    const { checked } = e.target;
    const keys = useOfferStore.getState().data;
    useOfferStore.setState({ data: keys.set(blok.name, checked ? true : false) });

    if (blok.inputElements?.length > 0) {
      const allCurrentFieldNames: string[] = getAllValuesBySpecifiedKey(blok.inputElements, 'name');
      allCurrentFieldNames.forEach((fieldName) => {
        setFieldTouched(fieldName, false);
      });
    }
  };

  const innerHtml = {
    __html: blok.highlightedLinkText
      ? getHighlightedText(
          blok.label,
          blok.highlightedLinkText,
          'cyan-700',
          { fontFamily: 'font-body', isBold: true },
          blok.link
        )
      : blok.label,
  };

  return (
    <div {...storyblokEditable(blok)} className="col-span-12 grid gap-y-6" data-test="input-checkbox">
      <CheckBox name={blok.name} onChange={handleValueChange}>
        <label className="text-sm font-normal" htmlFor={blok.name} dangerouslySetInnerHTML={innerHtml} />
      </CheckBox>
      {blok.inputElements?.length > 0 && values?.[blok.name] === true && (
        <div className="grid grid-cols-12 gap-x-4">
          {blok.inputElements.map((nestedBlok: SbBlokData) => (
            <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
          ))}
        </div>
      )}
    </div>
  );
};

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { BlogPostPage } from '@dtk/public-website-ui-components';
import React from 'react';

const LexikonPostBlok = ({ blok, story }) => (
  <div {...storyblokEditable(blok)} className="blog-single-page" key={blok._uid} data-test="post">
    {blok.headerBlocks.map((nestedBlok) =>
      nestedBlok.reference.slug === 'navigation' ? (
        <div key={nestedBlok._uid} className="sticky top-0 z-50">
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ) : (
        <div key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </div>
      )
    )}
    <div className="mt-10">
      <BlogPostPage story={story} content={blok} />
      {blok.blocks.map((nestedBlok) => (
        <div key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ))}
    </div>
  </div>
);

export default LexikonPostBlok;

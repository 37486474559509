import Link from 'next/link';

export const CertificationIcon = (props: {
  styles?: string;
  showBanner?: string;
  iconImageFilename: string;
  iconImageText?: string;
  iconLink?: string;
}) => {
  return props.iconLink && props.showBanner === 'certificationIcon' ? (
    <div className={props.styles}>
      <Link href={props.iconLink} passHref>
        {props.iconImageFilename && (
          <img
            className="xs:h-32 xs:w-32 h-24 w-24 object-scale-down md:h-40 md:w-40 xl:h-52 xl:w-52"
            src={props.iconImageFilename}
            alt={props.iconImageText}
          />
        )}
      </Link>
    </div>
  ) : null;
};

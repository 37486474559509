import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

export const StandardCardContainerBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="standard-card-container">
      <div className={blok.backgroundTheme || 'bg-white'}>
        <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14">
          {blok.overline && (
            <p className="text-gold-gold mb-10 text-center text-lg uppercase tracking-[.2em]">{blok.overline}</p>
          )}
          <div className="grid grid-cols-4 gap-5 sm:grid-cols-8 lg:grid-cols-12">
            {blok.allCards &&
              blok.allCards.map((nestedBlok: SbBlokData, index: number) => (
                <StoryblokComponent
                  key={nestedBlok._uid}
                  blok={nestedBlok}
                  index={index}
                  numberOfCards={blok.allCards.length}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import { TrustPilotBox, getTrustPilotWidgetStyles } from '@dtk/ui-components';

import { isSVG } from '../utils/utils';

export interface CertImage {
  filename: string;
  alt: string;
}

export interface Certificate {
  Image: CertImage;
  Title: string;
  Subtitle: string;
  Link: string;
}

interface TextElements {
  title: string;
  subtitle: string;
}

export interface Certificates {
  certificates: Certificate[];
}

const TitleAndSubtitle = ({ title, subtitle }: TextElements) => (
  <>
    <p className="text-navy font-body-semibold mt-3 hidden text-center text-xl sm:block">{title}</p>
    <p className="text-navy hidden text-center text-lg font-normal sm:block">{subtitle}</p>
  </>
);

export function Certificate({ certificates }: Certificates) {
  return (
    <div className="mx-auto max-w-screen-xl px-4">
      <div className="-mx-4 flex flex-wrap">
        {certificates.map((certificate, index) =>
          certificate.Title !== 'Trustpilot' ? (
            <a
              href={certificate.Link}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              className="mb-5 flex w-1/2 flex-col p-1 lg:w-1/3"
            >
              <span className="bg-coolGray-50 relative block h-full p-2 md:h-auto md:p-8">
                <img
                  alt={certificate.Image.alt}
                  src={
                    isSVG(certificate.Image?.filename)
                      ? certificate.Image?.filename
                      : certificate.Image?.filename + '/m/0x160'
                  }
                  className="mx-auto max-h-20 object-scale-down"
                  width="400px"
                  height="80px"
                  loading="lazy"
                  {...{ fetchpriority: 'low' }}
                />
              </span>
              <TitleAndSubtitle title={certificate.Title} subtitle={certificate.Subtitle} />
            </a>
          ) : (
            <div key={index} className="mb-5 flex w-1/2 flex-col p-1 lg:w-1/3">
              <span className="bg-coolGray-50 block h-full p-2 md:h-auto md:p-8">
                <span className="mx-auto table h-20 align-middle">
                  <TrustPilotBox width={'110px'} height={'80px'} reviewStyle={getTrustPilotWidgetStyles().mini} />
                </span>
              </span>
              <TitleAndSubtitle title={certificate.Title} subtitle={certificate.Subtitle} />
            </div>
          )
        )}
      </div>
    </div>
  );
}

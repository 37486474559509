import classNames from 'classnames';
import { FullHeightImage } from './FullHeightImage';
import { Button, ButtonVariantsRedesign, Headline, StoryblokIcon, headingType } from '../../01_elements';
import { useMediaQuery } from '../../hooks';
import {
  BackgroundThemeVariants,
  ImageProps,
  RichtextContent,
  getAlternativePath,
  themesForBackgroundColor,
} from '../../utils';

export interface TwoColumnSectionProps {
  theme?: BackgroundThemeVariants;
  image: ImageProps;
  imageLeft: boolean;
  icon?: ImageProps;
  overline?: string;
  headingType?: headingType;
  title: string;
  content: unknown;
  primaryButtonColor?: ButtonVariantsRedesign;
  buttonText: string;
  buttonLink: string;
  alternativePrimaryButtonColor: boolean;
  secondaryButtonColor?: ButtonVariantsRedesign;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
}

export const TwoColumnSection = ({ ...props }: TwoColumnSectionProps) => {
  const {
    theme,
    image,
    imageLeft,
    icon,
    overline,
    headingType,
    primaryButtonColor,
    buttonText,
    buttonLink,
    secondaryButtonColor,
    secondaryButtonText,
    secondaryButtonLink,
  } = props;
  const { smallerMd, largerMd, largerLg, smallerSm } = useMediaQuery();

  const chosenTheme = theme || 'white';
  const chosenHeadingType = headingType || 'h2';
  const chosenPrimaryButtonColor =
    primaryButtonColor ||
    (props.alternativePrimaryButtonColor && (chosenTheme === 'gold-sand' || chosenTheme === 'gold-creme')
      ? 'red'
      : 'cyan');

  const primaryRedirectPath = getAlternativePath({
    originalLink: buttonLink,
    condition: smallerSm,
  });
  const secondaryRedirectPath = secondaryButtonLink
    ? getAlternativePath({
        originalLink: secondaryButtonLink,
        condition: smallerSm,
      })
    : '';

  return (
    <div className={`mx-auto md:relative bg-${chosenTheme}`}>
      {smallerMd && <FullHeightImage alignedLeft={imageLeft} image={image} />}
      <div className="mx-auto max-w-7xl">
        <div className={classNames(imageLeft ? 'md:ml-auto' : 'md:mr-auto', 'md:w-1/2')}>
          <div className="grid grid-cols-6">
            <div
              className={classNames(
                themesForBackgroundColor.darkBackgroundColors.includes(chosenTheme) ? 'text-white' : 'text-navy',
                'col-span-12 py-10 px-6 md:py-14 lg:col-span-6 lg:px-8 xl:col-span-6'
              )}
            >
              {icon?.filename && (
                <StoryblokIcon className="mb-6" iconSource={icon.filename} alt={icon.alt} width={40} height={40} />
              )}
              {overline && (
                <p
                  className={classNames(
                    `text-${themesForBackgroundColor.overlineColor[chosenTheme]}`,
                    'mb-2 text-sm uppercase leading-[2rem] tracking-[.2em]'
                  )}
                >
                  {overline}
                </p>
              )}
              <Headline
                headingType={chosenHeadingType}
                headlineClassNames="font-headline mb-8"
                headline={props.title}
                isBold={true}
              />
              <RichtextContent content={props.content} />
              <div className="flex flex-col space-y-4 pt-2 sm:w-full sm:flex-row sm:space-y-0 sm:space-x-6">
                {buttonText && (
                  <Button variant={chosenPrimaryButtonColor} size={largerLg ? 'lg' : 'base'} href={primaryRedirectPath}>
                    {buttonText}
                  </Button>
                )}
                {secondaryButtonText && (
                  <Button
                    variant={secondaryButtonColor || 'white'}
                    size={largerLg ? 'lg' : 'base'}
                    href={secondaryRedirectPath}
                  >
                    {secondaryButtonText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {largerMd && <FullHeightImage alignedLeft={imageLeft} image={image} />}
    </div>
  );
};

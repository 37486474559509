import { Placeholder } from './Placeholder';
import classNames from 'classnames';
import parse from 'html-react-parser';

export type HTMLParserVariation = 'center' | 'default';

export interface HTMLParserProbs {
  htmlcode: string;
  variation?: HTMLParserVariation;
  rawStyle?: boolean;
}

function getContent(htmlcode: string, variation: HTMLParserVariation, rawStyle: boolean): JSX.Element {
  return (
    <div className={classNames(variation === 'center' && 'flex justify-center', !rawStyle && 'richtext-editor-styles')}>
      {parse(htmlcode)}
    </div>
  );
}

export const HTMLParser = ({ htmlcode = '', variation = 'default', rawStyle = false }: HTMLParserProbs) => {
  if (htmlcode === '') return <Placeholder title="HTML Widget" />;
  if (rawStyle) return <>{parse(htmlcode)}</>;

  return getContent(htmlcode, variation, rawStyle);
};

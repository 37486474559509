import classNames from 'classnames';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';

import { ADDRESS_FIELD_NAMES, InputField } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';
import { useAppConfig } from '@dtk/config';
import { useOfferStore } from '../../../store/OfferStore';

export const InputAdresseWithAutoCompleteBlok = ({ blok }) => {
  const UC_GOOGLE_SERVICE_ID = 'wgTnPe7i1';
  const uc_settings = JSON.parse(localStorage.getItem('uc_settings'));
  const googleService = uc_settings && uc_settings.services.find((el) => el.id === UC_GOOGLE_SERVICE_ID);
  const isGoogleConsentAccepted = googleService?.status === true;

  const keys = useOfferStore.getState().data;

  const { values, setFieldValue } = useFormikContext();
  const [useAutoComplete, setUseAutoComplete] = useState(isGoogleConsentAccepted);
  const { config } = useAppConfig();

  useEffect(() => {
    setUseAutoComplete(isGoogleConsentAccepted);

    const queryParams = new URLSearchParams(window.location.search);
    const zipCode = queryParams.get('zip-code');

    if (zipCode) {
      setFieldValue('zipCode', zipCode);
      const keys = useOfferStore.getState().data;
      useOfferStore.setState({ data: keys.set('zipCode', zipCode) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGoogleConsentAccepted]);

  const autocompleteRef = useRef<HTMLInputElement>(null);

  function handleChangeAutocomplete(event) {
    function getAddressValue(name: string) {
      return event.address_components.find((item) => item.types[0] === name)?.long_name || '';
    }

    const street = getAddressValue('route');
    const houseNumber = getAddressValue('street_number');
    const zip = getAddressValue('postal_code');
    const city = getAddressValue('locality');

    if (street) {
      setFieldValue('street', street);
      useOfferStore.setState({ data: keys.set('street', street) });
    }

    if (houseNumber) {
      setFieldValue('houseNumber', houseNumber);
      useOfferStore.setState({ data: keys.set('houseNumber', houseNumber) });
    }

    if (zip) {
      setFieldValue('zipCode', zip);
      useOfferStore.setState({ data: keys.set('zipCode', zip) });
    }

    if (city) {
      setFieldValue('city', city);
      useOfferStore.setState({ data: keys.set('city', city) });
    }
  }

  const handleValueChange = () => {
    const keys = useOfferStore.getState().data;
    ADDRESS_FIELD_NAMES.forEach((fieldName) => {
      if (values[fieldName]) {
        useOfferStore.setState({ data: keys.set(fieldName, values[fieldName]) });
      }
    });
  };

  return (
    <div
      key={blok._uid}
      {...storyblokEditable(blok)}
      data-test="react-google-autocomplete"
      className="col-span-12 grid grid-flow-row auto-rows-min grid-cols-12 gap-x-6 gap-y-4"
    >
      {useAutoComplete && (
        <Autocomplete
          ref={autocompleteRef}
          apiKey={config['GOOGLE_PLACES_API_KEY']}
          onPlaceSelected={handleChangeAutocomplete}
          options={{
            types: ['address'],
            componentRestrictions: { country: 'de' },
            fields: ['address_components'],
          }}
          language="de"
          className={classNames(
            'border-coolGray-300 col-span-12 mb-2 grid w-full grid-flow-row auto-rows-min rounded-sm border py-2 px-4'
          )}
        />
      )}
      <>
        <div className="col-span-12 mb-2 grid grid-flow-row auto-rows-min sm:col-span-8">
          <InputField id="street" name="street" label="Straße" type="text" onBlur={handleValueChange} />
        </div>

        <div className="col-span-12 mb-2 grid grid-flow-row auto-rows-min sm:col-span-4">
          <InputField id="houseNumber" name="houseNumber" label="Nr." type="text" onBlur={handleValueChange} />
        </div>

        <div className="col-span-12 mb-2 grid grid-flow-row auto-rows-min sm:col-span-4">
          <InputField id="zipCode" name="zipCode" label="PLZ" type="text" onBlur={handleValueChange} />
        </div>

        <div className="col-span-12 mb-2 grid grid-flow-row auto-rows-min sm:col-span-8">
          <InputField id="city" name="city" label="Ort" type="text" onBlur={handleValueChange} />
        </div>
      </>
    </div>
  );
};

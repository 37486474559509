import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { Button } from '../../01_elements';

export interface OverviewCardProps {
  showTitleAndButton: boolean;
  title: string;
  buttonText: string;
  buttonLink: string;
  cards?: SbBlokData[];
}

interface ButtonProps {
  classNames: string;
  buttonText: string;
  buttonLink: string;
}

const CardButton = ({ classNames, buttonText, buttonLink }: ButtonProps) => (
  <div className={classNames}>
    <Button variant="white" href={buttonLink}>
      {buttonText}
    </Button>
  </div>
);

export function OverviewCardSection({ showTitleAndButton, title, buttonText, buttonLink, cards }: OverviewCardProps) {
  return (
    <div className="mx-auto max-w-7xl px-4 pt-16 pb-24 sm:px-6 lg:px-8">
      {showTitleAndButton && (
        <div className="border-cold-gray-300 mx-auto mb-6 max-w-7xl items-center justify-between border-b sm:mb-9 sm:grid sm:grid-cols-12">
          <p className="text-navy-navy text-lg font-semibold sm:col-span-7 md:col-span-8 lg:col-span-9">{title}</p>
          {buttonText && buttonLink && (
            <CardButton
              classNames="mb-3 hidden sm:block sm:col-span-5 sm:justify-self-end md:col-span-4 lg:col-span-3"
              buttonText={buttonText}
              buttonLink={buttonLink}
            />
          )}
        </div>
      )}
      <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {cards && cards.map((nestedBlok: SbBlokData) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
        {buttonText && buttonLink && (
          <CardButton classNames="flex justify-center sm:hidden" buttonText={buttonText} buttonLink={buttonLink} />
        )}
      </div>
    </div>
  );
}

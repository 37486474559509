import { RechnerGesamtauszahlung } from '@dtk/public-website-ui-components';
import { storyblokEditable } from '@storyblok/react';

const RechnerGesamtauszahlungBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="rechner-gesamtauszahlung">
    <RechnerGesamtauszahlung {...blok} />
  </div>
);

export default RechnerGesamtauszahlungBlok;

import { Badge } from '../../../01_elements/Badge';
import { CardMeta } from '../CardMeta';
import { ChevronRightIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import classNames from 'classnames';
import { substringText } from '../../../utils/substringText';

export type ImagePositionPreset = 'top' | 'left' | 'right';

export interface CardWithMetaProps {
  viewOption: string;
  content: PostProps;
  full_slug?: string;
  slug?: string;
  first_published_at: string;
  titleMaxLength: number;
  descMaxLength: number;
  isImageVisible?: boolean;
  isCategoryVisible?: boolean;
  isMetaVisible?: boolean;
  isButtonVisible?: boolean;
  imagePosition?: ImagePositionPreset;
  style?: string;
  className?: string;
  children?: React.ReactNode;
}

export interface PostProps {
  _uid: string;
  name: string;
  title: string;
  author: AuthorProps;
  categories: KategorienProps[];
  postImageUrl: string;
  description?: string;
  featured_image: PostImageProps;
  excerpt?: string;
  reading_time?: string;
  blog_slug?: string;
}

interface PostImageProps {
  filename: string;
  alt: string;
}

interface KategorienProps {
  name: string;
  full_slug: string;
}

interface AuthorProps {
  content: AuthorContentProps;
  full_slug: string;
}

interface AuthorContentProps {
  author_email: string;
  author_image: AuthorImageProps;
  author_name: string;
  author_position: string;
  authorSlug: string;
}

interface AuthorImageProps {
  filename: string;
}

const ImagePosition: Record<ImagePositionPreset, string> = {
  top: 'flex flex-col h-auto',
  left: 'flex-row',
  right: 'flex-row-reverse',
};

export const CardWithMeta = ({
  isImageVisible,
  isCategoryVisible,
  isMetaVisible,
  isButtonVisible,
  viewOption,
  imagePosition = 'top',
  className,
  ...restProps
}: CardWithMetaProps) => {
  const { content, titleMaxLength, descMaxLength, full_slug, first_published_at } = restProps;
  const { title, author, categories, featured_image, excerpt, reading_time } = content;
  // Needs Fix B: WEB-672 remove default Author
  const defaultAuthorImage = {
    filename: 'https://a.storyblok.com/f/148087/40x40/bffb345878/microsoftteams-image-46.png',
  };
  const defaultAuthor = {
    author_image: defaultAuthorImage,
    author_name: 'Deutsche Teilkauf',
    author_position: 'Redakteur',
  };
  const { author_image, author_name, author_position } = author.content || defaultAuthor;

  const cardClassName = classNames(
    'flex w-full rounded-sm shadow-lg overflow-hidden',
    ImagePosition[imagePosition],
    className
  );

  return (
    <div className="flex justify-center">
      <div className={cardClassName}>
        {isImageVisible && featured_image?.filename && (
          <div className="flex-shrink-1">
            <img
              width={374}
              height={184}
              className={classNames(viewOption === 'Liste' ? 'h-40' : 'w-full', 'object-cover')}
              src={`${featured_image.filename}/m/374x184/smart`}
              alt={featured_image.alt}
              loading="lazy"
              {...{ fetchpriority: 'low' }}
            />
          </div>
        )}
        <div
          className={classNames(
            'flex flex-1 bg-white p-2 md:p-6 ',
            viewOption === 'Kacheln' ? 'flex-col' : 'w-full flex-row items-center justify-between'
          )}
        >
          <div className={viewOption === 'Liste' ? 'order-2' : ''}>
            {isCategoryVisible &&
              categories &&
              categories.map((cat: { full_slug: string; name: string }, index) => (
                <span className="my-2 mr-4 inline-block text-sm font-medium" key={index}>
                  <Badge
                    className="rounded-full px-3"
                    name={cat.name}
                    bgColor={cat.name === 'Presseveröffentlichung' ? 'cyan' : 'gold'}
                  />
                </span>
              ))}
          </div>
          <div className={viewOption === 'Liste' ? 'order-1 w-96' : 'w-auto'}>
            {full_slug && (
              <Link passHref href={full_slug}>
                <a href={full_slug} className="mt-2 block">
                  <p className="text-navy text-xl font-semibold">{substringText(title, titleMaxLength)}</p>
                  {viewOption === 'Liste' && (
                    <p className="text-navy mt-3 text-base">{excerpt && substringText(excerpt, descMaxLength)}</p>
                  )}
                  {author && isMetaVisible && (
                    <CardMeta
                      className={viewOption === 'Liste' ? 'order-2 items-center' : 'mt-auto pt-5'}
                      authorName={author_name}
                      authorPosition={author_position}
                      authorSlug={author?.full_slug}
                      authorImageUrl={author_image?.filename}
                      first_published_at={first_published_at}
                      readingTime={reading_time || ''}
                    />
                  )}
                </a>
              </Link>
            )}
          </div>

          {isButtonVisible && viewOption === 'Kacheln' && (
            <div>
              {full_slug && (
                <Link passHref href={full_slug}>
                  <a href="/#" className="text-cyan my-3 flex flex-row">
                    <span className="my-2 mr-4 text-sm font-medium">
                      mehr erfahren <ChevronRightIcon className="h-6 w-6" />
                    </span>
                  </a>
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { Tabs, Tab, Button, StoryblokIcon, MessageBanner, validationMessages } from '@dtk/ui-components';
import { useAppConfig } from '@dtk/config';

import axios from 'axios';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';

import { BuildingStandardsForm } from './BuildingStandardsForm';
import { ModernizationForm } from './ModernizationForm';
import { PersonalDataForm } from './PersonalDataForm';
import { RealEstateTypeForm } from './RealEstateTypeForm';
import {
  allLeadTransmissionValidationSchema,
  personalDataValidationSchema,
  realEstateTypeValidationSchema,
} from './leadTransmissionValidationSchemas';
import { LeadTransmissionValues, allFormsInitialValues } from './types';
import { SpinnerWeb } from '../../01_elements';

export type LeadTransmissionStep =
  | 'Personenbezogene Daten'
  | 'Immobilientyp'
  | 'Gebäudestandard (optional)'
  | 'Modernisierungen (optional)';

let leadTransmissionTabs: Tab[] = [
  { name: 'Personenbezogene Daten', current: true },
  { name: 'Immobilientyp', current: false },
  { name: 'Gebäudestandard (optional)', current: false },
  { name: 'Modernisierungen (optional)', current: false },
];

export const EstateAgentLeadTransmissionFormSection = () => {
  const { config } = useAppConfig();
  const [currentTab, setCurrentTab] = useState<LeadTransmissionStep>('Personenbezogene Daten');
  const [showSendButton, setShowSendButton] = useState(true);
  const [showMessageBanner, setShowMessageBanner] = useState(false);
  const componentHead = useRef() as React.MutableRefObject<HTMLDivElement>;

  const ALL_LEAD_TRANSMISSION_FIELD_NAMES = Object.keys(allFormsInitialValues);
  const IS_OPTIONAL_FORM = currentTab === 'Gebäudestandard (optional)' || currentTab === 'Modernisierungen (optional)';

  const mapCurrentTabToLeadTransmissionTabs = (currentTab: LeadTransmissionStep): Tab[] => {
    return leadTransmissionTabs.map((tab) => {
      if (tab.name === currentTab) {
        return { ...tab, current: true };
      } else {
        return { ...tab, current: false };
      }
    });
  };

  const handleClick = (isValid: boolean, isLastTab: boolean) => {
    if (isValid && isLastTab) {
      setShowMessageBanner(false);
      return;
    }

    setShowMessageBanner(true);
    return componentHead.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = (
    values: LeadTransmissionValues,
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
  ) => {
    if (currentTab !== 'Modernisierungen (optional)') {
      switchToNextTab();
      ALL_LEAD_TRANSMISSION_FIELD_NAMES.forEach((fieldName) => setFieldTouched(fieldName, false));
      return;
    }
    setShowSendButton(false);

    const payload = {
      estateAgentID: values.estateAgentID,
      salutation: values.salutation,
      userTitle: values.userTitle,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      street: values.street,
      houseNumber: values.houseNumber,
      zipCode: values.zipCode.toString(),
      city: values.city,
      realEstateValue: values.realEstateValue.toString(),
      requestedPayout: values.requestedPayout.toString(),
      existingLoads: values.existingLoads?.toLowerCase().includes('ja'),
      existingLoadsAmount: values.existingLoadsAmount.toString(),
      objectType: values.objectType,
      buildingType: values.buildingType,
      livingSpace: values.livingSpace.toString(),
      landArea: values.landArea.toString(),
      constructionYear: values.constructionYear.toString(),
      floorCount: values.floorCount,
      garageParkingSpaces: values.garageParkingSpaces,
      outdoorParkingSpaces: values.outdoorParkingSpaces,
      construction: values.construction,
      loftConversion: values.loftConversion,
      basementType: values.basementType,
      basementExtension: values.basementExtension,
      multipleBathrooms: values.multipleBathrooms?.toLowerCase().includes('ja'),
      guestToilet: values.guestToilet?.toLowerCase().includes('ja'),
      cablesPlaster: values.cablesPlaster?.toLowerCase().includes('ja'),
      outerWallsInsulated: values.outerWallsInsulated?.toLowerCase().includes('ja'),
      chimneyOrTiledStove: values.chimneyOrTiledStove?.toLowerCase().includes('ja'),
      sauna: values.sauna?.toLowerCase().includes('ja'),
      heating: values.heating,
      roofCovering: values.roofCovering,
      window: values.window,
      outdoorLivingArea: values.outdoorLivingArea,
      balcony: values.balcony,
      energyEfficiencyClass: values.energyEfficiencyClass,
      energyEfficiencyHouseStandard: values.energyEfficiencyHouseStandard,
      floorCoveringModernization: values.floorCoveringModernization,
      bathToiletModernization: values.bathToiletModernization,
      heatingModernization: values.heatingModernization,
      electricityWaterHeatingModernization: values.electricityWaterHeatingModernization,
      windowModernization: values.windowModernization,
      thermalInsulationModernization: values.thermalInsulationModernization,
      roofModernization: values.roofModernization,
      roomLayoutModernization: values.roomLayoutModernization,
      floorLocation: values.floorLocation,
      upperFloorType: values.upperFloorType,
      flatCount: values.flatCount,
      floorCoveringLivingRoom: values.floorCoveringLivingRoom,
      storageRoom: values.storageRoom,
      additionalInfo: values.additionalInfo,
    };

    axios
      .post(config['API_BASE'] + '/public/estate-agent/lead-transmission', payload, {})
      .then(() => {
        window.location.href = '/erfolgreiche-eingabe';
      })
      .catch(() => {
        window.location.href = '/error';
      });
  };

  const handleCurrentTab = (
    selectedTab: string,
    setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
  ) => {
    setCurrentTab(selectedTab as LeadTransmissionStep);
    setShowMessageBanner(false);
    ALL_LEAD_TRANSMISSION_FIELD_NAMES.forEach((fieldName) => setFieldTouched(fieldName, false));
  };

  const setTabDirection = (isNextTab: boolean) => {
    const numberOfTabMoves = isNextTab ? 1 : -1;
    setShowMessageBanner(false);

    const currentTabIndex = leadTransmissionTabs.findIndex((tab) => tab.name === currentTab);
    setCurrentTab(leadTransmissionTabs[currentTabIndex + numberOfTabMoves].name as LeadTransmissionStep);
    leadTransmissionTabs = mapCurrentTabToLeadTransmissionTabs(
      leadTransmissionTabs[currentTabIndex + numberOfTabMoves].name as LeadTransmissionStep
    );
  };

  const switchToNextTab = () => {
    setTabDirection(true);
  };
  const switchToPreviousTab = () => {
    setTabDirection(false);
    componentHead.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-coolGray-50 pb-20" ref={componentHead}>
      <Formik
        initialValues={allFormsInitialValues}
        validationSchema={
          (currentTab === 'Personenbezogene Daten' && personalDataValidationSchema) ||
          (currentTab === 'Immobilientyp' && realEstateTypeValidationSchema) ||
          (IS_OPTIONAL_FORM && allLeadTransmissionValidationSchema)
        }
        validateOnChange={false}
        onSubmit={(values, { setFieldTouched }) => handleSubmit(values, setFieldTouched)}
      >
        {({ isValid, setFieldTouched }) => (
          <>
            <div className="flex justify-center py-8">
              <Tabs
                tabs={mapCurrentTabToLeadTransmissionTabs(currentTab)}
                handleCurrent={(selectedTab) => handleCurrentTab(selectedTab, setFieldTouched)}
              />
            </div>
            <Form>
              {!isValid && showMessageBanner && (
                <MessageBanner
                  classNames="mb-10"
                  message={
                    IS_OPTIONAL_FORM
                      ? validationMessages.error.leadBanner.text
                      : validationMessages.error.defaultBanner.text
                  }
                  highlightText={
                    IS_OPTIONAL_FORM
                      ? validationMessages.error.leadBanner.highlightedPart
                      : validationMessages.error.defaultBanner.highlightedPart
                  }
                  isError
                />
              )}
              <div className="mx-auto flex flex-col gap-x-6 gap-y-4 px-8 sm:max-w-[35rem]">
                {currentTab === 'Personenbezogene Daten' && <PersonalDataForm />}
                {currentTab === 'Immobilientyp' && <RealEstateTypeForm />}
                {currentTab === 'Gebäudestandard (optional)' && <BuildingStandardsForm />}
                {currentTab === 'Modernisierungen (optional)' && <ModernizationForm />}
              </div>

              <div className="mx-auto mt-10 flex flex-col gap-y-4 px-8 sm:max-w-[35rem] sm:flex-row">
                {showSendButton && (
                  <Button
                    className="sm:order-2"
                    fullWidth
                    type="submit"
                    onClick={() => handleClick(isValid, currentTab === 'Modernisierungen (optional)')}
                  >
                    {currentTab === 'Modernisierungen (optional)' ? 'Jetzt senden' : 'Weiter'}
                  </Button>
                )}
                {!showSendButton && <SpinnerWeb />}
                {currentTab !== 'Personenbezogene Daten' && showSendButton && (
                  <Button
                    className="sm:mr-6"
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => switchToPreviousTab()}
                  >
                    <StoryblokIcon
                      iconSource="https://a.storyblok.com/f/148087/64x107/4944ded22e/chevron-left-navy.png"
                      className="mr-2 -ml-3 sm:ml-0"
                      width={7}
                    />
                    <span className="text-md font-semibold">zurück</span>
                  </Button>
                )}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { FeatureSectionList } from '@dtk/ui-components';

const FeatureSectionListBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="feature-section-list">
      <FeatureSectionList {...blok}>
        {blok.textItems &&
          blok.textItems.map((nestedBlok) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
      </FeatureSectionList>
    </div>
  );
};

export default FeatureSectionListBlok;

import { formatCurrency } from '@dtk/formatter';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Tooltip } from '../../01_elements/Tooltip';

interface LabelWithTooltipAndCalculatedValueProps {
  containerClassNames?: string;
  label: string;
  tooltipText?: string;
  calculatedValue?: number;
  calculatedValueClassNames?: string;
}

export const LabelWithTooltipAndCalculatedValue = ({
  containerClassNames,
  label,
  tooltipText,
  calculatedValue,
  calculatedValueClassNames,
}: LabelWithTooltipAndCalculatedValueProps) => {
  return (
    <div
      key={label}
      className={
        containerClassNames ? containerClassNames : 'flex flex-col justify-between space-y-2 sm:flex-row sm:space-y-0'
      }
    >
      <div className="xs:items-center flex items-start sm:max-w-[70%] md:max-w-[60%] lg:max-w-[80%]">
        {tooltipText && (
          <Tooltip text={tooltipText}>
            <InformationCircleIcon className="h-5 w-5" />
          </Tooltip>
        )}
        {label && <p className="xs:mr-2 xs:ml-0 xs:order-first ml-2 text-lg font-medium">{label}</p>}
      </div>
      {(calculatedValue || calculatedValue === 0) && (
        <p className={calculatedValueClassNames || 'font-body-bold ml-auto text-2xl text-green-400'}>
          {formatCurrency(calculatedValue, 0)}
        </p>
      )}
    </div>
  );
};

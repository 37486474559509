type MyWindow = Window & {
  UC_UI?: {
    isInitialized: () => boolean;
    getServicesBaseInfo: (id?: string) => { id: string; consent: { status: boolean } }[];
    acceptService: (id: string) => void;
    rejectService: (id: string) => void;
    showSecondLayer: () => void;
    showFirstLayer: () => void;
  };
};

const getUC_UI = (): MyWindow['UC_UI'] | undefined => {
  return typeof window !== 'undefined' ? (window as unknown as MyWindow).UC_UI : undefined;
};

const isUC_UIInitialized = (): boolean => {
  return Boolean(getUC_UI()?.isInitialized());
};

export const getConsentStatus = (serviceID: string): boolean => {
  if (isUC_UIInitialized()) {
    const baseInfo = getUC_UI()?.getServicesBaseInfo(serviceID);
    return baseInfo?.find((service) => service.id === serviceID)?.consent?.status ?? false;
  }
  return false;
};

export const acceptAllConsents = (): void => {
  if (isUC_UIInitialized()) {
    const allServices = getUC_UI()?.getServicesBaseInfo();
    allServices?.map((service) => getUC_UI()?.acceptService(service.id));
  }
};

export const acceptConsent = (serviceID: string): void => {
  isUC_UIInitialized() && getUC_UI()?.acceptService(serviceID);
};

export const rejectAllConsents = (): void => {
  if (isUC_UIInitialized()) {
    const allServices = getUC_UI()?.getServicesBaseInfo();
    allServices?.map((service) => getUC_UI()?.rejectService(service.id));
  }
};

export const rejectConsent = (serviceID: string): void => {
  isUC_UIInitialized() && getUC_UI()?.rejectService(serviceID);
};

export const showSecondLayer = (): void => {
  isUC_UIInitialized() && getUC_UI()?.showSecondLayer();
};
export const showFirstLayer = (): void => {
  isUC_UIInitialized() && getUC_UI()?.showFirstLayer();
};

import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { storyblokEditable } from '@storyblok/react';

const Text = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="text" className="inline-block">
    <span className={'block ' + blok.classes}>
      {' '}
      <div className="richtext-editor-styles mx-auto max-w-7xl">{render(blok.text)}</div>
    </span>
  </div>
);

export default Text;

import { ImagesCarousel } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const ImagesCarouselBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="images-carousel">
      <ImagesCarousel
        variant={blok.variant}
        backgroundColor={blok.backgroundColor}
        overline={blok.overline}
        images={blok.imageFolder}
      />
    </div>
  );
};

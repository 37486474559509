import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { SplitWithImage } from '@dtk/public-website-ui-components';

const SplitWithImageBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="splitWithImage">
    <SplitWithImage
      headingType={blok.headingType}
      headline={blok.headline}
      subtitle={blok.subtitle}
      buttonLink={blok.buttonLink}
      buttonText={blok.buttonText}
      imagePosition={blok.imagePosition}
      image={blok.image}
      content={blok.content}
    />
  </div>
);

export default SplitWithImageBlok;

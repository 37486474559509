import create from 'zustand';
import axios from 'axios';

/**
 * A app global store that fetches remote configuration.
 */
export const useAppConfig = create<FrontendConfig>((set, get) => ({
  config: {},
  ready: false,

  /**
   * Reload the configuration.
   *
   * @param path
   */
  reload: async (path = '/config.json') => {
    const response = await axios.get<AppConfig>(path, { responseType: 'json', baseURL: undefined });
    set((state) => ({ ...state, config: response.data, ready: true }));
  },

  /**
   * Return the latest configuration or fetch it if it hasn't been retrieved yet.
   *
   * @returns a promise with the config
   */
  getLatest: async () => {
    const state = get();
    if (state.ready) {
      return state.config;
    }
    await state.reload();
    return get().config;
  },

  /**
   * Reset the app configuration.
   */
  reset: () => {
    set({ config: {}, ready: false });
  },
}));

export interface FrontendConfig {
  ready: boolean;
  config: AppConfig;
  reload: () => Promise<void>;
  getLatest: () => Promise<AppConfig>;
  reset: () => void;
}

export type AppConfig = Record<string, string>;

import { boolean, object } from 'yup';
import { PartnerWrapperProps } from '../../../types';
import { PartnerOfferDefaultForm } from '../../DefaultForm';

export const EnterWrapper = ({ blok, customerData }: PartnerWrapperProps) => {
  const redirectUrl = `${blok.redirectUrl}${customerData.vorgangsNummer}`;
  blok.redirectUrl = redirectUrl;

  const getValidationSchema = () => {
    return object({
      leadDistributionAllowed: boolean().oneOf([true]),
    });
  };

  return (
    <PartnerOfferDefaultForm
      externalFormData={blok}
      customerData={customerData}
      validationSchema={getValidationSchema()}
    />
  );
};

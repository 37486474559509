import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Controller, Navigation, Pagination, Thumbs } from 'swiper';

import styles from './ImagesCarousel.module.scss';
import { BackgroundThemeVariants, ImageProps } from '../../utils';
import classNames from 'classnames';

SwiperCore.use([Autoplay, Navigation, Pagination, Controller, Thumbs]);

type carouselVariant = 'logoSlider' | 'instagramSlider';

export interface ImagesCarouselProps {
  variant: carouselVariant;
  backgroundColor: BackgroundThemeVariants;
  overline?: string;
  images: ImageProps[];
}

export const ImagesCarousel = ({ variant, backgroundColor, overline, images }: ImagesCarouselProps) => {
  return (
    <div className={classNames(backgroundColor ? `bg-${backgroundColor}` : 'bg-white', 'py-12')}>
      {overline && <p className="text-gold-gold mb-2 text-center text-sm uppercase tracking-[.2em]">{overline}</p>}
      <Swiper
        className={variant === 'instagramSlider' ? styles['swiperSlideWrapper'] : styles['swiperSlideWrapperCentered']}
        speed={2000}
        loop={true}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="bg-gray mr-1.5 ' + className + '"></span>';
          },
        }}
        // Responsive breakpoints
        breakpoints={{
          1024: {
            slidesPerGroup: 4,
            slidesPerView: 4,
          },
          834: {
            slidesPerGroup: 3,
            slidesPerView: 3,
          },
          320: {
            slidesPerGroup: 1,
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: true,
          },
          0: {
            slidesPerGroup: 1,
            slidesPerView: 1,
            spaceBetween: 60,
            centeredSlides: true,
          },
        }}
      >
        {images[0]?.filename &&
          images.length > 0 &&
          images.map((image, id) => (
            <SwiperSlide
              key={id}
              className={classNames(
                variant === 'instagramSlider' && styles['swiperSlide'],
                'flex flex-col items-center px-12'
              )}
            >
              <img
                className={variant === 'logoSlider' ? 'grayscale hover:grayscale-0' : 'rounded-md'}
                src={image.filename + (variant === 'instagramSlider' ? '/m/200x0/smart' : '')}
                alt={image.alt}
                width={200}
                height={0}
                loading="lazy"
              />
            </SwiperSlide>
          ))}
        <div className={classNames('swiper-button-next text-navy bg-white', styles['swiperButtonNext'])}>
          <ChevronRightIcon className="h-6 w-6" />
        </div>
        <div className={classNames('swiper-button-prev text-navy bg-white', styles['swiperButtonPrev'])}>
          <ChevronLeftIcon className="h-6 w-6" />
        </div>
        <div className={classNames('swiper-pagination px-1', styles['swiperPaginationCustom'])} />
      </Swiper>
    </div>
  );
};

import { Button } from './Button';
import { SetStateAction } from 'react';

interface ButtonProps {
  primaryButtonText: string;
  secondaryButtonText: string;
  logoLink?: string;
  setIsLeavingPageModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

export const PageRedirectionButtons = ({
  primaryButtonText,
  secondaryButtonText,
  logoLink,
  setIsLeavingPageModalOpen,
}: ButtonProps) => {
  return (
    <>
      <Button
        className="my-7"
        size="lg"
        fullWidth
        onClick={() => {
          setIsLeavingPageModalOpen(false);
        }}
      >
        {primaryButtonText}
      </Button>
      <Button variant="white" size="lg" fullWidth href={logoLink ? logoLink : '/'}>
        {secondaryButtonText}
      </Button>
    </>
  );
};

import { BackgroundThemeVariants, ImageProps, StoryblokIcon } from '@dtk/ui-components';
import classNames from 'classnames';

export interface NavigationHeaderProps {
  alternativeTopLineColor?: BackgroundThemeVariants;
  phoneIcon?: ImageProps;
  phoneNumber?: string;
  appointmentIcon?: ImageProps;
  appointmentLink?: string;
  appointmentText?: string;
  infobrochureIcon?: ImageProps;
  infobrochureText?: string;
  infobrochureLink?: string;
}

export const NavigationHeader = ({
  alternativeTopLineColor,
  phoneIcon,
  phoneNumber,
  appointmentIcon,
  appointmentText,
  infobrochureIcon,
  infobrochureText,
  appointmentLink,
  infobrochureLink,
}: NavigationHeaderProps) => {
  return (
    <div
      className={classNames(
        alternativeTopLineColor ? `bg-${alternativeTopLineColor}` : 'bg-navy-700',
        'navi:block hidden h-10'
      )}
    >
      <div className="mx-auto flex max-w-7xl justify-end space-x-4 px-4 sm:px-6 lg:px-8">
        <div className="mt-2 flex">
          {phoneIcon?.filename && (
            <StoryblokIcon iconSource={phoneIcon.filename} className="mr-2 inline" alt={phoneIcon?.alt} width={20} />
          )}
          <a className="ml-1 inline text-base font-normal text-white" href={'tel:' + phoneNumber}>
            {phoneNumber}
          </a>
        </div>
        <div className="mt-2 flex">
          {appointmentIcon?.filename && (
            <StoryblokIcon
              iconSource={appointmentIcon.filename}
              className="mr-2 inline"
              alt={appointmentIcon?.alt}
              width={20}
            />
          )}
          <a className="ml-1 inline text-base font-normal text-white" href={appointmentLink}>
            {appointmentText}
          </a>
        </div>
        <div className="mt-2 flex">
          {infobrochureIcon?.filename && (
            <StoryblokIcon
              iconSource={infobrochureIcon.filename}
              className="mr-2 inline"
              alt={infobrochureIcon?.alt}
              width={20}
            />
          )}
          <a className="ml-1 inline text-base font-normal text-white" href={infobrochureLink}>
            {infobrochureText}
          </a>
        </div>
      </div>
    </div>
  );
};

export interface ImageProps {
  filename: string;
  alt: string;
  focus?: string;
}

export interface FocalPointCoordinates {
  x: number;
  y: number;
}

const calculateRelativeFocalPoint = (
  desktopImageUrl: string,
  desktopFocalPoint: string,
  isMobileDevice?: boolean,
  mobileImageUrl?: string,
  mobileFocalPoint?: string
) => {
  let relativeFocalPoint: FocalPointCoordinates = { x: 50, y: 50 };
  let imageUrl = desktopImageUrl;
  let focalPoint = desktopFocalPoint;

  if (isMobileDevice && mobileImageUrl && mobileFocalPoint) {
    imageUrl = mobileImageUrl;
    focalPoint = mobileFocalPoint;
  }

  if (focalPoint) {
    const imageSize = imageUrl.split('/f/')[1].split('/')[1];
    const [imageWidth, imageHeight] = imageSize.split('x');
    const [absoluteFocalPointX, absoluteFocalPointY] = focalPoint.split(/[x:]/);

    const x = (parseInt(absoluteFocalPointX) / parseInt(imageWidth)) * 100;
    const y = (parseInt(absoluteFocalPointY) / parseInt(imageHeight)) * 100;

    relativeFocalPoint = { x, y };
  }
  return relativeFocalPoint;
};

export const setObjectPosition = (
  desktopImageUrl: string,
  desktopFocalPoint?: string,
  isMobileDevice?: boolean,
  mobileImageUrl?: string,
  mobileFocalPoint?: string
) => {
  let imageStyle = {};

  const relativeFocalPoint = calculateRelativeFocalPoint(
    desktopImageUrl,
    desktopFocalPoint || '',
    isMobileDevice,
    mobileImageUrl,
    mobileFocalPoint
  );

  imageStyle = {
    objectPosition: `${relativeFocalPoint.x}% ${relativeFocalPoint.y}%`,
  };

  return imageStyle;
};

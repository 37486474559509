export const PlaceholderBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div className="border border-red-200 bg-red-100 py-4">
      <p className="text-center italic text-red-700">
        The component <strong>{blok.componentName}</strong> has not been created yet.
      </p>
    </div>
  );
};

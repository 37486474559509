import { Button } from '../../../../01_elements/Button';
import Image from 'next/image';
import { ShareButtonComponentProps } from '../socialMediaPlatformConfig';
import { storyBlokLoader } from '../../../../utils/storyblok-loader';

export const WhatsAppShareButton = ({ ...props }: ShareButtonComponentProps) => {
  const { platformUrl, className, icon } = props;

  const url = `${platformUrl}text=${encodeURI(document.title)}`;

  return (
    <Button
      className={className}
      size="sm"
      variant="ghost"
      isexternal={true}
      data-action="share/whatsapp/share"
      href={url}
    >
      <Image className={className} loader={storyBlokLoader} src={icon} width="16" height="16" />
      <span className="ml-3">WhatsApp</span>
    </Button>
  );
};

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { AngebotsstreckeProvider } from '../../contexts/AngebotsstreckeContext';

const AngebotsstreckeBlok = ({ blok }) => {
  const allKeyPoints = [blok.firstKeyPoint, blok.secondKeyPoint, blok.thirdKeyPoint, blok.fourthKeyPoint];

  return (
    <div key={blok._uid} {...storyblokEditable(blok)} data-test="angebotsstrecke">
      <AngebotsstreckeProvider>
        {blok.Header && blok.Header.map((nestedBlok) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}

        {blok.IntroductionFlow &&
          blok.IntroductionFlow.map((nestedBlok) => (
            <StoryblokComponent
              key={nestedBlok._uid}
              blok={nestedBlok}
              trustElementsHeadline={blok.headline}
              trustElementsKeyPoints={allKeyPoints}
              footer={blok.Footer}
            />
          ))}

        {blok.HouseFlow &&
          blok.HouseFlow.map((nestedBlok) => (
            <StoryblokComponent
              key={nestedBlok._uid}
              blok={nestedBlok}
              trustElementsHeadline={blok.headline}
              trustElementsKeyPoints={allKeyPoints}
              footer={blok.Footer}
            />
          ))}

        {blok.ApartmentFlow &&
          blok.ApartmentFlow.map((nestedBlok) => (
            <StoryblokComponent
              key={nestedBlok._uid}
              blok={nestedBlok}
              trustElementsHeadline={blok.headline}
              trustElementsKeyPoints={allKeyPoints}
              footer={blok.Footer}
            />
          ))}

        {blok.GeneralFlow &&
          blok.GeneralFlow.map((nestedBlok) => (
            <StoryblokComponent
              key={nestedBlok._uid}
              blok={nestedBlok}
              trustElementsHeadline={blok.headline}
              trustElementsKeyPoints={allKeyPoints}
              footer={blok.Footer}
            />
          ))}
      </AngebotsstreckeProvider>
    </div>
  );
};

export default AngebotsstreckeBlok;

import { FlagIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import useIntersectionObserver from '../utils/useIntersection';
import { useRef } from 'react';

export interface StepProps {
  title: string;
  description?: string;
  stepId: number;
  isLastElement: boolean;
}

export const Step = ({ title, description, stepId, isLastElement }: StepProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <div ref={ref} className={classNames('w-auto', isLastElement ? 'relative' : 'pb-10', 'relative')}>
      {!isLastElement ? (
        <>
          <div className="absolute top-9 left-5 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
          <div
            className={classNames(
              'bg-navy-600 absolute top-9 left-5 -ml-px mt-0.5 w-0.5 transition-all ',
              isVisible ? 'h-full duration-700 ' : 'h-0 duration-300 '
            )}
            aria-hidden="true"
          />
        </>
      ) : null}
      <div className="group relative flex items-start">
        <span className="flex h-9 items-center" aria-hidden="true">
          <span className="z-11 relative flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
            <span
              className={classNames(
                'z-9 absolute left-1 h-7 w-7 rounded-full transition duration-150 ease-in-out ',
                isVisible ? 'bg-navy-600' : 'bg-gray-100'
              )}
            />
            <span
              className={classNames(
                'z-11 relative flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800 ',
                isVisible ? 'text-white' : 'text-navy'
              )}
            >
              <span className="z-11">
                {isLastElement ? (
                  <FlagIcon className={classNames('h-4 w-4', isVisible ? 'text-white' : 'text-navy')} />
                ) : (
                  stepId + 1
                )}
              </span>
            </span>
          </span>
        </span>
        <span className="ml-12 flex min-w-0 flex-col">
          <span className="text-navy mb-3 mt-1 w-64 text-xl font-semibold tracking-wide text-indigo-600">{title}</span>
          <span className="text-gray w-64 text-lg sm:w-72">{description}</span>
        </span>
      </div>
    </div>
  );
};

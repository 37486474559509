export interface BenefitsSectionProps {
  headline: string;
  subline?: string;
  benefitsBlocks: IconTextItemProps[];
}

interface IconTextItemProps {
  text: string;
  icon: IconProps;
}

interface IconProps {
  filename: string;
  alt: string;
}

export const BenefitsSection = ({ ...benefits }: BenefitsSectionProps) => {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-20 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row">
          <div className="w-5/5 mr-0 pr-0 md:mr-0 lg:mr-20 lg:w-2/5 lg:pr-20">
            <h2 className="font-headline text-navy mt-5 mb-5 text-left text-xl leading-10 tracking-wide sm:mb-3 sm:text-center md:text-center md:text-4xl lg:text-left">
              {benefits.headline}
            </h2>
            <h3 className="text-navy mb-12 text-left text-lg font-normal tracking-tight sm:text-center sm:text-xl lg:text-left">
              {benefits.subline}
            </h3>
          </div>
          <dl className="md:6/7 w-7/7 grid-flow-col grid-cols-2 grid-rows-5 gap-y-5 gap-x-5 sm:m-auto sm:grid lg:w-auto">
            {benefits.benefitsBlocks?.map((block, itemId) => (
              <dt key={itemId} className="mb-16 flex items-center sm:mb-0 lg:mr-20">
                <img
                  src={block.icon.filename}
                  alt={block.icon.alt}
                  className="absolute h-10 w-10"
                  loading="lazy"
                  {...{ fetchpriority: 'low' }}
                />
                <p className="text-navy ml-16 w-44 text-lg font-medium leading-6">{block.text}</p>
              </dt>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

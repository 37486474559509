import classNames from 'classnames';
import { BackgroundThemeVariants } from '../utils';

interface ContainerProps {
  as?: 'div' | 'nav';
  className?: string;
  children: React.ReactNode;
  backgroundColor?: BackgroundThemeVariants;
}

export const Container = ({ as: HTMLElement = 'div', className, children, backgroundColor }: ContainerProps) => (
  <div className={backgroundColor ? `bg-${backgroundColor}` : 'bg-white'}>
    <HTMLElement className={classNames('text-navy mx-auto max-w-screen-xl px-3 xl:px-0', className)}>
      {children}
    </HTMLElement>
  </div>
);

import { ContactRequestForm } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const ContactRequestFormBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="contact-request-form">
    <ContactRequestForm />
  </div>
);

export default ContactRequestFormBlok;

import { useEffect } from 'react';

export interface JavascriptTagProps {
  sourceURL:   string;
  cssStyle:    string;
}

export const JavascriptTag = ({ sourceURL = '', cssStyle = '' }: JavascriptTagProps) => {

  useEffect(() => {

    const externalScript = document.createElement('script');
    externalScript.src   = sourceURL;
    externalScript.async = true;

    const finalDiv       = document.createElement('div');
    finalDiv.id          = 'javascript-tag-blok-final-div';
    finalDiv.appendChild(externalScript);
    document.getElementById('javascript-tag-blok-to-be-replaced-div')?.replaceWith(finalDiv);

    return () => {
      externalScript.remove();
    };
  }, [sourceURL]);

  return (<div id="javascript-tag-blok-lasting-div" className="mx-auto content-center px-4 sm:px-6 md:max-w-xl lg:max-w-2xl lg:px-8 xl:max-w-[50rem]">
            <style>
            {cssStyle}
            </style>
            <div id='javascript-tag-blok-to-be-replaced-div'></div>
          </div>);
};

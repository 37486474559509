import Link from 'next/link';
import React, { useState } from 'react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { Disclosure } from '@headlessui/react';
import { DtkLogo } from '@dtk/ui-components';

const NavigationAngebotsstreckeBlok = ({ blok }) => {
  const [isLeavingPage, setIsLeavingPage] = useState(false);

  return (
    <div key={blok._uid} {...storyblokEditable(blok)} data-test="angebotsstrecke">
      <Disclosure as="nav" className="w-full bg-white shadow">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between">
            <div className="relative flex h-16 justify-between">
              {blok.leavingPagePopOver && blok.leavingPagePopOver?.length > 0 ? (
                <div className="flex flex-shrink-0 cursor-pointer items-center" onClick={() => setIsLeavingPage(true)}>
                  <DtkLogo loading="eager" />
                </div>
              ) : (
                <Link href={blok.logoLink} passHref>
                  <a className="flex flex-shrink-0 items-center">
                    <DtkLogo loading="eager" />
                  </a>
                </Link>
              )}
            </div>
            <div className="my-auto flex">
              {blok.body &&
                blok.body.map((nestedBlok) => (
                  <div key={nestedBlok._uid}>
                    <StoryblokComponent blok={nestedBlok} setIsLeavingPage={setIsLeavingPage} />
                  </div>
                ))}
              {blok.leavingPagePopOver &&
                blok.leavingPagePopOver.map((nestedBlok) => (
                  <div key={nestedBlok._uid}>
                    <StoryblokComponent
                      blok={nestedBlok}
                      logoLink={blok.logoLink}
                      isModalForLeavingPage={true}
                      isLeavingPage={isLeavingPage}
                      setIsLeavingPage={setIsLeavingPage}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Disclosure>
    </div>
  );
};

export default NavigationAngebotsstreckeBlok;

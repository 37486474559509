declare global {
  interface Window {
    UC_UI: unknown;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const UC_UI: any;

const excludingPageSlugs = ['datenschutz', 'impressum'];

// Note: UC_UI is part of usercentric's API;
// it is created when the usercentric CMP is loaded locally and is hence not known here
const displayCmp = (story) => {
  if (excludingPageSlugs.includes(story?.slug)) {
    UC_UI.closeCMP();
  } else if (UC_UI.isConsentRequired()) {
    UC_UI.showFirstLayer();
  }
};

// check if the CMP banner should be displayed if the location path changes
export const initialiseAndHandleCmp = (story) => {
  if (!window.UC_UI || !UC_UI.isInitialized()) {
    window.addEventListener('UC_UI_INITIALIZED', function () {
      displayCmp(story);
    });
  } else {
    displayCmp(story);
  }
};

import classNames from 'classnames';
import { isExternalPage } from '../../utils/checkLinks';
import { HeroStoererProps } from './types';
import { Button } from '../../01_elements';
import { themesForBackgroundColor, useMediaQuery, getAlternativePath } from '../..';

export const HeroStoerer = ({ ...props }: HeroStoererProps) => {
  const {
    backgroundColor,
    icon,
    textSize,
    isHeadlineBold,
    headline,
    subline,
    buttonColor,
    buttonNameMobil,
    buttonText,
    buttonLink,
  } = props;
  const { largerSm, smallerSm } = useMediaQuery();
  const backgroundTextColor = themesForBackgroundColor.darkBackgroundColors.includes(backgroundColor)
    ? 'text-white'
    : 'text-navy';

  const buttonLinkPath = getAlternativePath({ originalLink: buttonLink, condition: smallerSm });

  const StoererButton = () => (
    <Button
      size={largerSm ? 'lg' : 'base'}
      variant={buttonColor || 'cyan'}
      fullWidth
      href={buttonLinkPath}
      isexternal={isExternalPage(buttonLinkPath)}
    >
      {buttonNameMobil ? <span>{largerSm ? buttonText : buttonNameMobil}</span> : <span>{buttonText}</span>}
    </Button>
  );

  return (
    <div
      className={classNames(
        backgroundColor ? `bg-${backgroundColor}` : 'bg-creme',
        'inline-flex flex-col rounded p-3 shadow-md sm:p-4 lg:flex-row lg:space-x-4'
      )}
    >
      <div className="mb-3 inline-flex basis-6/12 lg:mb-0">
        {icon?.filename && (
          <div className="min-w-50px inline-flex items-center pr-5">
            <img src={icon?.filename} alt={icon?.alt || 'Störer Icon'} width={30} height={30} />
          </div>
        )}
        {subline && (
          <div className="flex flex-col lg:justify-center">
            {headline && (
              <p className={classNames(backgroundTextColor, textSize || 'text-lg', isHeadlineBold && 'font-body-bold')}>
                {headline}
              </p>
            )}
            <p className={classNames(backgroundTextColor, textSize || 'text-lg')}>{subline}</p>
          </div>
        )}
      </div>
      {buttonText && (
        <div className="lg:flex lg:basis-6/12 lg:items-center lg:justify-end">
          <StoererButton />
        </div>
      )}
    </div>
  );
};

export type ColumnImagesCardProps = {
  personName: string;
  personPosition: string;
  personText: string;
  personImage: PersonImageProps;
};

interface PersonImageProps {
  filename: string;
  alt: string;
}

export const ColumnImagesCard = ({ personName, personImage, personPosition, personText }: ColumnImagesCardProps) => {
  return (
    <div>
      <div className="relative overflow-hidden rounded-md shadow-lg	">
        {personImage?.filename && (
          <img
            alt={personImage.alt}
            className="mx-auto mr-3 h-full w-full"
            src={personImage.filename + '/m/627x368/smart'}
            loading="lazy"
            {...{ fetchpriority: 'low' }}
          />
        )}
        <div className="bg-navy-800 xs:text-sm absolute bottom-0 right-0 left-0 top-0 p-5 text-xs text-white opacity-0 hover:opacity-70 sm:text-xs md:text-sm lg:text-lg">
          <span>"{personText}"</span>
        </div>
      </div>
      <div className="mt-3 mb-4 sm:mb-0">
        <span className="text-navy text-lg font-bold">{personName}</span>
        <br></br>
        <span className="text-navy text-lg font-normal">{personPosition}</span>
      </div>
    </div>
  );
};

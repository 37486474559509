import Link from 'next/link';
import { CheckBox } from '../01_elements';

interface OptInCheckboxProps {
  showAdvertisingConsent?: boolean;
  showLeadDistributionAllowed?: boolean;
  isMandatory?: boolean;
  isDisabled?: boolean;
}

export const OptInCheckboxes = ({
  showAdvertisingConsent,
  showLeadDistributionAllowed,
  isMandatory,
  isDisabled,
}: OptInCheckboxProps) => {
  const showAllCheckboxes = !showAdvertisingConsent && !showLeadDistributionAllowed;

  return (
    <>
      {(showAdvertisingConsent || showAllCheckboxes) && (
        <div className="text-navy-navy col-span-6">
          <CheckBox name="advertisingConsent" disabled={isDisabled}>
            <label>
              {`${
                isMandatory ? '*' : ''
              }Hiermit erkläre ich mich zum Erhalt regelmäßiger Informationen (Angebote und Neuigkeiten) einverstanden.
                Nähere Informationen zur Verarbeitung meiner Daten kann ich den`}{' '}
              <Link passHref href="/datenschutz">
                <a target="_blank" rel="noopener" href="/datenschutz" className="text-cyan-700">
                  Datenschutzhinweisen
                </a>
              </Link>{' '}
              entnehmen.
            </label>
          </CheckBox>
        </div>
      )}
      {(showLeadDistributionAllowed || showAllCheckboxes) && (
        <div className="text-navy-navy col-span-6">
          <CheckBox name="leadDistributionAllowed" disabled={isDisabled}>
            <label>
              {`${
                isMandatory ? '*' : ''
              }Hiermit erkläre ich mich mit der Weitergabe meiner Daten zur Erstellung individueller Angebote
                an Partner einverstanden. Details kann ich dem Hinweis`}{' '}
              <Link passHref href="/einwilligungserklaerung/">
                <a target="_blank" rel="noopener" href="/einwilligungserklaerung/" className="text-cyan-700">
                  Datenweitergabe an Partner
                </a>
              </Link>{' '}
              entnehmen. Ein Widerruf ist jederzeit möglich.
            </label>
          </CheckBox>
        </div>
      )}
    </>
  );
};

import { useMediaQuery } from '@dtk/ui-components';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import FooterLogoAndIcon from './FooterLogoAndIcon';
import FooterContact from './FooterContact';

const gridColumnSizeConfig = {
  2: 'lg:grid-cols-2',
  3: 'lg:grid-cols-3',
  4: 'lg:grid-cols-4',
  5: 'lg:grid-cols-5',
  6: 'lg:grid-cols-6',
};

const FooterWrapperBlok = ({ blok }) => {
  const { largerXl } = useMediaQuery();

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="footer-wrapper">
      <div key="footer-top" className="bg-navy-50 pt-[4.5rem] pb-4 sm:pb-7 md:pb-10 lg:pb-[4.8rem] xl:px-[4.5rem]">
        <div className="mx-6 text-lg sm:text-base md:text-sm xl:mx-auto xl:max-w-[96rem]">
          {!largerXl && <FooterLogoAndIcon blok={blok} />}
          <div
            className={`grid grid-cols-1 sm:grid-cols-3 ${
              gridColumnSizeConfig[blok.columns.length + (largerXl ? 2 : 1)]
            } gap-x-4 gap-y-6 sm:gap-y-7`}
          >
            {largerXl && <FooterLogoAndIcon blok={blok} />}
            <FooterContact key={`${blok._uid}footer-contact`} blok={blok} />
            {blok.columns &&
              blok.columns.map((nestedBlok) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
          </div>
        </div>
      </div>
      <div key="footer-bottom">
        {blok.footerBottom &&
          blok.footerBottom.map((nestedBlok) => <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />)}
      </div>
    </div>
  );
};

export default FooterWrapperBlok;

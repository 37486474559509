import { Button, Headline, headingType } from '../../01_elements';
import { useMediaQuery } from '../../hooks';
import { getAlternativePath } from '../../utils';

interface FeatureSectionListProps {
  overline: string;
  headingType?: headingType;
  headline: string;
  leftButtonName?: string;
  leftButtonRedirectionUrl?: string;
  bottomButtonName?: string;
  bottomButtonRedirectionUrl?: string;
}

export const FeatureSectionList = ({ ...props }: React.PropsWithChildren<FeatureSectionListProps>) => {
  const { overline, leftButtonName, leftButtonRedirectionUrl, bottomButtonRedirectionUrl, children } = props;
  const { smallerSm } = useMediaQuery();
  const offerLink = bottomButtonRedirectionUrl
    ? getAlternativePath({ originalLink: bottomButtonRedirectionUrl, condition: smallerSm })
    : '';
  const chosenHeadingType = props.headingType || 'h2';

  return (
    <div className="my-14 mx-auto grid max-w-7xl grid-cols-4 bg-white px-4 sm:px-6 md:grid-cols-8 md:gap-6 lg:px-8 xl:grid-cols-12">
      <div className="col-span-4 md:col-span-6 md:col-start-2 xl:col-span-3">
        {overline && (
          <p className="text-gold text-center text-sm uppercase tracking-[.2em] sm:mb-1 xl:text-left">{overline}</p>
        )}
        <Headline
          headingType={chosenHeadingType}
          headlineClassNames="font-headline text-navy mb-6 text-center xl:text-left"
          headline={props.headline}
        />
        <div className="hidden xl:inline">
          {leftButtonName && leftButtonRedirectionUrl && (
            <Button size="lg" variant="navy" href={leftButtonRedirectionUrl}>
              {leftButtonName}
            </Button>
          )}
        </div>
      </div>
      <div className="col-span-4 md:col-span-6 md:col-start-2 xl:col-span-4 xl:col-start-5">
        {children}
        <div className="flex justify-center xl:hidden">
          {props.bottomButtonName && (
            <Button size="lg" variant="navy" href={offerLink}>
              {props.bottomButtonName}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

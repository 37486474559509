import { EstateAgentRegistrationForm, EstateAgentRegistrationFormWrapper } from '@dtk/public-website-ui-components';
import { storyblokEditable } from '@storyblok/react';

const EstateAgentRegistrationFormSectionBlok = ({ blok }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryToken = queryParams.get('token');

  const Component = queryToken ? (
    <EstateAgentRegistrationFormWrapper token={queryToken} />
  ) : (
    <EstateAgentRegistrationForm />
  );

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="estate-agent-registration-form-section">
      {Component}
    </div>
  );
};

export default EstateAgentRegistrationFormSectionBlok;

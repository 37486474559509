import AnchorBlok from './AnchorBlok';
import AngebotsstreckeBlok from './AngebotStrecke/AngebotsstreckeBlok';
import AngebotsstreckeHeaderBlok from './AngebotStrecke/AngebotsstreckeHeaderBlok';
import AuthorComponentBlok from './Author/AuthorComponentBlok';
import AuthorsBlok from './AuthorBlok';
import BenefitsSectionBlok from './BenefitsSectionBlok';
import BlogHeaderBlok from './BlogHeaderBlok';
import BlogPostBlok from './BlogPostBlok';
import BreadcrumbsBlok from './BreadcrumbsBlok';
import CenteredGridBlok from './CenteredGridBlok';
import CertificationCloud from './CertificationCloud';
import CheckoutBlok from './AngebotStrecke/Checkout/CheckoutBlok';
import ContactFormBlok from './ContactFormBlok';
import ContactRequestFormBlok from './ContactRequestFormBlok';
import DownloadAreaBlok from './DownloadAreaBlok';
import EstateAgentLeadTransmissionFormSectionBlok from './EstateAgent/LeadTransmissionFormSectionBlok';
import EstateAgentRegistrationFormSectionBlok from './EstateAgent/RegistrationFormSectionBlok';
import FooterBottomBlok from './Footer/FooterBottomBlok';
import FooterColumnBlok from './Footer/FooterColumnBlok';
import FooterLinkBlok from './Footer/FooterLinkBlok';
import PrivacySettingsLinkBlok from './Footer/PrivacySettingsLinkBlok';
import FooterSocialMediaBlok from './Footer/FooterSocialMediaBlok';
import FooterWrapperBlok from './Footer/FooterWrapperBlok';
import FormBlok from './FormElements/Form/FormBlok';
import GamificationBlok from './GamificationBlok';
import GlobalBlok from './GlobalBlok';
import GlobalReferenceBlok from './GlobalReferenceBlok';
import Grid from './Grid';
import HTMLParserBlok from './HTMLParserBlok';
import JavascriptTagBlok from './JavascriptTagBlok';
import HeaderSectionWithBGImageBlok from './HeaderSectionWithBGImageBlok';
import IframeBlok from './Iframe/IframeBlok';
import InfoBrochureFormSectionBlok from './InfoBrochureFormSectionBlok';
import { InputAdresseWithAutoCompleteBlok } from './FormElements/InputAdresseWithAutoComplete/InputAdresseWithAutoCompleteBlok';
import { InputCheckboxBlok } from './FormElements/InputCheckbox/InputCheckboxBlok';
import { InputCounterBlok } from './FormElements/InputCounter/InputCounterBlok';
import { InputMultipleSelectBlok } from './FormElements/InputMultipleSelect/InputMultipleSelectBlok';
import { InputRechnerBlok } from './FormElements/InputRechner/InputRechnerBlok';
import { InputSelectBlok } from './FormElements/InputSelect/InputSelectBlok';
import { InputSelectTextBlok } from './FormElements/InputSelectText/InputSelectTextBlok';
import { InputSelectWithDropdownBlok } from './FormElements/InputSelectWithDropdown/InputSelectWithDropdownBlok';
import { InputSelectWithRadioBlok } from './FormElements/InputSelectWithRadio/InputSelectWithRadioBlok';
import { InputTextAreaBlok } from './FormElements/InputTextArea/InputTextAreaBlok';
import JobsCarouselBlok from './JobCarousel';
import KontaktSectionBlok from './KontaktSectionBlok';
import LexikonPageBlok from './Lexikon/LexikonPageBlok';
import LexikonPostBlok from './Lexikon/LexikonPostBlok';
import LoadingComponentBlok from './AngebotStrecke/LoadingComponentBlok';
import NavigationBlok from './NavigationBlok';
import NewsletterSignUpBlok from './NewsletterSignUpBlok';
import Page from './Page';
import PeopleSectionBlok from './PeopleSectionBlok';
import PlaceholderBlok from './PlaceholderBlok';
import PostsListingBlok from './PostsListingBlok';
import PresseArchivBlok from './PresseArchivBlok';
import PressePostBlok from './PressePostBlok';
import ProcessFlowBlok from './ProcessFlow';
import QuickRechnerBlok from './QuickRechnerBlok';
import RechnerGesamtauszahlungBlok from './MultipageRechner/RechnerGesamtauszahlungBlok';
import RechnerSofortauszahlungBlok from './MultipageRechner/RechnerSofortauszahlungBlok';
import ReinforcementLayer from './AngebotStrecke/ReinforcementLayer/ReinforcementLayer';
import SaveFormBlok from './AngebotStrecke/SaveFormBlok';
import SmallFooterBlok from './SmallFooterBlok';
import SocialMediaShareButtonBlok from './SocialMediaShareButton';
import SplitWithImageBlok from './SplitWithImageBlok';
import StepBlok from './AngebotStrecke/StepBlok';
import StepHeadlineBlok from './AngebotStrecke/StepHeadlineBlok';
import SubPublicHeroSectionBlok from './SubPublicHeroSectionBlok';
import TableOfContentBlok from './TableOfContentBlok';
import Text from './Text';
import { TextAndNumberInputBlok } from './FormElements/TextAndNumberInput/TextAndNumberInputBlok';
import TrustPilotBoxBlok from './TrustPilotBox/TrustPilotBoxBlok';
import { TwoColumnSectionBlok } from './TwoColumnSectionBlok';
import FeatureSectionListBlok from './FeatureSectionList/FeatureSectionListBlok';
import FeatureSectionListItemBlok from './FeatureSectionList/FeatureSectionListItemBlok';
import YoutubeVideoWidescreenBlok from './YoutubeVideoWidescreenBlok';
import LeadGenerationFormBlok from './LeadGenerationFormBlok';
import { sharedStoryblokComponents } from '@dtk/storyblok';

export const StoryblokComponents = {
  ...sharedStoryblokComponents,
  page: Page,
  text: Text,
  SmallFooter: SmallFooterBlok,
  FooterWrapper: FooterWrapperBlok,
  FooterColumn: FooterColumnBlok,
  FooterLink: FooterLinkBlok,
  PrivacySettingsLink: PrivacySettingsLinkBlok,
  FooterSocialMedia: FooterSocialMediaBlok,
  FooterBottom: FooterBottomBlok,
  TrustPilotBox: TrustPilotBoxBlok,
  SubPublicHeroSection: SubPublicHeroSectionBlok,
  Kontakt: KontaktSectionBlok,
  PeopleSection: PeopleSectionBlok,
  JobCarousel: JobsCarouselBlok,
  ProcessFlow: ProcessFlowBlok,
  WhatWeAre: FeatureSectionListBlok,
  WhatWeAreTextItem: FeatureSectionListItemBlok,
  DownloadArea: DownloadAreaBlok,
  grid: Grid,
  CertificationCloud: CertificationCloud,
  BenefitsSection: BenefitsSectionBlok,
  ProcessFlowSection: ProcessFlowBlok,
  ContactForm: ContactFormBlok,
  YoutubeVideoWidescreen: YoutubeVideoWidescreenBlok,
  Gamification: GamificationBlok,
  SplitWithImage: SplitWithImageBlok,
  presse_post: PressePostBlok,
  blog_post: BlogPostBlok,
  pressearchiv: PresseArchivBlok,
  postsListing: PostsListingBlok,
  Anchor: AnchorBlok,
  blogHeader: BlogHeaderBlok,
  CenteredGrid: CenteredGridBlok,
  global: GlobalBlok,
  global_reference: GlobalReferenceBlok,
  HeaderSectionWithBGImage: HeaderSectionWithBGImageBlok,
  TwoColumnSection: TwoColumnSectionBlok,
  Breadcrumbs: BreadcrumbsBlok,
  Navigation: NavigationBlok,
  RechnerGesamtauszahlung: RechnerGesamtauszahlungBlok,
  RechnerSofortauszahlung: RechnerSofortauszahlungBlok,
  TableOfContent: TableOfContentBlok,
  InfoBrochureFormSection: InfoBrochureFormSectionBlok,
  LoadingComponent: LoadingComponentBlok,
  step: StepBlok,
  textAndNumberInput: TextAndNumberInputBlok,
  Angebotsstrecke: AngebotsstreckeBlok,
  AngebotsstreckeHeader: AngebotsstreckeHeaderBlok,
  HTMLParser: HTMLParserBlok,
  JavascriptTag: JavascriptTagBlok,
  Iframe: IframeBlok,
  LexikonListing: LexikonPageBlok,
  LexikonListingCopy: LexikonPageBlok,
  lexikon_post: LexikonPostBlok,
  NewsletterSignUp: NewsletterSignUpBlok,
  QuickRechner: QuickRechnerBlok,
  Form: FormBlok,
  InputSelect: InputSelectBlok,
  InputSelectWithRadio: InputSelectWithRadioBlok,
  InputRechner: InputRechnerBlok,
  InputSelectText: InputSelectTextBlok,
  InputCounter: InputCounterBlok,
  InputTextArea: InputTextAreaBlok,
  InputSelectWithDropdown: InputSelectWithDropdownBlok,
  StepHeadline: StepHeadlineBlok,
  Checkout: CheckoutBlok,
  ReinforcementLayer: ReinforcementLayer,
  InputMultipleSelect: InputMultipleSelectBlok,
  InputCheckbox: InputCheckboxBlok,
  SaveForm: SaveFormBlok,
  Placeholder: PlaceholderBlok,
  Author: AuthorsBlok,
  InputAddressWithAutocomplete: InputAdresseWithAutoCompleteBlok,
  SocialMediaShareButton: SocialMediaShareButtonBlok,
  AuthorComponent: AuthorComponentBlok,
  EstateAgentLeadTransmissionFormSection: EstateAgentLeadTransmissionFormSectionBlok,
  EstateAgentRegistrationFormSection: EstateAgentRegistrationFormSectionBlok,
  ContactRequestForm: ContactRequestFormBlok,
  LeadGenerationForm: LeadGenerationFormBlok,
};

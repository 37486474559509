import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import React from 'react';

const GlobalReferenceBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="global-reference">
    {blok.reference.content?.global
      ? blok.reference.content.global.map((nestedBlok) => (
          <div key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </div>
        ))
      : null}
  </div>
);

export default GlobalReferenceBlok;

import { TwoColumnSection } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const TwoColumnSectionBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <TwoColumnSection {...blok} />
    </div>
  );
};

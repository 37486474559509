import date from 'date-and-time';
import { NextRouter } from 'next/router';
import { NavigationSubLinkProps } from './Navigation';

export const cutText = (str: string, n: number) => {
  return str?.length > n ? str?.substr(0, n - 1) + '...' : str;
};

export const isActive = (router: NextRouter, subLinks?: NavigationSubLinkProps[], url?: string) => {
  if (url && router.asPath === url) {
    return true;
  }

  const sublink = subLinks?.find((link) => router.asPath.includes(link.url));
  return sublink ? true : false;
};

const getCurrentDateInMilliseconds = (dateTime: Date) => {
  return dateTime.toString() && date.isValid(dateTime.toString(), 'YYYY-MM-DD hh:mm:ss')
    ? date.parse(dateTime.toString(), 'YYYY-MM-DD hh:mm:ss').getTime()
    : new Date(dateTime.toString().replace(/-/g, '/')).getTime();
};

export const isDateWithinTimePeriod = (timePeriodStart: Date, timePeriodEnd: Date, showBanner: boolean) => {
  if (
    !timePeriodStart ||
    !timePeriodEnd ||
    !showBanner ||
    typeof sessionStorage === 'undefined' ||
    sessionStorage.getItem('bannerIsShown')
  )
    return false;

  const currentDate = new Date().getTime();
  const timePeriodStartFormated = getCurrentDateInMilliseconds(timePeriodStart);
  const timePeriodEndFormated = getCurrentDateInMilliseconds(timePeriodEnd);

  const isCurrentDateWithinTimePeriod = timePeriodStartFormated <= currentDate && currentDate <= timePeriodEndFormated;
  return isCurrentDateWithinTimePeriod;
};

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { useEffect, useState } from 'react';

import Link from 'next/link';

const germanLettersArr = [
  'A', 'B', 'C', 'D', 'E', 'F',	'G', 'H',	'I', 'J',	'K', 'L',	'M',
  'N', 'O', 'P', 'Q', 'R',	'S', 'T',	'U', 'V',	'W', 'X',	'Y', 'Z',
];

interface PostsLinksProps {
  links: LinksProps[];
}

interface LinksProps {
  name: string;
  slug: string;
}

export const LexikonListing = ({ links }: PostsLinksProps) => {
  const [germanLetters, setGermanLetters] = useState<string[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<LinksProps[]>([]);
  const [isAllselected, setIsAllSelected] = useState<boolean>(false);

  const filterPosts = () => {
    if (germanLetters.length < 1) {
      selectAll();
      return setFilteredPosts([...links]);
    }
    const newFilteredPosts = links.filter((post) => {
      return (
        germanLetters.includes(post.name.slice(0, 1))
      );
    });
    setIsAllSelected(germanLetters.length === germanLettersArr.length ? true : false);
    setFilteredPosts([...newFilteredPosts]);
  };

  const toggleLetter = (inputLetter: string) => {
    setGermanLetters([...(germanLetters.includes(inputLetter) ? germanLetters.filter((letter) => letter !== inputLetter) : [...germanLetters, inputLetter])]);
  };

  const selectAll = () => {
    setGermanLetters(germanLettersArr);
    setIsAllSelected(true);
  };

  const removeAll = () => {
    setGermanLetters(['A']);
    setIsAllSelected(false);
  };

  useEffect(() => {
    filterPosts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ germanLetters, links ]);

  return (
    <div className="relative m-auto max-w-7xl	pt-10 pb-20 px-4 justify-center">
      <div className={'m-auto flex flex-row items-center mb-10'}>
        <div className='flex md:flex-row flex-col gap-5'>
          <button className={(isAllselected ? 'bg-gray-200 text-cyan' : 'bg-cyan text-white') + '  text-sm rounded-md py-1'} onClick={selectAll}>Alle auswählen</button>
          <button className={(isAllselected ? 'bg-cyan text-white' : 'bg-gray-200 text-cyan') + '  text-sm rounded-md py-1'} onClick={removeAll}>Alle entfernen</button>
        </div>
        <div className="text-center w-full">
          {germanLettersArr.map((letter, index) => {
            return (
              <button
                key={index}
                onClick={(e) => toggleLetter(letter)}
                className={
                  (germanLetters.includes(letter) ? 'text-gray border-cyan border-b-2' : 'text-gray-500') +
                  ' border-b-2 border-transparent pb-3 mx-4'
                }
              >
                {letter}
              </button>
            );
          })}
        </div>
      </div>
      <div className={'grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4'}>
        {
          filteredPosts &&
          filteredPosts.length > 0 &&
          filteredPosts.sort((a, b) => a.name.localeCompare(b.name))
            .map((post, id) => (
              <div key={id} className=''>
                <Link passHref href={post.slug}>
                  <a href={post.slug} className='my-3 flex flex-row'>
                    <span className="my-2 text-navy text-lg font-bold">
                      {post.name}
                    </span>
                  </a>
                </Link>
              </div>
            )
          )
        }
      </div>
    </div>
  );
};

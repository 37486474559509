import classNames from 'classnames';
import { getHighlightedText } from '../../utils/highlight-text';
import { themesForBackgroundColor } from '../../utils';
import { HeroDescriptionProps } from './types';

export const HeroDescription = ({ ...props }: HeroDescriptionProps) => {
  const { theme, text, boldText, descriptionSize } = props;
  const textSize = `text-${descriptionSize}`;
  return (
    <p
      className={classNames(
        themesForBackgroundColor.darkBackgroundColors.includes(theme) ? 'text-white' : 'text-navy',
        'mt-2 text-left text-lg md:mt-0',
        `md:${textSize}`
      )}
      dangerouslySetInnerHTML={{
        __html: boldText ? getHighlightedText(text, boldText, 'navy', { fontFamily: 'font-body', isBold: true }) : text,
      }}
    />
  );
};

import { PartnerBannerOfferingList } from '@dtk/dtk-club';
import { storyblokEditable } from '@storyblok/react';

export const PartnerBannerOfferingListBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="PartnerBannerOfferingList">
      <PartnerBannerOfferingList {...blok} />
    </div>
  );
};

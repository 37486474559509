import { Container } from '@dtk/ui-components';
import { DownloadArea } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const DownloadAreaBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="download-area">
    <Container>
      <DownloadArea
        headline={blok.headline}
        subtitle={blok.subtitle}
        logos={blok.logos}
        pictures={blok.pictures}
        downloadLogosUrl={blok.downloadLogosUrl}
        downloadPicturesUrl={blok.downloadPicturesUrl}
        downloadButtonText={blok.downloadButtonText}
        logosTitle={blok.logosTitle}
        logosButtonText={blok.logosButtonText}
        picturesTitle={blok.picturesTitle}
        picturesButtonText={blok.picturesButtonText}
      />
    </Container>
  </div>
);

export default DownloadAreaBlok;

import { BlogPostSummaryListing } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const BlogPostSummaryListingBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div {...storyblokEditable(blok)} data-test="blog-post-summary-listing">
      <div className="bg-gray-50">
        <BlogPostSummaryListing postSummaries={blok.blog_post_summaries} categories={blok.categories} />
      </div>
    </div>
  );
};

import create from 'zustand';

interface OfferState {
  resetValues(): unknown;
  isShortForm: boolean;
  requestedPayout: number;
  data: Map<string, never | string | boolean | number | Array<string>>;
}

export const useOfferStore = create<OfferState>((set) => ({
  isShortForm: false,
  requestedPayout: null,
  data: new Map<string, never | string | boolean | number | Array<string>>(),
  resetValues: () => {
    set({
      isShortForm: false,
      requestedPayout: null,
      data: new Map<string, never | string | boolean | number | Array<string>>(),
    });
  },
}));

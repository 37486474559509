import { Fragment, ReactNode } from 'react';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Button } from '../01_elements';

interface SliderOverStylingOptions {
  mainBgColor?: string;
  headerBgColor?: string;
  showCloseButton?: boolean;
  xButtonColor?: string;
  slidePaddingLeft?: string;
  headlineProps?: string;
}
interface SliderOverProps {
  headline?: string;
  subline?: string;
  mainBgColor?: string;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  stylingOptions?: SliderOverStylingOptions;
}

export function SlideOver({ isOpen, setOpen, headline, subline, stylingOptions, children }: SliderOverProps) {
  const closeButton = stylingOptions && stylingOptions.showCloseButton === false ? false : true;

  return (
    <>
      {/* Blur Background effect */}
      {isOpen && (
        <div className="relative">
          <div className="fixed inset-0 z-40 bg-black bg-opacity-50" />
        </div>
      )}
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className=" relative z-50" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div
                className={classNames(
                  stylingOptions?.slidePaddingLeft ? stylingOptions?.slidePaddingLeft : 'pl-16',
                  'pointer-events-none fixed inset-y-0 right-0 flex max-w-full  sm:pl-20 md:pl-24'
                )}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen md:max-w-md lg:max-w-lg 2xl:max-w-2xl">
                    <div
                      className={classNames(
                        stylingOptions?.mainBgColor ? stylingOptions?.mainBgColor : 'bg-white',
                        'border-b-coolGray-300 flex h-full flex-col overflow-y-scroll shadow-xl'
                      )}
                    >
                      <div
                        className={classNames(
                          stylingOptions?.headerBgColor ? stylingOptions?.headerBgColor : 'bg-navy-100',
                          ' py-4 px-2 sm:px-6'
                        )}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex flex-row align-baseline">
                            <span>
                              <p
                                className={classNames(
                                  stylingOptions?.headlineProps
                                    ? stylingOptions?.headlineProps
                                    : 'text-navy pl-2 font-medium'
                                )}
                              >
                                {headline}
                              </p>
                              <p className="text-navy pl-2 text-lg">{subline}</p>
                            </span>
                          </div>
                          <div className="ml-3 flex h-7 items-center">
                            <button onClick={() => setOpen(false)}>
                              <XIcon
                                aria-hidden="true"
                                className={classNames(
                                  stylingOptions?.xButtonColor ? stylingOptions?.xButtonColor : 'text-coolGray-700',
                                  'h-5 w-5 '
                                )}
                              />
                            </button>
                            <span className="sr-only">Schließen</span>
                          </div>
                        </div>
                      </div>
                      <div className="text-navy py-6 px-4 pt-5 leading-6 sm:px-6">{children}</div>
                      {closeButton && (
                        <div className="m-auto mx-10 mb-11">
                          <Button className="mt-5" variant="white" size="lg" fullWidth onClick={() => setOpen(false)}>
                            Schließen
                          </Button>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

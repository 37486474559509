import { BenefitsSection } from '@dtk/public-website-ui-components';
import { Container } from '@dtk/ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const BenefitsSectionBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="benefits-section">
    <Container>
      <BenefitsSection {...blok}></BenefitsSection>
    </Container>
  </div>
);

export default BenefitsSectionBlok;

import classNames from 'classnames';
import { FieldHookConfig, useField } from 'formik';
import { CheckIcon } from '@heroicons/react/solid';

export interface SelectButtonGroupOptionProps {
  name: string;
  value: string;
}

interface SelectButtonGroupProps {
  buttonValue: string;
  disabled?: boolean;
  numberOfOptions?: number;
}

export const SelectButton = (
  props: React.HTMLProps<HTMLInputElement> & FieldHookConfig<string | number | unknown> & SelectButtonGroupProps
) => {
  const { name, buttonValue, disabled, numberOfOptions, onClick, ...restProps } = props;
  const [field, meta, helpers] = useField(props);

  return (
    <>
      <button
        id={name}
        name={name}
        type="button"
        className={classNames(
          'border-coolGray-300 flex w-full items-center justify-center rounded border border-solid bg-white px-3 py-2 shadow-sm',
          disabled && '!bg-coolGray-100 !text-coolGray-400',
          meta.error && meta.touched && '!border-red-secondary focus:!border-red-secondary !border-2',
          field.value ? 'border-2 border-cyan-700' : ''
        )}
        disabled={disabled}
        onClick={() => (!field.value ? helpers.setValue(buttonValue) : helpers.setValue(''))}
      >
        {field.value ? <CheckIcon className="h-4 w-4" /> : null}
        <p className="ml-2">{name}</p>
      </button>
      <input {...field} {...restProps} id={name} type="checkbox" className="hidden" disabled={disabled} />
    </>
  );
};

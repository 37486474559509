import { storyblokEditable } from '@storyblok/react';
import { NewsletterSignUp } from '@dtk/public-website-ui-components';

const NewsletterSignUpBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="newsletter-sign-up" className="bg-navy">
    <NewsletterSignUp {...blok} />
  </div>
);

export default NewsletterSignUpBlok;

import { KontaktSection } from '@dtk/ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';
const KontaktSectionBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="kontakt-section">
    <KontaktSection
      imageUrl={blok.image?.filename}
      personEmail={blok.email}
      personName={blok.name}
      personPosition={blok.position}
      headingType={blok.headingType}
      headline={blok.headline}
      buttonText={blok.buttonText}
      buttonLink={blok.buttonLink}
      firstLine={blok.firstLine}
      secondLine={blok.secondLine}
      thirdLine={blok.thirdLine}
      lastLine={blok.lastLine}
    />
  </div>
);

export default KontaktSectionBlok;

import { EstateAgentLeadTransmissionFormSection } from '@dtk/public-website-ui-components';
import { storyblokEditable } from '@storyblok/react';

const EstateAgentLeadTransmissionFormSectionBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="estate-agent-lead-transmission-form-section">
    <EstateAgentLeadTransmissionFormSection />
  </div>
);

export default EstateAgentLeadTransmissionFormSectionBlok;

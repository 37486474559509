import { FAQSection } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const FAQSectionBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="faq-section">
      <FAQSection
        faqs={blok.questions}
        overline={blok.overline}
        headingType={blok.headingType}
        headline={blok.title}
        subtitle={blok.subtitle}
      />
    </div>
  );
};

import { StoryblokIcon } from '.';

export const Icon = ({ ...props }) => {
  const { blok } = props;

  return (
    blok?.icon && (
      <div className="float-left flex items-center justify-center pr-2">
        <StoryblokIcon iconSource={blok?.icon?.filename} alt={blok?.icon?.alt} width={20} height={20} />
      </div>
    )
  );
};

import { useEffect, useState } from 'react';
import { boolean, object, string } from 'yup';
import { getAllEntriesForSingleDatasource, validationMessages } from '@dtk/ui-components';

import { FassadenBlitzForm } from '.';
import { PartnerOfferDefaultForm } from '../../DefaultForm';
import { PartnerWrapperProps } from '../../../types';


export const FassadenBlitzWrapper = ({ blok, customerData }: PartnerWrapperProps) => {
  const { partnerName } = blok;
  const [state, setState] = useState({ data: [''] });

  useEffect(() => {
    const getAllZipCodesForSinglePartner = async (datasourceName: string) => {
      return getAllEntriesForSingleDatasource(`${datasourceName}-zip-codes`);
    };

    getAllZipCodesForSinglePartner(partnerName)
      .then((datasourceData) => setState({ data: datasourceData }))
      .catch(() => setState({ data: [] }));

    return () => {
      setState({ data: [] });
    };
  }, [partnerName]);

  const requestedData = state.data;
  const getFassadenBlitzValidationSchema = (data: string[]) => {
    return object({
      zipCode: string()
        .oneOf(data, 'Für die angegebene Postleitzahl können wir Ihnen leider noch kein Angebot unterbreiten.')
        .required(validationMessages.error.defaultInputField.text),
      leadDistributionAllowed: boolean().oneOf([true]),
    });
  };

  return (
    <PartnerOfferDefaultForm
      externalFormData={blok}
      validationSchema={getFassadenBlitzValidationSchema(requestedData)}
      customerData={customerData}
      children={<FassadenBlitzForm />}
    />
  );
};

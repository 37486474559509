import classNames from 'classnames';
import { storyblokEditable } from '@storyblok/react';

interface ContactProps {
  styles?: string;
  textContent: string;
}

const companyEmailDomain = '@deutsche-teilkauf.de';

const ContactContent = ({ styles, textContent }: ContactProps) => {
  return (
    <>
      {textContent.split('\n').map((textItem, index) => {
        if (index === 0 && styles) {
          return (
            <p key={`${textItem}-${index}-first-row`} className={`${styles} mb-1`}>
              {textItem}
            </p>
          );
        } else if (textItem.includes(companyEmailDomain)) {
          const emailAdressInRow = textItem.split(' ').filter((textInRow) => textInRow.includes(companyEmailDomain));
          return (
            <a key={`${textItem}-${index}-email`} className="flex flex-row" href={`mailto:${emailAdressInRow}`}>
              {textItem.split(' ').map((word, index) => (
                <p
                  key={`${word}_${index}`}
                  className={classNames(
                    'mb-1',
                    index > 0 && 'ml-1',
                    word.includes(companyEmailDomain) && 'cursor-pointer text-cyan-700 underline'
                  )}
                >
                  {word}
                </p>
              ))}
            </a>
          );
        } else {
          return (
            <p key={`${textItem}-${index}`} className="mb-1">
              {textItem}
            </p>
          );
        }
      })}
    </>
  );
};

const FooterContact = ({ blok }) => (
  <div
    {...storyblokEditable(blok)}
    key={blok._uid}
    data-test="footer-contact"
    className="text-navy-700 font-body col-span-1 text-lg sm:col-span-3 sm:text-base md:text-sm lg:col-span-1"
  >
    {(blok.officeInformation || blok.contactInformation || blok.pressAndMarketingAdresses) && (
      <p key={`${blok._uid}-contact-area`} className="text-navy font-body-bold mb-4 antialiased">
        Kontakt
      </p>
    )}
    {blok.officeInformation && (
      <div key={`${blok._uid}-office-information`}>
        <ContactContent textContent={blok.officeInformation} />
        <div className="mb-4" />
      </div>
    )}
    {blok.contactInformation && (
      <div key={`${blok._uid}-contact-information`}>
        <ContactContent textContent={blok.contactInformation} />
        <div className="mb-4" />
      </div>
    )}
    {blok.pressAndMarketingAdresses && (
      <ContactContent
        key={`${blok._uid}-press-and-marketing-adresses`}
        styles="font-body-bold antialiased"
        textContent={blok.pressAndMarketingAdresses}
      />
    )}
  </div>
);

export default FooterContact;

import { AppConfig } from '@dtk/config';
import { isApartment, isHouse, getStorageItemWithExpiry } from '@dtk/ui-components';
import { useOfferStore } from '../../store/OfferStore';
import axios from 'axios';

type CookieType = 'utmpara' | 'recommendationId' | 'faOrderID' | 'referrer';

interface BasicEstateValues {
  isShortForm: boolean;
  salutation: string;
  title: string;
  energyEfficiencyClass: string;
  energyEfficiencyHouseStandard: string;
  existingLoads: boolean;
  existingLoadsAmount: string;
  desiredAmount: string;
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  constructionYear: string;
  livingSpace: number;
  garageParkingSpaces: number;
  outdoorParkingSpaces: number;
  window: string;
  heating: string;
  bathToiletModernization: string;
  electricityWaterHeatingModernization: string;
  floorCoveringModernization: string;
  heatingModernization: string;
  windowModernization: string;
  thermalInsulationModernization: string;
  roofModernization: string;
  roomLayoutModernization: string;
  firstname: string;
  lastname: string;
  email: string;
  mobile: string;
  ageUnder55: boolean;
  privacy: number;
  cookieUtmpara: string;
  recommendationId: string;
  altStreet: string;
  altStreetNumber: string;
  altCity: string;
  altZip: string;
  comment: string;
  altAddress: boolean;
  faOrderID: string;
  extpublisher: string;
  referrer: string;
  knownsProductFrom: string;
  advertisingConsent: boolean;
  leadDistributionAllowed: boolean;
  estateAgentReferenceId?: string;
}

interface ValuesFlat extends BasicEstateValues {
  floorLocation: string;
  flatCount: number;
  outdoorLivingArea: string;
  outerWallsInsulated: boolean;
  guestToilet: boolean;
  bathType: string;
  storageRoom: string;
  floorCoveringLivingRoom: string;
  objectType: string;
}

interface ValuesHouse extends BasicEstateValues {
  houseFacilities: string;
  construction: string;
  landArea: number;
  floorCount: number;
  basementType: string;
  basementExtension: string;
  loftConversion: string;
  roofCovering: string;
  buildingType: string;
  objectType: string;
}

function mapCookieArrToObj(str) {
  const obj = {};
  for (let i = 0; i < str.length; i++) {
    const split = str[i].split('=');
    obj[split[0]?.trim()] = split[1]?.trim();
  }
  return obj;
}

const getCookie = (cookieType: CookieType) => {
  const cookie = document.cookie.match('(^|;)\\s*' + cookieType + '\\s*=\\s*([^;]+)');
  if (cookie && cookie.length > 0) {
    const valueStart = cookie[0].indexOf('=');
    if (valueStart !== -1) {
      return cookie[0].substring(valueStart + 1);
    }
    return cookie[0];
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapData = (values: any) => {
  const DEFAULT_MISSING_DATA_VALUE = null;
  const inputValues = useOfferStore.getState().data;
  const isShortForm = useOfferStore.getState().isShortForm;
  const existingLoads = inputValues.get('existingLoads')?.toString().toLowerCase().includes('ja');
  const extpublisher = mapCookieArrToObj(getCookie('utmpara').split('&'))['extpublisher']?.substring(0, 50) || '';
  const referrer = getCookie('referrer')?.substring(0, 150) || '';
  const maklerId = getStorageItemWithExpiry('maklerId');

  const basicBody: BasicEstateValues = {
    isShortForm: isShortForm,
    salutation: values?.salutation?.toString() || DEFAULT_MISSING_DATA_VALUE,
    title: inputValues.get('userTitle')?.toString() || 'keine Angabe',
    energyEfficiencyClass: inputValues.get('energyEfficiencyClass')?.toString() || 'kA',
    energyEfficiencyHouseStandard: inputValues.get('energyEfficiencyHouseStandard')?.toString() || 'kA',
    existingLoads: existingLoads,
    existingLoadsAmount: existingLoads
      ? inputValues.get('existingLoadsAmount')?.toString() || DEFAULT_MISSING_DATA_VALUE
      : '0',
    desiredAmount: useOfferStore.getState().requestedPayout?.toString() || DEFAULT_MISSING_DATA_VALUE,
    street: inputValues.get('street')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    streetNumber: inputValues.get('houseNumber')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    zip: inputValues.get('zipCode')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    city: inputValues.get('city')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    constructionYear: inputValues.get('constructionYear')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    livingSpace: parseInt(inputValues.get('livingSpace')?.toString()) ?? DEFAULT_MISSING_DATA_VALUE,
    garageParkingSpaces: parseInt(inputValues.get('garageParkingSpaces')?.toString()) ?? DEFAULT_MISSING_DATA_VALUE,
    outdoorParkingSpaces: parseInt(inputValues.get('outdoorParkingSpaces')?.toString()) ?? DEFAULT_MISSING_DATA_VALUE,
    window: inputValues.get('window')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    heating: inputValues.get('heating')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    bathToiletModernization: inputValues.get('bathToiletModernization')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    electricityWaterHeatingModernization:
      inputValues.get('electricityWaterHeatingModernization')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    floorCoveringModernization: inputValues.get('floorCoveringModernization')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    heatingModernization: inputValues.get('heatingModernization')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    windowModernization: inputValues.get('windowModernization')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    thermalInsulationModernization:
      inputValues.get('thermalInsulationModernization')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    roofModernization: inputValues.get('roofModernization')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    roomLayoutModernization: inputValues.get('roomLayoutModernization')?.toString() || DEFAULT_MISSING_DATA_VALUE,

    firstname: values?.firstName?.toString() || DEFAULT_MISSING_DATA_VALUE,
    lastname: values?.lastName?.toString() || DEFAULT_MISSING_DATA_VALUE,
    email:
      values?.email?.toString() || inputValues.get('eMailAdresseSaveForm')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    mobile: values?.phone?.toString() || DEFAULT_MISSING_DATA_VALUE,
    ageUnder55: values?.ageUnder55 === 'lessThan55',

    privacy: 1,
    cookieUtmpara: getCookie('utmpara'),
    recommendationId: getCookie('recommendationId'),
    faOrderID: getCookie('faOrderID'),
    altCity: values?.alternativeCity?.toString() || DEFAULT_MISSING_DATA_VALUE,
    altStreet: values?.alternativeStreet?.toString() || DEFAULT_MISSING_DATA_VALUE,
    altStreetNumber: values?.alternativeHouseNumber?.toString() || DEFAULT_MISSING_DATA_VALUE,
    altZip: values?.alternativeZipCode.toString() || DEFAULT_MISSING_DATA_VALUE,
    comment: inputValues.get('optionalComment')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    altAddress: !!inputValues.get('alternativeAddress'),
    extpublisher: extpublisher,
    referrer: referrer,
    knownsProductFrom: inputValues.get('knownsProductFrom')?.toString() || 'keine Angabe',
    advertisingConsent: values?.advertisingConsent,
    leadDistributionAllowed: values?.leadDistributionAllowed,
    estateAgentReferenceId: maklerId,
  };

  if (isApartment(inputValues.get('objectType').toString())) {
    let outdoorLivingArea = inputValues.get('outdoorLivingArea')?.toString() || DEFAULT_MISSING_DATA_VALUE;
    if (outdoorLivingArea === 'balkon') outdoorLivingArea = inputValues.get(outdoorLivingArea).toString();

    let floorLocation = inputValues.get('floorLocation')?.toString() || DEFAULT_MISSING_DATA_VALUE;
    if (floorLocation === 'obergeschoss') floorLocation = inputValues.get(floorLocation).toString();

    const body: ValuesFlat = {
      ...basicBody,
      floorLocation: floorLocation,
      flatCount: parseInt(inputValues.get('flatCount')?.toString()) ?? DEFAULT_MISSING_DATA_VALUE,
      outdoorLivingArea: outdoorLivingArea,
      objectType: 'Eigentumswohnung',
      outerWallsInsulated: inputValues.get('outerWallsInsulated')?.toString().toLowerCase().includes('ja'),
      guestToilet: inputValues.get('guestToilet')?.toString().toLowerCase().includes('ja'),
      bathType: inputValues.get('bathType')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      storageRoom: inputValues.get('storageRoom')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      floorCoveringLivingRoom: inputValues.get('floorCoveringLivingRoom')?.toString() || DEFAULT_MISSING_DATA_VALUE,
    };

    return body;
  } else if (isHouse(inputValues.get('objectType').toString())) {
    let objectType = inputValues.get('haus').toString();
    let buildingType = '';

    switch (objectType) {
      case 'Einfamilienhaus':
        objectType = 'Einfamilienhaus';
        buildingType = 'freistehend';
        break;
      case 'Reihenendhaus':
        objectType = 'Einfamilienhaus';
        buildingType = 'Reihenendhaus';
        break;
      case 'Zweifamilienhaus':
        objectType = 'Zweifamilienhaus';
        buildingType = 'freistehend';
        break;
      case 'Doppelhaushaelfte':
        objectType = 'Einfamilienhaus';
        buildingType = 'Doppelhaushaelfte';
        break;
      case 'Reihenmittelhaus':
        objectType = 'Einfamilienhaus';
        buildingType = 'Reihenmittelhaus';
        break;
      case 'EinfamilienhausMitEinliegerwohnung':
        objectType = 'EinfamilienhausMitEinliegerwohnung';
        buildingType = 'freistehend';
        break;
    }

    const basementExtension = inputValues.get(inputValues.get('basementType')?.toString())?.toString() || '';

    const body: ValuesHouse = {
      ...basicBody,
      objectType: objectType,
      construction: inputValues.get('construction')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      houseFacilities: inputValues.get('houseFacilities')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      landArea: parseInt(inputValues.get('landArea')?.toString()) ?? DEFAULT_MISSING_DATA_VALUE,
      floorCount: parseInt(inputValues.get('floorCount')?.toString()) ?? DEFAULT_MISSING_DATA_VALUE,
      basementType: inputValues.get('basementType')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      basementExtension: basementExtension,
      loftConversion: inputValues.get('loftConversion')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      roofCovering: inputValues.get('roofCovering')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      buildingType: buildingType,
    };
    return body;
  } else {
    const body: Partial<ValuesFlat> = {
      isShortForm: isShortForm,
      existingLoads: existingLoads,
      existingLoadsAmount: inputValues.get('existingLoadsAmount')?.toString() || '0',
      desiredAmount: useOfferStore.getState().requestedPayout?.toString() || DEFAULT_MISSING_DATA_VALUE,
      street: inputValues.get('street')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      streetNumber: inputValues.get('houseNumber')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      zip: inputValues.get('zipCode')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      city: inputValues.get('city')?.toString() || DEFAULT_MISSING_DATA_VALUE,
      email: values?.eMailAdresseSaveForm?.toString() || DEFAULT_MISSING_DATA_VALUE,
      cookieUtmpara: getCookie('utmpara'),
      recommendationId: getCookie('recommendationId'),
      faOrderID: getCookie('faOrderID'),
      extpublisher: extpublisher,
      referrer: referrer,
      knownsProductFrom: inputValues.get('knownsProductFrom')?.toString() || 'keine Angabe',
    };
    return body;
  }
};

export const sendOffer = async (values: unknown, config: AppConfig) => {
  try {
    const mappedData: ValuesHouse | ValuesFlat | Partial<ValuesFlat> = mapData(values);
    const { data } = await axios.post(config['API_BASE'] + '/public/offer/request', mappedData);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error while sending data!', error);
  }
};

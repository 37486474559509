import Image from 'next/future/image';
import { storyBlokLoader } from '../../utils';
import { useMediaQuery } from '@dtk/ui-components';

export const BackgroundImage = (props?: { imageFilename: string | undefined; imageAltText: string | undefined }) => {
  const { largerLg } = useMediaQuery();

  if (props?.imageFilename) {
    return largerLg ? (
      <Image
        loader={storyBlokLoader}
        className="absolute h-[40rem] w-full object-cover object-top"
        src={props.imageFilename}
        alt={props.imageAltText}
        width={2000}
        height={0}
        loading="eager"
        priority
      />
    ) : (
      <Image
        loader={storyBlokLoader}
        src={props.imageFilename}
        alt={props.imageAltText}
        width={1250}
        height={1250}
        sizes="(max-width: 640) 100vw, 75vw"
        loading="eager"
        priority
      />
    );
  }
  return null;
};

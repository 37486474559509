import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const StepHeadlineBlok = ({ blok }) => {
  return (
    <span
      key={blok._uid}
      className="font-headline col-span-12 w-full text-2xl"
      {...storyblokEditable(blok)}
      data-test="step-headline"
    >
      {blok.title}
    </span>
  );
};

export default StepHeadlineBlok;

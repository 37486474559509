import {
  ContactTrackingEvent,
  DocumentServiceTrackingEvent,
  FAQTrackingEvent,
  FormTrackingEvent,
  PropertyInfoTrackingEvent,
  TaskTrackingEvent,
  CTATrackingEvent,
  trackDataPoint,
  NavigationTrackingEvent,
  DocumentTrackingEvent,
} from './data-layer';

export const trackUserLoginStart = () => {
  trackDataPoint({
    event: 'registration_start',
    registration_action: 'start',
  });
};

export const trackUserLoginSuccess = () => {
  trackDataPoint({
    event: 'registration_success',
    registration_action: 'success',
  });
};

export const trackFormStart = (formName: string) => {
  trackDataPoint({
    event: 'form',
    form_action: 'start',
    form_name: formName,
  } as FormTrackingEvent);
};

export const trackFormError = (formName: string) => {
  trackDataPoint({
    event: 'form',
    form_action: 'error',
    form_name: formName,
  } as FormTrackingEvent);
};

export const trackFormSuccess = (formName: string) => {
  trackDataPoint({
    event: 'form',
    form_action: 'success',
    form_name: formName,
  } as FormTrackingEvent);
};

export const trackContact = (channel: 'mail' | 'telephone', contact: string) => {
  trackDataPoint({
    event: 'contact',
    contact_person: contact,
    contact_type: channel,
  } as ContactTrackingEvent);
};

export const trackFAQ = (topic: string) => {
  trackDataPoint({
    event: 'faq',
    faq_topic: topic,
  } as FAQTrackingEvent);
};

export const trackTask = (taskName: string) => {
  trackDataPoint({
    event: 'task',
    task_action: 'start',
    task_name: taskName,
  } as TaskTrackingEvent);
};

export const trackDocumentService = (action: string) => {
  trackDataPoint({
    event: 'document_service',
    document_service_action: action,
  } as DocumentServiceTrackingEvent);
};

export const trackDocumentAction = (
  category: string,
  action: 'upload start' | 'upload success' | 'download' | 'upload error'
) => {
  trackDataPoint({
    document_action: action,
    document_category: category,
    event: 'document',
  } as DocumentTrackingEvent);
};

export const trackPropertyInformation = (action: 'start' | 'actively engaged', type: 'chart' | 'table') => {
  trackDataPoint({
    event: 'property information',
    property_chart: type,
    property_information_action: action,
  } as PropertyInfoTrackingEvent);
};

export const trackNavigation = (navigationElement: string) => {
  trackDataPoint({
    event: 'navigation',
    navigation_item: navigationElement,
  } as NavigationTrackingEvent);
};

export const trackCtaEngagement = (
  ctaId: string,
  ctaText: string,
  ctaAction: 'edit' | 'redirect' | 'upload' | 'download'
) => {
  trackDataPoint({
    event: 'cta',
    cta_action: ctaAction,
    cta_name: ctaId,
    cta_text: ctaText,
  } as CTATrackingEvent);
};

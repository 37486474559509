/**
 * Returns the Tailwind utility class to position the last card
 * in a 12-columns-grid on a Tablet depending on the number of existing cards
 */
export const getColumnStartOfLastCardOnTablet = (index: number, numberOfCards: number): string => {
  // the following utility classes depend on a 12-column grid
  return index === numberOfCards - 1 && numberOfCards % 2 === 1 ? 'sm:col-start-3' : 'sm:col-start-auto';
};

/**
 * Returns the Tailwind utility class to position the last two cards
 * in a 12-columns-grid on Desktop depending on the number of existing cards
 */
export const getColumnStartOfLastCardsOnDesktop = (index: number, numberOfCards: number): string => {
  if (index === numberOfCards - 1 && numberOfCards % 3 === 1) {
    return 'lg:col-start-5';
  } else if (index === numberOfCards - 2 && numberOfCards % 3 === 2) {
    return 'lg:col-start-3';
  }
  return 'lg:col-start-auto';
};

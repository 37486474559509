import { Icon } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const IconBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <span key={blok._uid} {...storyblokEditable(blok)} data-test="icon-blok">
      <Icon props={props} />
    </span>
  );
};

import { useContext } from 'react';
import { AngebotsstreckeContext } from '../../contexts/AngebotsstreckeContext';

import { Button, ButtonSizes, ButtonVariantsRedesign } from '@dtk/ui-components';
import { ChevronLeftIcon } from '@heroicons/react/solid';

interface BackButtonProps {
  variant?: ButtonVariantsRedesign;
  size?: ButtonSizes;
  styles?: string;
  buttonText: string;
}

export const NavigationButtons = ({ activeStep }) => {
  return (
    <>
      {activeStep !== 0 && <BackButton variant="ghost" buttonText="zurück" />}
      <Button type="submit" className="navigation-next-button" fullWidth>
        Weiter
      </Button>
    </>
  );
};

export const BackButton = ({ variant, size, styles, buttonText }: BackButtonProps) => {
  const { handleBack } = useContext(AngebotsstreckeContext);

  return (
    <Button type="button" variant={variant} size={size} className={styles} onClick={() => handleBack()}>
      <ChevronLeftIcon className="mr-2 h-5 w-5" />
      <span className="text-md font-semibold">{buttonText}</span>
    </Button>
  );
};

import { formatCurrency } from '@dtk/formatter';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import TagManager from 'gtm-for-react';
import Slider from 'rc-slider';
import { useState } from 'react';
import { object, string } from 'yup';

import { Button, HeaderText, InputField } from '../01_elements';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { BackgroundThemeVariants, getAlternativePath, themesForBackgroundColor } from '../utils';

type InputType = 'slider' | 'inputField';

type SliderProps = {
  backgroundColor: BackgroundThemeVariants;
  overline?: string;
  inputType: InputType;
  headline: string;
  description?: string;
  isAlternativePrimaryButtonColor: boolean;
  buttonText: string;
  buttonRedirectionLocation: string;
};

const initValues = {
  zipCode: '',
};

const onlyDigitsExpression = /^\d+$/g;
const zipCodeErrorMessage = 'Die eingegebene Postleitzahl muss fünfstellig sein.';
const validationSchema = object({
  zipCode: string()
    .length(5, zipCodeErrorMessage)
    .matches(onlyDigitsExpression, 'Die eingegebene Postleitzahl darf nur Zahlen enthalten')
    .required('PLZ ist ein Pflichtfeld'),
});

const requestedPayoutValues = {
  min: 100000,
  max: 1000000,
  queryParameterName: 'requested-payout',
};

export const Gamification = ({
  backgroundColor,
  overline,
  inputType,
  headline,
  description,
  isAlternativePrimaryButtonColor,
  buttonText,
  buttonRedirectionLocation,
}: SliderProps) => {
  const { smallerXs, smallerSm } = useMediaQuery();
  const [sliderValue, setSliderValue] = useState<number>(requestedPayoutValues.min);
  const [zipCode, setZipCode] = useState<string>('');
  const [, setInputFieldErrorMessage] = useState<string>('');

  const triggerDataLayerPush = () => {
    if (inputType === 'inputField') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'property_information',
          property_information_type: 'zipCode',
          property_information_detail: zipCode,
        },
      });
    } else if (inputType === 'slider') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'property_information',
          property_information_type: 'requestedPayout',
          property_information_detail: formatCurrency(sliderValue, 0),
        },
      });
    }
  };

  const redirectPath = getAlternativePath({ originalLink: buttonRedirectionLocation, condition: smallerSm });

  const handleSubmit = () => {
    triggerDataLayerPush();

    window.location.href =
      inputType === 'inputField'
        ? `${redirectPath}?zip-code=${zipCode}`
        : `${redirectPath}?${requestedPayoutValues.queryParameterName}=${sliderValue}`;
  };

  const handleZipCodeChange = (
    value: string,
    setFieldValue: {
      (field: string, value: string, shouldValidate?: boolean | undefined): void;
    }
  ) => {
    setFieldValue('zipCode', value);
    setZipCode(value);
    setInputFieldErrorMessage(zipCodeErrorMessage);
  };

  return (
    <div className={`bg-${backgroundColor || 'white'} pb-9`}>
      <HeaderText
        headingType="h2"
        theme={backgroundColor || 'white'}
        overline={overline}
        headline={headline}
        subtitle={description}
      />
      <Formik
        initialValues={initValues}
        validationSchema={inputType === 'inputField' && validationSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div
              className={classNames(
                inputType === 'inputField' ? 'xs:flex xs:flex-row xs:items-start xs:justify-center' : 'xs:w-96',
                'xs:mx-auto space-y-4 px-6'
              )}
            >
              {inputType === 'slider' && (
                <>
                  <p
                    className={classNames(
                      themesForBackgroundColor.darkBackgroundColors.includes(backgroundColor)
                        ? 'text-white'
                        : 'text-navy',
                      'text-center text-4xl font-bold antialiased'
                    )}
                  >
                    {formatCurrency(sliderValue, 0)}
                  </p>
                  <Slider
                    railStyle={{
                      backgroundColor: themesForBackgroundColor.sliderRailColor[backgroundColor],
                      height: 8,
                    }}
                    trackStyle={{ backgroundColor: '#4873AD', height: 8 }}
                    handleStyle={{
                      borderWidth: 8,
                      borderColor: '#ffffff',
                      height: 40,
                      width: 40,
                      marginTop: -16,
                      backgroundColor: '#CDB991',
                      opacity: 1,
                      filter: 'drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))',
                      boxShadow: 'none',
                    }}
                    included={true}
                    className="mt-5 mb-12"
                    min={requestedPayoutValues.min}
                    max={requestedPayoutValues.max}
                    step={5000}
                    value={sliderValue}
                    onChange={(value) => {
                      setSliderValue(Number(value));
                    }}
                  />
                </>
              )}
              {inputType === 'inputField' && (
                <InputField
                  containerClassNames="xs:w-96 xs:mr-6 xs:mt-2"
                  className="xs:mb-0 mb-6"
                  data-testid="gamification-input"
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  placeholder="PLZ"
                  isLargeField
                  onChange={(e: { currentTarget: { value: string } }) => {
                    const value = e.currentTarget.value;
                    handleZipCodeChange(value, setFieldValue);
                  }}
                />
              )}
              {inputType && (
                <div className={classNames(inputType === 'slider' && 'pt-5', 'flex justify-center')}>
                  <Button
                    type="submit"
                    size="lg"
                    variant={isAlternativePrimaryButtonColor ? 'red' : 'cyan'}
                    fullWidth={smallerXs}
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

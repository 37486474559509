export interface BlogHeaderProps {
  imageUrl: string;
  headline: string;
  personName: string;
  personPosition: string;
  personEmail: string;
  buttonText: string;
  buttonLink: string;
  firstLine: string;
  secondLine: string;
  thirdLine: string;
  lastLine: string;
}

export const BlogHeader = ({
  imageUrl,
  headline,
  personName,
  personPosition,
  personEmail,
  buttonText,
  buttonLink,
  firstLine,
  secondLine,
  thirdLine,
  lastLine,
}: BlogHeaderProps) => {
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-7xl py-2">
        <h1 className="font-headline text-navy mt-3 text-center text-3xl tracking-tight sm:text-4xl">
          Deutsche Teilkauf <span className="text-gold underline--wave block whitespace-pre">Pressearchiv</span>
        </h1>
        <p className="text-navy mt-3 text-left text-xl sm:mx-0 sm:text-center">
          Hier finden Sie die neuesten Nachrichten und Updates von der Deutschen Teilkauf: Pressemitteilungen und
          Presseveröffentlichungen über das Unternehmen.
        </p>
      </div>
    </div>
  );
};

import { useMediaQuery, UseMediaQueryResult } from '@dtk/ui-components';
import { PartnerBannerOffering } from './PartnerBannerOffering';
import { TeaserBanner } from './TeaserBanner';
import { TopPartnerBannerProps, isPartnerBannerComponent, PartnerBannerProps, TeaserBannerProps } from './types';

export interface PartnerBannerOfferingListProps {
  topBannerComponent: TopPartnerBannerProps[];
  PartnerBannerComponents: PartnerBannerProps[];
  teaserBannerComponent: TeaserBannerProps[];
}

export const PartnerBannerOfferingList = (props: PartnerBannerOfferingListProps) => {
  const { topBannerComponent, PartnerBannerComponents, teaserBannerComponent } = props;

  const sortedBanners = PartnerBannerComponents.sort((a, b) => (a.priority < b.priority ? -1 : 1));
  const topBanner =
    topBannerComponent[0].reference?.content?.global[0] || sortedBanners.find((banner) => banner.isTopBanner);
  const allOtherBanners: (PartnerBannerProps | TeaserBannerProps)[] = sortedBanners.filter(
    (banner) => !banner.isTopBanner
  );

  if (teaserBannerComponent.length > 0) {
    allOtherBanners.length >= 7
      ? allOtherBanners.splice(6, 0, teaserBannerComponent[0])
      : allOtherBanners.push(teaserBannerComponent[0]);
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      {topBanner && (
        <div className="col-span-12">
          <PartnerBannerOffering {...topBanner} key={topBanner._uid} />
        </div>
      )}
      <div className="col-span-12 grid grid-cols-12 gap-6">
        {allOtherBanners.map((component, index) => {
          const rowSize = getComponentSize(useMediaQuery, index);

          return isPartnerBannerComponent(component) ? (
            <div className={`col-span-${rowSize.size}`} key={component._uid}>
              <PartnerBannerOffering {...(component as PartnerBannerProps)} isSmall={rowSize.isSmall} />
            </div>
          ) : (
            <div className="col-span-12 lg:col-span-4 xl:col-span-6" key={component._uid}>
              <TeaserBanner {...(component as TeaserBannerProps)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getComponentSize = (mediaQueryFunc: () => UseMediaQueryResult, index: number): ComponentSize => {
  const { largerSm, largerXl, largerLg } = mediaQueryFunc();

  let rowSize: ComponentSize = { size: 12, isSmall: true };
  const isDoubleBanner = (bannerPosition: number) => {
    return (index + 1) % bannerPosition === 0 || (index + 1) % bannerPosition === 1;
  };

  if (largerSm && !largerLg && !largerXl) {
    const size = isDoubleBanner(6) ? 12 : 6;
    rowSize = { size, isSmall: size === 6 };
  }

  if (largerLg && !largerXl) {
    const size = isDoubleBanner(4) ? 8 : 4;
    rowSize = { size, isSmall: size === 4 };
  }

  if (largerXl) {
    const size = isDoubleBanner(6) ? 6 : 3;
    rowSize = { size, isSmall: size === 3 };
  }

  return rowSize;
};

interface ComponentSize {
  size: number;
  isSmall: boolean;
}

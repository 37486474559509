import classNames from 'classnames';
import { headingType } from '../../01_elements/Headline';
import { getHighlightedText } from '../../utils/highlight-text';
import { themesForBackgroundColor } from '../../utils/themes';
import { HeadlineHeroProps } from './types';

export const HeadlineHero = ({
  headlineText,
  highlightText,
  theme,
  preline,
  highlightTextColor,
  isBold,
  headingType,
}: HeadlineHeroProps) => {
  return (
    <div>
      <p
        className={classNames(
          'mb-1 text-sm uppercase tracking-[.2em]',
          `text-${themesForBackgroundColor.overlineColor[theme]}`
        )}
      >
        {preline}
      </p>
      <HighLightedHeadline
        headingType={headingType}
        headlineText={headlineText}
        highlightText={highlightText}
        highlightTextColor={highlightTextColor}
        isBold={isBold}
        theme={theme}
      />
    </div>
  );
};

const HighLightedHeadline = ({
  headlineText,
  highlightText,
  highlightTextColor,
  isBold,
  theme,
  headingType,
}: HighLightedHeadlineProps) => {
  const headlineClasses = classNames(
    themesForBackgroundColor.darkBackgroundColors.includes(theme) ? 'text-white' : 'text-navy',
    'font-headline text-2xl md:text-4xl md:leading-[3rem] xl:text-5xl xl:leading-[3.75rem]'
  );
  const innerHtml = {
    __html: highlightText
      ? getHighlightedText(headlineText, highlightText, highlightTextColor, {
          fontFamily: 'font-headline',
          isBold: isBold,
        })
      : headlineText,
  };

  if (headingType === 'h3') {
    return <h3 className={headlineClasses} dangerouslySetInnerHTML={innerHtml} />;
  }
  if (headingType === 'h2') {
    return <h2 className={headlineClasses} dangerouslySetInnerHTML={innerHtml} />;
  }

  return <h1 className={headlineClasses} dangerouslySetInnerHTML={innerHtml} />;
};

interface HighLightedHeadlineProps {
  headlineText: string;
  highlightText: string;
  theme: string;
  highlightTextColor?: string;
  headingType: headingType;
  isBold?: boolean;
}

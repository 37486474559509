import { useState } from 'react';
import { StageCurrentActivity } from '@dtk/query';
import { trackTask } from '@dtk/user-tracking';
import { DefaultModal } from '../../01_elements';
import { ContactInfoContent } from '../../03_business';
import { Info } from '../Info';
import { IconDocument } from '../../static';
import { TaskButton } from './TaskButton';
import { TooltipColor } from '../Tooltip';

const INFO_VARIANT = 'tooltip';

export function CustomerTask({ task, infoIconTooltipColorVariant }: CustomerTaskProps) {
  const { title, description, relativeButtonLink, buttonText } = task;
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const isButtonVisible = relativeButtonLink && buttonText;
  const isVollmachtButton = relativeButtonLink?.includes('vollmacht');

  const fireTrackingEvent = () => {
    if (!isButtonVisible) {
      return;
    }

    trackTask(task.title);
  };

  return (
    <>
      <DefaultModal showDialog={showDialog} handleModalClose={() => setShowDialog(false)}>
        <ContactInfoContent />
      </DefaultModal>
      <div className="border-coolGray-300 mb-[37px] flex justify-between rounded-[4px] border bg-white px-[15px] py-[10px] last:mb-0">
        <div className="flex w-full flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="mb-[10px] flex w-full items-center justify-between md:mb-0 md:w-fit md:justify-start">
            <div className="flex items-center">
              <IconDocument />
              <div className="mr-[5px] ml-[10px] text-[16px] font-semibold">{title}</div>
            </div>

            <div className="xs:mt-[2px] mr-[15px] flex h-fit items-center md:mt-[6px]">
              <Info content={description} variant={INFO_VARIANT} colorVariant={infoIconTooltipColorVariant} />
            </div>
          </div>

          {isButtonVisible &&
            (isVollmachtButton ? (
              <TaskButton
                text={buttonText}
                onClick={() => {
                  fireTrackingEvent();
                  setShowDialog(true);
                }}
              />
            ) : (
              <TaskButton href={relativeButtonLink} text={buttonText} onClick={fireTrackingEvent} />
            ))}
        </div>
      </div>
    </>
  );
}

export interface CustomerTask {
  activities?: StageCurrentActivity[];
  uniqueName?: CustomerTaskUniqueNames;
  title: string;
  description: string;
  relativeButtonLink?: string;
  buttonText?: string;
  priority: string;
  status: CustomerTaskStatus;
}

export interface CustomerTaskProps {
  task: CustomerTask;
  infoIconTooltipColorVariant: TooltipColor;
}

export enum CustomerTaskStatus {
  OPEN = '0',
  DONE = '1',
}

export enum CustomerTaskUniqueNames {
  VOLLMACHT = '0',
  MISSING_DOCUMENTS = '1',
}

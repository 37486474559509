import { StepStatus } from './';
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export const StepsHorizontal = ({ steps, handleCurrent, handleStep }: StepsHorizontalProps) => {
  const handleClick = (step: Steps) => {
    if (handleStep) {
      handleStep(step.key);
    }
  };

  const renderStep = (step: Steps, stepIdx: number) => (
    <div className="flex w-full flex-col items-center">
      <div
        className="flex w-full items-center justify-center align-middle"
        onClick={step.status === StepStatus.current ? handleCurrent : () => handleClick(step)}
      >
        <div className="flex h-8 flex-1 items-center">
          {stepIdx > 0 && (
            <div
              className={classNames(
                'h-0.5 w-full',
                step.status !== StepStatus.upcoming ? 'bg-navy-600' : 'bg-gray-200'
              )}
            />
          )}
        </div>
        <div className="relative h-8 text-center">
          {step.status === StepStatus.current && (
            <div className="border-gold-sand absolute -top-1.5 -left-1.5 -bottom-0 z-10 flex h-11 w-11 justify-center rounded-full border-2 bg-white hover:bg-indigo-900" />
          )}

          <button
            data-testid="button-step"
            className={classNames(
              'relative z-20 flex h-8 w-8 items-center justify-center rounded-full',
              step.status === StepStatus.upcoming ? 'bg-coolGray-200' : 'bg-navy-600'
            )}
          >
            {step.status === StepStatus.complete ? (
              <CheckIcon className={classNames('h-5 w-5', 'text-white')} aria-hidden="true" />
            ) : (
              <span className={step.status === StepStatus.current ? 'text-white' : 'text-navy'}>{stepIdx + 1}</span>
            )}
            <span className="sr-only">{step.name}</span>
          </button>
        </div>

        <div className="flex h-8 flex-1 items-center">
          {stepIdx < steps.length - 1 && (
            <div
              className={classNames(
                'h-0.5 w-full',
                step.status !== StepStatus.upcoming ? 'bg-navy-600' : 'bg-gray-200'
              )}
            />
          )}
        </div>
      </div>

      <span className={classNames('pt-4 text-center ', step.status === StepStatus.current ? 'font-semibold' : '')}>
        {step.description}
        <span className="inline-block align-middle">
          {handleCurrent && handleStep && (
            <button
              onClick={step.status === StepStatus.current ? handleCurrent : () => handleClick(step)}
              className="flex items-center pl-1 text-sm sm:text-base"
              data-testid={`info-button`}
            >
              <InformationCircleIcon className="text-navy w-6" />
            </button>
          )}
        </span>
      </span>
    </div>
  );

  return (
    <nav aria-label="Progress">
      <ol className="items-top flex">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="text-navy relative flex w-full">
            {renderStep(step, stepIdx)}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export interface StepsHorizontalProps {
  steps: Steps[];
  handleCurrent?: () => void;
  handleStep?: (status: string) => void;
  renderSpecificStep?: string;
}

export interface Steps {
  id: number;
  name: string;
  description?: string;
  key: string;
  status: StepStatus;
}

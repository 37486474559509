const OFFER_JOURNEY_SLUG = 'angebot-anfordern';
const OFFER_JOURNEY_MOBILE_PATH = `/${OFFER_JOURNEY_SLUG}-mobil/`;

export const getAlternativePath = ({
  originalLink,
  slugToCheck,
  alternativeLink,
  condition,
}: AlternativePathProps): string => {
  const slug = slugToCheck ? slugToCheck : OFFER_JOURNEY_SLUG;
  const newLink = alternativeLink ? alternativeLink : OFFER_JOURNEY_MOBILE_PATH;

  return originalLink?.includes(slug) && condition ? newLink : originalLink;
};

interface AlternativePathProps {
  condition: boolean;
  originalLink: string;
  slugToCheck?: string;
  alternativeLink?: string;
}

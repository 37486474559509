import { storyblokEditable } from '@storyblok/react';
import { FeatureSectionListitem } from '@dtk/ui-components';

const FeatureSectionListItemBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="feature-section-list-item">
    <FeatureSectionListitem {...blok} />
  </div>
);

export default FeatureSectionListItemBlok;

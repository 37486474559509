import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import React from 'react';

const Grid = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="grid">
    {blok.body
      ? blok.body.map((nestedBlok) => (
          <div key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </div>
        ))
      : null}
  </div>
);

export default Grid;

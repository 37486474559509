/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Public Website Service
 * Access Public Website Fucntions
 * OpenAPI spec version: 1.0.0
 */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query';
import type { Newsletter, EstateAgent, Encryption } from '../../model/public';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

/**
 * @summary Registier for Newsletter
 */
export const postNewsletter = (
  newsletter: Newsletter,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.post(`/public/newsletter/register`, newsletter, options);
};

export const usePostNewsletter = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof postNewsletter>, TError, { data: Newsletter }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof postNewsletter>, { data: Newsletter }> = (props) => {
    const { data } = props || {};

    return postNewsletter(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof postNewsletter>, TError, { data: Newsletter }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * @summary Registier for estate agent
 */
export const registerestateAgent = (
  estateAgent: EstateAgent,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.post(`/public/estate-agent/register`, estateAgent, options);
};

export const useRegisterestateAgent = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof registerestateAgent>, TError, { data: EstateAgent }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof registerestateAgent>, { data: EstateAgent }> = (props) => {
    const { data } = props || {};

    return registerestateAgent(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof registerestateAgent>, TError, { data: EstateAgent }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * Decode JWT for estate agent data
 * @summary Decode JWT for estate agent data
 */
export const decodeEstateAgentData = (
  token: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<Encryption>> => {
  return axios.get(`/public/estate-agent/decode/${token}`, options);
};

export const getDecodeEstateAgentDataQueryKey = (token: string) => [`/public/estate-agent/decode/${token}`];

export const useDecodeEstateAgentData = <
  TData = AsyncReturnType<typeof decodeEstateAgentData>,
  TError = AxiosError<unknown>
>(
  token: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof decodeEstateAgentData>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getDecodeEstateAgentDataQueryKey(token);

  const queryFn: QueryFunction<AsyncReturnType<typeof decodeEstateAgentData>> = () =>
    decodeEstateAgentData(token, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof decodeEstateAgentData>, TError, TData>(queryKey, queryFn, {
    enabled: !!token,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

import { ChevronRightIcon } from '@heroicons/react/outline';
import Link from 'next/link';

export interface BreadcrumbProps {
  label: string;
  url: string;
  isLastIndex?: boolean;
}

export const Breadcrumb = ({ label, url, isLastIndex }: BreadcrumbProps) => {
  return isLastIndex ? (
    <span className="text-sm font-semibold text-gray-500">{label}</span>
  ) : (
    <>
      <Link href={url} className="text-gray hover:text-gold xs:mr-4 mr-2 ml-1 font-semibold">
        <a href={url} className="text-sm">
          {label}
        </a>
      </Link>
      <ChevronRightIcon className="text-coolGray-400 my-auto mx-1 h-5 w-5 sm:mx-4" />
    </>
  );
};

import { useFormikContext } from 'formik';
import {
  DropdownField,
  InputField,
  InputFieldCounter,
  floorCoveringLivingRoomOptions,
  floorLocationOptions,
  storageRoomOptions,
  threeChoiceOptions,
  upperFloorTypeOptions,
} from '@dtk/ui-components';
import { LeadTransmissionValues } from './types';

export const ModernizationForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <div className="flex flex-row items-center px-1">
        <div className="text-navy basis-5/12 text-sm uppercase tracking-[.2em]">Modernisierung</div>
        <span className="border-gold-gold basis-7/12 border-b border-solid" />
      </div>

      <p className="text-navy mt-1 text-xl font-semibold">Wurden Modernisierungen vorgenommen?</p>

      <DropdownField
        data-testid="floorCoveringModernization"
        name="floorCoveringModernization"
        label="Bodenbeläge, Wandverkleidungen und Treppenhaus"
        options={threeChoiceOptions}
      />

      <DropdownField
        data-testid="bathToiletModernization"
        name="bathToiletModernization"
        label="Bäder und WCs"
        options={threeChoiceOptions}
      />

      <DropdownField
        data-testid="heatingModernization"
        name="heatingModernization"
        label="Heizung"
        options={threeChoiceOptions}
      />

      <DropdownField
        data-testid="electricityWaterHeatingModernization"
        name="electricityWaterHeatingModernization"
        label="Strom, (Ab-)Wasser, Heizungsleitungen und Heizkörper"
        options={threeChoiceOptions}
      />

      <DropdownField
        data-testid="windowModernization"
        name="windowModernization"
        label="Fenster"
        options={threeChoiceOptions}
      />

      <DropdownField
        data-testid="thermalInsulationModernization"
        name="thermalInsulationModernization"
        label="Wärmedämmung"
        options={threeChoiceOptions}
      />

      <DropdownField
        data-testid="roofModernization"
        name="roofModernization"
        label="Dach"
        options={threeChoiceOptions}
      />

      <DropdownField
        data-testid="roomLayoutModernization"
        name="roomLayoutModernization"
        label="Raumaufteilung"
        options={threeChoiceOptions}
      />

      <p className="text-navy mt-1 text-xl font-semibold">Nur bei Eigentumswohnung auszufüllen:</p>

      <DropdownField
        data-testid="floorLocation"
        name="floorLocation"
        label="Geschosslage der Wohnung"
        options={floorLocationOptions}
        disabled={(values as LeadTransmissionValues).objectType !== 'Eigentumswohnung'}
      />

      <DropdownField
        data-testid="upperFloorType"
        name="upperFloorType"
        label="Obergeschosslage"
        options={upperFloorTypeOptions}
        disabled={
          (values as LeadTransmissionValues).floorLocation !== 'Obergeschoss' ||
          (values as LeadTransmissionValues).objectType !== 'Eigentumswohnung'
        }
      />

      <InputFieldCounter
        id="flatCount"
        name="flatCount"
        label="Gesamtzahl der Wohneinheiten"
        startValue={(values as LeadTransmissionValues).objectType !== 'Eigentumswohnung' ? 0 : 1}
        disabled={(values as LeadTransmissionValues).objectType !== 'Eigentumswohnung'}
      />

      <DropdownField
        data-testid="floorCoveringLivingRoom"
        name="floorCoveringLivingRoom"
        label="Fußbodenbelag"
        options={floorCoveringLivingRoomOptions}
        disabled={(values as LeadTransmissionValues).objectType !== 'Eigentumswohnung'}
      />

      <DropdownField
        data-testid="storageRoom"
        name="storageRoom"
        label="Abstellraum"
        options={storageRoomOptions}
        disabled={(values as LeadTransmissionValues).objectType !== 'Eigentumswohnung'}
      />

      <InputField
        label="Sie haben weitere Anmerkungen?"
        name="additionalInfo"
        maxLength={255}
        component="textarea"
        rows={4}
        placeholder="Ihre optionale Nachricht an uns..."
      />
    </>
  );
};

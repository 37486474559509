import { InputField, MandatoryFieldsMarker } from '@dtk/ui-components';

export const FassadenBlitzForm = () => {
  return (
    <>
      <div className="col-span-6">
        <InputField id="zipCode" name="zipCode" label="Ihre Postleitzahl" type="text" isMandatory />
      </div>
      <MandatoryFieldsMarker />
    </>
  );
};

import { Step } from './Step';

export interface ProcessFlowProps {
  processBlocks: processBlocksProps[];
}

export interface processBlocksProps {
  title: string;
  text: string;
}

export const ProcessFlow = ({ ...processes }: ProcessFlowProps) => {
  return (
    <div className="overflow-hidden bg-gray-50">
      <div className="relative mx-auto max-w-7xl py-5 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="progress flex flex-col items-center justify-center">
            {processes.processBlocks?.map((item, itemId) => (
              <Step
                key={itemId}
                title={item.title}
                description={item.text}
                stepId={itemId}
                isLastElement={itemId !== processes.processBlocks.length - 1 ? false : true}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { Button } from '../01_elements';
import { ImageProps, getAlternativePath, isExternalPage } from '../utils';
import { useMediaQuery } from '../hooks';

export interface DarkVerticalBannerProps {
  _uid: string;
  hasSquareCorners?: boolean;
  image: ImageProps;
  headline: string;
  description: string;
  buttonText: string;
  buttonLink: string;
}

export function DarkVerticalBanner({
  image,
  headline,
  description,
  buttonText,
  buttonLink,
  hasSquareCorners,
}: DarkVerticalBannerProps) {
  const { smallerSm, smallerLg, largerXl } = useMediaQuery();

  const linkPath = getAlternativePath({
    originalLink: buttonLink,
    condition: smallerSm,
  });

  return (
    <div className={classNames('bg-navy grid grid-cols-12 shadow-md', !hasSquareCorners && 'rounded-lg')}>
      <div className="col-span-12 sm:col-span-6 sm:h-[422px] lg:col-span-12 lg:h-auto xl:col-span-6 xl:h-[422px]">
        {image?.filename && (
          <img
            src={`${image.filename}/m/smart`}
            className={classNames(
              !hasSquareCorners &&
                'rounded-t-lg sm:rounded-bl-lg sm:rounded-tr-none lg:rounded-t-lg xl:rounded-bl-lg xl:rounded-tr-none',
              'h-full max-h-[193px] w-full  object-cover sm:max-h-fit ',
              'lg:max-h-[229px]  xl:max-h-fit '
            )}
            alt={image.alt}
            loading="lazy"
            {...{ fetchpriority: 'low' }}
          />
        )}
      </div>
      <div className="col-span-12 flex flex-col p-6 sm:col-span-6 lg:col-span-12 xl:col-span-6">
        <div className="font-headline-semibold text-2xl text-white">{headline}</div>
        {(smallerLg || largerXl) && <p className="text-white">{description}</p>}
        <Button
          className="mt-7 sm:mt-auto lg:mt-5 xl:mt-auto"
          href={linkPath}
          isexternal={isExternalPage(linkPath)}
          variant="cyan"
          size={smallerSm ? 'base' : 'lg'}
          fullWidth
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

import { AnySchema, mixed, number, object, string } from 'yup';
import Reference from 'yup/lib/Reference';
import { validationMessages } from './validationMessages';
import {
  MAXIMUM_CONSTRUCTION_YEAR,
  MAXIMUM_LAND_AREA,
  MAXIMUM_LIVING_SPACE,
  MAXIMUM_REAL_ESTATE_VALUE,
  MAXIMUM_SALES_CLOSING_YEAR,
  MINIMUM_CONSTRUCTION_YEAR,
  MINIMUM_EXISTING_LOADS_AMOUNT,
  MINIMUM_LAND_AREA,
  MINIMUM_LIVING_SPACE,
  MINIMUM_REAL_ESTATE_VALUE,
  MINIMUM_REQUESTED_PAYOUT,
  MINIMUM_SALES_CLOSING_YEAR,
  balconySizeOptions,
  buildingTypeOptions,
  isApartment,
  isHouse,
  isUpperFloor,
  upperFloorTypeOptions,
  yesOrNoOptions,
} from '../estateValues';

const INPUT_FIELD_DEFAULT_ERROR = validationMessages.error.defaultInputField.text;
const DROPDOWN_DEFAULT_ERROR = validationMessages.error.defaultDropdownField.text;

interface OptionProps {
  name: string;
  value: string;
}
const getValidationOptions = (objectOfOptions: OptionProps[], startIndex: number) => {
  return objectOfOptions.map((option) => option.value).slice(startIndex);
};

export const propertyFields = {
  realEstateValue: mixed()
    .test('required', INPUT_FIELD_DEFAULT_ERROR, (value) => !isNaN(value) && value !== '')
    .test('min', validationMessages.error.realEstateValue.min.text, (value) => value >= MINIMUM_REAL_ESTATE_VALUE)
    .test('max', validationMessages.error.realEstateValue.max.text, (value) => value <= MAXIMUM_REAL_ESTATE_VALUE),
  requestedPayout: mixed()
    .test('required', INPUT_FIELD_DEFAULT_ERROR, (value) => !isNaN(value) && value !== '')
    .test('min', validationMessages.error.requestedPayout.min.text, (value) => value >= MINIMUM_REQUESTED_PAYOUT)
    .test({
      name: 'max',
      exclusive: false,
      message: validationMessages.error.requestedPayout.max.text,
      test: function (value: number) {
        return value <= parseInt(this.parent.realEstateValue) / 2;
      },
    }),
};

export const offerJourneyPropertyField = {
  requestedPayout: mixed()
    .test('required', INPUT_FIELD_DEFAULT_ERROR, (value) => !isNaN(value) && value !== '')
    .test('min', validationMessages.error.requestedPayout.min.text, (value) => value >= MINIMUM_REQUESTED_PAYOUT),
};

export const offerJourneyPersonalDataFields = {
  salutation: string().required(DROPDOWN_DEFAULT_ERROR),
  firstName: string().required(INPUT_FIELD_DEFAULT_ERROR),
  lastName: string().required(INPUT_FIELD_DEFAULT_ERROR),
  email: string().email(validationMessages.error.email.text).required(INPUT_FIELD_DEFAULT_ERROR),
  phone: string().required(INPUT_FIELD_DEFAULT_ERROR),
  street: string().required(INPUT_FIELD_DEFAULT_ERROR),
  houseNumber: string().required(INPUT_FIELD_DEFAULT_ERROR),
  zipCode: string()
    .length(5, validationMessages.error.zipCode.text)
    .matches(/^\d+$/, validationMessages.error.zipCode.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  city: string().required(INPUT_FIELD_DEFAULT_ERROR),
  existingLoads: string().oneOf(getValidationOptions(yesOrNoOptions, 1)).required(DROPDOWN_DEFAULT_ERROR),
  existingLoadsAmount: mixed().when('existingLoads', {
    is: 'Ja',
    then: mixed()
      .test('required', INPUT_FIELD_DEFAULT_ERROR, (value) => !isNaN(value) && value !== '')
      .test(
        'min',
        validationMessages.error.existingLoadsAmount.min.text,
        (value) => value >= MINIMUM_EXISTING_LOADS_AMOUNT
      )
      .test({
        name: 'max',
        exclusive: false,
        message: validationMessages.error.existingLoadsAmount.max.text,
        test: function (value) {
          return (value || 0) <= parseInt(this.parent.requestedPayout);
        },
      })
      .typeError(INPUT_FIELD_DEFAULT_ERROR),
    otherwise: mixed(),
  }),
  ...offerJourneyPropertyField,
};

export const calculatorFields = {
  totalSalesAfterYears: number()
    .min(MINIMUM_SALES_CLOSING_YEAR, validationMessages.error.totalSalesAfterYears.min.text)
    .max(MAXIMUM_SALES_CLOSING_YEAR, validationMessages.error.totalSalesAfterYears.max.text),
};

export const realEstateTypeFields = {
  objectType: string().required(DROPDOWN_DEFAULT_ERROR),
  haus: string().when('objectType', {
    is: (value: string) => isHouse(value),
    then: string().oneOf(getValidationOptions(buildingTypeOptions, 1)).required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
  livingSpace: number()
    .min(MINIMUM_LIVING_SPACE, validationMessages.error.livingSpace.min.text)
    .max(MAXIMUM_LIVING_SPACE, validationMessages.error.livingSpace.max.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  landArea: number()
    .min(MINIMUM_LAND_AREA, validationMessages.error.landArea.min.text)
    .max(MAXIMUM_LAND_AREA, validationMessages.error.landArea.max.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  constructionYear: number()
    .min(MINIMUM_CONSTRUCTION_YEAR, validationMessages.error.constructionYear.min.text)
    .max(MAXIMUM_CONSTRUCTION_YEAR, validationMessages.error.constructionYear.max.text)
    .required(INPUT_FIELD_DEFAULT_ERROR),
  floorCount: number().when('objectType', {
    is: (value: string) => isHouse(value),
    then: number().min(1, validationMessages.error.floorCount.min.text).required(INPUT_FIELD_DEFAULT_ERROR),
    otherwise: number(),
  }),
  flatCount: number().when('objectType', {
    is: (value: string) => isApartment(value),
    then: number().min(1, validationMessages.error.flatCount.min.text).required(INPUT_FIELD_DEFAULT_ERROR),
    otherwise: number(),
  }),
  floorLocation: string().required(DROPDOWN_DEFAULT_ERROR),
  obergeschoss: string().when('floorLocation', {
    is: (value: string) => isUpperFloor(value),
    then: string().oneOf(getValidationOptions(upperFloorTypeOptions, 1)).required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
  basementType: string().required(DROPDOWN_DEFAULT_ERROR),
  basementExtension: string().when('basementType', {
    is: (value: string) => value === 'vollunterkellert' || value === 'teilweiseunterkellert',
    then: string().required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
};

export const offerJourneyApartmentFields = {
  outdoorLivingArea: string().required(DROPDOWN_DEFAULT_ERROR),
  balkon: string().when('outdoorLivingArea', {
    is: 'balkon',
    then: string().oneOf(getValidationOptions(balconySizeOptions, 1)).required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
  outerWallsInsulated: string().when('objectType', {
    is: (value: string) => isApartment(value),
    then: string().required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
  guestToilet: string().when('objectType', {
    is: (value: string) => isApartment(value),
    then: string().required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
  bathType: string().required(DROPDOWN_DEFAULT_ERROR),
  storageRoom: string().required(DROPDOWN_DEFAULT_ERROR),
  floorCoveringLivingRoom: string().required(DROPDOWN_DEFAULT_ERROR),
};

const offerJourneyHouseFields = {
  construction: string().required(DROPDOWN_DEFAULT_ERROR),
  loftConversion: string().required(DROPDOWN_DEFAULT_ERROR),
  vollunterkellert: string().when('basementType', {
    is: 'vollunterkellert',
    then: string().required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
  teilweiseunterkellert: string().when('basementType', {
    is: 'teilweiseunterkellert',
    then: string().required(DROPDOWN_DEFAULT_ERROR),
    otherwise: string(),
  }),
  roofCovering: string().required(DROPDOWN_DEFAULT_ERROR),
  alternativeStreet: string().when('alternativeAddress', {
    is: true,
    then: string().required(INPUT_FIELD_DEFAULT_ERROR),
    otherwise: string(),
  }),
  alternativeHouseNumber: string().when('alternativeAddress', {
    is: true,
    then: string().required(INPUT_FIELD_DEFAULT_ERROR),
    otherwise: string(),
  }),
  alternativeZipCode: string().when('alternativeAddress', {
    is: true,
    then: string()
      .length(5, validationMessages.error.zipCode.text)
      .matches(/^\d+$/, validationMessages.error.zipCode.text)
      .required(INPUT_FIELD_DEFAULT_ERROR),
    otherwise: string(),
  }),
  alternativeCity: string().when('alternativeAddress', {
    is: true,
    then: string().required(INPUT_FIELD_DEFAULT_ERROR),
    otherwise: string(),
  }),
};

export const extendedOfferJourneyFields = {
  ...offerJourneyApartmentFields,
  ...offerJourneyHouseFields,
  window: string().required(DROPDOWN_DEFAULT_ERROR),
  heating: string().required(DROPDOWN_DEFAULT_ERROR),
  bathToiletModernization: string().required(DROPDOWN_DEFAULT_ERROR),
  electricityWaterHeatingModernization: string().required(DROPDOWN_DEFAULT_ERROR),
  floorCoveringModernization: string().required(DROPDOWN_DEFAULT_ERROR),
  heatingModernization: string().required(DROPDOWN_DEFAULT_ERROR),
  windowModernization: string().required(DROPDOWN_DEFAULT_ERROR),
  thermalInsulationModernization: string().required(DROPDOWN_DEFAULT_ERROR),
  roofModernization: string().required(DROPDOWN_DEFAULT_ERROR),
  roomLayoutModernization: string().required(DROPDOWN_DEFAULT_ERROR),
};

export const offerJourneySchema = {
  ...offerJourneyPersonalDataFields,
  ...realEstateTypeFields,
  ...extendedOfferJourneyFields,
};

export const getValidationSchema = (schema: Record<string, AnySchema | Reference<unknown>>, fieldNames: string[]) => {
  const currentValidationFields = Object.fromEntries(
    Object.entries(schema).filter(([key]) => fieldNames.includes(key))
  );
  return object(currentValidationFields);
};

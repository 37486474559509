export function SpinnerAngebotsstrecke() {
  return (
    <div data-testid="spinner" className="flex w-full flex-row items-start justify-start">
      <div
        style={{ borderTopColor: '#9CA3AF' }}
        className="border-dtkGold-100 h-9 w-9 animate-spin rounded-full border-4 border-solid"
      />
      <div className="mt-1 ml-4 text-base font-medium">Ihre Angaben werden geprüft...</div>
    </div>
  );
}

import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import classNames from 'classnames';

export const TableRow = ({ ...props }) => {
  const { blok, index, backgroundColor, fixedColumns, highlightedColumns } = props['data'];

  return (
    <tr className={classNames(backgroundColor, '!divide-y-1 !divide-x-0 !divide-gray-400 ')}>
      {blok?.cells?.map((nestedBlok: SbBlokData, cellIndex: number) => {
        const isHighlighted: boolean = highlightedColumns.includes(cellIndex + 1);
        const heightlightColor: string = index % 2 === 0 ? '!bg-gold-100' : '!bg-gold-300';
        const isFixed: boolean = fixedColumns?.includes(cellIndex + 1);

        return (
          <td
            key={nestedBlok._uid}
            className={classNames(
              isHighlighted && heightlightColor,
              isFixed ? 'min-w-56.25' : 'min-w-[10rem]',
              '!rounded-none !border-0 !p-0  align-top	'
            )}
          >
            <StoryblokComponent isFixed={isFixed} key={nestedBlok._uid} blok={nestedBlok} />
          </td>
        );
      })}
    </tr>
  );
};

//TODO: FIX TYPES
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import resolveConfig from 'tailwindcss/resolveConfig';
import presetConfig from '../../../../../tailwind.preset';
import get from 'lodash.get';

export interface TailwindConfig {
  colors: {
    [key: string]: string | [key: string];
  };
  screens: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
    '2xl': number;
  };
}

export const useTailwind = () => {
  const tailwindConfig = resolveConfig(presetConfig);

  const colors = tailwindConfig?.theme?.colors;

  const screens = {
    xs: formatScreenSize(tailwindConfig?.theme?.screens?.xs),
    sm: formatScreenSize(tailwindConfig?.theme?.screens?.sm),
    md: formatScreenSize(tailwindConfig?.theme?.screens?.md),
    lg: formatScreenSize(tailwindConfig?.theme?.screens?.lg),
    xl: formatScreenSize(tailwindConfig?.theme?.screens?.xl),
  };

  return { colors: colors, screens: screens };
};

// Tailwind does not support dynamic classes / class concatenation
export const tailwindClassToThemeValue = (tailwindClass: string | undefined): string | undefined => {
  if (!tailwindClass) return;

  const tailwindConfig = resolveConfig(presetConfig);

  const colors = tailwindConfig?.theme?.colors;

  const classes = tailwindClass.split('-').join('.');
  const color = get(colors, classes);
  if (typeof color === 'object') {
    return get(colors, `${classes}.DEFAULT`);
  }

  return color;
};

const formatScreenSize = (screenSize: string | undefined): number => {
  if (!screenSize) throw new Error('Screen size is not defined');
  return Number(screenSize.replace('px', ''));
};

import React, { useLayoutEffect } from 'react';

import { storyblokEditable } from '@storyblok/react';
import styles from './IframeBlok.module.scss';

const IframeBlok = ({ blok }) => {

  useLayoutEffect(() => {
    document.querySelector('html').style.overflow = 'auto';
  }, []);

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="post-3">
      {blok.id ? (
        <div id="container" />
      ) : (
        <iframe
          id={blok.id || 'iframe'}
          className={styles['Iframe'] + ' iframe'}
          src={blok.link}
          height={blok.height || 1000}
        />
      )}
    </div>
  );
};

export default IframeBlok;

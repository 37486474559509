import { Certificate } from '@dtk/public-website-ui-components';
import { Container } from '@dtk/ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const CertificationCloud = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="certification-cloud">
    <Container className="mb-16">
      <Certificate {...blok} />
    </Container>
  </div>
);

export default CertificationCloud;

import { Button } from '../../../../01_elements/Button';
import { PrinterIcon } from '@heroicons/react/outline';
import { ShareButtonComponentProps } from '../socialMediaPlatformConfig';

export const PrintButton = ({ ...props }: ShareButtonComponentProps) => {
  const { className } = props;

  return (
    <Button onClick={() => window.print()} className={className} size="sm" variant="ghost">
      <PrinterIcon width="18" height="18" />
      <span className="ml-3">Drucken</span>
    </Button>
  );
};

import { Headline, headingType } from '../../01_elements/Headline';

import { ChevronDownIcon } from '@heroicons/react/outline';
import { Container } from '../../01_elements/Container';
import classNames from 'classnames';
import { useState } from 'react';

export interface FAQSectionProps {
  faqs: Faq[];
  overline: string;
  headingType: headingType;
  headline: string;
  subtitle: string;
}

interface Faq {
  question: string;
  answer: string;
}

export function FAQSection({ faqs, overline, headingType, headline, subtitle }: FAQSectionProps) {
  const chosenHeadingType = headingType || 'h2';

  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <Container className="py-10 md:py-16">
      <div className="mb-10 space-y-4 text-center">
        <div className="text-gold text-center text-sm uppercase tracking-[.2em]">{overline}</div>
        <Headline
          headingType={chosenHeadingType}
          headlineClassNames="text-navy font-headline break-all"
          headline={headline}
          isBold={true}
        />
        <div className="text-navy text-xl">{subtitle}</div>
      </div>

      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 sm:pb-16 lg:px-8">
        <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
          <dl className="faq-section mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq, index) => (
              <div key={faq.question} className="pt-6">
                <button
                  type="button"
                  className="text-navy flex w-full items-start justify-between text-left"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="question font-body-semibold text-lg">{faq.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    <ChevronDownIcon
                      className={classNames(activeIndex === index ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                      aria-hidden="true"
                    />
                  </span>
                </button>
                <div className={`${activeIndex === index ? 'block' : 'hidden'} mt-2 pr-12`}>
                  <span className="text-navy answer text-lg">{faq.answer}</span>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </Container>
  );
}

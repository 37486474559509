import classNames from 'classnames';

export interface InfoBrochureFormSectionMarkProbs {
  text: string;
  icon: string;
  iconAlt: string;
}

export const InfoBrochureFormSectionMark = ({ text = '', icon = '', iconAlt }: InfoBrochureFormSectionMarkProbs) => {
  return (
    <div className="pl-[2rem]">
      <div className={classNames(!icon && 'bg-navy rounded', 'inline-block h-[1rem] w-[1rem]')}>
        <img
          src={icon}
          alt={iconAlt}
          loading="lazy"
          className={classNames(!icon && 'invisible', 'h-full w-full object-cover')}
        />
      </div>
      <h2 className="font-body ml-2 inline-block text-lg leading-10">{text}</h2>
    </div>
  );
};

import Head from 'next/head';
import React from 'react';

const NextAndPrevLinks: React.FC<{ story }> = ({ story }) => {
  // todo this should be replaced with .env.config https://github.com/vercel/next.js/discussions/16429
  const domain = 'https://deutsche-teilkauf.de/';

  const prevLink = story?.prevLink ? `${domain}${story.prevLink}` : undefined;
  const nextLink = story?.nextLink ? `${domain}${story.nextLink}` : undefined;

  return (
    <Head>
      {prevLink && <link rel="prev" href={prevLink} />}
      {nextLink && <link rel="next" href={nextLink} />}
    </Head>
  );
};

export default NextAndPrevLinks;

import classNames from 'classnames';
import Link from 'next/link';
import {
  StoryblokIcon,
  TrustPilotBox,
  getTrustPilotWidgetStyles,
  isTrustPilotConsentConfirmed,
  useMediaQuery,
} from '@dtk/ui-components';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

const FooterBottomBlok = ({ blok }) => {
  const { smallerSm, largerSm } = useMediaQuery();
  const optimizedImageSize = smallerSm ? '/m/240x0/smart' : '/m/480x0/smart';
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="footer-bottom">
      <div className="mx-6 text-lg sm:text-base md:text-sm xl:max-w-[96rem] 2xl:mx-auto">
        <div className="mt-16 mb-5 grid grid-cols-12">
          <div className="col-span-12 mb-7 text-sm md:col-span-8 md:ml-6 lg:col-span-6 lg:col-end-7">
            <div className="flex flex-col sm:flex-row sm:items-start md:mr-7 md:justify-end">
              <TrustPilotBox
                containerClassNames="sm:order-2 sm:mx-7"
                width={largerSm ? '150px' : '240px'}
                height={largerSm ? '100px' : '150px'}
                reviewStyle={getTrustPilotWidgetStyles().mini}
              />
              <div
                className={classNames(
                  'flex flex-row space-x-7 sm:order-1',
                  !isTrustPilotConsentConfirmed() && 'mt-7 sm:mt-0'
                )}
              >
                {blok?.firstCertificationImage?.filename && blok?.firstCertificationLink && (
                  <Link href={blok.firstCertificationLink} passHref>
                    <a target="_blank" rel="noopener noreferrer">
                      <StoryblokIcon
                        iconSource={`${blok.firstCertificationImage.filename}${optimizedImageSize}`}
                        alt={blok.firstCertificationImage.alt}
                        width={56}
                      />
                    </a>
                  </Link>
                )}
                {blok?.secondCertificationImage?.filename && blok?.secondCertificationLink && (
                  <Link href={blok.secondCertificationLink} passHref>
                    <a target="_blank" rel="noopener noreferrer">
                      <StoryblokIcon
                        iconSource={`${blok.secondCertificationImage.filename}${optimizedImageSize}`}
                        alt={blok.secondCertificationImage.alt}
                        width={158}
                      />
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-4 md:col-start-9 lg:col-span-6">
            <p className="text-navy font-body-bold mb-2 antialiased">{blok.certificationHeadline}</p>
            {blok.certificationSubline.split('\n').map((textItem, index) => (
              <div key={`${textItem}_${index}`} className="text-navy-700 leading-6">
                {textItem}
              </div>
            ))}
          </div>
        </div>
        <div
          key="upper-seperator"
          className="border-cold-gray-200 mb-4 flex flex-row justify-center border-0 border-b sm:mb-6"
        />
        <div
          key="links"
          className="text-navy-700 flex flex-col flex-wrap justify-center space-x-0 sm:my-10 sm:flex-row sm:space-x-7 sm:space-y-0"
        >
          {largerSm && <p className="font-body-bold">{blok.copyrightText}</p>}
          {blok.bottomLinks &&
            blok.bottomLinks.map((nestedBlok) => (
              <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} styles="pb-1 sm:pb-2" />
            ))}
          {smallerSm && (
            <>
              <div
                key="lower-seperator"
                className="border-cold-gray-200 mb-4 flex flex-row justify-center border-0 border-b sm:mb-6"
              />
              <p className="font-body-bold pb-16">{blok.copyrightText}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterBottomBlok;

import { StoryblokIcon } from '@dtk/ui-components';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Link from 'next/link';
import { useState } from 'react';
import { MobileNavigationLinkProps, NavigationSubLinkProps } from './Navigation';

export const MobileMenu = ({
  mobileIcon,
  mobileImageWidth,
  name,
  navigationSubLinks,
  portalBaseUrl,
  url,
}: MobileNavigationLinkProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <li
        className={classNames(
          navigationSubLinks?.length === 0 && 'hover:bg-gray-50',
          'text-navy border-b px-4 py-3 text-base font-normal'
        )}
        onClick={() => setOpen(!open)}
      >
        <span className="flex">
          {url && url?.includes('#') && (
            <Disclosure.Button as="a" href={url} className="flex w-full">
              <NavigationIcon name={name} mobileIcon={mobileIcon} mobileImageWidth={mobileImageWidth} />
              {name}
            </Disclosure.Button>
          )}
          {url && !url?.includes('#') && (
            <a className="w-full" href={url}>
              <NavigationIcon name={name} mobileIcon={mobileIcon} mobileImageWidth={mobileImageWidth} />
              {name}
            </a>
          )}
          {!url && <p>{name}</p>}

          {!open && navigationSubLinks && navigationSubLinks.length > 0 && <ChevronDownIcon className="ml-auto h-5" />}
          {open && navigationSubLinks && navigationSubLinks.length > 0 && <ChevronUpIcon className="ml-auto h-5" />}
        </span>
      </li>
      {navigationSubLinks && navigationSubLinks.length > 0 && (
        <ul className={classNames(open ? 'block' : 'hidden', 'border-b')} id="mobil-navi-submenu-list">
          {navigationSubLinks?.map(({ name, url, redirectsToPortal }: NavigationSubLinkProps, id) => (
            <li
              key={id}
              className="text-navy px-6 py-3 text-base font-normal hover:bg-gray-50"
              id={`mobil-navi-submenu-list-${name}`}
            >
              <Link passHref href={redirectsToPortal ? `${portalBaseUrl}${url}` : url}>
                <a href={redirectsToPortal ? `${portalBaseUrl}${url}` : url} className="flex">
                  {name}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const NavigationIcon = ({ mobileIcon, mobileImageWidth: imageWidth }: MobileNavigationLinkProps) => {
  return mobileIcon?.filename ? (
    <StoryblokIcon
      className="pr-2"
      iconSource={mobileIcon?.filename}
      alt={mobileIcon?.alt}
      width={imageWidth}
      height={imageWidth}
    />
  ) : null;
};

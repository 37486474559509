export const MINIMUM_REAL_ESTATE_VALUE = 200000;
export const MAXIMUM_REAL_ESTATE_VALUE = 10000000;
export const MINIMUM_REQUESTED_PAYOUT = 100000;
export const MINIMUM_EXISTING_LOADS_AMOUNT = 1000;
export const MINIMUM_LIVING_SPACE = 50;
export const MAXIMUM_LIVING_SPACE = 1000;
export const MINIMUM_LAND_AREA = 50;
export const MAXIMUM_LAND_AREA = 10000;
export const MINIMUM_CONSTRUCTION_YEAR = 1700;
export const MAXIMUM_CONSTRUCTION_YEAR = new Date().getFullYear();

export const MINIMUM_SALES_CLOSING_YEAR = 10;
export const MAXIMUM_SALES_CLOSING_YEAR = 30;
export const USAGE_FEE_RATE = 0.0499;
export const VALUE_INCREASE_RATE = 0.0226;
export const DTK_BONUS_RATE = 0.085;
export const MINIMUM_SHARE_FOR_DTK = 0.17;
export const ESTATE_AGENT_COST_RATE = 0.0357;
export const RENTAL_COST_RATE = 0.035;

export const ADDRESS_FIELD_NAMES = ['street', 'houseNumber', 'zipCode', 'city'];
export const REQUESTED_PAYOUT_FIELD_NAME = 'requestedPayout';

type TYPE_HOUSE = 'Haus' | 'haus';
type TYPE_APARTMENT = 'Eigentumswohnung' | 'wohnung';
type TYPE_UPPER_FLOOR = 'Obergeschoss' | 'obergeschoss';

export const isHouse = (value: string): value is TYPE_HOUSE => {
  return value === 'Haus' || value === 'haus';
};

export const isApartment = (value: string): value is TYPE_APARTMENT => {
  return value === 'Eigentumswohnung' || value === 'wohnung';
};

export const isUpperFloor = (value: string): value is TYPE_UPPER_FLOOR => {
  return value === 'Obergeschoss' || value === 'obergeschoss';
};

export const defaultOption = 'Bitte wählen Sie aus';
export const yesOrNoOptions = [
  { name: defaultOption, value: '' },
  { name: 'Ja', value: 'Ja' },
  { name: 'Nein', value: 'Nein' },
];

export const salutationOptions = [defaultOption, 'Frau', 'Herr'];
export const titleOptions = [defaultOption, 'Dir.', 'Dr.', 'Mag.', 'Ing.', 'Dipl.Ing.', 'Prof.', 'Dkfm.', 'Prok.'];

export const age55_Options = [
  { name: defaultOption, value: '' },
  { name: 'Ich bin jünger als 55', value: 'lessThan55' },
  { name: 'Ich bin 55 oder älter', value: 'moreThan55' },
];

export const knownsProductFromOptions = [
  defaultOption,
  'Suchmaschine (Google / Bing)',
  'Soziale Medien',
  'Zeitung online',
  'Zeitung gedruckt',
  'E-Mail',
  'YouTube',
  'Fernsehen/TV',
  'Radio',
  'Außenwerbung (Plakate, Bus etc.)',
  'Brief/Flyer',
  'Veranstaltung/Webinar',
  'Empfehlung',
  'keine Angabe',
  'Sonstige',
];

export const existingLoadsOptions = [defaultOption, 'Ja', 'Nein'];

export const objectTypeOptions = [defaultOption, 'Haus', 'Eigentumswohnung'];

export const buildingTypeOptions = [
  { name: defaultOption, value: '' },
  { name: 'Einfamilienhaus', value: 'Einfamilienhaus' },
  { name: 'Doppelhaushälfte', value: 'Doppelhaushaelfte' },
  { name: 'Reihenendhaus', value: 'Reihenendhaus' },
  { name: 'Reihenmittelhaus', value: 'Reihenmittelhaus' },
  { name: 'Einfamilienhaus mit Einliegerwohnung', value: 'EinfamilienhausMitEinliegerwohnung' },
  { name: 'Zweifamilienhaus', value: 'Zweifamilienhaus' },
  { name: 'Mehrfamilienhaus', value: 'Mehrfamilienhaus' },
];

export const constructionOptions = [
  { name: defaultOption, value: '' },
  { name: 'Massivbau', value: 'Massiv' },
  { name: 'Fertigbau (Massiv)', value: 'Fertighausmassiv' },
  { name: 'Fertigbau (Holz)', value: 'FertighausHolz' },
];

export const loftConversionOptions = [
  { name: defaultOption, value: '' },
  { name: 'Voll ausgebaut', value: 'vollausgebaut' },
  { name: 'Teilweise ausgebaut', value: 'teilweiseausgebaut' },
  { name: 'Nicht ausgebaut', value: 'nichtausgebaut' },
  { name: 'Flachdach', value: 'Flachdach' },
];

export const basementTypeOptions = [
  { name: defaultOption, value: '' },
  { name: 'Vollunterkellert', value: 'vollunterkellert' },
  { name: 'Teilweise unterkellert', value: 'teilweiseunterkellert' },
  { name: 'Nicht unterkellert', value: 'nichtunterkellert' },
];

export const basementExtensionOptions = [
  { name: defaultOption, value: '' },
  { name: 'Komplett ausgebaut', value: 'vollausgebaut' },
  { name: 'Teilweise ausgebaut', value: 'teilweiseausgebaut' },
  { name: 'Nicht ausgebaut', value: 'nichtausgebaut' },
];

export const heatingOptions = [
  { name: defaultOption, value: '' },
  { name: 'Einzelöfen', value: 'Einzeloefen' },
  { name: 'Fußbodenheizung', value: 'Fussbodenheizung' },
  { name: 'Gebäude- oder Zentralheizung', value: 'GebaeudeoderWohnungszentralheizung' },
  { name: 'Sonstiges', value: 'sonstige' },
];

export const roofCoveringOptions = [
  { name: defaultOption, value: '' },
  { name: 'Dachpappe', value: 'Dachpappe' },
  { name: 'Dachpfannen / Ziegel', value: 'Dachpfannen' },
  { name: 'Kunstschiefer', value: 'Kunstschiefer' },
  { name: 'Naturschiefer / Metall', value: 'NaturschieferMetall' },
  { name: 'Sonstiges', value: 'sonstige' },
];

export const windowOptions = [
  { name: defaultOption, value: '' },
  { name: 'einfach verglast', value: 'einfachverglast' },
  { name: 'zweifach verglast', value: 'zweifachverglast' },
  { name: 'dreifach verglast', value: 'dreifachverglast' },
  { name: 'große feststehende Fensterflächen', value: 'grossefeststehendeFensterflaechen' },
  { name: 'Spezialverglasung', value: 'Spezialverglasung' },
  { name: 'Sonstiges', value: 'sonstige' },
];

export const outdoorLivingAreaOptions = [
  { name: defaultOption, value: '' },
  { name: 'Balkon/Terrasse', value: 'BalkonOderTerrasse' },
  { name: 'Wintergarten', value: 'Wintergarten' },
  { name: 'Keine', value: 'keine' },
];

export const balconySizeOptions = [
  { name: defaultOption, value: '' },
  { name: 'bis 10m²', value: 'Balkonkleiner10qm' },
  { name: 'über 10m²', value: 'Balkongroesser10qm' },
];

export const energyEfficiencyClassOptions = [
  { name: 'keine Angabe', value: 'kA' },
  { name: 'A+', value: 'Aplus' },
  { name: 'A', value: 'A' },
  { name: 'B', value: 'B' },
  { name: 'C', value: 'C' },
  { name: 'D', value: 'D' },
  { name: 'E', value: 'E' },
  { name: 'F', value: 'F' },
  { name: 'G', value: 'G' },
  { name: 'H', value: 'H' },
];

export const energyEfficiencyHouseStandardOptions = [
  { name: 'keine Angabe', value: 'kA' },
  { name: 'KfW 40+', value: 'kfWEffizienhaus40plus' },
  { name: 'KfW 40', value: 'kfWEffizienzhaus40' },
  { name: 'KfW 55', value: 'kfWEffizienzhaus55' },
  { name: 'KfW 70', value: 'kfWEffizienzhaus70' },
  { name: 'KfW 85', value: 'kfWEffizienzhaus85' },
  { name: 'KfW 100', value: 'kfWEffizienzhaus100' },
  { name: 'KfW 115', value: 'kfWEffizienzhaus115' },
  { name: 'Denkmal', value: 'kfWEffizienzhausDenkmal' },
];

export const threeChoiceOptions = [
  { name: defaultOption, value: '' },
  { name: 'Keine', value: 'keine' },
  { name: 'Teilweise', value: 'teilweise' },
  { name: 'Komplett', value: 'komplett' },
];

export const floorLocationOptions = [
  { name: defaultOption, value: '' },
  { name: 'Untergeschoss', value: 'Untergeschoss' },
  { name: 'Erdgeschoss', value: 'Erdgeschoss' },
  { name: 'Obergeschoss', value: 'Obergeschoss' },
];

export const upperFloorTypeOptions = [
  { name: defaultOption, value: '' },
  { name: '1. Obergeschoss', value: '1_Obergeschoss' },
  { name: '2. Obergeschoss', value: '2_Obergeschoss' },
  { name: '3. Obergeschoss', value: '3_Obergeschoss' },
  { name: '4. Obergeschoss', value: '4_Obergeschoss' },
  { name: '5. Obergeschoss', value: '5_Obergeschoss' },
  { name: '6. Obergeschoss', value: '6_Obergeschoss' },
  { name: '7. Obergeschoss', value: '7_Obergeschoss' },
  { name: 'über 7. Obergeschoss', value: 'ueber8Geschosse' },
];

export const floorCoveringLivingRoomOptions = [
  { name: defaultOption, value: '' },
  { name: 'Kunststoff/PVC', value: 'KunststoffPVC' },
  { name: 'Fliesen', value: 'Fliesen' },
  { name: 'Parkett', value: 'ParkettNaturstein' },
  { name: 'Teppich/Laminat', value: 'TeppichLaminat' },
  { name: 'Sonstiges', value: 'sonstiges' },
];

export const storageRoomOptions = [
  { name: defaultOption, value: '' },
  { name: 'innerhalb der Wohnung', value: 'innerhalbderWohnung' },
  { name: 'außerhalb der Wohnung', value: 'ausserhalbderWohnung' },
  { name: 'nicht vorhanden', value: 'keiner' },
];

export const estateValueDropdownMapper = {
  energyEfficiencyClass: energyEfficiencyClassOptions,
  energyEfficiencyHouseStandard: energyEfficiencyHouseStandardOptions,
  salutation: salutationOptions,
  userTitle: titleOptions,
  knownsProductFrom: knownsProductFromOptions,
  ageUnder55: age55_Options,
};

import { ArrowCircleRightIcon, XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import { isDateWithinTimePeriod } from './utils';

export interface NavigationBannerProps {
  showBanner?: boolean;
  bannerUrl: string;
  timePeriodStart?: Date;
  timePeriodEnd?: Date;
  displayTimeInMinutes?: number;
  badgeText?: string;
  badgeBgColor?: string;
  text?: string;
  icon?: IconProps;
  iconText?: string;
  colorCombination?: string;
}

type IconProps = {
  filename: string;
  alt: string;
};

type ColorCombinationProps = {
  [key: string]: {
    backgroundColor: string;
    badgeBackgroundColor: string;
  };
};

export const NavigationBanner = ({
  showBanner = false,
  bannerUrl = '',
  colorCombination = '',
  timePeriodStart,
  timePeriodEnd,
  displayTimeInMinutes = 1,
  badgeText,
  text,
  iconText,
}: NavigationBannerProps) => {
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);

  const handleClose = () => {
    setDisplayBanner(false);
    sessionStorage.setItem('bannerIsShown', 'true');
  };

  const isBannerActive = useCallback(() => {
    if (timePeriodStart && timePeriodEnd) {
      return isDateWithinTimePeriod(timePeriodStart, timePeriodEnd, showBanner);
    }
    return false;
  }, [timePeriodEnd, timePeriodStart, showBanner]);

  const colorCombinations: ColorCombinationProps = {
    blue: {
      backgroundColor: 'from-navy-700 via-cyan-600 to-navy-700 bg-gradient-to-r',
      badgeBackgroundColor: 'bg-cyan-900',
    },
    red: {
      backgroundColor: 'from-red-800 via-red-500 to-red-800 bg-gradient-to-r',
      badgeBackgroundColor: 'bg-red-800',
    },
    gold: {
      backgroundColor: 'from-gold-800 via-gold-600 to-gold-800 bg-gradient-to-r',
      badgeBackgroundColor: 'bg-gold-800',
    },
  };

  useEffect(() => {
    setDisplayBanner(isBannerActive());
    const timeout = setTimeout(() => {
      handleClose();
    }, displayTimeInMinutes * 60 * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [displayTimeInMinutes, isBannerActive, displayBanner]);

  const backgroundColor = colorCombination ? colorCombinations[colorCombination]?.backgroundColor : 'bg-cyan';
  const badgeBackgroundColor = colorCombination ? colorCombinations[colorCombination]?.badgeBackgroundColor : 'bg-navy';

  return (
    <div>
      {displayBanner && (
        <div className={classNames(backgroundColor, 'navi:absolute navi:top-0 z-50 w-full text-white')}>
          <div className="mx-auto flex max-w-7xl flex-row items-center justify-center py-4 px-4 sm:px-6 lg:px-8 xl:py-1">
            <Link passHref href={bannerUrl}>
              <a href={bannerUrl} className="flex flex-col items-center md:flex-row">
                {badgeText && (
                  <span
                    className={classNames(
                      badgeBackgroundColor,
                      'mb-2 rounded-md py-1 px-2 font-bold uppercase hover:underline md:mb-0 md:mr-4'
                    )}
                  >
                    {badgeText}
                  </span>
                )}
                <div className="mr-0 items-center text-center sm:flex sm:flex-row md:mr-8 md:text-left">
                  {text && <span className="leading-1 inline text-base lg:leading-8">{text}</span>}
                  {iconText && <span>{iconText}</span>}
                  <ArrowCircleRightIcon className="ml-2 inline h-5 w-5 md:h-7 md:w-7" />
                </div>
              </a>
            </Link>
            <div className="absolute right-2 top-0 flex items-center md:right-4 lg:right-8 lg:top-auto">
              <button
                onClick={() => handleClose()}
                type="button"
                className="flex rounded-md py-2 pr-0 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white md:px-2"
              >
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Customer Data Service
 * Access customer's data and estate objects
 * OpenAPI spec version: 1.0.0
 */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query';
import type {
  Contact,
  Immobilie,
  MarketPlaceEmail,
  PricingTrend,
  RentForecast,
  IndexExplanation,
  SocioDemographics,
  ProposalEstateValueIncrease,
  Stage,
  Customer,
  TutorialStatus,
  ProductOffer,
} from '../../model/customer-data';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R> ? R : any;

/**
 * @summary Get contact object
 */
export const getContactData = (options?: AxiosRequestConfig): Promise<AxiosResponse<Contact>> => {
  return axios.get(`/customer-data/contact`, options);
};

export const getGetContactDataQueryKey = () => [`/customer-data/contact`];

export const useGetContactData = <
  TData = AsyncReturnType<typeof getContactData>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getContactData>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetContactDataQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getContactData>> = () => getContactData(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getContactData>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get estate object
 */
export const getEstateData = (options?: AxiosRequestConfig): Promise<AxiosResponse<Immobilie>> => {
  return axios.get(`/customer-data/estate`, options);
};

export const getGetEstateDataQueryKey = () => [`/customer-data/estate`];

export const useGetEstateData = <
  TData = AsyncReturnType<typeof getEstateData>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getEstateData>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetEstateDataQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getEstateData>> = () => getEstateData(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getEstateData>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Update estate object
 */
export const updateEstateData = (
  immobilie: Immobilie,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.patch(`/customer-data/estate`, immobilie, options);
};

export const useUpdateEstateData = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof updateEstateData>, TError, { data: Immobilie }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof updateEstateData>, { data: Immobilie }> = (props) => {
    const { data } = props || {};

    return updateEstateData(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof updateEstateData>, TError, { data: Immobilie }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * @summary Validate marketplace
 */
export const updateValidateMarketplace = (
  marketPlaceEmail: MarketPlaceEmail,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.patch(`/customer-data/marketplace-validation`, marketPlaceEmail, options);
};

export const useUpdateValidateMarketplace = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateValidateMarketplace>,
    TError,
    { data: MarketPlaceEmail },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof updateValidateMarketplace>, { data: MarketPlaceEmail }> = (
    props
  ) => {
    const { data } = props || {};

    return updateValidateMarketplace(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof updateValidateMarketplace>, TError, { data: MarketPlaceEmail }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * Returns pricing trend data for past 5 years and future 5 years
 * @summary Get pricing trend
 */
export const getEstatePricingTrend = (options?: AxiosRequestConfig): Promise<AxiosResponse<PricingTrend>> => {
  return axios.get(`/customer-data/pricing-trend`, options);
};

export const getGetEstatePricingTrendQueryKey = () => [`/customer-data/pricing-trend`];

export const useGetEstatePricingTrend = <
  TData = AsyncReturnType<typeof getEstatePricingTrend>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getEstatePricingTrend>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetEstatePricingTrendQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getEstatePricingTrend>> = () =>
    getEstatePricingTrend(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getEstatePricingTrend>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * Returns rent forecast for 20 years
 * @summary Get rent forecast
 */
export const getRentForecast = (plz: string, options?: AxiosRequestConfig): Promise<AxiosResponse<RentForecast>> => {
  return axios.get(`/customer-data/rent-forecast/${plz}`, options);
};

export const getGetRentForecastQueryKey = (plz: string) => [`/customer-data/rent-forecast/${plz}`];

export const useGetRentForecast = <TData = AsyncReturnType<typeof getRentForecast>, TError = AxiosError<unknown>>(
  plz: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getRentForecast>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetRentForecastQueryKey(plz);

  const queryFn: QueryFunction<AsyncReturnType<typeof getRentForecast>> = () => getRentForecast(plz, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getRentForecast>, TError, TData>(queryKey, queryFn, {
    enabled: !!plz,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Returns dtkIndex with Explanation
 * @summary Get rent forecast
 */
export const getDtkIndexExplanation = (
  plz: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<IndexExplanation>> => {
  return axios.get(`/customer-data/index-explanation/${plz}`, options);
};

export const getGetDtkIndexExplanationQueryKey = (plz: string) => [`/customer-data/index-explanation/${plz}`];

export const useGetDtkIndexExplanation = <
  TData = AsyncReturnType<typeof getDtkIndexExplanation>,
  TError = AxiosError<unknown>
>(
  plz: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getDtkIndexExplanation>, TError, TData>;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetDtkIndexExplanationQueryKey(plz);

  const queryFn: QueryFunction<AsyncReturnType<typeof getDtkIndexExplanation>> = () =>
    getDtkIndexExplanation(plz, axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getDtkIndexExplanation>, TError, TData>(queryKey, queryFn, {
    enabled: !!plz,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Returns socio-demographics for last 10 years
 * @summary Get socio demographics
 */
export const getSocioDemographics = (options?: AxiosRequestConfig): Promise<AxiosResponse<SocioDemographics>> => {
  return axios.get(`/customer-data/socio-demographics`, options);
};

export const getGetSocioDemographicsQueryKey = () => [`/customer-data/socio-demographics`];

export const useGetSocioDemographics = <
  TData = AsyncReturnType<typeof getSocioDemographics>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getSocioDemographics>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetSocioDemographicsQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getSocioDemographics>> = () => getSocioDemographics(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getSocioDemographics>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * Returns DTK Index values
 * @summary Get proposal estate value increase
 */
export const getProposalEstateValueIncrease = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProposalEstateValueIncrease>> => {
  return axios.get(`/customer-data/proposal-estate-value-increase`, options);
};

export const getGetProposalEstateValueIncreaseQueryKey = () => [`/customer-data/proposal-estate-value-increase`];

export const useGetProposalEstateValueIncrease = <
  TData = AsyncReturnType<typeof getProposalEstateValueIncrease>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getProposalEstateValueIncrease>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetProposalEstateValueIncreaseQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getProposalEstateValueIncrease>> = () =>
    getProposalEstateValueIncrease(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getProposalEstateValueIncrease>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Indicates the stage of the estate
 */
export const getStage = (options?: AxiosRequestConfig): Promise<AxiosResponse<Stage>> => {
  return axios.get(`/customer-data/stage`, options);
};

export const getGetStageQueryKey = () => [`/customer-data/stage`];

export const useGetStage = <TData = AsyncReturnType<typeof getStage>, TError = AxiosError<unknown>>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getStage>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetStageQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getStage>> = () => getStage(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getStage>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Get Customer
 */
export const getCustomer = (options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> => {
  return axios.get(`/customer-data/user`, options);
};

export const getGetCustomerQueryKey = () => [`/customer-data/user`];

export const useGetCustomer = <TData = AsyncReturnType<typeof getCustomer>, TError = AxiosError<unknown>>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getCustomer>, TError, TData>;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomerQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getCustomer>> = () => getCustomer(axiosOptions);

  const query = useQuery<AsyncReturnType<typeof getCustomer>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Update Customer
 */
export const patchCustomer = (customer: Customer, options?: AxiosRequestConfig): Promise<AxiosResponse<Customer>> => {
  return axios.patch(`/customer-data/user`, customer, options);
};

export const usePatchCustomer = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<AsyncReturnType<typeof patchCustomer>, TError, { data: Customer }, TContext>;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof patchCustomer>, { data: Customer }> = (props) => {
    const { data } = props || {};

    return patchCustomer(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof patchCustomer>, TError, { data: Customer }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * @summary Update Tutorial Status
 */
export const patchTutorialStatus = (
  tutorialStatus: TutorialStatus,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<TutorialStatus>> => {
  return axios.patch(`/customer-data/tutorial-status`, tutorialStatus, options);
};

export const usePatchTutorialStatus = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof patchTutorialStatus>,
    TError,
    { data: TutorialStatus },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof patchTutorialStatus>, { data: TutorialStatus }> = (
    props
  ) => {
    const { data } = props || {};

    return patchTutorialStatus(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof patchTutorialStatus>, TError, { data: TutorialStatus }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * @summary Create dtx opp for requested product
 */
export const patchPartnerProductOffer = (
  productOffer: ProductOffer,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProductOffer>> => {
  return axios.patch(`/customer-data/accept-product-offer`, productOffer, options);
};

export const usePatchPartnerProductOffer = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof patchPartnerProductOffer>,
    TError,
    { data: ProductOffer },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options || {};

  const mutationFn: MutationFunction<AsyncReturnType<typeof patchPartnerProductOffer>, { data: ProductOffer }> = (
    props
  ) => {
    const { data } = props || {};

    return patchPartnerProductOffer(data, axiosOptions);
  };

  return useMutation<AsyncReturnType<typeof patchPartnerProductOffer>, TError, { data: ProductOffer }, TContext>(
    mutationFn,
    mutationOptions
  );
};

import { SmallFooter } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

const SmallFooterBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="small-footer">
      <SmallFooter
        datenschutzLink={blok.datenschutzLink}
        copyrightText={blok.copyrightText}
        datenschutzText={blok.datenschutzText}
        impressumText={blok.impressumText}
        impressumLink={blok.impressumLink}
      />
    </div>
  );
};

export default SmallFooterBlok;

import { HeaderSectionWithBGImage } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const HeaderSectionWithBGImageBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="grid">
    <HeaderSectionWithBGImage
      image={blok.image}
      overline={blok.overline}
      headline={blok.headline}
      subtitle={blok.subtitle}
      theme={blok.theme}
    />
  </div>
);

export default HeaderSectionWithBGImageBlok;

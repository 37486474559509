import { render } from 'storyblok-rich-text-react-renderer';
import { blokResolvers, defaultBlockResolver, markResolvers, nodeResolvers } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

import styles from './RichText.module.scss';

export const RichTextBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="rich-text">
      <div className="w-full">
        <div
          className={
            styles?.['richTextWrapper'] +
            ' richtext m-auto my-8 mx-auto grid-cols-12 content-center px-4 sm:px-6 md:max-w-xl lg:max-w-2xl lg:px-8 xl:max-w-[50rem]'
          }
        >
          {render(blok.content, {
            defaultBlokResolver: defaultBlockResolver,
            blokResolvers: blokResolvers,
            nodeResolvers: nodeResolvers,
            markResolvers: markResolvers,
          })}
        </div>
      </div>
    </div>
  );
};

import { BottomAuthorBlogPost } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const AuthorComponentBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="author-component-blok">
      <BottomAuthorBlogPost author={blok.author} />
    </div>
  );
};

export default AuthorComponentBlok;

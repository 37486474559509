import { ImageComponent, ImageComponentData } from '../../01_elements/ImageComponent';
import classNames from 'classnames';
import { HeroBackgroundImageProps } from './types';

export const HeroBackgroundImage = ({ ...props }: HeroBackgroundImageProps) => {
  const {
    loadingPriority,
    backgroundBlur,
    backgroundImage,
    backgroundImageMobile,
    blurDesktop,
    blurMobile,
    bgFrozeColor,
  } = props;

  const bgImageData: ImageComponentData = {
    BigImage: {
      filename: backgroundImage?.filename || '',
      alt: backgroundImage?.alt || '',
      focus: backgroundImage?.focus || '',
    },
    SmallImage: {
      filename: backgroundImageMobile?.filename || '',
      alt: backgroundImageMobile?.alt || '',
      focus: backgroundImageMobile?.focus || '',
    },
    fetchingpriority: loadingPriority || 'high',
  };

  return (
    <>
      <div className="xs:absolute xs:h-full relative top-0 bottom-0 left-0 right-0 h-52 overflow-hidden">
        {backgroundImage?.filename && <ImageComponent data={bgImageData} />}
      </div>
      {backgroundBlur && (
        <div
          className={classNames(
            blurDesktop,
            blurMobile,
            'xs:block absolute right-0 top-0 bottom-0 hidden w-full skew-x-[0deg] overflow-hidden  md:right-[44%]  md:skew-x-[-8.8deg] xl:right-[44%]'
          )}
          style={{
            backgroundColor: `rgba(${bgFrozeColor.r}, ${bgFrozeColor.g}, ${bgFrozeColor.b}, ${bgFrozeColor.a})`,
          }}
        />
      )}
    </>
  );
};

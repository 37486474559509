import { ProcessFlow } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';
const ProcessFlowBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="process-flow">
    <div className="bg-gray-50">
      <ProcessFlow {...blok}></ProcessFlow>
    </div>
  </div>
);

export default ProcessFlowBlok;

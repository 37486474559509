import React from 'react';
import { logoSrc, useMediaQuery } from '@dtk/ui-components';

const GoogleStructuredData: React.FC<{ story }> = ({ story }) => {
  const { smallerMd } = useMediaQuery();
  const hasAdvisorPageSubPath = story?.full_slug.split('/')[0] === 'ratgeber' && story?.full_slug.split('/')[1];

  if (story?.content?.component === 'blog_post' || hasAdvisorPageSubPath) {
    //todo this should be replaced with .env.config https://github.com/vercel/next.js/discussions/16429
    const basePageUrl = 'https://deutsche-teilkauf.de';
    const currentPageUrl = `${basePageUrl}/${story?.full_slug}/`;

    const getLdJsonArticleValues = () => {
      if (story?.content?.component === 'blog_post') {
        const {
          featured_image,
          MetaTags: { title, description },
          author: {
            content: { author_name, author_position },
            full_slug,
          },
        } = story.content;

        const authorPageUrl = `${basePageUrl}/${full_slug}/`;
        const getImageSrc = () => {
          return `${featured_image.filename}/m/960x0/smart`;
        };
        return { title, description, author_name, author_position, authorPageUrl, getImageSrc };
      } else if (hasAdvisorPageSubPath) {
        const {
          MetaTags: { title, description },
        } = story.content;

        const getImageSrc = () => {
          return smallerMd && story?.content?.body[1]?.mobileImage
            ? `${story?.content?.body[1]?.mobileImage.filename}/m/960x0/smart`
            : `${story?.content?.body[1]?.image.filename}/m/960x0/smart`;
        };

        return { title, description, getImageSrc };
      }
    };

    const articleValues = getLdJsonArticleValues();

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "${articleValues.title}",
            "description": "${articleValues.description}",
            "image": "${articleValues.getImageSrc()}",
            "url": "${currentPageUrl}",
            "datePublished": "${story.first_published_at}",
            "dateModified": "${story.published_at}",
            ${
              articleValues.authorPageUrl
                ? `"author": [{
              "@type": "Person",
              "name": "${articleValues.author_name}",
              "jobTitle": "${articleValues.author_position}",
              "url": "${articleValues.authorPageUrl}"
            }],`
                : ''
            }
            "publisher": {
              "@type": "Organization",
              "name": "Deutsche Teilkauf",
              "logo": {
                "@type": "ImageObject",
                "url": "${logoSrc}",
                "width": 242,
                "height": 57
              }
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${currentPageUrl}"
            }
          }`,
        }}
      />
    );
  } else if (story?.full_slug === 'home') {
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Deutsche Teilkauf",
            "legalName": "Deutsche Teilkauf GmbH",
            "url": "https://deutsche-teilkauf.de/",
            "logo": "${logoSrc}",
            "sameAs": [
              "https://www.instagram.com/deutsche.teilkauf/",
              "https://www.youtube.com/c/DeutscheTeilkauf",
              "https://de.linkedin.com/company/deutsche-teilkauf",
              "https://www.xing.com/pages/deutscheteilkaufgmbh"
            ],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Hohenzollernring 79-83",
              "postalCode": "50672",
              "addressLocality": "Köln",
              "addressCountry": "Deutschland"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "customer support",
              "telephone": "+4922171829100",
              "email": "info@deutsche-teilkauf.de"
            }
          }`,
        }}
      />
    );
  }
};

export default GoogleStructuredData;

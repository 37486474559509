import classNames from 'classnames';
import Link from 'next/link';
import {
  themesForBackgroundColor,
  Button,
  ButtonVariantsRedesign,
  useMediaQuery,
  setObjectPosition,
} from '@dtk/ui-components';
import { PartnerBannerProps } from './types';

export const PartnerBannerOffering = (props: PartnerBannerProps) => {
  const { smallerMd, smallerLg } = useMediaQuery();
  const {
    isTopBanner,
    discount,
    discountText,
    partnerImage,
    isSmall,
    partnerName,
    partnerDescription,
    hasSquareCorners,
  } = props;

  const theme = isTopBanner
    ? themesForBackgroundColor.lightBackgroundColors[0]
    : themesForBackgroundColor.defaultBackgroundColor;

  const bannerHeadline = isTopBanner ? props.headlineText : props.headlineTextShort;
  const backgroundColor = isTopBanner ? 'bg-mint' : 'bg-white';

  return (
    <Link href={smallerLg ? '' : props.offerButtonLink} passHref scroll={smallerLg ? false : true}>
      <a href="passHref" className="cursor-default lg:cursor-pointer">
        <div
          className={classNames(
            `${backgroundColor} grid grid-cols-12 gap-0 shadow-md sm:min-h-[422px]`,
            !hasSquareCorners && 'rounded-lg'
          )}
        >
          <div className={classNames(isSmall ? 'sm:h-[193px]' : 'sm:col-span-6', 'col-span-12')}>
            {partnerImage?.filename && (
              <img
                src={`${partnerImage.filename}/m/smart`}
                className={classNames(
                  !hasSquareCorners
                    ? isSmall
                      ? 'max-h-[193px] rounded-t-lg'
                      : 'max-h-[422px] rounded-t-lg sm:rounded-l-lg sm:rounded-tr-none'
                    : '',
                  'h-full w-full object-cover'
                )}
                style={setObjectPosition(partnerImage.filename, partnerImage.focus || '')}
                alt={partnerImage.alt}
                loading={isTopBanner ? 'eager' : 'lazy'}
                {...{ fetchpriority: isTopBanner ? 'high' : 'low' }}
              />
            )}
          </div>
          <div className={classNames(isSmall ? 'pt-2' : 'sm:col-span-6', 'col-span-12 grid p-6')}>
            <div
              className="grid content-start gap-2"
              style={{ hyphens: 'auto', WebkitHyphens: 'auto', msHyphens: 'auto' }}
            >
              {isTopBanner && props.topBannerHeadlineText && (
                <p className="text-gold-gold text-sm uppercase tracking-[.2rem]">{props.topBannerHeadlineText}</p>
              )}
              <p className="font-headline-semibold text-navy text-2xl">{bannerHeadline}</p>
              <p>
                <span className="font-body-bold">{partnerName}</span>
                {!isSmall && !smallerMd && <span>{` ${partnerDescription.slice(0, 120 - partnerName.length)}`}</span>}
              </p>
            </div>
            <div
              className={classNames(
                isSmall ? 'gap-2' : 'gap-4',
                'xs:flex-row flex flex-col justify-end justify-items-end pt-6 sm:flex-col'
              )}
            >
              {(discountText || discount) && (
                <div className="flex flex-nowrap justify-end gap-1">
                  <p className="self-end">{discountText}</p>
                  <p className="text-coral font-body-bold -mb-[1px] self-end text-2xl">{discount}</p>
                </div>
              )}
              <div className="self-end">
                <Button
                  href={props.offerButtonLink}
                  variant={theme as ButtonVariantsRedesign}
                  size={!isTopBanner ? 'sm' : smallerLg ? 'sm' : 'base'}
                >
                  {props.offerButtonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

import Image from 'next/future/image';
import { Button } from '../../../01_elements/Button';
import { useMediaQuery } from '../../../hooks';
import { storyBlokLoader } from '../../../utils/storyblok-loader';
import {
  ImageProps,
  setObjectPosition,
  getAlternativePath,
  RichtextContent,
  RichtextContentProps,
  hasRichtextElement,
  isExternalPage,
} from '../../../utils';

export interface CardWithImageTextElementsAndButtonProps {
  cardImage: ImageProps;
  overline?: string;
  title: string;
  description?: string;
  content?: RichtextContentProps;
  buttonLink?: string;
  buttonText?: string;
  onClick?: () => void;
}

export const CardWithImageTextElementsAndButton = (props: CardWithImageTextElementsAndButtonProps) => {
  const { smallerSm } = useMediaQuery();
  const { title, description, content, cardImage, overline, buttonLink, buttonText, onClick } = props;
  const richtextContent = content?.content;

  const linkPath = buttonLink && getAlternativePath({ originalLink: buttonLink, condition: smallerSm });

  return (
    <div className="mx-auto flex h-full flex-col bg-white shadow-lg">
      {cardImage?.filename && (
        <Image
          loader={storyBlokLoader}
          className="h-60 w-full object-cover"
          style={setObjectPosition(cardImage.filename, cardImage.focus || '')}
          src={cardImage.filename}
          alt={cardImage.alt}
          width={750}
          height={750}
        />
      )}

      <div className="flex-1 p-6">
        <p className="text-gold-gold text-sm uppercase tracking-[.2em]">{overline}</p>
        <p className="font-headline-semibold text-navy my-1 text-2xl">{title}</p>
        {(!richtextContent || !hasRichtextElement(richtextContent)) && description && (
          <p className="text-navy mt-1 text-base md:text-lg">{description}</p>
        )}
        {!description && richtextContent && hasRichtextElement(richtextContent) && (
          <RichtextContent content={content} />
        )}
      </div>

      <div className="p-6 pb-8">
        {buttonText &&
          (linkPath ? (
            <Button variant="navy" fullWidth href={linkPath} isexternal={isExternalPage(linkPath)}>
              {buttonText}
            </Button>
          ) : (
            <Button variant="navy" fullWidth onClick={onClick || undefined}>
              {buttonText}
            </Button>
          ))}
      </div>
    </div>
  );
};

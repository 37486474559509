import { ThankYouSection } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const ThankYouSectionBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="thank-you-section">
      <ThankYouSection
        backgroundTheme={blok.backgroundTheme}
        hasShadow={blok.hasShadow}
        iconimage={blok.iconimage}
        headingType={blok.headingType}
        overline={blok.overline}
        headline={blok.headline}
        subtitle={blok.subtitle}
      />
    </div>
  );
};

import './styles/styles.css';
import '@dtk/default-theme';

import { AppProps } from 'next/app';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { DataLayerScript } from '@dtk/user-tracking';
import { apiPlugin, storyblokInit } from '@storyblok/react';

import CanonicalLink from '../components/CanonicalLink';
import GoogleStructuredData from '../components/GoogleStructuredData';
import Head from 'next/head';
import Layout from '../components/Layout';
import MetaTags from '../components/Head/MetaTags';
import NextAndPrevLinks from '../components/NextAndPrevLinks';
import { StoryblokComponents } from '../components/StoryblokComponents';
import { initialiseAndHandleCmp } from '../utils/cmp';

storyblokInit({
  accessToken: '3koxfeFUxXuPQrk6kSVJxAtt',
  use: [apiPlugin],
  components: StoryblokComponents,
});

function CustomApp({ Component, pageProps }: AppProps) {
  const { story } = pageProps;

  useEffect(() => {
    initialiseAndHandleCmp(story);
  }, [story]);

  return (
    <>
      <Head>
        <title key="title">{story?.content?.MetaTags?.title || 'Deutsche-Teilkauf'}</title>
        <link
          rel="preload"
          href="/_next/static/media/Inter-Regular.afe848f8.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <GoogleStructuredData story={story} />
      <DataLayerScript containerId="5EZLMRvWP" />
      <Script
        type="text/plain"
        data-usercentrics="Google Tag Manager"
        id="tagmanager-placeholder"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KWF3DCF');
            `,
        }}
      />
      {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
      <Script
        type="text/plain"
        id="vwoCode"
        data-usercentrics="Visual Website Optimizer (VWO)"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          /* eslint-disable */
          __html: `
            window._vwo_code=window._vwo_code || (function() {
            var account_id=740237,
            version = 1.5,
            settings_tolerance=2000,
            library_tolerance=2500,
            use_existing_jquery=false,
            is_spa=1,
            hide_element='body',
            hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
            /* DO NOT EDIT BELOW THIS LINE */
            f=false,w=window,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){window._vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;w.settings_timer=setTimeout(function(){window._vwo_code.finish()},settings_tolerance);var e=d.currentScript,t=d.createElement('style'),i=e&&!e.async?hide_element?hide_element+'{'+hide_element_style+'}':'':code.lA=1,n=d.getElementsByTagName('head')[0];t.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&t.setAttribute('nonce',vwoCodeEl.nonce);t.setAttribute('type','text/css');if(t.styleSheet)t.styleSheet.cssText=i;else t.appendChild(d.createTextNode(i));n.appendChild(t);var o=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(o?'&c='+o:''));return settings_timer}};w._vwo_settings_timer = code.init();return code;}());`,
          /* eslint-enable */
        }}
      />
      <CanonicalLink story={story} />
      <NextAndPrevLinks story={story} />
      {story?.content?.MetaTags && <MetaTags metaTags={story?.content?.MetaTags} />}
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default CustomApp;

import React, { useEffect } from 'react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { BlogPostPage } from '@dtk/public-website-ui-components';

const BlogPostBlok = ({ blok, story }) => {
  useEffect(() => {
    document.querySelectorAll('.richtext h2').forEach((el: HTMLElement, i) => {
      el.innerText = el.innerText.trim();
      const numberSpan = document.createElement('span');
      numberSpan.classList.add('float-left', 'h-9', 'items-center');
      const spanTemplate = `<span class="z-11 border-gold relative flex h-10 w-10 items-center justify-center rounded-full border-2 bg-white ">
                              <span class="z-9 bg-navy-600 absolute left-1 h-7 w-7 rounded-full" />
                                <span class="z-11 relative flex items-center justify-center rounded-full bg-indigo-600 text-white group-hover:bg-indigo-800">
                                  <span class="text-lg text-center">${i + 1}</span>
                              </span>
                            </span>`;
      numberSpan.innerHTML = spanTemplate;
      el.classList.add('mb-6', 'flex', 'flex-col', 'gap-y-3.5');
      el.prepend(numberSpan);
    });
  }, []);

  return (
    <div {...storyblokEditable(blok)} className="blog-single-page" key={blok._uid} data-test="blog-post">
      {blok.headerBlocks.map((nestedBlok) => {
        const isNavigation = nestedBlok.reference.slug === 'navigation';
        const className = isNavigation ? 'sticky top-0 z-50' : '';
        return (
          <div key={nestedBlok._uid} className={className}>
            <StoryblokComponent blok={nestedBlok} />
          </div>
        );
      })}
      <div key={blok._uid} className="mt-10">
        <BlogPostPage story={story} content={blok} />
        {blok?.blocks
          ?.filter((nestedBlok) => nestedBlok.component !== 'blog_post_summary_select')
          ?.map((nestedBlok) => {
            return (
              <div key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BlogPostBlok;

import React from 'react';
import { InputField } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';
import { useOfferStore } from '../../../store/OfferStore';

export const InputTextAreaBlok = ({ blok }) => {
  const handleValueChange = (event) => {
    const keys = useOfferStore.getState().data;
    useOfferStore.setState({ data: keys.set(blok.name, event.target.value) });
  };

  return (
    <div
      {...storyblokEditable(blok)}
      key={blok._uid}
      className="col-span-12 grid grid-flow-row"
      data-test="input-text-area"
    >
      <InputField
        id={blok.name}
        name={blok.name}
        label={blok.label}
        placeholder={blok.placeholder}
        rows={4}
        component="textarea"
        isMandatory={blok.isRequired}
        isOptional={blok.isOptional}
        onBlur={handleValueChange}
      />
    </div>
  );
};

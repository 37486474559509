import React from 'react';
import { ShareButton } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

const SocialMediaShareButtonBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="social-media-share-button">
      <ShareButton selectedPlatforms={blok.platforms} />
    </div>
  );
};

export default SocialMediaShareButtonBlok;

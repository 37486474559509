import { ArrowLeftIcon, HomeIcon } from '@heroicons/react/solid';
import { Button, getBaseUrl } from '@dtk/ui-components';

import { Breadcrumb } from './Breadcrumb';
import Script from 'next/script';
import { useRouter } from 'next/router';

export const Breadcrumbs = () => {
  const router = useRouter();

  const pathWithoutQuery = router.asPath.split('?')[0];
  let pathArray = pathWithoutQuery.split('/');
  pathArray.shift();
  pathArray = pathArray.filter((path) => path !== '' && path !== '#');

  const breadcrumbs = pathArray.map((path, index) => {
    const url = '/' + pathArray.slice(0, index + 1).join('/') + '/';
    const documentTitle = document?.title ? document?.title?.split('|')[0] : path.replace(/-/g, ' ').toUpperCase();
    const lastIndex = pathArray.length - 1;
    if (index === lastIndex) {
      return {
        label: documentTitle.slice(0, documentTitle.length - 1),
        url,
      };
    }
    return {
      label: path.charAt(0).toUpperCase() + path.slice(1),
      url,
    };
  });

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Startseite',
        item: `${getBaseUrl}/`,
      },
      ...breadcrumbs.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        position: 2 + index,
        name: breadcrumb.label,
        item: `${getBaseUrl}${breadcrumb.url}`,
      })),
    ],
  };

  return (
    <div className="mb-4 flex items-center" aria-label="breadcrumb">
      <Button variant="white" size="sm" className="xs:mr-4 mr-2 flex items-center border" onClick={() => router.back()}>
        <ArrowLeftIcon className="h-5 w-5" /> <span className="xs:ml-2 xs:text-sm ml-1 text-xs"> zurück</span>
      </Button>
      <HomeIcon className="xs:mr-2 mr-1 h-5 w-5" /> <Breadcrumb label="Home" url="/" />
      {breadcrumbs?.map((breadcrumb, index) => (
        <Breadcrumb
          key={breadcrumb.label}
          isLastIndex={index === breadcrumbs.length - 1}
          label={breadcrumb.label}
          url={breadcrumb.url}
        />
      ))}
      <Script
        id="structured-data-breadcrumb"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
        strategy="afterInteractive"
      />
    </div>
  );
};

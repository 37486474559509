import { trackCtaEngagement } from '@dtk/user-tracking';
import { Button } from '../../01_elements';

export const CtaButton = ({ text, href, handleClick }: CtaButtonProps) => {
  const fireTrackingEvent = () => {
    trackCtaEngagement('Hero.CTA.' + text, text, 'redirect');
  };
  return (
    <div className="mt-5">
      {href ? (
        <Button className="font-semibold sm:w-fit" href={href} onClick={() => fireTrackingEvent()}>
          {text}
        </Button>
      ) : (
        <Button
          className="font-semibold sm:w-fit"
          onClick={() => {
            handleClick && handleClick();
            fireTrackingEvent();
          }}
        >
          {text}
        </Button>
      )}
    </div>
  );
};

export type CtaButtonProps = {
  text: string;
  href?: string;
  handleClick?: () => void;
};

import { PartnerOfferWrapper } from '@dtk/dtk-club';
import { storyblokEditable } from '@storyblok/react';

export const PartnerOfferFormSectionBlok = ({ ...props }) => {
  const { blok } = props;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="partner-offer-form-section">
      <PartnerOfferWrapper blok={blok} partnerName={blok.partnerName} />
    </div>
  );
};

import {
  getTrustPilotWidgetStyles,
  isTrustPilotConsentConfirmed,
  StoryblokIcon,
  TrustPilotBox,
  useMediaQuery,
} from '@dtk/ui-components';
import classNames from 'classnames';

interface TrustElementsProps {
  headline: string;
  keyPoints: string[];
  isContentContainer?: boolean;
}

export const TrustElements = ({ headline, keyPoints, isContentContainer }: TrustElementsProps) => {
  const { smallerSm } = useMediaQuery();

  return (
    <div
      className={classNames(
        isContentContainer ? 'lg:flex-row' : 'sm:flex-row',
        smallerSm && 'absolute bottom-0 left-[50%] translate-x-[-50%]',
        'flex flex-col pb-3 md:mt-10'
      )}
    >
      {smallerSm ? (
        <TrustPilotBox
          containerClassNames="flex justify-center scale-110 pt-auto"
          backgroundColor="coolGray-50"
          width="250px"
          height="30px"
          reviewStyle={getTrustPilotWidgetStyles().microTrustScore}
        />
      ) : (
        <>
          <div className={classNames(isContentContainer ? 'lg:mb-0' : 'sm:mb-0', 'text-navy my-7 sm:mt-0')}>
            {headline && <p className="font-body-semibold mb-4 text-lg">{headline}</p>}
            {keyPoints.map(
              (keyPoint, index) =>
                keyPoint && (
                  <div className="flex flex-row items-start" key={index}>
                    <StoryblokIcon
                      className="mt-1"
                      iconSource="https://a.storyblok.com/f/148087/x/eb10faeee1/check-badge.svg"
                      width={16}
                    />
                    <p className="ml-2 mb-2">{keyPoint}</p>
                  </div>
                )
            )}
          </div>
          <div
            className={classNames(
              isContentContainer ? 'lg:ml-auto lg:flex-col' : 'sm:ml-auto sm:flex-col',
              'flex flex-col sm:flex-row'
            )}
          >
            <a href="https://www.certipedia.com/quality_marks/9000020216" target="_blank" rel="noopener noreferrer">
              <StoryblokIcon
                className={classNames(isContentContainer ? 'lg:ml-auto' : 'sm:ml-auto', 'mb-2')}
                iconSource="https://a.storyblok.com/f/148087/x/89d3bbe846/tuv-siegel.svg"
                alt="TÜV-Siegel"
                width={160}
              />
            </a>
            <TrustPilotBox
              containerClassNames={classNames(
                isContentContainer && 'sm:mt-5',
                !isTrustPilotConsentConfirmed && 'flex sm:justify-end sm:mr-1',
                'mt-5 sm:ml-0'
              )}
              backgroundColor="coolGray-50"
              width={smallerSm ? '240px' : '150px'}
              reviewStyle={getTrustPilotWidgetStyles().mini}
            />
          </div>
        </>
      )}
    </div>
  );
};

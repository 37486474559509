import { ArrowRightIcon } from '@heroicons/react/solid';
import { hasAnchor, isExternalPage } from '../../utils/checkLinks';
import { Button } from '../../01_elements/Button';
import { useMediaQuery } from '../..';
import { HeroButtonProps } from './types';

export const ButtonHero = ({ buttonText, buttonLink, buttonColor }: HeroButtonProps) => {
  const { largerXs } = useMediaQuery();

  return buttonLink && hasAnchor(buttonLink) ? (
    <Button
      size={largerXs ? 'lg' : 'base'}
      className="xs:w-auto w-full"
      variant={buttonColor || 'cyan'}
      href={buttonLink}
      icon={ArrowRightIcon}
      iconPosition="right"
    >
      {buttonText}
    </Button>
  ) : buttonText && buttonLink ? (
    <Button
      size={largerXs ? 'lg' : 'base'}
      className="xs:w-auto w-full"
      variant={buttonColor || 'cyan'}
      icon={ArrowRightIcon}
      iconPosition="right"
      href={buttonLink}
      isexternal={isExternalPage(buttonLink)}
    >
      {buttonText}
    </Button>
  ) : null;
};

import { Headline } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const HeadlineBlok = ({ ...props }) => {
  const { blok } = props;
  const chosenHeadingType = blok.headingType || 'h2';
  return (
    <div
      key={blok._uid}
      className=" text-navy-navy col-span-12 w-full"
      {...storyblokEditable(blok)}
      data-test="headline"
    >
      <Headline isBold={blok.isBold} headingType={chosenHeadingType} headline={blok.text} />
    </div>
  );
};

import { FormikTouched } from 'formik';
import { Button, useMediaQuery } from '@dtk/ui-components';
import { PartnerOfferButtonsProps } from '../types';

export const PartnerOfferButtons = ({
  setCurrentButtonName,
  setShowMessageBanner,
  componentHead,
  offerButtonText,
  alternativeButtonText,
  touched,
  isValid,
}: PartnerOfferButtonsProps) => {
  const { smallerLg } = useMediaQuery();
  const handleClick = (buttonName: string, touched: FormikTouched<unknown>, isValid: boolean) => {
    setCurrentButtonName(buttonName);
    if (isValid && Object.keys(touched).length > 0) {
      setShowMessageBanner(false);
      return;
    }

    setShowMessageBanner(true);
    return componentHead.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div className={alternativeButtonText ? 'col-span-6 sm:col-span-3' : 'col-span-6'}>
        <Button
          fullWidth
          type="submit"
          variant="coral"
          onClick={() => (smallerLg ? handleClick(offerButtonText, touched, isValid) : undefined)}
        >
          {offerButtonText}
        </Button>
      </div>
      {alternativeButtonText && (
        <div className="col-span-6 sm:col-span-3">
          <Button
            fullWidth
            type="submit"
            variant="coral"
            onClick={() => (smallerLg ? handleClick(alternativeButtonText, touched, isValid) : undefined)}
          >
            {alternativeButtonText}
          </Button>
        </div>
      )}
    </>
  );
};

export interface RegistrationBaseValues {
  company: string;
  ihkNumber: string;
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  houseNr: string;
  plz: string;
  city: string;
  phone: string;
}

export interface RegistrationValues extends RegistrationBaseValues {
  userTitle: string;
  additionalInfo: string;
  advertisingConsent: boolean;
}

export interface PersonalDataValues {
  estateAgentID: string;
  salutation: string;
  userTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  realEstateValue: number | string;
  requestedPayout: number | string;
  existingLoads: string;
  existingLoadsAmount: string;
}

export interface RealEstateTypeValues {
  objectType: string;
  buildingType: string;
  livingSpace: number | string;
  landArea: number | string;
  constructionYear: number | string;
  floorCount: number;
  garageParkingSpaces: number;
  outdoorParkingSpaces: number;
  construction: string;
  loftConversion: string;
  basementType: string;
  basementExtension: string;
}

export interface LeadTransmissionValues extends PersonalDataValues, RealEstateTypeValues {
  multipleBathrooms: string;
  guestToilet: string;
  cablesPlaster: string;
  outerWallsInsulated: string;
  chimneyOrTiledStove: string;
  sauna: string;
  heating: string;
  roofCovering: string;
  window: string;
  outdoorLivingArea: string;
  balcony: string;
  energyEfficiencyClass: string;
  energyEfficiencyHouseStandard: string;
  floorCoveringModernization: string;
  bathToiletModernization: string;
  heatingModernization: string;
  electricityWaterHeatingModernization: string;
  windowModernization: string;
  thermalInsulationModernization: string;
  roofModernization: string;
  roomLayoutModernization: string;
  floorLocation: string;
  upperFloorType: string;
  flatCount: number;
  floorCoveringLivingRoom: string;
  storageRoom: string;
  additionalInfo: string;
}

export const getRegistrationInitialValues = (externalData: RegistrationBaseValues | undefined) => {
  return {
    company: externalData?.company || '',
    ihkNumber: externalData?.ihkNumber || '',
    salutation: externalData?.salutation || '',
    userTitle: '',
    firstName: externalData?.firstName || '',
    lastName: externalData?.lastName || '',
    email: externalData?.email || '',
    street: externalData?.street || '',
    houseNr: externalData?.houseNr || '',
    plz: externalData?.plz || '',
    city: externalData?.city || '',
    phone: externalData?.phone || '',
    additionalInfo: '',
    advertisingConsent: false,
  };
};

export const personalDataInitialValues = {
  estateAgentID: '',
  salutation: '',
  userTitle: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  street: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  realEstateValue: '',
  requestedPayout: '',
  existingLoads: '',
  existingLoadsAmount: '',
};

export const realEstateTypeInitialValues = {
  objectType: '',
  buildingType: '',
  livingSpace: '',
  landArea: '',
  constructionYear: '',
  floorCount: 1,
  garageParkingSpaces: 0,
  outdoorParkingSpaces: 0,
  construction: '',
  loftConversion: '',
  basementType: '',
  basementExtension: '',
};

export const allFormsInitialValues = {
  ...personalDataInitialValues,
  ...realEstateTypeInitialValues,
  multipleBathrooms: '',
  guestToilet: '',
  cablesPlaster: '',
  outerWallsInsulated: '',
  chimneyOrTiledStove: '',
  sauna: '',
  heating: '',
  roofCovering: '',
  window: '',
  outdoorLivingArea: '',
  balcony: '',
  energyEfficiencyClass: '',
  energyEfficiencyHouseStandard: '',
  floorCoveringModernization: '',
  bathToiletModernization: '',
  heatingModernization: '',
  electricityWaterHeatingModernization: '',
  windowModernization: '',
  thermalInsulationModernization: '',
  roofModernization: '',
  roomLayoutModernization: '',
  floorLocation: '',
  upperFloorType: '',
  flatCount: 1,
  floorCoveringLivingRoom: '',
  storageRoom: '',
  additionalInfo: '',
};

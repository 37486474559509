/**
 * Formats a local ISO Date to custom Date Format.
 */
export const formatDate = (value: string) => {
  const date = new Date(value);
  return date.toLocaleDateString('de-DE', { year: 'numeric', month: 'short', day: '2-digit' });
};

export const formatDateWithLongMonth = (value: string) => {
  const date = new Date(value);
  return date.toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: '2-digit' });
};

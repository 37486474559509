import classNames from 'classnames';
import { Field, FieldHookConfig, useField } from 'formik';
import { useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { getHighlightedText, validationMessages } from '../../utils';

export type Suffix = '€' | '%' | 'm²';
export type Prefix = 'https://';

export const isSuffix = (value: string): value is Suffix => {
  return value === '€' || value === '%' || value === 'm²';
};

export const isPrefix = (value: string): value is Prefix => {
  return value === 'https://';
};

interface ValidationTextProps {
  text: string;
  highlightedPart: string;
}

interface InputFieldWithPrefixProps {
  isMandatory?: boolean;
  isOptional?: boolean;
  prefix: Prefix | undefined;
  suffix?: string | undefined;
  infoMessage?: ValidationTextProps;
  decimalScale?: number;
}
export interface InputFieldWithSuffixProps {
  isMandatory?: boolean;
  isOptional?: boolean;
  suffix: Suffix | undefined;
  prefix?: string | undefined;
  infoMessage?: ValidationTextProps;
  decimalScale?: number;
}

export function InputFieldWithAddOn(
  props: React.HTMLProps<HTMLInputElement> &
    FieldHookConfig<number> &
    (InputFieldWithPrefixProps | InputFieldWithSuffixProps) & { 'data-testid'?: string }
) {
  const [showInfoText, setShowInfoText] = useState(false);
  const [field, meta, helper] = useField(props);
  const {
    id,
    name,
    label,
    placeholder,
    isMandatory,
    isOptional,
    prefix,
    suffix,
    infoMessage,
    decimalScale,
    disabled,
    onBlur,
    ...restProps
  } = props;
  const { 'data-testid': dataTestId } = props;

  const fieldClassNames = classNames(
    'block w-full min-w-0 flex-1 rounded-none px-5 py-3 text-base focus:border-cyan-700 focus:outline-0 focus:ring-0 border-coolGray-300',
    disabled && 'bg-coolGray-100 text-coolGray-400',
    suffix ? 'rounded-l' : 'rounded-r',
    !disabled && meta.error && meta.touched
      ? 'placeholder:text-red-secondary text-red-secondary border-red-500 bg-red-50'
      : 'bg-white focus:border-cyan-700 placeholder:text-coolGray-400'
  );

  return (
    <div className="font-body">
      <div className="flex justify-between">
        <label htmlFor={id || name} className="text-navy font-body-medium block">
          {isMandatory && !isOptional ? `${label}*` : label}
        </label>
        {label && isOptional && !isMandatory && <label className="text-coolGray-500 antialiased">Optional</label>}
      </div>
      <div className={classNames('mt-2 flex rounded-md shadow-sm', suffix ? 'flex-row-reverse' : 'flex-row')}>
        <span
          className={classNames(
            'border-coolGray-300 bg-coolGray-100 text-coolGray-500 inline-flex cursor-default',
            'items-center border px-5 py-3',
            suffix ? 'rounded-r border-l-0' : 'rounded-l border-r-0'
          )}
        >
          {suffix ? suffix : prefix}
        </span>
        {suffix === '€' || suffix === '%' ? (
          <NumberFormat
            className={fieldClassNames}
            data-testid={dataTestId}
            value={field.value}
            disabled={disabled}
            id={id}
            name={field.name}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={decimalScale || 0}
            placeholder={placeholder}
            onValueChange={(values: NumberFormatValues) => {
              helper.setValue(Number(values.floatValue));
            }}
            onFocus={() => {
              setShowInfoText(true);
            }}
            onBlur={(event: never) => {
              field.onBlur(event);
              onBlur && onBlur(event);
              setShowInfoText(false);
            }}
          />
        ) : (
          <Field
            {...field}
            {...restProps}
            id={id}
            className={fieldClassNames}
            type={prefix ? 'text' : 'number'}
            disabled={disabled}
            onFocus={() => {
              setShowInfoText(true);
            }}
            onBlur={(event: never) => {
              field.onBlur(event);
              onBlur && onBlur(event);
              setShowInfoText(false);
            }}
          />
        )}
      </div>
      {(!meta.touched || !meta.error) && showInfoText && infoMessage && (
        <p
          className="text-coolGray-500 font-body mt-2 text-sm antialiased"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(infoMessage.text, infoMessage.highlightedPart, 'coolGray-500', {
              fontFamily: 'font-body',
              isBold: true,
            }),
          }}
        />
      )}
      {!disabled && meta.touched && meta.error && (
        <p
          className="text-red-secondary font-body mt-2 text-sm"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(
              meta.error,
              validationMessages.error.defaultInputField.highlightedPart,
              'red-secondary',
              { fontFamily: 'font-body', isBold: true }
            ),
          }}
        />
      )}
    </div>
  );
}

import { Container, Team } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const TeamSectionBlok = ({ ...props }) => {
  const { blok } = props;
  const chosenTheme = blok.backgroundColor || 'white';

  return (
    <div className={`bg-${chosenTheme}`} {...storyblokEditable(blok)} key={blok._uid} data-test="team-section">
      <Container>
        <Team theme={chosenTheme} {...blok} />
      </Container>
    </div>
  );
};

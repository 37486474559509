import { Navigation } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const NavigationBlok = ({ blok }) => (
  <div {...storyblokEditable(blok)} key={blok._uid} data-test="navigation" id="web-navigation">
    <Navigation {...blok} />
  </div>
);

export default NavigationBlok;

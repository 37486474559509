import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';

export const TableRowCell = ({ ...props }) => {
  const { blok, backgroundColor, heightlightColor } = props['data'];

  return (
    <span className={classNames(heightlightColor, backgroundColor)}>
      {blok.blocks?.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </span>
  );
};

import { ShareIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import { ShareButtonsType, socialMediaPlatformConfig } from './socialMediaPlatformConfig';

import classNames from 'classnames';
import { Fragment } from 'react';
import { useRouter } from 'next/router';
import { StoryblokIcon } from '../../../01_elements';

export interface ShareButtonProps {
  selectedPlatforms: string[];
}

export const ShareButton = ({ selectedPlatforms }: ShareButtonProps) => {
  const router = useRouter();
  return (
    <div className="w-48 text-left">
      <Menu as="div" className="relative inline-block w-full text-left print:hidden">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                className={classNames(
                  'border-coolGray-200 text-navy inline-flex w-full items-center border',
                  'justify-between bg-white px-3 py-3 text-sm font-medium hover:bg-opacity-80',
                  'focus:border-cyan-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
                  open ? 'rounded-t-md' : 'rounded-md'
                )}
              >
                <ShareIcon width="20" height="20" className="text-coolGray-500 mr-2 md:mr-0" />
                <p className="-ml-5">Beitrag teilen</p>
                {open ? (
                  <StoryblokIcon
                    iconSource="https://a.storyblok.com/f/148087/250x150/b03a9ed03e/chevron-up-navy.svg"
                    className="text-coolGray-500 mr-1 h-3 w-3 justify-end"
                    aria-hidden="true"
                  />
                ) : (
                  <StoryblokIcon
                    iconSource="https://a.storyblok.com/f/148087/250x150/7d37c5f67d/chevron-down-navy.svg"
                    className="text-coolGray-500 mr-1 h-3 w-3 justify-end"
                    aria-hidden="true"
                  />
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute flex w-48 origin-top-right flex-col rounded-b-md drop-shadow-xl focus:outline-none">
                {selectedPlatforms.map((platform, index) => {
                  const platformConfig = socialMediaPlatformConfig[platform as ShareButtonsType];
                  return (
                    platformConfig && (
                      <div key={index} className="hover:bg-navy-50 cursor-pointer bg-white text-sm">
                        <Menu.Item as={'button'}>
                          <platformConfig.Component
                            className="text-coolGray-500 hover:text-navy flex w-full !justify-start px-4 text-left hover:!no-underline"
                            articleUrl={window.location.host + router.asPath}
                            platformUrl={platformConfig.href}
                            icon={platformConfig.icon}
                          />
                        </Menu.Item>
                      </div>
                    )
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

import { ImageComponent } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const ImageComponentBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="image-component">
      <ImageComponent data={blok} />
    </div>
  );
};

import { useAppConfig } from '@dtk/config';
import {
  Button,
  DtkLogo,
  ImageProps,
  NavigationContactMenuItemProps,
  NavigationContactMenuItemsProps,
  StoryblokIcon,
  useMediaQuery,
  getAlternativePath,
  BackgroundThemeVariants,
} from '@dtk/ui-components';
import { Disclosure, Popover } from '@headlessui/react';
import classNames from 'classnames';

import { MainMenu } from './MainMenu';
import { MobileMenu } from './MobileMenu';
import { NavigationBanner, NavigationBannerProps } from './NavigationBanner';
import { NavigationContactMenuComponent } from './NavigationContactMenu';
import { NavigationHeader } from './NavigationHeader';
import { NavigationIconWithTextMobile } from './NavigationIconWithText';
import { NavigationToPortalComponent } from './NavigationToPortal';
import { isDateWithinTimePeriod } from './utils';

export interface NavigationFooterLinkProps {
  name: string;
  url: string;
}

export interface NavigationFooterItemProps {
  headline: string;
  links: NavigationFooterLinkProps[];
}

interface NavigationProps {
  alternativeTopLineColor?: BackgroundThemeVariants;
  customLogoIcon?: ImageProps;
  phoneIcon?: ImageProps;
  phoneNumber?: string;
  appointmentIcon?: ImageProps;
  appointmentText?: string;
  appointmentLink?: string;
  infobrochureIcon?: ImageProps;
  infobrochureText?: string;
  infobrochureLink?: string;
  navigationLinks: NavigationLinkProps[];
  showLoginLinks?: boolean;
  loginText?: string;
  registerText?: string;
  registerLink?: string;
  requestQuoteIcon?: ImageProps;
  requestQuoteText?: string;
  requestQuoteLink?: string;
  secondMobileButtonIcon?: ImageProps;
  secondMobileButtonLink?: string;
  secondMobileButtonText?: string;
  banner?: NavigationBannerProps[];
  contactSlideOver?: NavigationSlideOverProps[];
}

export interface MobileNavigationLinkProps {
  imageWidth?: number;
  mobileImageWidth?: number;
  mobileIcon?: ImageProps;
  hoverIcon?: ImageProps;
  name: string;
  portalBaseUrl?: string;
  url?: string;
  navigationSubLinks?: NavigationSubLinkProps[];
}

export interface NavigationLinkProps extends MobileNavigationLinkProps {
  featuredBlogPosts: NavigationFeaturedBlogPostProps[];
  navigationFooter?: NavigationFooterItemProps[];
  showMobileOnly: boolean;
}

export interface NavigationSubLinkProps {
  name: string;
  redirectsToPortal: boolean;
  url: string;
  icon: ImageProps;
}

export interface NavigationFeaturedBlogPostProps {
  content: NavigationFeaturedBlogPostContentProps;
  full_slug: string;
  slug: string;
}

interface NavigationFeaturedBlogPostContentProps {
  excerpt: string;
  title: string;
  featured_image: ImageProps;
  component?: string;
  blog_slug?: string;
}

interface NavigationSlideOverProps {
  showMenu: boolean;
  titel: string;
  description: string;
  body: Array<NavigationContactMenuItemsProps | unknown>;
}

export const Navigation = ({
  alternativeTopLineColor,
  customLogoIcon,
  phoneIcon,
  phoneNumber,
  appointmentIcon,
  appointmentText,
  appointmentLink,
  infobrochureIcon,
  infobrochureText,
  infobrochureLink,
  navigationLinks,
  showLoginLinks,
  loginText,
  registerText,
  registerLink,
  requestQuoteIcon,
  requestQuoteLink,
  requestQuoteText,
  secondMobileButtonIcon,
  secondMobileButtonLink,
  secondMobileButtonText,
  banner,
  contactSlideOver,
}: NavigationProps) => {
  const { config } = useAppConfig();
  const { largerXl, smallerSm } = useMediaQuery();

  const requestOfferPath =
    requestQuoteLink && getAlternativePath({ originalLink: requestQuoteLink, condition: smallerSm });

  const navgationBanner = banner?.[0] as NavigationBannerProps;
  const navigationContactMenu = contactSlideOver?.[0] as NavigationSlideOverProps;

  const isBannerActive = () => {
    if (navgationBanner?.timePeriodStart && navgationBanner?.timePeriodEnd && navgationBanner?.showBanner) {
      return isDateWithinTimePeriod(
        navgationBanner.timePeriodStart,
        navgationBanner.timePeriodEnd,
        navgationBanner.showBanner
      );
    }
    return false;
  };

  const contactMenuData = {
    showMenu: navigationContactMenu?.showMenu as boolean,
    title: navigationContactMenu?.titel as string,
    description: navigationContactMenu?.description as string,
    contactItems: navigationContactMenu?.body as NavigationContactMenuItemProps[],
  };

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => {
        document.body.classList.toggle('!overflow-y-hidden', open);
        return (
          <>
            <div className="border-b p-0">
              {navgationBanner && <NavigationBanner {...navgationBanner} />}
              <NavigationHeader
                alternativeTopLineColor={alternativeTopLineColor}
                phoneIcon={phoneIcon}
                phoneNumber={phoneNumber}
                appointmentIcon={appointmentIcon}
                appointmentLink={appointmentLink}
                appointmentText={appointmentText}
                infobrochureIcon={infobrochureIcon}
                infobrochureText={infobrochureText}
                infobrochureLink={infobrochureLink}
              />
              <div className="z-50 mx-auto max-w-7xl px-4 sm:px-6">
                <div className="flex justify-between">
                  <div className="relative flex h-16">
                    <a aria-label="deutsche teilkauf" className="flex flex-shrink-0 items-center" href="/">
                      {customLogoIcon ? (
                        <StoryblokIcon
                          iconSource={customLogoIcon.filename}
                          className="mr-2 inline h-8 w-auto"
                          alt={customLogoIcon?.alt}
                          loading="eager"
                        />
                      ) : (
                        <DtkLogo className="h-8" loading="eager" />
                      )}
                    </a>

                    <Popover.Group className="navi:visible navi:ml-6 navi:flex navi:space-x-8 hidden">
                      {navigationLinks.map((link: NavigationLinkProps, id) => (
                        <MainMenu
                          key={id}
                          showMobileOnly={link.showMobileOnly}
                          imageWidth={link.imageWidth}
                          mobileIcon={link.mobileIcon}
                          hoverIcon={link.hoverIcon}
                          name={link.name}
                          url={link.url}
                          featuredBlogPosts={link.featuredBlogPosts}
                          navigationSubLinks={link.navigationSubLinks}
                          navigationFooter={link.navigationFooter}
                        />
                      ))}
                    </Popover.Group>
                  </div>

                  <div className="flex justify-end space-x-4">
                    {/* Login, Customer Portal  */}
                    {showLoginLinks && (
                      <NavigationToPortalComponent
                        loginLink={config['PORTAL_BASE_URL']}
                        loginText={loginText}
                        registerLink={registerLink}
                        registerText={registerText}
                      />
                    )}
                    {contactMenuData.showMenu && <NavigationContactMenuComponent {...contactMenuData} />}
                    {requestOfferPath && (
                      <Button className="my-2 hidden sm:flex" href={requestOfferPath}>
                        {requestQuoteText}
                      </Button>
                    )}

                    <div className="navi:hidden -mr-2 flex items-center">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <NavigationIconWithTextMobile
                            iconSource="https://a.storyblok.com/f/148087/24x24/fbb6da2e3e/x.svg"
                            iconText="Menü"
                          />
                        ) : (
                          <NavigationIconWithTextMobile
                            iconSource="https://a.storyblok.com/f/148087/25x24/6c480a0547/menu.svg"
                            iconText="Menü"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* new mobile navigation  */}
            <div
              className={classNames(
                open && !largerXl ? 'block' : 'hidden',
                isBannerActive() ? 'pb-[14rem]' : 'pb-[6rem]',
                'fixed top-[4.05rem] z-30 h-full w-full overflow-auto bg-white sm:top-[4.2rem]'
              )}
            >
              <ul>
                {navigationLinks.map(
                  (
                    {
                      mobileIcon,
                      mobileImageWidth,
                      hoverIcon,
                      name,
                      navigationSubLinks,
                      url,
                    }: MobileNavigationLinkProps,
                    id
                  ) => (
                    <MobileMenu
                      key={id}
                      mobileImageWidth={mobileImageWidth}
                      mobileIcon={mobileIcon}
                      hoverIcon={hoverIcon}
                      name={name}
                      portalBaseUrl={config['PORTAL_BASE_URL']}
                      url={url}
                      navigationSubLinks={navigationSubLinks}
                    />
                  )
                )}
              </ul>
              {(requestOfferPath || secondMobileButtonLink) && (
                <div className="bg-coolGray-50 relative flex w-full flex-col pt-4 sm:hidden">
                  {requestOfferPath && (
                    <Button
                      className="m-2"
                      href={requestOfferPath}
                      storyblokIconSource={requestQuoteIcon?.filename}
                      storyblokIconAlt={requestQuoteIcon?.alt}
                      iconPosition="left"
                    >
                      {requestQuoteText}
                    </Button>
                  )}
                  {secondMobileButtonLink && (
                    <Button
                      className="m-2"
                      variant="white"
                      href={secondMobileButtonLink}
                      storyblokIconSource={secondMobileButtonIcon?.filename}
                      storyblokIconAlt={secondMobileButtonIcon?.alt}
                      iconPosition="left"
                    >
                      {secondMobileButtonText}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </>
        );
      }}
    </Disclosure>
  );
};

import { formatCurrency } from '@dtk/formatter';

interface PercentageComponentProps {
  percent: number;
  amount: number;
  owner: 'DTK' | 'user';
}

export const PercentageComponent = ({ percent = 0, amount = 0, owner }: PercentageComponentProps) => {
  const color = owner === 'DTK' ? 'text-coolGray-400' : 'text-cyan';
  const firstTextLine = owner === 'DTK' ? 'Teilverkauf an' : 'Verbleibender';
  const secondTextLine = owner === 'DTK' ? 'Deutsche Teilkauf' : 'Eigentumsanteil';

  return (
    <div className="grid justify-items-stretch gap-0 md:justify-items-center md:gap-0.5">
      <div className="flex items-baseline gap-1">
        <div className={`${color} text-[52px] font-bold`}>{percent}</div>
        <div className={`${color} text-[40px] font-bold`}>{` %`}</div>
      </div>
      <div className="grid grid-cols-2 gap-0 md:justify-items-center">
        <div className="text-navy-navy col-span-2 self-start text-lg md:self-center">{firstTextLine}</div>
        <div className="text-navy-navy col-span-1 self-start text-lg font-bold md:col-span-2 md:self-center">
          {secondTextLine}
        </div>
        <div className={`col-span-1 justify-self-end md:col-span-2 md:justify-self-center ${color} text-lg font-bold`}>
          {formatCurrency(amount, 0)}
        </div>
      </div>
    </div>
  );
};

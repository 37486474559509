import { AuthorProps } from '../../types';
import Link from 'next/link';

type BottomAuthorBlogPostProps = {
  author: AuthorProps;
};

export const BottomAuthorBlogPost = ({ author }: BottomAuthorBlogPostProps) => {
  const {
    content: { author_image, author_name, author_position },
    full_slug,
  } = author;

  const alt = author_image?.alt;
  const filename = author_image?.filename;

  return (
    <div className="xs:flex-row xs:space-x-5 text-navy-navy mx-auto flex max-w-7xl flex-col justify-start px-4 pb-4 sm:px-6 md:max-w-xl lg:max-w-2xl lg:px-8 xl:max-w-[50rem] xl:justify-end">
      {author_image && (
        <Link passHref href={'/' + full_slug}>
          <img
            alt={alt}
            src={`${filename}/m/120x0`}
            className="xs:mb-0 mb-3 h-24 w-24 cursor-pointer rounded-full object-cover object-top"
            loading="lazy"
            {...{ fetchpriority: 'low' }}
          />
        </Link>
      )}

      <div className="flex flex-col">
        <Link passHref href={'/' + full_slug}>
          <p className="font-body-semibold cursor-pointer text-lg hover:underline">{author_name}</p>
        </Link>
        <Link passHref href={'/' + full_slug}>
          <p className="cursor-pointer hover:underline">{author_position}</p>
        </Link>
      </div>
    </div>
  );
};

export type BackgroundThemeVariants =
  | 'coolGray-50'
  | 'coolGray-100'
  | 'gold-creme'
  | 'gold-sand'
  | 'navy-700'
  | 'navy'
  | 'navy-50'
  | 'white'
  | 'mint'
  | 'blue-pastelBlue'
  | 'gray-stoneGray'
  | 'khaki';

export const themesForBackgroundColor = {
  defaultBackgroundColor: 'white',
  darkBackgroundColors: ['navy-700', 'navy', 'khaki', 'blue-pastelBlue'],
  lightBackgroundColors: ['mint'],
  overlineColor: {
    'coolGray-50': 'gold',
    'coolGray-100': 'gold',
    'gold-creme': 'navy',
    'gold-sand': 'navy',
    'navy-50': 'gold',
    'navy-700': 'white',
    navy: 'white',
    white: 'gold',
    mint: 'navy',
    'blue-pastelBlue': 'white',
    'gray-stoneGray': 'navy',
    khaki: 'white',
  },
  sliderRailColor: {
    'coolGray-50': '#F1F4F9',
    'coolGray-100': '#FFFFFF',
    'gold-creme': '#E7DFCE',
    'gold-sand': '#F1F4F9',
    'navy-50': '#FFFFFF',
    'navy-700': '#F1F4F9',
    navy: '#2A4365',
    white: '#F1F4F9',
    mint: '#F1F4F9',
    'blue-pastelBlue': '#F1F4F9',
    'gray-stoneGray': '#F1F4F9',
    khaki: '#2A4365',
  },
};

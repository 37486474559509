import { getConsentStatus } from './userCentricsConsent';

export interface WidgetStyle {
  microButton: string;
  microCombo: string;
  microReviewCount: string;
  microStar: string;
  microTrustScore: string;
  mini: string;
  reviewCollector: string;
  slider: string;
  starter: string;
}

export const getTrustPilotWidgetStyles = (): WidgetStyle => {
  return {
    microButton: '5419b757fa0340045cd0c938',
    microCombo: '5419b6ffb0d04a076446a9af',
    microReviewCount: '5419b6a8b0d04a076446a9ad',
    microStar: '5419b732fbfb950b10de65e5',
    microTrustScore: '5419b637fa0340045cd0c936',
    mini: '53aa8807dec7e10d38f59f32',
    reviewCollector: '56278e9abfbbba0bdcd568bc',
    slider: '54ad5defc6454f065c28af8b',
    starter: '5613c9cde69ddc09340c6beb',
  };
};

export const getBusinessUnitId = (businessUnitName: string) => {
  const businessUnitId: Record<string, string> = {
    dtk: '5f69f19af3d1210001782544',
    enter: '64623165c7e3ecfb983e8702',
  };
  return businessUnitId[businessUnitName] ?? businessUnitId['dtk'];
};

export const getBusinessUnitLink = (businessUnitName: string) => {
  const reviewSiteLink: Record<string, string> = {
    dtk: 'https://de.trustpilot.com/review/deutsche-teilkauf.de',
    enter: 'https://de.trustpilot.com/review/enter.de',
  };
  return reviewSiteLink[businessUnitName] ?? reviewSiteLink['dtk'];
};

export const isTrustPilotConsentConfirmed = () => {
  return getConsentStatus('qEs4t49Rg');
};

import { Newsletter, NewsletterType, usePostNewsletter } from '@dtk/query';
import {
  Button,
  CheckBox,
  headingType,
  Headline,
  InputField,
  StoryblokIcon,
  useMediaQuery,
  validationMessages,
} from '@dtk/ui-components';

import { Form, Formik } from 'formik';
import { useState } from 'react';
import { boolean, object, string } from 'yup';
import classNames from 'classnames';
import { MailIcon } from '@heroicons/react/solid';

export interface NewsletterSignUpProps {
  headingType: headingType;
  title: string;
  subline: string;
  listItem1: string;
  listItem2: string;
  listItem3: string;
  icon: { filename: string; alt?: string };
  thankYouTitle: string;
  thankYouText: string;
  thankYouTextSecondLine: string;
  userType: NewsletterType;
}

export const NewsletterSignUp = (props: NewsletterSignUpProps) => {
  const { mutate } = usePostNewsletter();
  const { smallerXs } = useMediaQuery();

  const [showThankyou, setThankyou] = useState(false);
  const [inputFieldErrorMessage, setInputFieldErrorMessage] = useState('');
  const chosenHeadingType = props.headingType || 'h2';
  const allListItems = [props.listItem1, props.listItem2, props.listItem3];

  const initValues = {
    email: '',
    acceptcheckbox: false,
  };

  const validationSchema = object({
    email: string()
      .email(validationMessages.error.email.text)
      .required(validationMessages.error.defaultInputField.text),
    acceptcheckbox: boolean().oneOf([true]).required(),
  });

  const handleSubmit = (values: Newsletter) => {
    mutate(
      {
        data: {
          ...values,
          type: props.userType,
        },
      },
      {
        onSuccess: async () => {
          setThankyou(true);
        },
        onError: () => {
          setInputFieldErrorMessage(
            'Fehler bei der Datenübermittlung! Versuchen Sie es zu einem späteren Zeitpunkt erneut.'
          );
        },
      }
    );
  };

  return (
    <Formik<Newsletter>
      initialValues={initValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <div className="bg-navy">
          <div className="bg-navy mx-auto grid max-w-7xl grid-cols-12 py-8 px-4 lg:py-16 lg:px-8">
            <div className={showThankyou ? 'col-span-12' : 'col-span-12 md:col-span-7 xl:col-span-6'}>
              {!showThankyou ? (
                <>
                  <Headline
                    headingType={chosenHeadingType}
                    headlineClassNames="tracking-tight text-white"
                    headline={props.title}
                    isBold={true}
                  />
                  <p className="mb-6 mt-3 max-w-3xl text-lg leading-6 text-gray-300">
                    {!showThankyou && props.subline}
                  </p>
                  {props.icon?.filename && (
                    <ul className=" text-white">
                      {allListItems.map((listItem, index) => (
                        <li key={`${listItem}-${index}`} className="mb-3 flex flex-row items-start">
                          <StoryblokIcon
                            className="pr-2"
                            iconSource={props.icon.filename}
                            alt={props.icon.alt || 'icon'}
                            width={28}
                            height={28}
                            loading="lazy"
                          />
                          <p>{listItem}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ) : (
                <>
                  <Headline
                    headingType={chosenHeadingType}
                    headlineClassNames="tracking-tight text-white"
                    headline={props.thankYouTitle}
                    isBold={true}
                  />
                  <p className="mb-6 mt-3 max-w-3xl text-lg leading-6 text-gray-300">
                    {props.thankYouText}
                    <br />
                    {props.thankYouTextSecondLine}
                  </p>
                </>
              )}
            </div>
            {!showThankyou && (
              <div className="col-span-12 lg:col-span-5 xl:col-span-6">
                <Form>
                  <div className="flex flex-col">
                    <div className="my-4">
                      <div
                        className={classNames(
                          'xs:mb-0 xs:pr-4 xs:flex-row flex flex-col',
                          touched.email && errors.email ? 'xs:items-center' : 'xs:items-end'
                        )}
                      >
                        <InputField
                          containerClassNames="w-full mr-6"
                          data-testid="email-newslettersignup"
                          id="email"
                          name="email"
                          label="Ihre E-Mail-Adresse"
                          labelColor="white"
                          type="text"
                          placeholder="max@mustermail.de"
                          icon={MailIcon}
                          isLargeField
                        />
                        <Button className="xs:mt-2 mt-4" fullWidth={smallerXs} size="lg" type="submit">
                          Anmelden
                        </Button>
                      </div>
                      {inputFieldErrorMessage && <p className="text-red mt-2 ml-1 text-sm">{inputFieldErrorMessage}</p>}
                    </div>

                    <CheckBox data-testid="privacy-policy" name="acceptcheckbox">
                      <label className="mr-1 flex flex-row">
                        <span className="ml-1 text-white">
                          * Durch Klick auf “Anmelden” akzeptiere ich die Übermittlung meiner Daten an die Deutsche
                          Teilkauf GmbH. Ihre Daten werden bis zu Ihrem Widerruf bei uns gespeichert. Weitere
                          Informationen zum Datenschutz finden Sie in unserer
                          <a className="ml-1 text-cyan-500" href="/datenschutz/">
                            Datenschutzerklärung
                          </a>
                          .
                        </span>
                      </label>
                    </CheckBox>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

import { HeaderText } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const HeaderTextBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="header-text">
      <HeaderText
        key={blok._uid}
        overline={blok.overline}
        headline={blok.headline}
        subtitle={blok.subtitle}
        headingType={blok.headingType}
        theme={blok.theme}
      />
    </div>
  );
};

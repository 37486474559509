import { useState } from 'react';
import classNames from 'classnames';

import { IconArrowLeft, IconArrowRight, RadialGauge, Step } from '../../index';

const FIRST_STEP = 1;

export enum StepStatus {
  complete,
  current,
  upcoming,
}

const getCurrentStepIndex = (steps: Steps[]) => {
  const step = steps.find((step) => step.status === StepStatus.current);

  if (!step) {
    return FIRST_STEP;
  }

  return step.id;
};

export const StepsCarousel = ({
  steps,
  handleCurrent,
  handleStep,
  footerColor,
  percent,
  stepTextColor,
  svgPrimaryColor,
  svgSecondaryColor,
  stepInfoIconColor,
  stepNumberColor,
}: StepsCarouselProps) => {
  const [focusedStep, setFocusedStep] = useState(getCurrentStepIndex(steps));

  const nextStep = steps.find((step) => step.id === focusedStep + 1);

  const isLastStep = focusedStep === steps.length;
  const isFirstStep = focusedStep === FIRST_STEP;

  return (
    <div className="flex h-full grow flex-col items-center justify-center">
      <div className="flex items-center justify-between">
        <button
          disabled={isFirstStep}
          onClick={() => setFocusedStep((prev) => prev - 1)}
          className={classNames(
            'flex h-8 w-8 items-center justify-center rounded-full shadow-sm ',
            isFirstStep ? 'opacity-25' : ''
          )}
        >
          <IconArrowLeft />
        </button>

        <div className="mx-4 lg:mx-2 lg:mt-[15px]">
          <RadialGauge percent={percent} svgPrimaryColor={svgPrimaryColor} svgSecondaryColor={svgSecondaryColor}>
            {steps.map((step, stepIdx) => {
              return (
                <Step
                  key={step.name}
                  step={step}
                  stepIdx={stepIdx}
                  focusedStep={focusedStep}
                  handleCurrent={handleCurrent}
                  handleStep={handleStep}
                  stepTextColor={stepTextColor}
                  stepInfoIconColor={stepInfoIconColor}
                  stepNumberColor={stepNumberColor}
                />
              );
            })}
          </RadialGauge>
        </div>

        <button
          disabled={isLastStep}
          onClick={() => setFocusedStep((prev) => prev + 1)}
          className={classNames(
            'flex h-8 w-8 items-center justify-center rounded-full shadow-sm',
            isLastStep ? 'opacity-25' : ''
          )}
        >
          <IconArrowRight />
        </button>
      </div>

      <span
        className={classNames(
          'text-coolGray-500 xs:pb-2 flex min-h-[48px] text-center md:pb-0 lg:mt-[15px] lg:items-center',
          isLastStep ? 'invisible' : '',
          footerColor && `text-${footerColor}`
        )}
      >
        Nächster Schritt: {nextStep?.id}. {nextStep?.description}
      </span>
    </div>
  );
};

export interface StepsCarouselProps {
  steps: Steps[];
  handleCurrent?: () => void;
  handleStep?: (status: string) => void;
  percent: number;
  svgPrimaryColor?: string;
  svgSecondaryColor?: string;
  footerColor?: string;
  stepTextColor?: string;
  stepInfoIconColor?: string;
  stepNumberColor?: string;
}

interface Steps {
  id: number;
  name: string;
  description?: string;
  key: string;
  status: StepStatus;
}

import classNames from 'classnames';
import Image from 'next/future/image';
import { ImageProps, setObjectPosition } from '../../utils';
import { storyBlokLoader } from '../../utils/storyblok-loader';

interface FullHeightImageProps {
  alignedLeft: boolean;
  image: ImageProps;
}

export const FullHeightImage = ({ alignedLeft, image }: FullHeightImageProps) => {
  return (
    <div
      className={classNames(
        alignedLeft ? 'md:left-0' : 'md:right-0',
        'image-container relative h-56 sm:h-72 md:absolute md:top-0 md:h-full md:w-1/2'
      )}
    >
      {image?.filename && (
        <Image
          loader={storyBlokLoader}
          className="h-full w-full object-cover"
          style={setObjectPosition(image.filename, image.focus)}
          src={image.filename}
          alt={image.alt}
          fill
          loading="lazy"
        />
      )}
    </div>
  );
};

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Controller, Navigation, Pagination, Thumbs } from 'swiper';
import { useRef, useState } from 'react';

import { Button, StoryblokIcon } from '@dtk/ui-components';
import Link from 'next/link';
import { Transition } from '@headlessui/react';
import styles from './JobsCarousel.module.scss';
import { translateSchedule } from '../../utils/translateJobSchedule';
import { JobsProps } from './JobsCarouselDataLoader';

SwiperCore.use([Autoplay, Navigation, Pagination, Controller, Thumbs]);

export interface JobsCarouselProps {
  title: string;
  subtitle: string;
  jobs: JobsProps[];
  positionOptions: string[];
}

export const JobsCarousel = ({ title, subtitle, jobs, positionOptions }: JobsCarouselProps) => {
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownMenuRef = useRef<HTMLHeadingElement>(null);
  const dropdownButtonRef = useRef<HTMLHeadingElement>(null);

  const filteredJobs =
    selectedJobs.length > 0
      ? jobs.filter((job) => selectedJobs.includes(job.department?.toLowerCase() || 'initiativbewerbung'))
      : jobs;

  function handleDropdownClick() {
    setIsOpen(!isOpen);
  }

  const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedJobs((selectedJobs) => {
      if (e.target.checked) {
        return [...selectedJobs, e.target.value.toLowerCase()];
      } else {
        return selectedJobs.filter((job) => job.toLowerCase() !== e.target.value.toLowerCase());
      }
    });
  };

  return (
    <div className="bg-navy pt-16 pb-20">
      <p className="font-headline text-gold mt-5 mb-8 text-center text-4xl">Aktuelle Jobs</p>
      <div className="flex flex-row justify-center">
        <div className="mx-2 flex flex-col sm:mx-0">
          <span className="text-md mb-1 text-white">Bitte wählen Sie</span>
          <div className="text-gray mb-4 flex w-80 flex-row items-center rounded-sm bg-white px-2 text-xs sm:w-96">
            <button
              className="text-gray flex w-full flex-row items-center justify-between"
              onClick={handleDropdownClick}
            >
              <span ref={dropdownButtonRef} className="text-navy text-sm">
                {selectedJobs.length === 0
                  ? 'Abteilung'
                  : selectedJobs.length === 1
                  ? selectedJobs[0].charAt(0).toUpperCase() + selectedJobs[0].slice(1)
                  : `Abteilungen (${selectedJobs.length})`}
              </span>
              <span
                className="flex h-12 items-center justify-center border-l-2 pl-2 pr-2"
                onClick={handleDropdownClick}
              >
                {isOpen ? (
                  <StoryblokIcon
                    iconSource="https://a.storyblok.com/f/148087/250x150/b03a9ed03e/chevron-up-navy.svg"
                    className="ml-2 h-2 w-4 text-gray-500"
                  />
                ) : (
                  <StoryblokIcon
                    iconSource="https://a.storyblok.com/f/148087/250x150/7d37c5f67d/chevron-down-navy.svg"
                    className="ml-2 h-2 w-4 text-gray-500"
                  />
                )}
              </span>
            </button>
          </div>
          <Transition
            unmount={false}
            show={isOpen}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute z-10 mt-20 w-80 rounded-md bg-white p-2 shadow-lg sm:w-96"
          >
            <div ref={dropdownMenuRef} className="space-y-4">
              {positionOptions &&
                positionOptions
                  .filter((option) => option)
                  .map((option, id) => (
                    <div key={id} className="relative flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id={option}
                          name={option}
                          type="checkbox"
                          value={option}
                          onChange={handlePositionChange}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor={option} className="font-medium text-gray-700">
                          {option}
                        </label>
                      </div>
                    </div>
                  ))}
            </div>
          </Transition>
        </div>
      </div>
      <Swiper
        className={styles['swiperSlideWrapper'] + ' pb-16 '}
        loop={true}
        speed={2000}
        preventClicksPropagation={true}
        preventInteractionOnTransition={true}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (_, className) {
            return '<span class="bg-white mr-1.5 ' + className + '"></span>';
          },
        }}
        centeredSlidesBounds={true}
        centeredSlides={true}
        breakpoints={{
          321: {
            slidesPerGroup: 1,
            slidesPerView: 1,
          },
          1320: {
            slidesPerGroup: 1,
            slidesPerView: 3,
          },
        }}
      >
        {filteredJobs?.length > 0 ? (
          filteredJobs.map((job, id) => (
            <SwiperSlide key={id} className={styles['swiperSlide'] + ' mb-10 flex flex-col items-center'}>
              <div className="border-gold flex h-96 w-80 flex-col items-center rounded-md border px-8 pt-8 pb-12 text-white sm:h-80 sm:w-96">
                <span className="mt-3 mb-10 flex justify-center rounded-full bg-gray-100 px-3 py-1 text-xs tracking-wide text-gray-700">
                  {job.department}
                </span>
                <span className="mb-4 w-3/4 break-words text-center text-xl sm:break-normal sm:text-2xl">
                  {
                    <Link href={`https://deutsche-teilkauf.jobs.personio.de/job/${job.id}?display=de`} passHref={true}>
                      <a href="/#" className="" target="_blank" rel="noopener noreferrer">
                        {job.name}
                      </a>
                    </Link>
                  }
                </span>
                <span className="text-md mb-10 text-center font-thin text-gray-400">
                  {job.recruitingCategory + ', ' + translateSchedule(job.schedule) + ' · ' + job.office}
                </span>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <div className="flex justify-center text-white">Keine Ergebnisse</div>
        )}
        <div className={'swiper-button-next bg-navy-700 text-white ' + styles['swiperButtonNext']}>
          <ChevronRightIcon className="h-6 w-6" />
        </div>
        <div className={'swiper-button-prev bg-navy-700 text-white ' + styles['swiperButtonPrev']}>
          <ChevronLeftIcon className="h-6 w-6" />
        </div>
        <div className={'swiper-pagination ' + styles['swiperPaginationCustom']} />
      </Swiper>
      <div className="mt-4 flex justify-center">
        <Button href="https://deutsche-teilkauf.jobs.personio.de/" isexternal>
          Zeige alle {filteredJobs.length} Jobs an
        </Button>
      </div>
    </div>
  );
};

import { ChevronUpIcon } from '@heroicons/react/solid';
import { Disclosure } from '@headlessui/react';
import { trackFAQ } from '@dtk/user-tracking';

export interface FaqDisclosureProps {
  questionAnswerData: QuestionAnswerData[];
}

export interface QuestionAnswerData {
  question: string;
  answer: string;
}

//Rename
export const FaqDisclosure = ({ questionAnswerData }: FaqDisclosureProps) => {
  return (
    <div className="border-coolGray-200 w-full rounded-t-md border bg-white">
      {questionAnswerData.map((questionAnswer, index) => (
        <Disclosure key={`${questionAnswer.question}-${index}`}>
          {({ open }) => (
            <>
              <Disclosure.Button
                onClick={() => trackFAQ(questionAnswer.question)}
                className={`
                flex
                w-full
                justify-between
                px-4 py-4
                text-left
                text-base
                font-semibold
                ${open ? 'border-b-0' : questionAnswerData.length - 1 !== index ? 'border-b' : 'border-b-0'}
                ${open ? 'text-navy' : 'text-coolGray-500'}
                ${open ? 'bg-coolGray-100' : 'bg-white'}
                ${index === 0 ? 'rounded-t-md' : ''}
                `}
              >
                <span data-testid={`faq-disclosure-question-${index + 1}`}>{questionAnswer.question}</span>
                <ChevronUpIcon className={`${!open ? 'rotate-180 transform' : ''} text-coolGray-500 h-5 w-5`} />
              </Disclosure.Button>
              <Disclosure.Panel
                className={`
                text-navy whitespace-pre-line px-4
                pt-4
                pb-6
                text-justify
                text-base
                font-normal
                ${questionAnswerData.length - 1 !== index ? 'border-b' : 'border-b-0'}
                `}
              >
                {questionAnswer.answer}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

import { CheckCircleIcon } from '@heroicons/react/solid';
import { storyblokEditable } from '@storyblok/react';

export const ListItemBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <li key={blok._uid} className="" {...storyblokEditable(blok)} data-test="list-item">
      <CheckCircleIcon width="10" height={'10'}></CheckCircleIcon> {blok.text}
    </li>
  );
};

export const formatMeasuringUnitForAreaOrVolume = (textToFormat: string) => {
  let formattedText = textToFormat;

  if (textToFormat.includes('m2')) {
    formattedText = textToFormat.replace('m2', 'm²');
  } else if (textToFormat.includes('m3')) {
    formattedText = textToFormat.replace('m3', 'm³');
  }
  return formattedText;
};

export const addThousandSeparatorToNumericValues = (numberValueToFormat: number) => {
  return new Intl.NumberFormat('de-DE').format(numberValueToFormat);
};

export const removeThousandSeparatorFromNumericValue = (stringToNumberValue: string, thousandSeparator = '.') => {
  return parseInt(stringToNumberValue?.split(thousandSeparator).join(''));
};


import { Field, useFormikContext } from 'formik';
import React from 'react';
import { calculateGridColumnSpan, getHighlightedText, validationMessages } from '@dtk/ui-components';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import styles from '../InputField.module.scss';
import { formatMeasuringUnitForAreaOrVolume } from '../../../utils/format';
import { useOfferStore } from '../../../store/OfferStore';
import { getNumberOfOptionsByFieldName } from '../utils';

export const InputSelectBlok = ({ blok }) => {
  const { values, errors, touched, setFieldValue, setErrors } = useFormikContext();
  const MAX_NUMBER_OF_COLUMNS = 12;

  const handleChoiceChange = (e) => {
    const { checked, name } = e.target;
    const keys = useOfferStore.getState().data;
    const value = checked ? name : '';

    setFieldValue(blok.name, value);
    useOfferStore.setState({ data: keys.set(blok.name, value) });
    setErrors({});

    (document.activeElement as HTMLElement).blur();
  };

  return (
    <div {...storyblokEditable(blok)} data-test="input-select" className={`col-span-${MAX_NUMBER_OF_COLUMNS} grid`}>
      {blok.suboptionHeading && blok.options.length > 0 && (
        <div className="font-body-semibold mt-7 mb-4 text-gray-800 antialiased">{blok.suboptionHeading}</div>
      )}
      <div className={`grid grid-cols-${MAX_NUMBER_OF_COLUMNS} gap-x-6 gap-y-4`}>
        {blok.options.map((option) => (
          <div
            key={option._uid}
            className={`col-span-${MAX_NUMBER_OF_COLUMNS} grid sm:col-span-${calculateGridColumnSpan(
              blok.options.length,
              12
            )}`}
          >
            <Field
              parentid={blok.id}
              id={option.id}
              className={styles['checkboxInput'] + ''}
              type="checkbox"
              name={option.name}
              checked={values?.[blok.name] === option.name}
              onChange={handleChoiceChange}
              onBlur={handleChoiceChange}
            />
            <label className="font-body-medium" htmlFor={option.id}>
              {formatMeasuringUnitForAreaOrVolume(option.label)}
            </label>
          </div>
        ))}
        {getNumberOfOptionsByFieldName(blok, values?.[blok.name]) > 0 &&
          blok.options &&
          blok.options.map(
            (nestedBlok: SbBlokData) =>
              values?.[blok.name] === nestedBlok.name && <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
          )}
      </div>

      {touched?.[blok.name] && errors?.[blok.name] && (
        <p
          className="text-red-secondary font-body mt-2 text-sm"
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(
              errors?.[blok.name],
              validationMessages.error.defaultInputField.highlightedPart,
              'red-secondary',
              { fontFamily: 'font-body', isBold: true }
            ),
          }}
        />
      )}
    </div>
  );
};

import React from 'react';
import { InputField, InputFieldWithAddOn, isPrefix, isSuffix } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';
import { useOfferStore } from '../../../store/OfferStore';

export const TextAndNumberInputBlok = ({ blok }) => {
  const hasSuffixOrPrefix = isSuffix(blok.fieldAddOn) || isPrefix(blok.fieldAddOn);

  const handleValueChange = (event) => {
    const keys = useOfferStore.getState().data;
    useOfferStore.setState({ data: keys.set(blok.name, event.target.value) });
  };

  return (
    <div
      {...storyblokEditable(blok)}
      data-test="text-and-number-input"
      className={`col-span-12 sm:col-span-${blok.width} mb-4 grid`}
    >
      {hasSuffixOrPrefix && (
        <InputFieldWithAddOn
          id={blok.name}
          name={blok.name}
          prefix={isPrefix(blok.fieldAddOn) && blok.fieldAddOn}
          suffix={isSuffix(blok.fieldAddOn) && blok.fieldAddOn}
          type={blok.type}
          label={blok.label}
          placeholder={blok.placeholder}
          isMandatory={blok.isRequired}
          isOptional={blok.isOptional}
          onBlur={handleValueChange}
        />
      )}
      {!hasSuffixOrPrefix && (
        <InputField
          id={blok.name}
          name={blok.name}
          type={blok.type}
          label={blok.label}
          placeholder={blok.placeholder}
          isMandatory={blok.isRequired}
          isOptional={blok.isOptional}
          onBlur={handleValueChange}
        />
      )}
    </div>
  );
};

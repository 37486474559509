import {
  DTK_BONUS_RATE,
  ESTATE_AGENT_COST_RATE,
  MINIMUM_SHARE_FOR_DTK,
  RENTAL_COST_RATE,
  USAGE_FEE_RATE,
  VALUE_INCREASE_RATE,
} from '@dtk/ui-components';

const verifyValue = (value: string | number) => {
  if (!value) return 0;
  return typeof value === 'string' ? parseInt(value, 10) : value;
};

export const calculateRealEstateValueAfterYears = (realEstateValue: string | number, years: number) => {
  return verifyValue(realEstateValue) * (1 + VALUE_INCREASE_RATE) ** years;
};

const firstThresholdValue = (realEstateValue: string | number, requestedPayout: string | number) => {
  return verifyValue(requestedPayout) * MINIMUM_SHARE_FOR_DTK + verifyValue(realEstateValue);
};

const secondThresholdValue = (realEstateValue: string | number, requestedPayout: string | number) => {
  const differenceOfAmountTypeValues = verifyValue(realEstateValue) - verifyValue(requestedPayout);
  return (
    differenceOfAmountTypeValues * DTK_BONUS_RATE +
    differenceOfAmountTypeValues * MINIMUM_SHARE_FOR_DTK +
    firstThresholdValue(realEstateValue, requestedPayout)
  );
};

export const calculatePercentageValueForDTK = (realEstateValue: string | number, requestedPayout: string | number) => {
  if (verifyValue(realEstateValue) === 0) return 0;
  return 1 - (verifyValue(realEstateValue) - verifyValue(requestedPayout)) / verifyValue(realEstateValue);
};

const calculateAbsoluteAmountForDTK = (
  realEstateValue: string | number,
  requestedPayout: string | number,
  years: number
) => {
  const requestedPayoutNumber = verifyValue(requestedPayout);
  const estateValue = calculateRealEstateValueAfterYears(realEstateValue, years);
  return (
    (estateValue - secondThresholdValue(realEstateValue, requestedPayout)) *
      calculatePercentageValueForDTK(realEstateValue, requestedPayout) +
    (requestedPayoutNumber * MINIMUM_SHARE_FOR_DTK + requestedPayoutNumber)
  );
};

export const calculateRealEstateValueWithValueEnhancement = (
  realEstateValue: string | number,
  requestedPayout: string | number,
  year: number
) => {
  const estateValue = calculateRealEstateValueAfterYears(realEstateValue, year);
  if (estateValue <= secondThresholdValue(realEstateValue, requestedPayout)) {
    return firstThresholdValue(realEstateValue, requestedPayout);
  }
  return estateValue - calculateAbsoluteAmountForDTK(realEstateValue, requestedPayout, year);
};

export const calculateDtkBonus = (realEstateValue: string | number, requestedPayout: string | number) => {
  return (verifyValue(realEstateValue) - verifyValue(requestedPayout)) * DTK_BONUS_RATE;
};

export const calculateTotalPayoutForYears = (
  realEstateValue: string | number,
  requestedPayout: string | number,
  years: number
) => {
  return (
    verifyValue(requestedPayout) + calculateRealEstateValueWithValueEnhancement(realEstateValue, requestedPayout, years)
  );
};

export const calculateEstateAgentCost = (realEstateValue: string | number) => {
  return verifyValue(realEstateValue) * ESTATE_AGENT_COST_RATE;
};

export const calculateRentalCostForYears = (realEstateValue: string | number, years: number) => {
  return verifyValue(realEstateValue) * years * RENTAL_COST_RATE;
};

export const calculateMonthlyUsageFee = (requestedPayout: string | number) => {
  return (verifyValue(requestedPayout) * USAGE_FEE_RATE) / 12;
};

export const calculateUsageFeeForYears = (requestedPayout: string | number, years: number) => {
  return verifyValue(requestedPayout) * years * USAGE_FEE_RATE;
};

export const calculateModernizationCostForYears = (year: number) => {
  const AVERAGE_MODERNIZATION_COST = 880;
  return AVERAGE_MODERNIZATION_COST * year;
};

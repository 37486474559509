import { ClubFAQCard } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

export const ClubFAQCardBlok = ({ ...props }) => {
  const { blok } = props;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="club-faq-card">
      <ClubFAQCard {...blok} />
    </div>
  );
};

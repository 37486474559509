import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Image from 'next/future/image';

import { Button, ButtonVariantsRedesign } from '../../01_elements';
import { storyBlokLoader } from '../../utils/storyblok-loader';
import { useMediaQuery } from '../../hooks';
import { BackgroundThemeVariants, setObjectPosition, themesForBackgroundColor } from '../../utils';

interface ContactPersonProps {
  contact: ContactPersonDetailProps;
}

export interface ContactPersonDetailProps {
  firstname: string;
  lastname: string;
  pictureUrl: string;
  pictureAltName?: string;
  pictureFocus?: string;
  eMail?: string;
  phone?: string;
  position?: string;
  theme?: BackgroundThemeVariants;
  linearGradientBackgroundColor?: string;
  primaryButtonColor?: ButtonVariantsRedesign;
}

export const ContactPerson = ({ ...props }: ContactPersonProps) => {
  const {
    firstname,
    lastname,
    pictureUrl,
    pictureAltName,
    pictureFocus,
    eMail,
    phone,
    position,
    theme,
    linearGradientBackgroundColor,
    primaryButtonColor,
  } = props.contact;
  const { largerXl } = useMediaQuery();

  const openEMail = (mail?: string) => {
    mail && window.open(`mailto:${mail}`);
  };

  return (
    <div className={classNames(linearGradientBackgroundColor, 'lg:grid lg:gap-8')}>
      <div>
        <div className="mx-auto flex max-w-7xl justify-center sm:block">
          <div className="flex flex-col sm:h-72 sm:w-full sm:flex-row lg:h-96">
            {pictureUrl && (
              <div className="relative flex h-48 w-full sm:h-auto sm:w-48 lg:h-96">
                <div
                  className={classNames(
                    'absolute inset-x-6 top-12 h-48 max-w-[16.875rem]',
                    'sm:top-16 sm:h-40 sm:w-52 md:top-6 md:h-60 md:w-72 lg:top-11 lg:h-72 lg:w-[25rem] lg:max-w-none xl:inset-x-12'
                  )}
                >
                  <Image
                    loader={storyBlokLoader}
                    className="h-full w-full object-cover"
                    style={setObjectPosition(pictureUrl, pictureFocus)}
                    data-testid="contact-image"
                    src={pictureUrl}
                    alt={pictureAltName}
                    width={1000}
                    height={1000}
                  />
                </div>
              </div>
            )}
            <div
              className={classNames(
                `bg-${theme}`,
                'max-w-[20rem] rounded-sm p-6 sm:w-full sm:max-w-none md:pl-24 lg:pl-80'
              )}
            >
              <div
                className={classNames(
                  'pt-10 sm:ml-4 sm:px-16 sm:py-3',
                  themesForBackgroundColor.darkBackgroundColors.includes(theme || 'white') ? 'text-white' : 'text-navy'
                )}
              >
                <div className="mb-5 space-y-2">
                  <h2 className="font-headline text-2xl lg:mt-12 lg:text-3xl xl:text-4xl" data-testid="contact-heading">
                    {firstname} {lastname}
                  </h2>
                  {position && <p>{position}</p>}
                </div>
                <div className="mb-5 space-y-2">
                  {phone && (
                    <div className="flex">
                      <PhoneIcon className="text-gold mr-2 mt-0.5 h-5 w-5" />
                      <a className="cursor-pointer" href={`tel:${phone}`} data-testid="contact-phone">
                        {phone}
                      </a>
                    </div>
                  )}
                  {eMail && (
                    <div className="flex cursor-pointer" onClick={() => openEMail(eMail)}>
                      <MailIcon className="text-gold w-50 mr-2 mt-1 h-5" />
                      <a href={eMail} data-testid="contact-email">
                        {eMail}
                      </a>
                    </div>
                  )}
                </div>
                <Button
                  onClick={() => openEMail(eMail)}
                  size={largerXl ? 'lg' : 'base'}
                  variant={primaryButtonColor || 'cyan'}
                >
                  Ansprechpartner kontaktieren
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { CardWithMeta, CardWithMetaProps } from '@dtk/ui-components';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

import ReactPaginate from 'react-paginate';
import styles from './PostsListing.module.scss';
import { useMediaQuery } from '@dtk/ui-components';

const viewOptions = [{ name: 'Kacheln' }, { name: 'Liste' }];

export interface PostsListingProps {
  posts: CardWithMetaProps[];
  categories: CategoriesProps[];
}

interface CategoriesProps {
  name: string;
  content: CategoriesContentProps;
}

interface CategoriesContentProps {
  name: string;
}

interface ChangePageProps {
  selected: number;
}

export const PostsListing = ({ posts, categories }: PostsListingProps) => {
  const customCategories = ['Alle', ...categories.map((cat) => cat.content?.name)];
  const [categoryName, setCategoryName] = useState<string>('Alle');
  const initialYears = ['2020', '2021', '2022', '2023'];
  const [years, setYears] = useState<string[]>(['2020', '2021', '2022', '2023']);
  const [filteredPosts, setFilteredPosts] = useState<CardWithMetaProps[]>([]);

  const [selectedView, setSelectedView] = useState(viewOptions[0]);
  const { smallerMd } = useMediaQuery();
  const [pageNumber, setPageNumber] = useState(0);
  const postsPerPage = 6;
  const alreadyShownPosts = pageNumber * postsPerPage;
  const pageCount = Math.ceil(filteredPosts.length / postsPerPage);
  const changePage = ({ selected }: ChangePageProps) => {
    setPageNumber(selected);
  };

  const filterAndSortPosts = () => {
    setPageNumber(0);
    if (categoryName === 'Alle')
      return setFilteredPosts([
        ...posts
          .filter((post) => years.includes(post.first_published_at.slice(0, 4)))
          .sort((a, b) => (a.first_published_at > b.first_published_at ? -1 : 1)),
      ]);
    const newFilteredPosts = posts
      .filter((post) => {
        return (
          post.content?.categories.some((cat: { name: string }) => cat.name === categoryName) &&
          years.includes(post.first_published_at.slice(0, 4))
        );
      })
      .sort((a, b) => (a.first_published_at > b.first_published_at ? -1 : 1));
    setFilteredPosts([...newFilteredPosts]);
  };

  const toggleYear = (inputYear: string) => {
    setYears([...(years.includes(inputYear) ? years.filter((year) => year !== inputYear) : [...years, inputYear])]);
  };

  useEffect(() => {
    filterAndSortPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryName, postsPerPage, years]);

  return (
    <div className="relative m-auto max-w-7xl	justify-center py-5 px-5">
      <div className="mb-8 ">
        <div className="border-cold-gray-300 mb-4 flex flex-row justify-center border-0 sm:mb-6 sm:border-b">
          <div className="mx-2 flex flex-col sm:mx-0">
            <div className="flex flex-row items-center justify-center px-4 text-xs text-gray-500">
              {customCategories.map((category, index) => {
                return (
                  <button
                    className={
                      (category === categoryName ? 'text-gray relative font-bold' : '') +
                      ' mx-2	inline-block w-max pb-2 text-sm sm:mx-4 sm:pb-6'
                    }
                    key={index}
                    onClick={() => setCategoryName(category)}
                  >
                    {category}
                    {category === categoryName ? (
                      <div className="border-gold absolute bottom-0 left-0 right-0 h-1 border-b-2" />
                    ) : null}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['years'] + ' m-auto flex	flex-col'}>
        <div className="flex w-max justify-center gap-4 place-self-center self-center	justify-self-center">
          {initialYears.map((year, index) => {
            return (
              <button
                key={index}
                onClick={(e) => toggleYear(year)}
                className={
                  (years.includes(year) ? 'text-gray border-cyan border-b-2' : 'text-gray-500') +
                  ' border-b-2 border-transparent pb-3'
                }
              >
                {year}
              </button>
            );
          })}
        </div>
        {/* TODO: FIX ME PLS NO METHOD call without html in DOM */}
        {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
        <div className="flex-inline mb-5 w-32 justify-end	place-self-end self-end justify-self-end">
          {smallerMd && selectedView.name === 'Liste' ? setSelectedView({ name: 'Kacheln' }) : null}
          <Listbox value={selectedView} onChange={setSelectedView}>
            <div className="relative mt-1 mb-2 hidden md:block">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="block truncate">{selectedView.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {viewOptions.map((viewOption, viewIdx) => (
                    <Listbox.Option
                      key={viewIdx}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                        }`
                      }
                      value={viewOption}
                    >
                      {({ selected }) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                            {viewOption.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>

      <div className={'grid ' + (selectedView.name === 'Kacheln' ? 'gap-16 md:grid-cols-3' : 'grid-cols-1 gap-8')}>
        {filteredPosts &&
          filteredPosts.length > 0 &&
          filteredPosts
            .slice(alreadyShownPosts, alreadyShownPosts + postsPerPage)
            .map((post, id) => (
              <CardWithMeta
                viewOption={selectedView.name}
                className="flex justify-center"
                key={id}
                content={post.content}
                full_slug={post.full_slug}
                first_published_at={post.first_published_at}
                titleMaxLength={82}
                descMaxLength={120}
                isImageVisible={true}
                isMetaVisible={true}
                isCategoryVisible={true}
                isButtonVisible={false}
                imagePosition={selectedView.name === 'Kacheln' ? 'top' : 'left'}
              />
            ))}
      </div>
      <ReactPaginate
        className={styles['postsPagination'] + ' mt-5'}
        previousLabel={'Zurück'}
        nextLabel={'Vor'}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={'paginationBttns'}
        previousLinkClassName={'previousBttn'}
        nextLinkClassName={'nextBttn'}
        disabledClassName={'paginationDisabled'}
        activeClassName={styles['paginationActive']}
        forcePage={pageNumber}
      />
    </div>
  );
};
